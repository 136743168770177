<template>
  <a v-if="isLoading || tireaUrl" :href="tireaUrl" target="_blank">
    <v-btn color="primary" outlined :loading="isLoading" :disabled="isLoading" depressed>{{
        $t('tirea.access')
      }}</v-btn>
  </a>
</template>
<script>
import {useAccessToTirea} from "@/Tirea/AccessToTirea/use/useAccessToTirea";

export default {
  name: "AccessToTireaButton",
  props: {
    collegiateNumber: {
      type: String,
      default: null
    },
    professionalId: {
      type: String,
      default: null
    },
    patientId: {
      type: String,
      default: null
    }
  },
  setup() {
    const {
      tireaUrl,
      isLoading,
      retrieveAccessTireaUrl
    } = useAccessToTirea();

    return {
      tireaUrl,
      isLoading,
      retrieveAccessTireaUrl
    };
  },

  created() {
    this.retrieveAccessTireaUrl(this.collegiateNumber, this.professionalId, this.patientId);
  }
}
</script>

<style lang="scss">
.age-group-selectors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
</style>
