<template>
  <div class="pt-2">
    {{ $t('errors.collegiate_not_allow_to_prescribe') }}
    <div class="actions">
      <v-btn color="primary" depressed class="white--text" @click="goToProfile">
        {{ $t('common.go_to_profile') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { accountNamePaths } from '@/router/routes';

export default {
  name: 'CollegiateNotAllowToPrescribeMsg',
  methods: {
    goToProfile() {
      this.$router.push({ name: accountNamePaths.profile });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
