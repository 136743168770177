<template>
  <div>
    <div class="d-flex justify-center pt-2">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div class="pt-5">
      <b>{{ $t('prescriptions.checking_collegiate_number_1') }}</b>
      {{ $t('prescriptions.checking_collegiate_number_2') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckingColegiateNumber',
};
</script>
