<template>
  <div class="master-formula-form">
    <v-icon class="icon close mf-close" @click="$emit('remove')">
      mdi-close
    </v-icon>

    <div class="mf-options">
      <p />
      <!-- <v-switch v-model="value.narcotic" :label="$t('recipe.narcotic')" color="primary" /> -->
      <v-switch v-model="value.psychotropic" :label="$t('recipe.psychotropic')" color="primary" />
    </div>

    <v-textarea
      v-model="value.composition"
      filled
      outlined
      class="mx-1"
      rows="3"
      :label="$t('recipe.composition') + ' *'"
      :placeholder="$t('recipe.composition')"
      :error="fieldErrors.composition"
    />

    <div class="mf-section">
      <option-selector
        v-model="value.pharmaceuticalForm"
        :label="$t('recipe.pharmaceuticalForm')"
        :placeholder="$t('recipe.pharmaceuticalForm')"
        :items="pharmaceuticalFormOptions"
      />

      <option-selector
        v-model="value.administrationWay"
        :label="$t('recipe.administrationWay')"
        :placeholder="$t('recipe.administrationWay')"
        :items="administrationWayOptions"
      />
    </div>

    <v-text-field
      v-model="value.dosage"
      name="dosage"
      :label="$t('recipe.dosage')"
      :error="fieldErrors.dosage"
      maxlength="50"
      counter
    />

    <div class="mf-section">
      <posology-field
        :amount="value.takingAmount"
        :unit="value.takingUnit"
        :error="fieldErrors.takingUnit"
        @update="setTaking($event)"
      />

      <frecuency-field :amount="value.frecuencyAmount" :unit="value.frecuencyUnit" @update="setFrecuency($event)" />

      <duration-field :amount="value.durationAmount" :unit="value.durationUnit" @update="setDuration($event)" />
    </div>

    <v-textarea
      v-model="value.observations"
      filled
      outlined
      rows="3"
      :label="$t('recipe.observations')"
      :placeholder="$t('recipe.observations')"
    />

    <v-textarea
      v-model="value.pharmacistWarnings"
      filled
      outlined
      rows="3"
      :label="$t('recipe.pharmacist_warnings')"
      :placeholder="$t('recipe.pharmacist_warnings')"
    />
  </div>
</template>

<script>
import OptionSelector from '@/components/treatments/MasterFormulaForm/OptionSelector';
import PosologyField from '@/components/treatments/MasterFormulaForm/PosologyField';
import FrecuencyField from '@/components/treatments/MasterFormulaForm/FrecuencyField';
import DurationField from '@/components/treatments/MasterFormulaForm/DurationField';

export default {
  components: {
    OptionSelector,
    PosologyField,
    FrecuencyField,
    DurationField,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        composition: '',
        narcotic: false,
        psychotropic: false,
        dosage: '',
        pharmaceuticalForm: 1,
        administrationWay: 1,
        takingAmount: 1,
        takingUnit: '',
        frecuencyAmount: 1,
        frecuencyUnit: null,
        durationAmount: 1,
        durationUnit: null,
        observations: '',
        pharmacistWarnings: '',
      }),
    },

    pharmaceuticalFormOptions: {
      type: Array,
      required: false,
      default: () => [],
    },

    administrationWayOptions: {
      type: Array,
      required: false,
      default: () => [],
    },

    fieldErrors: {
      type: Object,
      required: true,
    },
  },

  watch: {
    value: {
      handler(newValue) {
        this.$emit('input', newValue);
      },

      deep: true,
    },
  },

  methods: {
    setTaking(taking) {
      this.value.takingAmount = taking.amount;
      this.value.takingUnit = taking.unit;
    },

    setFrecuency(frecuency) {
      this.value.frecuencyAmount = frecuency.amount;
      this.value.frecuencyUnit = frecuency.unit;
    },

    setDuration(duration) {
      this.value.durationAmount = duration.amount;
      this.value.durationUnit = duration.unit;
    },
  },
};
</script>

<style lang="scss" scoped>
.master-formula-form {
  position: relative;
  padding-top: 1.5rem;
}

.mf-close {
  position: absolute;
  top: 10px;
  right: 0;
}

.mf-options {
  display: flex;
  justify-content: flex-end;

  > div:not(:first-child) {
    margin-left: 2rem;
  }
}

.mf-section {
  display: flex;

  > div:not(:first-child) {
    margin-left: 2rem;
  }
}

@media (max-width: 740px) {
  .mf-section {
    display: block;

    > div:not(:first-child) {
      margin-left: 0;
    }
  }
}
</style>
