import i18n from '../../engine/framework/modules/i18n';

export const getPrescriptionStatus = id => {
  const options = {
    1: i18n.t('recipe.pending'),
    2: i18n.t('recipe.emitted'),
    3: i18n.t('recipe.canceled'),
    4: i18n.t('recipe.expired'),
    5: i18n.t('recipe.finished'),
  };
  return options[id];
};

export const getPrescriptionStatusCode = text => {
  const options = {
    PENDING: 1,
    EMITTED: 2,
    CANCELED: 3,
    EXPIRED: 4,
    FINISHED: 5,
  };
  return options[text];
};
