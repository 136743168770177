<template>
  <v-toolbar tabs flat color="white" class="create_prescription-box">
    <v-toolbar-title>
      {{ $t('recipe.prescriptions') }}
    </v-toolbar-title>
    <v-spacer />
    <span
      :title="isValidCollegiateNumber ? $t('recipe.create_prescription') : $t('errors.incorrect_collegiate_number')"
      class="prescription-btn btn-desktop"
    >
      <v-btn
        :disabled="!isValidCollegiateNumber"
        :color="custom_template ? 'primary' : 'info'"
        depressed
        @click.native.stop
        @click="$emit('newPrescription')"
      >
        <v-icon>mdi-plus</v-icon>{{ $t('recipe.create_prescription') }}</v-btn
      >
    </span>
    <v-btn
      class="mx-2 btn-mobile"
      fab
      dark
      small
      :color="custom_template ? 'primary' : 'info'"
      @click="$emit('newPrescription')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'PrescriptionTitle',
  props: {
    isValidCollegiateNumber: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
  }),
};
</script>

<style>
.btn-mobile {
  display: none;
}

@media (max-width: 575px) {
  .create_prescription-box {
    height: 65px;
  }
  .btn-mobile {
    display: inline-block;
  }
  .btn-desktop {
    display: none;
  }
}

@media (min-width: 576px) {
  .btn-desktop {
    display: inline-block;
  }
}
</style>
