<template>
  <div class="textarea-field">
    <p class="textarea-field__label">{{ label }} {{ required ? '*' : '' }}</p>
    <v-textarea
      v-model="inputValue"
      outlined
      no-resize
      :placeholder="
        maxLength
          ? $t('common.max_n_characters', {
              chars: maxLength,
            })
          : null
      "
      :error-messages="validationError"
    >
      <template v-if="validationError.length" v-slot:append>
        <div v-if="validationError.length" class="img-svg__container textarea-field__error-icon">
          <ErrorIcon />
        </div>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import ErrorIcon from "@/assets/icons/error.svg";

export default {
  name: 'DTextareaField',
  components: {ErrorIcon},
  props: {
    value: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$danger: #eb445a;

.textarea-field {
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__error-icon {
    width: rem(22);
  }

  & .v-text-field__details {
    padding: 0 !important;
    font-size: rem(12);
    font-weight: 500;
    color: $danger;
  }

  & textarea {
    overflow: hidden;
  }
}
</style>
