import {computed, ref} from 'vue';
import {getVideoconsultations} from "@/services/videoconsultation";

export function useVideoconsultationSelector() {
  const videoConsultations = ref([]);
  const selected = ref(null);

  const isLoadingVideoconsultations = ref(true);
  const isNetworkError = ref(false);

  const retrieveVideoconsultations = async (patientId, professionalId) => {
    try {
      isNetworkError.value = false;
      isLoadingVideoconsultations.value = true;
      videoConsultations.value = await getVideoconsultations(patientId, professionalId);
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingVideoconsultations.value = false;
    }
  };

  const select = (videoconsultationId)=> {
    selected.value = videoconsultationId;
  };

  const unSelect = () => {
    selected.value = null;
  };

  const isSelected = (videoconsultationId)=> {
    return selected.value === videoconsultationId;
  };

  const empty = computed(() => {
    return videoConsultations.value.length === 0 && !isLoadingVideoconsultations.value;
  });

  return {
    videoConsultations,
    isLoadingVideoconsultations,
    isNetworkError,
    retrieveVideoconsultations,
    selected,
    isSelected,
    select,
    unSelect,
    empty,
  };
}
