import { ref } from 'vue';
import { getTemplates } from '@/services/parapharmacy';

export function useParapharmacyTemplatesSelector() {
  const templates = ref([]);
  const currentTemplate = ref(null);

  const isLoadingTemplates = ref(false);
  const isLoadingCurrentTemplate = ref(false);
  const isNetworkError = ref(false);

  const retrieveTemplates = async professionalId => {
    try {
      isNetworkError.value = false;
      isLoadingTemplates.value = true;
      currentTemplate.value = null;
      const response = await getTemplates(professionalId);
      templates.value = response.data;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingTemplates.value = false;
    }
  };

  return {
    templates,
    currentTemplate,
    isLoadingTemplates,
    isLoadingCurrentTemplate,
    isNetworkError,
    retrieveTemplates,
  };
}
