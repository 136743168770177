<template>
  <div id="mensajeria">
    <form v-if="showListMessages" class="form_mensajeria">
      <v-toolbar flat>
        <v-spacer />
        <v-btn data-test="patient-edit-button-new-message" v-if="messagingPermission" color="primary" depressed dark @click.stop="openNewMessageDialog">
          {{ $t('mensajeria.nuevo_mensaje') }}
        </v-btn>
      </v-toolbar>
      <v-layout wrap>
        <v-flex sm12 lg12>
          <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel v-for="(mensajeria, i) in mensajerias" :key="i" class="mb-4">
              <v-expansion-panel-header>
                <h4 v-if="i < 2 && mensajeria.length > 0" class="header_mensajes">
                  <v-badge right color="red lighten-1">
                    <span slot="badge">{{ mensajeria.length }}</span>
                    <span>{{ titulo[i] }}</span>
                  </v-badge>
                </h4>
                <h4 v-else>{{ titulo[i] }}</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-list two-line>
                    <div v-if="mensajeria.length === 0" class="text-center grey--text pa-5">
                      {{ mensaje_sin[i] }}
                    </div>
                    <template v-for="(item, index) in mensajeria">
                      <v-list-item :key="item.title" ripple @click="showDetail(index, i)">
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>{{ item.fullName }}</strong>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ item.mensaje }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>{{ item.fecha }}</v-list-item-action-text>
                          <v-icon v-if="item.tiene_adjuntos" color="blue lighten-1">
                            mdi-paperclip
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index + 1 < mensajeria.length" :key="index" />
                    </template>
                  </v-list>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </form>
    <v-container v-if="!showListMessages">
      <mensaje
        :mail="mail"
        :loading-consultation="loadingConsultation"
        @clickedVolver="onVolverAtrasChild"
        @clickedRespuesta="onRespuestaChild"
        @clickedAtendida="onAtendidaChild"
        @documentDeleted="showDetail(selected.index, selected.type)"
      />
      <v-progress-linear v-if="loadingConsultation" indeterminate color="primary" />
    </v-container>
    <v-dialog v-model="dialog_nuevomensaje" persistent max-width="640px" scrollable>
      <nuevomensaje
        :pacientes="pacientes"
        :pack="{}"
        :specialties="specialties"
        @clickedVolver="dialog_nuevomensaje = false"
        @clickedNuevo="onNuevoChild"
      />
    </v-dialog>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import Mensaje from '@/components/ehealth/mensajeria/Mensaje.vue';
import Nuevomensaje from '@/components/ehealth/mensajeria/Nuevo.vue';
import { getHeader, mensajeLeidoUrl, pacientesUrl } from '@/config/config';

export default {
  name: 'Mensajeria',
  components: {
    Mensaje,
    Nuevomensaje,
  },
  props: {
    idpaciente: {
      type: String,
      default: '',
    },
    mensajerias: {
      type: Array,
      default: () => [],
    },
    pacientes: {
      type: Array,
      default: () => [],
    },
    specialties: {
      type: Array,
      default: () => [],
    },
    threadIdSelected: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      dialog_nuevomensaje: false,
      fecha_actual: new Date(),
      snackbar: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      titulo: [],
      mensaje_sin: [],
      showListMessages: true,
      mail: {},
      selected: {
        type: 0,
        index: 0,
      },
      panels: [...Array(this.mensajerias.length).keys()],
      loadingConsultation: false,
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      messagingPermission:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['messagingPermission'] === 1,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  watch: {
    threadIdSelected() {
      let found = false;
      for (let i = 0; i < 3 && !found; i++) {
        this.mensajerias[i].forEach(function(element, index) {
          if (!found && element.id === parseInt(this.threadIdSelected)) {
            this.showDetail(index, i);
            found = true;
          }
        }, this);
      }
    },
  },
  created() {
    this.titulo[0] = this.$t('mensajeria.pendientes');
    this.titulo[1] = this.$t('mensajeria.abiertos');
    this.titulo[2] = this.$t('mensajeria.cerrados');
    this.mensaje_sin[0] = this.$t('mensajeria.sin_mensajes_pendientes');
    this.mensaje_sin[1] = this.$t('mensajeria.sin_mensajes_abiertos');
    this.mensaje_sin[2] = this.$t('mensajeria.sin_mensajes_atendidos');
  },
  methods: {
    showDetail(index, type) {
      this.selected.type = type;
      this.selected.index = index;
      this.showListMessages = false;
      this.loadingConsultation = true;
      this.mail.mensajes = [];
      this.$http
        .get(pacientesUrl + '/' + this.idpaciente + '/messages/' + this.mensajerias[type][index].id, {
          headers: getHeader(),
        })
        .then(response => {
          this.mail = response.data;

          this.$http
            .post(
              mensajeLeidoUrl,
              {
                id: this.authUser.id,
                mensajeria_id: this.mail.id,
              },
              { headers: getHeader() }
            )
            .then(response => {
              if (response.status === 200 && response.data.estado === 1) {
                this.mail.leido = 1;

                if (this.selected.type === 0) {
                  this.mensajerias[1].push(this.mail);
                  this.mensajerias[0].splice(this.selected.index, 1);
                }
              }
            });
        })
        .catch(err => {
          this.$log.error(err);
          this.showListMessages = true;
        })
        .finally(() => {
          this.loadingConsultation = false;
        });
    },

    onVolverAtrasChild(valor) {
      this.showListMessages = valor;
    },

    onRespuestaChild(respuesta) {
      this.mail.mensajes.push(respuesta.mensaje);
      this.mensajerias[this.selected.type][this.selected.index].mensajes.push(respuesta.mensaje);
      if (respuesta.tiene_adjunto === '1') {
        this.showDetail(this.selected.index, this.selected.type);
      }
      if (this.selected.type === 0) {
        this.mensajerias[1].push(this.mail);
        this.mensajerias[0].splice(this.selected.index, 1);
      }
    },
    onNuevoChild(respuesta) {
      this.mensajerias[1].push(respuesta.mensaje);
      this.alerta.texto =
        respuesta.estado === '1'
          ? this.$t('mensajeria.enviado_correctamente')
          : this.$t('mensajeria.enviado_correctamente_error_adjunto');
      this.alerta.color = respuesta.estado === '1' ? 'success' : 'warning';
      this.snackbar = true;
      this.dialog_nuevomensaje = false;
    },
    onAtendidaChild() {
      this.mensajerias[2].unshift(this.mail);
      this.mensajerias[this.selected.type].splice(this.selected.index, 1);
      this.showListMessages = true;
      this.alerta.texto = this.$t('mensajeria.atendido_correctamente');
      this.alerta.color = 'success';
      this.snackbar = true;
    },

    openNewMessageDialog() {
      this.dialog_nuevomensaje = this.messagingPermission;
    },
  },
};
</script>

<style scoped>
.form_mensajeria {
  padding: 10px;
}
</style>
