<template>
  <div class="option-selector">
    <v-autocomplete
      :value="value"
      :items="items"
      :placeholder="placeholder"
      :label="label"
      item-text="name"
      item-value="id"
      :loading="loading"
      outlined
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.option-selector {
  width: 100%;
}
</style>
