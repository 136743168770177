<template>
  <div class="parapharmacy-product-selector__network-error-container">
    <div class="mb-4">{{ $t('parapharmacy_product_selector.network_error') }}</div>
    <div>
      <v-btn depressed color="primary" :disabled="loading" @click="retry">
        <v-icon left>
          mdi-refresh
        </v-icon>
        {{ $t('parapharmacy_product_selector.retry') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParapharmacyProductSelectorNetworkError',
  props: ['retry', 'loading'],
};
</script>

<style>
.parapharmacy-product-selector__network-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
