import { render, staticRenderFns } from "./Cuidar.vue?vue&type=template&id=1bacd11e"
import script from "./Cuidar.vue?vue&type=script&lang=js"
export * from "./Cuidar.vue?vue&type=script&lang=js"
import style0 from "./Cuidar.vue?vue&type=style&index=0&id=1bacd11e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports