<template>
  <div>
    <v-overlay :value="loading" :opacity="0.1">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <div class="dropdown-box-section">
      <v-toolbar tabs flat color="white">
        <v-layout xs12 wrap>
          <v-flex xs12 sm6>
            <v-toolbar-title class="mt-3">
              {{ title }}
              <v-chip v-if="confidential" small color="black" class="white--text mx-1">
                {{ $t('recipe.confidential') }}
              </v-chip>
              <span v-if="statusText !== ''" class="tags">
                <tag class="uppercase" :content="statusText" :color="color" :change-font-color="true" />
              </span>
            </v-toolbar-title>
            <v-spacer />
          </v-flex>
          <v-flex xs12 sm6>
            <span class="actions-btn">
              <span @click="show = !show">
                <span
                  :title="
                    isValidCollegiateNumber ? $t('common.emitir_receta') : $t('errors.incorrect_collegiate_number')
                  "
                  :class="'showEmitRecipeButton-' + showEmitRecipeButton"
                >
                  <v-btn
                    :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
                    :disabled="!isValidCollegiateNumber"
                    depressed
                    small
                    @click.native.stop
                    @click="$emit('emitRecipe')"
                  >
                    {{ $t('common.emitSignPrescription') }}
                  </v-btn>
                </span>

                <v-btn
                  v-if="hasDownload && status === prescriptionStatusCode('EMITTED')"
                  class="btn-emit-recipe mr-1"
                  :loading="printLoading"
                  depressed
                  small
                  @click.native.stop
                  @click="$emit('print')"
                >
                  {{ $t('recetas.imprimir') }}
                </v-btn>

                <v-btn
                  v-if="status !== prescriptionStatusCode('PENDING')"
                  :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
                  :disabled="!isValidCollegiateNumber"
                  :loading="loading"
                  depressed
                  small
                  @click.native.stop
                  @click="onRenew()"
                >
                  {{ $t('recipe.renew_prescription') }}
                </v-btn>

                <v-btn
                  v-if="status === prescriptionStatusCode('EMITTED') && repHomologation"
                  :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
                  :disabled="!isValidCollegiateNumber"
                  :loading="loading"
                  depressed
                  small
                  style="margin-left: 4px"
                  @click.native.stop
                  @click="onModify()"
                >
                  {{ $t('common.modify_recipe') }}
                </v-btn>

                <v-btn
                  v-if="status === prescriptionStatusCode('PENDING') && repHomologation"
                  :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
                  :disabled="!isValidCollegiateNumber"
                  :loading="loading"
                  depressed
                  small
                  style="margin-left: 4px"
                  @click.native.stop
                  @click="showDeleteDialog()"
                >
                  {{ $t('common.delete_recipe') }}
                </v-btn>

                <v-btn
                  v-if="status === prescriptionStatusCode('EMITTED') && repHomologation"
                  :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
                  :disabled="!isValidCollegiateNumber"
                  depressed
                  small
                  style="margin-left: 4px"
                  @click.native.stop
                  @click="$emit('cancel')"
                >
                  {{ $t('recipe.cancel_prescription') }}
                </v-btn>
                <v-btn
                  v-show="!repHomologation || !isPrescription"
                  icon
                  :title="titleEdit"
                  :class="'isEditable-' + isEditable"
                  color="grey"
                  @click.native.stop
                  @click="$emit('edit')"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  v-show="!repHomologation"
                  icon
                  :title="titleDelete"
                  :class="'isDelete-' + isEditable"
                  color="grey"
                  @click.native.stop
                  @click="$emit('delete')"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>

                <v-btn v-if="showArrowDown" icon class="expand" @click="$emit('openRecipe')">
                  <v-icon color="grey" :style="'font-size: xx-large;'">mdi-chevron-down</v-icon>
                </v-btn>
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-toolbar>

      <div class="dropdown-box-section-container" :class="{ show: show }">
        <div ref="content" class="dropdown-box-section-content">
          <slot />
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px" scrollable @click:outside="closeDeleteDialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('common.delete_recipe') }}</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <span>{{ $t('common.delete_recipe_confirm') }}</span>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="closeDeleteDialog"
          ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
          >
          <v-btn color="red darken-1" text @click.native="onDelete"
          ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getPrescriptionStatusCode } from '@/utils/getPrescriptionStatus';
import { mapGetters } from 'vuex';

export default {
  name: 'DropdownBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    statusText: {
      type: String,
      default: '',
    },
    color: {
      type: Number,
      required: false,
      default: 0,
    },
    titleDelete: {
      type: String,
      default: '',
      required: false,
    },
    titleEdit: {
      type: String,
      default: '',
      required: false,
    },
    confidential: {
      type: Boolean,
      default: false,
    },
    hasDownload: {
      type: Boolean,
      default: false,
      required: false,
    },
    showEmitRecipeButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    status: {
      type: Number,
      required: false,
      default: 0,
    },
    isEditable: {
      type: Boolean,
      default: true,
      required: false,
    },
    showArrowDown: {
      type: Boolean,
      default: true,
      required: false,
    },
    isValidCollegiateNumber: {
      type: Boolean,
      default: false,
    },
    isPrescription: {
      type: Boolean,
      default: false,
    },
    inactiveMedicines: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    printLoading: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      show: true,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
  },
  methods: {
    prescriptionStatusCode(status) {
      return getPrescriptionStatusCode(status);
    },
    onRenew() {
      if (this.inactiveMedicines) {
        this.$toast.error(this.$t('prescriptions.noActiveMedicines'));

        return;
      }
      this.$emit('renew');
    },
    onModify() {
      if (this.inactiveMedicines) {
        this.$toast.error(this.$t('prescriptions.noActiveMedicines'));

        return;
      }
      this.$emit('modify');
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    showDeleteDialog() {
      this.dialogDelete = true;
    },
    onDelete() {
      this.closeDeleteDialog();
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase;
}

.tags {
  vertical-align: text-bottom;
}

.hasDownload-false,
.showEmitRecipeButton-false,
.isEditable-false,
.isDelete-false {
  display: none;
}

.btn-emit-recipe {
  border: 1px solid #2196f3;
}

.actions-btn {
  white-space: nowrap;
  float: right;
}

@media (max-width: 575px) {
  .actions-btn {
    height: 4em;
  }
  /*.btn-emit-recipe {*/
  /*  width: 4em;*/
  /*  font-size: 10px;*/
  /*}*/
}

@media (min-width: 576px) and (max-width: 740px) {
  .actions-btn {
    height: 2.5em;
  }
}
</style>
