<template>
  <div class="parapharmacy-template-selector__container">
    <p class="parapharmacy-template-selector__label">{{ $t('parapharmacy_templates_selector.select_template') }}</p>
    <v-autocomplete
        v-model="currentTemplate"
        :items="templates"
        :loading="isLoadingTemplates"
        outlined
        hide-selected
        clearable
        item-text="name"
        :placeholder="$t('parapharmacy_templates_selector.select_option')"
        :label="
        isNetworkError
          ? $t('parapharmacy_templates_selector.network_error_short')
          : $t('parapharmacy_templates_selector.select_template')
      "
        item-value="id"
        :error-messages="validationError"
        :disabled="disabled"
    >
      <template slot="label">
        <div class="d-flex align-baseline">
          <template v-if="isNetworkError">
            <v-icon color="error" small class="mr-2">
              mdi-alert-circle
            </v-icon>
            <span class="error--text text-body-1">
              {{ $t('parapharmacy_templates_selector.network_error_short') }}
            </span>
          </template>
          <template v-else>
            <span>{{ $t('parapharmacy_templates_selector.select_option') }}</span>
          </template>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="py-1">
          <span class="parapharmacy-template-selector__item">
            {{ item.name }}
          </span>
        </div>
      </template>
      <template slot="no-data">
        <div class="parapharmacy-template-selector__no-data primary--text text-body-1 px-4 py-2">
          <ParapharmacyTemplateSelectorNetworkError
              v-if="isNetworkError"
              :retry="retrieveTemplates"
              :loading="isLoadingTemplates"
          />
          <div v-else>
            <v-progress-circular v-if="isLoadingTemplates" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
                isLoadingTemplates
                    ? $t('parapharmacy_templates_selector.loading_templates')
                    : $t('parapharmacy_templates_selector.not_found_templates')
              }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useParapharmacyTemplatesSelector } from '@/use/Parapharmacy/useParapharmacyTemplatesSelector';
import ParapharmacyTemplateSelectorNetworkError from './ParapharmacyTemplateSelectorNetworkError.vue';

export default {
  name: 'ParapharmacyTemplateSelector',
  components: {
    ParapharmacyTemplateSelectorNetworkError,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      templates,
      currentTemplate,
      isLoadingTemplates,
      isLoadingCurrentTemplate,
      isNetworkError,
      retrieveTemplates,
    } = useParapharmacyTemplatesSelector();

    return {
      templates,
      currentTemplate,
      isLoadingTemplates,
      isLoadingCurrentTemplate,
      isNetworkError,
      retrieveTemplates,
    };
  },

  created() {
    this.retrieveTemplates(this.authUser.user.professional_docline_api_id);
  },

  data: function() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  watch: {
    currentTemplate(val) {
      if (val) {
        this.$emit('templateSelected', val);
      }
    },
    isLoadingTemplates(val) {
      if (!val) {
        this.$emit('loadedTemplates', this.templates);
      }
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;

.parapharmacy-template-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__container {
    padding: 2.25rem 0 0 0;
  }

  &__label {
    font-size: rem(16);
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }
}

@media only screen and (min-width: 992px) {
  .parapharmacy-template-selector {
    &__container {
      padding: 2.25rem 0.5rem 0 0.5rem;
    }
  }
}

.parapharmacy-template-selector__container {
  &
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
