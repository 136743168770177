import { ref } from 'vue';
import { retrievePrescriptions as retrieveParapharmacyPrescriptions } from '@/services/parapharmacy';

const DEFAULT_ITEMS_PER_PAGE = 100;

export function useParapharmacyPatientPrescriptions() {
  const prescriptions = ref([]);

  const prescriptionsPage = ref(1);
  const isLoadingPrescriptions = ref(false);
  const isNetworkError = ref(false);

  const retrievePrescriptions = async (professionalId, patientId) => {
    try {
      isNetworkError.value = false;
      isLoadingPrescriptions.value = true;
      const response = await retrieveParapharmacyPrescriptions(
        professionalId,
        patientId,
        prescriptionsPage.value,
        DEFAULT_ITEMS_PER_PAGE
      );
      prescriptions.value = response.data;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingPrescriptions.value = false;
    }
  };

  return {
    prescriptions,
    isLoadingPrescriptions,
    isNetworkError,
    retrievePrescriptions,
  };
}
