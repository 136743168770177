<template>
  <div id="allergies">
    <allergy-modal
      :show-dialog="showDialog"
      :show-duplicates-warning="showDuplicatesWarning"
      :saving="saving"
      :allergy="currentAlergy"
      @handleEmit="handleValidateAllergy"
      @handleClose="handleCloseDialog"
    />

    <v-row justify="space-between" class="mb-4">
      <v-col cols="12" sm="6" md="3">
        <v-text-field data-test="patient-edit-search-alergy" v-model="tableSearch" clearable :label="$t('patient.searchAllergy')" />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="d-flex justify-end align-center">
        <v-btn data-test="patient-edit-button-add-alergy" depressed color="primary" dark class="mb-2" small @click="showDialog = true">
          <v-icon dark small>mdi-plus</v-icon>
          {{ $t('patient.add_allergy') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="allergies"
      :headers="headers"
      :items="allergies"
      :search="tableSearch"
      :hide-default-footer="allergies.length < 10"
      :footer-props="{
        itemsPerPageText: $t('datatable.rows_per_page'),
        itemsPerPageAllText: $t('datatable.all'),
        pageText: '',
      }"
      class="pb-4"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            <span>{{ props.item.name }}</span>
          </td>
          <td>
            <span>
              <template v-if="props.item.startedAt">{{ props.item.startedAt | formatDateLocaleDateString }}</template>
              <template v-else>
                <span class="font-italic grey--text lighten-2">{{ $t('patient.allergyStartDate') }}</span>
              </template>
            </span>
          </td>
          <td>
            <span>
              <template v-if="props.item.finishedAt">{{ props.item.finishedAt | formatDateLocaleDateString }}</template>
              <template v-else>
                <span class="font-italic grey--text lighten-2">{{ $t('patient.allergyEndDate') }}</span>
              </template>
            </span>
          </td>
          <td>
            <div class="d-flex justify-end ">
              <v-btn v-tooltip="$t('common.edit')" icon color="grey" @click="handleEdit(props.item)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn v-tooltip="$t('common.delete')" icon color="grey" @click="handleDelete(props.item)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>

      <template slot="no-data">
        <div class="py-12">
          <p>
            {{ $t('patient.no_allergies') }}
          </p>
          <div>
            <v-btn depressed color="primary" dense dark class="mt-6" @click="showDialog = true">
              <v-icon dark>mdi-plus</v-icon>
              {{ $t('patient.add_allergy') }}
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { apiPath, getHeader } from '@/config/config';
import AllergyModal from '@/components/ehealth/paciente/allergies/AllergyModal';
import AllergiesMixin from '@/mixins/Allergies';

export default {
  name: 'Allergies',

  components: {
    AllergyModal,
  },

  mixins: [AllergiesMixin],

  props: {
    patientId: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      isEditing: false,
      tableSearch: '',
      showDialog: false,
      showDuplicatesWarning: false,
      saving: false,
      currentAlergy: {},
      headers: [
        {
          text: this.$t('recipe.allergies'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('common.fecha_inicio'),
          value: 'started_at',
          align: 'left',
        },
        {
          text: this.$t('common.fecha_fin'),
          value: 'finished_at',
          align: 'left',
        },
        {
          text: this.$t('common.actions'),
          value: '',
          sortable: false,
          align: 'right',
        },
      ],
    };
  },

  mounted() {
    this.getPatientAllergies();
  },

  methods: {
    handleEdit(item) {
      this.currentAlergy = item;
      this.showDialog = true;
      this.isEditing = true;
    },

    async handleDelete(allergy) {
      (await this.$dialog.confirm({
        text: this.$t('common.confirmGenericMessage'),
        title: this.$t('common.confirm'),
        actions: {
          false: {
            color: 'grey',
            text: this.$t('common.no'),
          },
          true: {
            color: 'primary',
            text: this.$t('common.yes'),
          },
        },
      })) && this.deleteAllergy(allergy);
    },

    async deleteAllergy(allergy) {
      this.saving = true;

      try {
        await this.$http.delete(apiPath(`/patient/${this.patientId}/allergies`), {
          params: { id: allergy.id },
          headers: getHeader(),
        });
        this.$toast.success(this.$t('patient.removedAllergy'), { x: 'right', y: 'top' });
        this.getPatientAllergies();
      } catch (e) {
        this.$toast.error('Error', { x: 'right', y: 'top' });
        this.$log.error(e);
      } finally {
        this.handleCloseDialog();
      }
    },

    handleCloseDialog() {
      this.showDialog = false;
      this.saving = false;
      this.showDuplicatesWarning = false;
      this.isEditing = false;
      this.currentAlergy = {};
    },

    foundDuplicates(savingAllergy) {
      return this.allergies.some(item => item.name === savingAllergy.name);
    },

    handleValidateAllergy({ allergy, forceSave }) {
      if (this.foundDuplicates(allergy) && !forceSave && !this.isEditing) {
        this.showDuplicatesWarning = true;
        this.currentAlergy = allergy;
        return;
      }

      this.handleSaveAllergy(allergy);
    },

    async handleSaveAllergy(allergy, action = this.isEditing ? 'put' : 'post') {
      this.saving = true;

      try {
        await this.$http[action](apiPath(`/patient/${this.patientId}/allergies`), allergy, {
          headers: getHeader(),
        });
        this.$toast.success(this.$t('patient.addedAllergy'), { x: 'right', y: 'top' });
        this.getPatientAllergies();
      } catch (e) {
        this.$toast.error('Error', { x: 'right', y: 'top' });
        this.$log.error(e);
      } finally {
        this.handleCloseDialog();
      }
    },
  },
};
</script>
