<template>
  <div id="mensaje">
    <v-container>
      <v-row align="center" justify="center">
        <v-card flat max-width="1100" width="100%" class="transparent mb-5">
          <v-toolbar v-if="fromMessaging" flat class="transparent">
            <h1 class="headline">
              <a @click="cancelarVerMensaje">{{ $t('menu.messaging') }}</a> /
              {{ mail.fullName }}
            </h1>
            <v-spacer />
            <v-btn
              v-if="mail.cerrado === 0"
              depressed
              rounded
              text
              color="primary"
              @click="mostrarModalAtender(mail.id)"
            ><v-icon class="mr-2">mdi-check-circle</v-icon> {{ $t('mensajeria.marcar_como_atendida') }}</v-btn
            >
            <v-btn v-if="mail.cerrado === 1" depressed rounded text color="success"
            ><v-icon class="mr-2">mdi-check-circle</v-icon> {{ $t('mensajeria.atendido') }}</v-btn
            >
          </v-toolbar>
          <v-toolbar v-else flat class="transparent">
            <v-btn
              v-if="mail.cerrado === 0"
              depressed
              rounded
              text
              color="primary"
              @click="mostrarModalAtender(mail.id)"
            >
              <v-icon class="mr-2">mdi-check-circle</v-icon> {{ $t('mensajeria.marcar_como_atendida') }}
            </v-btn>
            <v-btn v-if="mail.cerrado === 1" depressed rounded text color="success">
              <v-icon class="mr-2">mdi-check-circle</v-icon> {{ $t('mensajeria.atendido') }}
            </v-btn>
            <v-spacer />
            <v-btn icon @click="$emit('clickedVolver', true)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-card-text class="pa-4">
              <template v-for="(sms, i) in mail.mensajes">
                <div :key="i" :class="[sms.emisor % 2 === 0 ? 'reverse' : '']" class="messaging-item layout row my-4">
                  <DAvatar
                    :text="`${authUser.user.name} ${authUser.user.surname}`"
                    :class="[sms.emisor % 2 === 0 ? '' : 'pink']"
                    size="40"
                    rounded
                    :two-letter="true"
                    :src="sms.emisor % 2 === 0 ? authUser.img : '/static/paciente_icon.png'"
                  />
                  <div :class="[sms.emisor % 2 === 0 ? 'column mx-2 msj-rta' : 'column mx-2 msj']">
                    <div class="text-r">
                      <p style="white-space: pre-wrap;" :class="sms.emisor % 2 === 0 ? 'texto_profes' : 'texto_pacien'">
                        {{ sms.texto }}
                      </p>
                      <p :class="sms.emisor % 2 === 0 ? 'fecha_profes' : 'fecha_pacien'">
                        <small>{{ sms.fecha }}</small>
                      </p>
                    </div>
                  </div>
                  <v-spacer />
                </div>
              </template>
              <div v-if="mail.tiene_adjuntos">
                <v-divider class="my-4" />
                <div class="title grey--text text--darken-1">
                  {{ $t('mensajeria.archivos_adjuntos') }}
                </div>
                <v-list>
                  <v-list-item v-for="(adjunto, index) in mail.adjuntos" :key="adjunto.file" class="item_adjunto">
                    <v-list-item-avatar v-if="!adjunto.edit" @click="openDocument(adjunto)">
                      <v-avatar
                        v-if="adjunto.icon === 'doc.png' || adjunto.icon === 'xls.png' || adjunto.icon === 'ppt.png'"
                        :class="adjunto.iconClass"
                        size="40"
                      >
                        <img
                          style="width: 26px; height: 26px; border-radius: 0;"
                          :src="'/static/' + adjunto.icon"
                          alt="icon"
                        />
                      </v-avatar>
                      <v-icon v-else :class="adjunto.iconClass">{{ adjunto.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="!adjunto.edit" @click="openDocument(adjunto)">
                      <v-list-item-title>{{ adjunto.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ adjunto.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="adjunto.edit">
                      <v-text-field
                        v-model="adjunto.nombreFile"
                        :label="$t('mensajeria.nombre_file')"
                        single-line
                        full-width
                        hide-details
                      />
                    </v-list-item-content>
                    <v-list-item-action v-if="!isEnabledChatV2Integration">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="disabledButton" icon ripple v-on="on" @click="editarNombreAdjunto(adjunto)">
                            <v-icon :color="!adjunto.edit ? 'grey' : 'green lighten-1'">{{
                                !adjunto.edit ? 'mdi-pencil' : 'mdi-content-save-outline'
                              }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ !adjunto.edit ? $t('mensajeria.modificar_name_file') : $t('common.save') }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action v-if="!adjunto.edit">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon ripple v-on="on" @click="eliminarDocumento(mail.paciente_id, adjunto.id, index)">
                            <v-icon color="grey">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('mensajeria.delete_file') }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action v-if="!adjunto.edit">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon ripple v-on="on" @click="openDocument(adjunto)">
                            <v-icon color="grey">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('mensajeria.ver_file') }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <div class="mail-reply--item">
                <div class="layout column">
                  <v-divider class="my-4" />
                  <transition name="slide-fade">
                    <div v-if="respuesta.adjuntado" class="my-3">
                      <v-alert outlined color="blue" icon="mdi-paperclip" :value="true">
                        {{ $t('mensajeria.mensaje_con_adjuntos') }}
                      </v-alert>
                    </div>
                  </transition>
                  <div style="text-align: center;">
                    <img
                      v-if="loading_envio"
                      class="text-center"
                      style="margin-top: -20px; margin-bottom: -20px"
                      src="/static/preloader.gif"
                      width="75px"
                      height="75px"
                      alt="loading"
                    />
                  </div>
                  <div v-if="mail.cerrado === 0 && !loadingConsultation">
                    <v-textarea
                      v-model="respuesta.texto"
                      outlined
                      filled
                      :placeholder="$t('mensajeria.enviar_nuevo_mensaje')"
                      full-width
                      hide-details
                      class="px-4"
                    />

                    <v-toolbar v-if="!loadingConsultation" dense flat>
                      <v-btn icon @click="adjuntarFile('responder')">
                        <v-icon>mdi-paperclip</v-icon>
                      </v-btn>
                      <input
                        id="file"
                        ref="file"
                        type="file"
                        accept="application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,
                        image/*, .pdf, video/mp4,video/x-m4v,video/avi, .pptx, .ppt"
                        style="display: none"
                        @change="onFileChange"
                      />

                      <v-spacer />
                      <v-btn text @click="cancelarVerMensaje"> {{ $t('common.volver_atras') }}</v-btn>

                      <v-btn depressed :disabled="disabledButton" color="primary" @click="enviarRespuesta">
                        {{ $t('mensajeria.enviar') }}
                        <v-icon class="ml-2">mdi-send</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-row>
    </v-container>
    <!-- Modal Atender Mensajeria -->
    <v-dialog v-model="dialog_confirm.dialog" dialog_confirm max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <v-toolbar-title> {{ dialog_confirm.titulo }} </v-toolbar-title>
        </v-card-title>
        <v-card-text>
          {{ dialog_confirm.texto }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialog_confirm.dialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            :color="dialog_confirm.btn_confirm_color + ' darken-1'"
            text
            @click="
              dialog_confirm.dialog = false;
              marcarComoAtendida();
            "
          ><v-icon>{{ dialog_confirm.btn_confirm_icono }}</v-icon> {{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fin Modal -->
    <!-- Dialog Salir sin guardar -->
    <v-dialog v-model="dialogSalirSinGuardar" width="500" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t('common.titulo_salir_sin_guardar') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialogSalirSinGuardar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-3" dark text @click="dialogSalirSinGuardar = false">{{
              $t('common.cancel')
            }}</v-btn>
          <v-btn color="primary" text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mensajeLeidoUrl,
  modificarFileUrl,
  nameEnv,
  sendMensajeUrl,
  eliminarFileUrl,
  cerrarMensajeUrl,
  getHeader,
  getHeaderMultimedia,
} from '@/config/config';
import DownloadFile from '@/mixins/DownloadFile';
import { mapActions, mapGetters } from 'vuex';
import DAvatar from '@/components/DAvatar.vue';

export default {
  name: 'Mensaje',
  components: {
    DAvatar,
  },
  mixins: [DownloadFile],
  props: {
    mail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loadingConsultation: {
      type: Boolean,
      default: false,
    },
    fromMessaging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogSalirSinGuardar: false,
      pageNext: {},
      esta_modificando: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      es_medyola: nameEnv === 'medyola',
      dialog: false,
      fecha_actual: new Date(),
      loading_envio: false,
      color_enviar: 'green',
      disabledButton: false,
      respuesta: {
        texto: '',
        tipo: '',
        mensajeria_id: 0,
        index: 0,
        adjuntado: false,
        file: '',
      },
      dialog_confirm: {
        dialog: false,
        tipo: '',
        titulo: '',
        texto: '',
        btn_confirm_texto: '',
        btn_confirm_icono: '',
        btn_confirm_color: '',
        paciente_id: 0,
        documento_id: 0,
        documento_indice: 0,
        id: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      isEnabledChatV2Integration: 'app/getChatV2IntegrationFeatureFlag',
    }),
  },
  created() {
    if (this.mail.leido === 0 && this.mail.cerrado === 0) {
      this.$http
        .post(mensajeLeidoUrl, { id: this.authUser.id, mensajeria_id: this.mail.id }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.mail.leido = 1;
          }
        });
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
      this.respuesta.file = this.$refs.file.files[0];
      const tam_max = this.authUser.id === 4612 || this.authUser.id === 7 ? 50000000 : 10000000;
      if (this.respuesta.file.size > tam_max) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.file_max_upload', { tam: tam_max / 100000 }),
        });
        this.respuesta.file = '';
      } else {
        this.respuesta.adjuntado = true;
      }
    },

    createImage(file) {
      const reader = new FileReader();

      reader.onload = e => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    cancelarVerMensaje() {
      if (this.respuesta.texto !== '' || this.respuesta.adjuntado) {
        this.dialogSalirSinGuardar = true;
      } else {
        this.$emit('clickedVolver', true);
      }
    },

    adjuntarFile(tipo) {
      if (tipo === 'nuevo') {
        this.$refs.filenew.click();
      } else {
        this.$refs.file.click();
      }
    },

    eliminarDocumento(paciente_id, documento_id, indice) {
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.tipo = 'eliminar';
      this.dialog_confirm.titulo = this.$t('mensajeria.delete_file');
      this.dialog_confirm.texto = this.$t('mensajeria.confirmar_delete_file');
      this.dialog_confirm.btn_confirm_icono = 'mdi-delete';
      this.dialog_confirm.btn_confirm_color = 'red';
      this.dialog_confirm.paciente_id = paciente_id;
      this.dialog_confirm.documento_id = documento_id;
      this.dialog_confirm.documento_indice = indice;
    },

    editarNombreAdjunto(file) {
      if (!this.disabledButton) {
        if (file.edit) {
          this.disabledButton = true;
          this.$http
            .post(
              modificarFileUrl,
              { id: this.authUser.id, mensajeria_id: this.mail.id, file: file },
              { headers: getHeader() }
            )
            .then(response => {
              const responseOK = response.status === 200 && response.data.estado === 1;
              this.showAlert({
                color: responseOK ? 'success' : 'error',
                icon: responseOK ? 'mdi-file' : 'mdi-alert-circle',
                message: responseOK ? this.$t('success.name_file_modificado') : this.$t('errors.try_again'),
              });
              this.disabledButton = false;
              file.edit = false;
              file.title = response.data.filename;
            });
        } else {
          file.edit = true;
        }
      }
    },

    enviarRespuesta() {
      if (this.respuesta.texto.length < 2) {
        this.showAlert({
          color: 'warning',
          icon: 'mdi-alert-circle',
          message: this.$t('mensajeria.add_texto_al_mensaje'),
        });
      } else if (!this.disabledButton) {
        this.disabledButton = true;
        this.loading_envio = true;
        this.color_enviar = 'blue-grey lighten-4';
        const formData = new FormData();
        formData.append('file', this.respuesta.file);
        formData.append('texto', this.respuesta.texto);
        formData.append('mensajeria_id', this.mail.id);
        formData.append('tiene_adjunto', this.respuesta.adjuntado);
        formData.append('id', this.authUser.id);
        this.$http.post(sendMensajeUrl, formData, { headers: getHeaderMultimedia() }).then(response => {
          const alert = {
            text: this.$t('mensajeria.error_enviando'),
            color: 'error',
          };
          if (response.status === 200 && (response.data.estado === '1' || response.data.estado === '2')) {
            alert.text =
              response.data.estado === '1'
                ? this.$t('mensajeria.enviado_correctamente')
                : this.$t('mensajeria.enviado_correctamente_error_adjunto');
            alert.color = response.data.estado === '1' ? 'success' : 'warning';
            this.$emit('clickedRespuesta', response.data);
          }
          this.showAlert({ color: alert.color, message: alert.text });
          this.color_enviar = 'green';
          this.loading_envio = false;
          this.disabledButton = false;
          this.respuesta.adjuntado = false;
          this.respuesta.texto = '';
          this.respuesta.file = '';
          this.respuesta.mensajeria_id = 0;
          this.respuesta.index = 0;
          this.image = '';
        });
      }
    },

    mostrarModalAtender(id) {
      this.dialog_confirm.tipo = 'atender';
      this.dialog_confirm.titulo = this.$t('mensajeria.marcar_como_atendida');
      this.dialog_confirm.texto = this.$t('mensajeria.confirm_atendida');
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.btn_confirm_icono = 'mdi-check';
      this.dialog_confirm.btn_confirm_color = 'blue';
      this.dialog_confirm.id = id;
    },

    marcarComoAtendida() {
      if (this.dialog_confirm.tipo === 'atender') {
        if (this.dialog_confirm.id > 0 && !this.disabledButton) {
          this.disabledButton = true;
          this.$http
            .post(
              cerrarMensajeUrl,
              { id: this.authUser.id, mensajeria_id: this.dialog_confirm.id },
              { headers: getHeader() }
            )
            .then(response => {
              if (response.status === 200 && response.data.estado === '1') {
                this.$emit('clickedAtendida', true);
              } else {
                this.showAlert({ color: 'error', message: this.$t('mensajeria.error_atendiendo') });
              }
              this.disabledButton = false;
            });
        }
      } else if (this.dialog_confirm.tipo === 'eliminar') {
        this.$http
          .post(
            eliminarFileUrl,
            {
              id: this.authUser.id,
              paciente_id: this.dialog_confirm.paciente_id,
              documento_id: this.dialog_confirm.documento_id,
            },
            { headers: getHeader() }
          )
          .then(response => {
            const responseOK = response.status === 200 && response.data.estado === 1;
            this.showAlert({
              color: responseOK ? 'success' : 'error',
              icon: responseOK ? 'mdi-delete' : 'mdi-alert-circle',
              message: responseOK ? this.$t('success.file_deleted') : this.$t('errors.try_again'),
            });
            this.loading = false;
            this.colorBotonSubir = 'blue';
            this.disabledButton = false;
            this.$emit('documentDeleted');
          });
      }
    },

    async openDocument(document) {
      await this.download({
        url: document.url ? document.url : document.file,
        name: document.name ? document.name : document.fileName,
        type: document.type ? document.type : document.fileType,
        open: true,
        openInNewTab: true,
        download: false,
      });
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.$emit('clickedVolver', true);
    },
  },
};
</script>

<style>
.tabs_mail {
  margin-top: -20px;
}
.msj-rta {
  float: right;
  background: #308fbc;
  clear: both;
  border-radius: 8px;
}
.msj {
  float: left;
  background: #607d8b;
  margin-left: -40px;
  border-radius: 8px;
}
.text-l {
  float: left;
  margin-left: 5px;
  padding-right: 10px;
  color: #fff;
}
.text-r {
  float: right;
  margin-right: 5px;
  padding-left: 10px;
  text-align: right;
  color: #fff;
}
.text > p:first-of-type {
  width: 100%;
  margin-top: 0;
  margin-bottom: auto;
  line-height: 18px;
  font-size: 15px;
}
.text > p:last-of-type {
  width: 100%;
  color: #bfc8cc;
  padding: 0px;
  margin-bottom: -7px;
  margin-top: -5px;
  font-size: 14px;
}
.fecha_pacien,
.texto_pacien {
  text-align: left;
  margin: 0px;
}
.fecha_profes,
.texto_profes {
  text-align: right;
  margin: 0px !important;
}
.texto_profes,
.texto_pacien {
  font-size: 17px !important;
}
.avatar {
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
  0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.01s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.boton_atras {
  z-index: 3;
  position: absolute;
}
.item_adjunto:hover {
  background: rgba(182, 218, 255, 0.25);
}

.field-message {
  margin: 0 16px;
}

.field-message > .v-input__control > .v-input__slot:before,
.field-message > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.v-btn-space {
  margin-right: 8px;
}
</style>
