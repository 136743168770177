<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-dialog
    v-model="show"
    scrollable
    max-width="640"
    class="pincode-dialog"
    content-class="two-fa-pincode-dialog"
    @click:outside="handleCancel"
    @keydown.esc="handleCancel"
    @keydown.enter="handleAccept"
  >
    <v-card class="px-6 py-6">
      <!-- title -->
      <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
        {{ title }}
        <v-spacer />
        <v-btn v-tooltip="$t('common.close')" class="pincode-dialog__close-button" icon @click="handleCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- body -->
      <p class="text-body-1 text--secondary" v-html="text" />
      <div class="pincode-dialog__input" :class="{ 'pincode-dialog__input--has-errors': invalidCodeError }">
        <pincode-input ref="pincode" v-model="code" :length="codeLength" />
        <span v-if="invalidCodeError" class="pincode-dialog__error mt-3">{{ invalidCodeError }}</span>
      </div>
      <!-- actions -->
      <v-card-actions class="mt-12">
        <action-buttons
          :accept-text="acceptButtonText"
          :cancel-text="$t('common.cancel')"
          :disable-accept-button="disableAcceptButton"
          @on-click:accept="handleAccept"
          @on-click:cancel="handleCancel"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
import ActionButtons from '@/components/ui/ActionButtons';
import { TWO_FA_CODE_LENGTH } from '@/config/constants';

export default {
  name: 'TwoFaPincodeDialog',

  components: {
    ActionButtons,
    PincodeInput,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default() {
        return this.$t('2fa.title');
      },
    },
    text: {
      type: String,
      default() {
        return this.$t('2fa.enterCodePromptText');
      },
    },
    acceptButtonText: {
      type: String,
      default() {
        return this.$t('2fa.disable');
      },
    },
  },

  data() {
    return {
      code: '',
      codeLength: TWO_FA_CODE_LENGTH,
      invalidCodeError: false,
      invalidCodeText: this.$t('2fa.invalidCodeError'),
      disableAcceptButton: false,
      pincodeInputEl: '.vue-pincode-input',
      domObserverEl: '.two-fa-pincode-dialog',
    };
  },

  watch: {
    show(val) {
      if (!val) return;
      this.setAutofocus();
    },
  },

  methods: {
    setAutofocus() {
      this.$nextTick(_ => {
        const pincode = this.$refs?.pincode?.$el;
        if (!pincode) return;

        const input = pincode.querySelector(this.pincodeInputEl);
        setTimeout(_ => input.focus(), 500);
      });
    },

    handleAccept() {
      if (this.code.length < this.codeLength) return;

      this.$emit('pincode', { code: this.code });
    },

    handleCancel() {
      this.$emit('close');
      this.code = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.pincode-dialog {
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ::v-deep input {
      @extend %two-fa-input !optional;
    }

    &--has-errors {
      ::v-deep input {
        border-color: #ff5454;
      }
    }
  }

  &__error {
    color: #ff5454;
  }

  &__close-button {
    position: absolute;
    top: rem(4);
    right: rem(4);

    @include breakpoint(md) {
      position: relative;
    }
  }
}
</style>
