import {
  getHeader,
  electronicPendingPrescription,
  electronicPendingPrescriptionBatch,
} from '@/config/config';
import axios from 'axios';

export const deletePendingPrescription = async (prescriptionId) => {
  const response = await axios.delete(electronicPendingPrescription(prescriptionId), {
    headers: getHeader(),
  });

  return response.data;
};

export const deletePendingPrescriptionsBatch = async (prescriptions) => {
  const response = await axios({
    method: 'delete',
    url: electronicPendingPrescriptionBatch,
    data: {
      prescriptions,
    },
    headers: getHeader(),
  });
  return response.data;
};
