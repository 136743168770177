<template>
  <div class="master-formula-section">
    <p class="text-h5 mb-3">{{ $t('recipe.master_formulas') }}</p>

    <div class="master-formula-section-items">
      <master-formula-form
        v-for="(item, index) in items"
        :key="index"
        v-model="items[index]"
        :pharmaceutical-form-options="pharmaceuticalFormOptions"
        :administration-way-options="administrationWayOptions"
        :field-errors="fieldErrors[index]"
        @remove="remove(index)"
      />
    </div>

    <div class="master-formula-section-btn-add" :style="`color:${btnAddColor}`" @click="add">
      {{ $t('recipe.add_master_formula') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getHeader, getPharmaceuticalFormsUrl, getAdministrationWaysUrl } from '@/config/config';
import MasterFormulaForm from '@/components/treatments/MasterFormulaForm/MasterFormulaForm';
import { reorderOptions } from '@/store/helpers';

export default {
  components: {
    MasterFormulaForm,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pharmaceuticalFormOptions: [],
      administrationWayOptions: [],
    };
  },

  computed: {
    ...mapState({
      btnAddColor: state => state.app.styles.themePrimary,
      fieldErrors: state => state.prescription.errors.masterFormulas,
    }),
  },

  async created() {
    if (this.items.length === 0) {
      this.add();
    }

    this.pharmaceuticalFormOptions = reorderOptions(await this.getOptions(getPharmaceuticalFormsUrl));
    this.administrationWayOptions = reorderOptions(await this.getOptions(getAdministrationWaysUrl));
  },

  methods: {
    ...mapActions('prescription', ['masterFormulasAddError', 'masterFormulasRemoveError']),

    getDefaultItem() {
      return {
        composition: '',
        narcotic: false,
        psychotropic: false,
        dosage: '',
        pharmaceuticalForm: 1,
        administrationWay: 10,
        takingAmount: 1,
        takingUnit: '',
        frecuencyAmount: 1,
        frecuencyUnit: null,
        durationAmount: 1,
        durationUnit: null,
        observations: '',
        pharmacistWarnings: '',
      };
    },

    async getOptions(url) {
      try {
        const response = await this.$http.get(url, { headers: getHeader() });

        return response.status === 200 && response.data ? response.data.data : [];
      } catch (e) {
        return [];
      }
    },

    add() {
      this.items.push(this.getDefaultItem());
      this.masterFormulasAddError();
    },

    remove(index) {
      this.items.splice(index, 1);
      this.masterFormulasRemoveError(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.master-formula-section {
  margin-bottom: 2rem;
}

.master-formula-section-items {
  margin: 0 0 1rem 0;

  > div:not(:first-child) {
    border-top: 1px solid #cccccc;
  }
}

.master-formula-section-btn-add {
  font-weight: bold;
  cursor: pointer;
}
</style>
