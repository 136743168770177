<template>
  <div :class="{ card__container: true, 'card--inverted': inverted }">
    <div v-if="title" class="card__header">
      <div class="card__title">{{ title }}</div>
      <div class="card__actions">
        <slot name="header-action" />
      </div>
    </div>
    <div :class="{ card__content: true, 'card--dense': dense }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DCard',
  props: {
    title: {
      type: String,
      default: null,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
$beige: #fff5d9;
$grey: #c6c6c6;
$greyLight: #f7f7f7;
$black: #212121;

.card {
  &__container {
    border-radius: 6px;
    border: $grey solid 1px;
    width: 100%;
    height: 100%;
  }
  &--inverted {
    border: none;
    background-color: $greyLight;
  }
  &__header {
    background-color: $beige;
    padding: 1rem 0.875rem;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__actions {
    position: relative;
    width: 100%;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    color: $black;
  }

  &__content {
    padding: 0.875rem;
    width: 100%;
    height: 100%;
  }

  &--dense {
    padding: 0;
  }
}
</style>
