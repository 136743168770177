<template>
  <div id="allergy-modal">
    <v-dialog v-model="showDialog" max-width="600px" scrollable persistent>
      <v-card>
        <v-card-title class="mt-2 mb-6">
          <span class="headline">{{ $t('patient.new_allergy') }}</span>
        </v-card-title>
        <v-card-text v-if="showDuplicatesWarning">
          <v-alert dense outlined type="error" :icon="false">
            {{ $t('patient.allergyExists') }}
          </v-alert>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-container grid-list-md class="px-0">
            <v-form ref="form">
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-select
                    data-test="patient-edit-allergy-to"
                    v-model="allergy.type"
                    filled
                    dense
                    outlined
                    auto
                    :items="allergiesTypes"
                    :label="`${$t('patient.allergy_to')}*`"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex xs12 sm6 md8>
                  <v-text-field
                    data-test="patient-edit-allergy-name"
                    v-if="allergy.type !== 'MEDICINE'"
                    v-model="allergy.name"
                    filled
                    dense
                    outlined
                    type="text"
                    class="mx-1"
                    :disabled="!allergy.type"
                    :label="`${$t('common.name')}*`"
                    :rules="ruleRequiredIfNotMedicine"
                  />
                  <active-ingredients-finder
                    v-else
                    :current-ingredient="allergy"
                    @activeIngredientSelected="activeIngredientSelected"
                  />
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-menu
                    ref="startedAtMenu"
                    v-model="startedAtMenu"
                    :close-on-content-click="false"
                    :return-value.sync="allergy.startedAt"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        data-test="patient-edit-allergy-started-at"
                        v-model="formattedStartedAt"
                        filled
                        dense
                        clearable
                        outlined
                        :label="$t('common.fecha_inicio')"
                        append-icon="mdi-calendar"
                        readonly
                        class="text-field-date"
                        v-on="on"
                        @click:clear="handleClearDate({ clearStartDate: true })"
                      />
                    </template>
                    <v-date-picker
                      ref="startedAtPicker"
                      v-model="startedAtDate"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      min="1900-01-01"
                      :max="new Date().toISOString().substr(0, 10)"
                      @input="$refs.startedAtMenu.save(startedAtDate)"
                    />
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6 class="finishedDate">
                  <v-menu
                    ref="finishedAtMenu"
                    v-model="finishedAtMenu"
                    :close-on-content-click="false"
                    :return-value.sync="allergy.finishedAt"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        data-test="patient-edit-allergy-finished-at"
                        v-model="formattedFinishedAt"
                        filled
                        dense
                        outlined
                        clearable
                        :label="$t('common.fecha_fin')"
                        append-icon="mdi-calendar"
                        readonly
                        class="text-field-date"
                        v-on="on"
                        @click:clear="handleClearDate({ clearStartDate: false })"
                      />
                    </template>
                    <v-date-picker
                      ref="finishedAtPicker"
                      v-model="finishedAtDate"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :min="startedAtDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      @input="$refs.finishedAtMenu.save(finishedAtDate)"
                    />
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-spacer />
          <v-btn data-test="patient-edit-alergy-cancel" color="grey darken-1" text @click="handleClose">{{ $t('common.cancel') }}</v-btn>
          <v-btn data-test="patient-edit-alergy-save" depressed color="primary" :loading="saving" @click="handleValidation">
            {{ showDuplicatesWarning ? $t('patient.saveDuplicateAllergy') : $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ActiveIngredientsFinder from '@/components/ehealth/paciente/allergies/ActiveIngredientsFinder';
import { formatDateLocaleDateString } from '@/filters';

export default {
  name: 'AllergyModal',

  components: { ActiveIngredientsFinder },

  props: {
    saving: {
      type: Boolean,
      default: false,
    },

    showDialog: {
      type: Boolean,
      default: false,
    },

    showDuplicatesWarning: {
      type: Boolean,
      default: false,
    },

    allergy: {
      type: Object,
      default: () => ({
        type: '',
        name: '',
        dcsa: null,
        startedAt: '',
        finishedAt: '',
      }),
    },
  },

  data() {
    return {
      newAllergy: {},
      startedAtMenu: false,
      formattedStartedAt: null,
      startedAtDate: null,
      finishedAtMenu: false,
      formattedFinishedAt: null,
      finishedAtDate: null,
      ruleRequired: [v => !!v || this.$t('common.obligatorio')],
      ruleRequiredIfNotMedicine: [v => (!!v && this.allergy.type === 'OTHER') || this.$t('common.obligatorio')],
    };
  },

  computed: {
    allergiesTypes() {
      return [
        { id: 'MEDICINE', name: vm.$t('recipe.active_ingredient') },
        { id: 'OTHER', name: vm.$t('common.otro') },
      ];
    },
  },

  watch: {
    startedAtDate() {
      this.formattedStartedAt = formatDateLocaleDateString(this.startedAtDate);
    },

    finishedAtDate() {
      this.formattedFinishedAt = formatDateLocaleDateString(this.finishedAtDate);
    },

    startedAtMenu(val) {
      val && setTimeout(() => (this.$refs.startedAtPicker.activePicker = 'YEAR'));
    },

    finishedAtMenu(val) {
      val && setTimeout(() => (this.$refs.finishedAtPicker.activePicker = 'YEAR'));
    },

    allergy: {
      immediate: true,
      deep: true,
      handler() {
        this.setExistingDates();
      },
    },
  },

  methods: {
    handleClearDate({ clearStartDate }) {
      const prop = clearStartDate ? 'startedAt' : 'finishedAt';
      this.allergy[prop] = null;
    },
    /**
     * set dates to local variables from allergy prop
     * needed in edit mode
     * @return {void}
     */
    setExistingDates() {
      this.startedAtDate = this.allergy.startedAt;
      this.finishedAtDate = this.allergy.finishedAt;
    },

    handleClose() {
      this.$emit('handleClose');
      this.resetLocalFormData();
    },

    handleValidation() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return;

      this.newAllergy = {
        ...this.allergy,
        ...this.newAllergy,
      };

      if (!this.newAllergy.id) {
        this.newAllergy.id = uuidv4();
      }

      this.$emit('handleEmit', { allergy: this.newAllergy, forceSave: this.showDuplicatesWarning });
      this.resetLocalFormData();
    },

    resetLocalFormData() {
      this.startedAtDate = '';
      this.finishedAtDate = '';
      this.newAllergy = {};
    },

    activeIngredientSelected(activeIngredient) {
      this.newAllergy.name = activeIngredient.text;
      this.newAllergy.dcsa = parseInt(activeIngredient.code);
    },
  },
};
</script>

<style>
.finishedDate {
  padding-right: 8px !important;
}
</style>
