<template>
  <div class="pdf-viewer">
    <div v-show="loading" class="pdf-viewer__loader">
      <v-progress-circular color="primary" indeterminate :size="60" :width="2"/>
    </div>
    <div
      v-show="!loading"
      ref="pdfViewer"
      class="pdf-viewer__content"
      :style="{
        cursor: isDragging ? 'grabbing' : 'grab',
        scrollSnapType: isDragging ? '' : '',
      }"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
    />
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import { getHeader } from '@/config/config';

function renderPage(page, pdfScale) {
  const desiredWidth = document.querySelector('.pdf-viewer .pdf-viewer__content').offsetWidth;

  const viewport = page.getViewport({ scale: pdfScale });
  const scale = desiredWidth / viewport.width;
  const scaledViewport = page.getViewport({ scale: scale });

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  document.querySelector('.pdf-viewer .pdf-viewer__content').innerHTML = '';
  document.querySelector('.pdf-viewer .pdf-viewer__content').appendChild(canvas);
  document.querySelector('.pdf-viewer .pdf-viewer__content').appendChild(document.createElement('br'));

  const outputScale = window.devicePixelRatio || 1;

  canvas.height = scaledViewport.height * outputScale;
  canvas.width = scaledViewport.width * outputScale;
  canvas.style.width = Math.floor(scaledViewport.width) + 'px';
  canvas.style.height = Math.floor(scaledViewport.height) + 'px';

  const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

  const renderContext = {
    canvasContext: context,
    transform: transform,
    viewport: scaledViewport,
  };
  page.render(renderContext);
}

function displayPage(pdf, num, scale) {
  pdf.getPage(num).then(function(page) {
    renderPage(page, scale);
  });
}

export default {
  name: 'PDFViewerExtended',

  props: {
    pdf: {
      type: String,
      required: true,
    },
    pdfScale: {
      type: Number,
      default: 1,
    },
  },

  data: function() {
    return {
      loading: true,
      pageNum: 1,
      shownPdf: null,
      isDragging: false,
    };
  },

  watch: {
    pdfScale() {
      this.showPdf(this.pdf);
    },
  },

  async created() {
    await this.showPdf(this.pdf);
  },

  methods: {
    async showPdf(url) {
      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');

      // const docInitParams = { data: this.pdf };

      const pdfLoadingTask = pdfjsLib.getDocument({
        url: url,
        httpHeaders: getHeader(),
      });
      const pdfDoc = await pdfLoadingTask.promise;

      displayPage(pdfDoc, pdfDoc.numPages, this.pdfScale);
      this.shownPdf = pdfDoc;
      this.loading = false;
    },

    /** @param {MouseEvent} ev */
    onMouseDown(ev) {
      this.cursorPos = [ev.pageX, ev.pageY];
      this.isDragging = true;

      window.addEventListener('mousemove', this.onMouseHold);
    },

    /** @param {MouseEvent} ev */
    onMouseUp(ev) {
      // console.log({ x: ev.clientX, y: ev.clientY });
      // console.log(this.$refs.pdfViewer.scrollLeft);
      // console.log(this.$refs.pdfViewer.scrollWidth);
      // console.log(this.$refs.pdfViewer.scrollWidth - this.$refs.pdfViewer.clientWidth);
      window.removeEventListener('mousemove', this.onMouseHold);
      this.isDragging = false;
    },

    /** @param {MouseEvent} ev */
    onMouseHold(ev) {
      ev.preventDefault();

      requestAnimationFrame(() => {
        const delta = [ev.pageX - this.cursorPos[0], ev.pageY - this.cursorPos[1]];

        this.cursorPos = [ev.pageX, ev.pageY];

        if (!this.$refs.pdfViewer) return;
        this.$refs.pdfViewer.scrollBy({
          left: -delta[0],
          top: -delta[1],
        });
      });
    },
  },
};
</script>

<style lang="scss">
$grey: #F1F1F1;

.pdf-viewer {
  height: 68vh;
  padding: rem(16) 0;
}

.pdf-viewer__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pdf-viewer__content {
  text-align: center;
  scroll-snap-align: start;
  overflow: hidden;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  height: 100%;
  width: 100%;

  border: solid 1px $grey;
  border-radius: 5px;

}

@media only screen and (min-width: 768px) {
  .pdf-viewer {
    padding: rem(32) rem(20) 0 rem(20);
  }
}
</style>
