<template>
  <v-container id="treatment-filters" fluid>
    <v-layout wrap>
      <v-flex xs12 class="container">
        <p :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
          {{ $t('recipe.filter_by_created_date') }}
          <v-icon v-if="isFiltering" class="icon close" @click="resetFilters()">mdi-close</v-icon>
        </p>
      </v-flex>
      <v-flex xs6 sm4 md3>
        <datepicker v-model="dateStart" class="item title-filter" :label="$t('recipe.from')" />
      </v-flex>
      <v-flex xs6 sm4 md3 class="options">
        <datepicker v-model="dateEnd" class="item title-filter" :label="$t('recipe.to')" />
      </v-flex>
      <v-flex xs12 sm6 md3>
        <v-btn class="btn_create" :color="appThemePrimary" dark @click="validateBeforeOpen">
          <v-icon>mdi-plus</v-icon>
          {{ $t('recipe.create_treatment_treat') }}
        </v-btn>
      </v-flex>
    </v-layout>

    <treatment-modal
      v-model="showTreatmentModal"
      :user="user_id"
      :patient="patient_id"
      :show-change-patient="false"
      @reloadTreatment="$emit('load-treatment')"
    />

    <v-dialog v-model="dialog_patient_document.dialog" max-width="500">
      <patient-document-msg :patient_id="dialog_patient_document.patient_id" @close="closePatientDocumentMsg" />
    </v-dialog>

    <v-dialog v-model="showCheckingCollegiate" max-width="400" persistent>
      <v-card>
        <v-card-text class="pa-4">
          <checking-collegiate-number />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCollegiateMsg" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h6 lighten-1">{{ $t('common.notice') }}</v-card-title>
        <v-card-text class="pa-4">
          <collegiate-msg @closeModal="showCollegiateMsg = false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTrialMsg" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h6 lighten-1">{{ $t('common.notice') }}</v-card-title>
        <v-card-text class="pa-4">
          <trial-msg @closeModal="showTrialMsg = false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCollegiateNumberCantEmmit" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h6 lighten-1">{{ $t('common.notice') }}</v-card-title>
        <v-card-text class="pa-4">
          <collegiate-not-allow-to-prescribe-msg @closeModal="showCollegiateNumberCantEmmit = false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import TreatmentModal from '@/components/treatments/TreatmentModal.vue';
import CollegiateMsg from '@/components/treatments/CollegiateMsg.vue';
import TrialMsg from '@/components/treatments/TrialMsg.vue';
import { haveValidCollegiateNumber, isValidDocument } from '@/utils';
import { checkCollegiateAllowPrescribe, getHeader, getPatientDetailUrl } from '@/config/config';
import PatientDocumentMsg from '@/components/treatments/PatientDocumentMsg';
import CollegiateNotAllowToPrescribeMsg from '@/components/treatments/CollegiateNotAllowToPrescribeMsg.vue';
import CheckingCollegiateNumber from './CheckingCollegiateNumber';

export default {
  name: 'TreatmentFilters',
  components: {
    TreatmentModal,
    CollegiateMsg,
    TrialMsg,
    PatientDocumentMsg,
    CollegiateNotAllowToPrescribeMsg,
    CheckingCollegiateNumber,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    dateStart: null,
    dateEnd: null,
    status: null,
    states: [],
    user_id: 0,
    showTreatmentModal: false,
    patient_id: 0,
    isFiltering: false,
    selectorDateElem: '#treatment-filters .js_clear input',
    isValidCollegiateNumber: false,
    collegiateNumberCanEmmit: false,
    showCollegiateMsg: false,
    showTrialMsg: false,
    trialMode: false,
    showCollegiateNumberCantEmmit: false,
    isValidating: false,
    dialog_patient_document: {
      dialog: false,
      patient_id: null,
    },
    isLoading: false,
    showCheckingCollegiate: false,
  }),

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),
  },

  watch: {
    dateStart() {
      this.setInputColor(0);
      this.changeFilters();
    },

    dateEnd() {
      this.setInputColor(1);
      this.changeFilters();
    },

    status() {
      this.changeFilters();
    },

    isLoading(val) {
      if (!val && this.showCheckingCollegiate) {
        this.showCheckingCollegiate = false;
        this.openTreatmentModal();
      }
    },
  },

  created() {
    if (this.authUser && this.authUser.trial) {
      this.trialMode = true;
    }
  },
  mounted() {
    this.states = [
      { id: 1, name: this.$t('mensajeria.abiertos') },
      { id: 0, name: this.$t('mensajeria.cerrados') },
    ];

    this.user_id = this.authUser.id;
    this.patient_id = parseInt(this.$route.params.id);
    this.isValidCollegiateNumber = haveValidCollegiateNumber();
    if (this.isValidCollegiateNumber) {
      this.collegiateNumberCanEmmit = this.checkCollegiateNumberAllowToPrescribe();
    }
  },
  methods: {
    changeFilters() {
      this.isFiltering = true;
      this.$emit('onChangeFilters', { dateStart: this.dateStart, dateEnd: this.dateEnd, status: this.status });
    },

    resetFilters() {
      this.setInvisibleDate();
      this.isFiltering = false;
      this.$emit('resetFilter');
    },

    setInputColor(pos) {
      const element = document.querySelectorAll(this.selectorDateElem)[pos];
      element.style.color = '#000000';
    },

    setInvisibleDate() {
      const elements = document.querySelectorAll(this.selectorDateElem);
      elements.forEach(function(e) {
        e.style.color = '#FFFFFF';
      });
    },

    validateBeforeOpen() {
      let validDocument = false;
      if (this.patient_id !== null && !this.isValidating) {
        try {
          this.isValidating = true;
          this.$http.get(getPatientDetailUrl + this.patient_id, { headers: getHeader() }).then(response => {
            if (response.status === 200 && response.data) {
              validDocument = isValidDocument(response.body.data.documentType, response.body.data.dni);
              if (validDocument) {
                this.openTreatmentModal();
              } else {
                this.dialog_patient_document.dialog = true;
              }
            }
          });
          this.isValidating = false;
        } catch (e) {
          this.dialog_patient_document.dialog = true;
          this.error = e.message;
          this.isValidating = false;
        }
      }
    },

    openTreatmentModal() {
      if (this.isLoading) {
        this.showCheckingCollegiate = true;
      } else if (!this.isValidCollegiateNumber) {
        this.showCollegiateMsg = true;
      } else if (!this.collegiateNumberCanEmmit) {
        this.showCollegiateNumberCantEmmit = true;
      } else if (this.trialMode) {
        this.showTrialMsg = true;
      } else {
        this.showTreatmentModal = true;
      }
    },

    closePatientDocumentMsg() {
      this.dialog_patient_document.dialog = false;
    },

    checkCollegiateNumberAllowToPrescribe() {
      this.isLoading = true;
      this.$http
        .post(checkCollegiateAllowPrescribe, { professional_id: 2 }, { headers: getHeader() })
        .then(res => {
          this.collegiateNumberCanEmmit = res.body.allow;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#treatment-filters {
  .separation {
    border-bottom: 1px solid lightgray;
  }

  .item {
    width: 90%;
  }

  .btn_create {
    float: right;
  }

  .title-filter {
    text-transform: capitalize;
  }

  .treat-title {
    color: #1976d2;
  }
}
</style>
