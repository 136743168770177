export const ALLOWED_FILE_TYPES = [
  'application/vnd.ms-excel',
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/x-m4v',
  'video/avi',
];

export const NOT_ALLOWED_FILE_TYPE_MESSAGE = 'Message mime type not allowed';
