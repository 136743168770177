import delay from '@/utils/delay';

function toggleEPrescription() {
  document.getElementById('prescription-expand').click();
}

function scrollToPrescription(id) {
  document.getElementById(`prescription_${id}`).scrollIntoView(false);
}

export async function navigateToPatient(patientId, router) {
  return await router.push({ path: `/paciente/${patientId}` });
}

export async function navigateToPatientWithPrescription(prescriptionId, patientId, router) {
  return await router.push({ path: `/paciente/${patientId}`, query: { prescriptionToExpand: prescriptionId } });
}

export async function navigateToEprescription(prescriptionId) {
  await delay(400);
  toggleEPrescription();
  await delay(600);
  scrollToPrescription(prescriptionId);
}
