<template>
  <div>
    <GenericModal
      v-model="modalValue"
      :max-width="1080"
      :title="$t('parapharmacy.emit')"
      :on-close="showExitModal"
      :persistent="true"
    >
      <div class="parapharmacy-form__container">
        <v-stepper v-model="currentStep" :steps="steps" :elevation="0" flat>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="parapharmacy-form__content">
                <DCard :title="$t('parapharmacy.patient')">
                  <template v-slot:header-action>
                    <div class="parapharmacy-patient__edit-patient-container">
                      <a @click="editPatient">
                        <EditIcon />
                      </a>
                    </div>
                  </template>

                  <div v-if="patientLoaded" class="parapharmacy-patient__data">
                    <div class="parapharmacy-patient__avatar hidden-xs-only">
                      <div class="icon-svg__container">
                        <PatientIcon />
                      </div>
                    </div>
                    <div class="parapharmacy-patient__info">
                      <div v-if="patient" class="parapharmacy-patient__content-title">
                        {{ patient.name }} {{ patient.surname }}
                      </div>
                      <div class="parapharmacy-patient__content ma-t-4">
                        <div>
                          <div class="flex-row full-center">
                            <div class="icon-svg__container mr-3">
                              <BDayIcon />
                            </div>
                            <span>{{ age }}</span>
                          </div>
                        </div>
                        <div>
                          <div class="flex-row full-center">
                            <div class="icon-svg__container mr-3">
                              <PhoneIcon/>
                            </div>
                            <span>{{ patient.phoneCode }} {{ patient.phone }}</span>
                          </div>
                        </div>
                        <div>
                          <div class="flex-row full-center">
                            <div class="icon-svg__container mr-3">
                              <EmailIcon />
                            </div>
                            <span>{{ patient.email }}</span>
                          </div>
                        </div>
                        <div>
                          <div class="flex-row full-center">
                            <div class="icon-svg__container mr-3">
                              <DocumentIdIcon />
                            </div>
                            <span>{{ patient.dni }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="patientLoading" class="parapharmacy-patient__loader">
                    <v-progress-circular indeterminate color="primary" width="2" size="60" />
                  </div>
                </DCard>
                <DCard :title="$t('parapharmacy.product')" class="ma-t-7">
                  <DynamicForm
                    v-if="!isLoadingTemplate && fields.length"
                    v-model="parapharmacyProductsForm"
                    :validations="validations"
                    :fields="fields"
                  />
                  <div v-if="isLoadingTemplate" class="parapharmacy-patient__loader">
                    <v-progress-circular indeterminate color="primary" width="2" size="60" />
                  </div>
                </DCard>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <PDFViewerExtended v-if="currentStep === 2" :pdf="pdf" :pdf-scale="pdfScale" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>

      <template v-slot:left-actions>
        <div v-if="currentStep === 2" class="pdf-viewer__slider-container hidden-xs-only">
          <div class="pdf-viewer__zoom-slider">
            <div class="pdf-viewer__zoom-slider-indicators">
              <div>-</div>
              <div>+</div>
            </div>
            <v-slider
              v-model="zoomSliderStep"
              :step="1"
              :min="minSlider"
              :max="maxSlider"
              color="#C6C6C6"
              track-color="#C6C6C6"
              thumb-color="primary"
              hide-details
              dense
            />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <DButton
          v-if="currentStep === 1"
          :disabled="fetchDisabled"
          :primary="true"
          :loading="fetchingPreview"
          @click.native="goToPreview"
          >{{ $t('parapharmacy.preview_and_emit') }}</DButton
        >
        <div v-if="currentStep === 2" class="parapharmacy-form__actions">
          <DButton :disabled="submitting" @click.native="edit">{{ $t('common.return_back') }}</DButton>
          <DButton
            v-if="currentStep === 2"
            :disabled="submitDisabled"
            :primary="true"
            :loading="submitting"
            @click.native="submit"
            >{{ $t('parapharmacy.sign_and_emit') }}</DButton
          >
        </div>
      </template>
    </GenericModal>
    <WarningModal
            v-model="exitModal"
            :title="$t('parapharmacy.exit_title')"
            :continue-text="$t('parapharmacy.continue')"
            :cancel-text="$t('parapharmacy.exit')"
            :on-continue="closeExitModal"
            :on-cancel="closeParapharmacyModal"
    >
        {{ $t('parapharmacy.exit_message') }}
    </WarningModal>
  </div>
</template>

<script>
import PatientIcon from "@/assets/icons/img-por-defecto-paciente.svg"
import BDayIcon from "@/assets/icons/info_paciente-fecha-nacimiento.svg"
import PhoneIcon from "@/assets/icons/info_paciente-telefono.svg"
import EmailIcon from "@/assets/icons/info_paciente-email.svg"
import EditIcon from "@/assets/icons/boton-editar.svg"
import DocumentIdIcon from "@/assets/icons/info_paciente-dni.svg"

import GenericModal from '@/components/atoms/GenericModal.vue';
import DCard from '@/components/atoms/DCard.vue';
import scssColors from '@/sass/_variables.scss';
import { useParapharmacyTemplate } from '@/use/Parapharmacy/useParapharmacyTemplate';
import { usePatient } from '@/use/Patients/usePatient';
import DynamicForm from '@/components/organisms/DynamicForm/DynamicForm.vue';
import DButton from '@/components/atoms/DButton.vue';
import getAge from '@/utils/getAge';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import arrayToObject from '@/utils/arrayToObject';
import PDFViewerExtended from '@/components/PDFViewerExtended.vue';
import { emitPrescription, generatePdfPreview } from '@/services/parapharmacy';
import { getHeader, talonarioUrl } from '@/config/config';
import WarningModal from "@/components/organisms/WarningModal/WarningModal.vue";

export default {
  name: 'EmitParapharmacyForm',
  components: {WarningModal, PDFViewerExtended, DButton, DynamicForm, GenericModal, DCard, PatientIcon, BDayIcon, PhoneIcon, EmailIcon, EditIcon, DocumentIdIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: String,
      required: true,
    },
    patientId: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const { patient, loading, genderIcon, patientAge, fetchPatient, toggleLoading } = usePatient();
    const { fields, isLoadingTemplate, isNetworkError, retrieveTemplate } = useParapharmacyTemplate(props.templateId);
    const v$ = useVuelidate({ $scope: false });

    if (props.patientId) {
      fetchPatient(props.patientId);
    } else {
      toggleLoading();
    }

    retrieveTemplate();

    return {
      patient,
      isLoadingPatient: loading,
      genderIcon,
      patientAge,
      fields,
      isLoadingTemplate,
      isNetworkError,
      retrieveTemplate,
      v$,
    };
  },

  validations() {
    const validations = {};

    for (const [key, value] of Object.entries(arrayToObject(this.fields, 'key'))) {
      if (value.required) {
        validations[`${key}`] = {
          value: {
            required: helpers.withMessage(
              this.$t('parapharmacy.field_required', { field: value.label[`${this.locale}`] }),
              required
            ),
          },
        };
      }
    }

    return {
      parapharmacyProductsForm: validations,
    };
  },

  data: function() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      scssColors,
      parapharmacyProductsForm: null,
      fetchingPreview: false,
      previewFailed: false,
      submitting: false,
      submittedWithErrors: false,
      submitFailed: false,
      exitModal: false,
      currentStep: 1,
      steps: 2,
      pdf: null,
      pdfScale: 1,
      zoomSliderStep: 1,
      minSlider: 1,
      maxSlider: 5,
    };
  },

  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    locale() {
      let locale = 'en_EN';

      switch (this.$i18n.locale) {
        case 'es':
          locale = 'es_ES';
          break;
        case 'en':
          locale = 'en_EN';
          break;
      }

      return locale;
    },
    patientLoading() {
      return this.isLoadingPatient;
    },
    patientLoaded() {
      return !this.isLoadingPatient && !this.isNetworkError && this.patient;
    },
    age() {
      return `${getAge(this.patient.birthdate.replace('-', '/'))} ${this.$t('common.years')} (${this.$moment(
        this.patient.birthdate
      ).format('DD/MM/YYYY')})`;
    },
    validForm() {
      return true;
    },
    fetchDisabled() {
      return this.fetchingPreview || !this.validForm;
    },
    submitDisabled() {
      return this.submitting;
    },

    validations() {
      return this.submittedWithErrors ? this.v$.parapharmacyProductsForm : null;
    },
  },

  watch: {
    zoomSliderStep(val) {
      this.zoom(val);
    },
  },
  methods: {
    scrollToFirstError() {
      this.$nextTick(() => {
        document.querySelector('.error--text').scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    },
    showExitModal() {
      this.exitModal = true;
    },
    closeExitModal() {
      this.exitModal = false;
    },
    closeParapharmacyModal() {
      this.exitModal = false;
      this.modalValue = false;
    },
    edit() {
      this.currentStep = 1;
    },
    editPatient() {
      this.$router.push(`/paciente/${this.patientId}`);
    },
    async goToPreview() {
      this.v$.$touch();
      this.fetchingPreview = true;

      if (this.v$.$invalid) {
        this.submittedWithErrors = true;
        this.fetchingPreview = false;
        this.scrollToFirstError();
        return;
      }

      try {
        const prescriptionFormData = await this.getPrescriptionFormData();

        const body = {
          data: {
            ...prescriptionFormData,
            watermark: this.$t('parapharmacy.draft'),
          },
        };
        this.pdf = await generatePdfPreview(this.templateId, body);
        this.currentStep = 2;
      } catch (e) {
        this.$toast.error(this.$t('errors.try_again'));
      } finally {
        this.fetchingPreview = false;
      }
    },

    zoom(step) {
      let realStep = 0;

      if (this.minSlider === step) {
        realStep = this.maxSlider;
      } else if (this.maxSlider > step) {
        realStep = this.maxSlider - step;
      } else {
        realStep = this.minSlider;
      }

      this.pdfScale = realStep * 0.25;
    },

    zoomIn() {
      if (this.pdfScale <= 0.5) {
        return;
      }
      this.pdfScale = this.pdfScale - 0.25;
    },

    zoomOut() {
      if (this.pdfScale >= 1.5) {
        return;
      }
      this.pdfScale = this.pdfScale + 0.25;
    },

    professionalData() {
      const professional = this.authUser;

      return `${professional.fullName}
NºColegiado: ${professional.collegiateNumber}
Especialidad ${professional.specialtyName}
Fecha prescripción: ${this.$moment(Date.now()).format('DD/MM/YYYY')}`;
    },

    patientData() {
      return `${this.patient.name}
${this.patient.birthdate}
${this.patient.dni}`;
    },

    async getPrescriptionFormData() {
      const data = {};

      for (const [key, value] of Object.entries(this.parapharmacyProductsForm)) {
        data[`${key}`] = value.value;
      }

      if (this.parapharmacyProductsForm['parapharmacy_product']) {
        const product = this.parapharmacyProductsForm['parapharmacy_product'].value;

        delete data['parapharmacy_product'];
        data.name = product.name;
        data[
          'parapharmacy_product_data'
        ] = `${product.name}, ${product.dosage} ${product.administrationUnit} (Cód: ${product.nationalCode})`;

        if (product.images.length > 0) {
          data['parapharmacy_product_image'] = product.images[0];
        }

        data['professional'] = this.professionalData();
        data['patient'] = this.patientData();
      }

      data.signature = await this.loadSignature();
      return data;
    },

    async loadSignature() {
      const formData = new FormData();
      formData.append('user_id', this.authUser.id);
      formData.append('accion', 'indexFirma');

      const response = await this.$http.post(talonarioUrl, formData, { headers: getHeader() });
      return response.data.firma !== '' ? response.data.firma : null;
    },

    async submit() {
      this.submitting = true;
      try {
        const prescriptionFormData = await this.getPrescriptionFormData();

        const body = {
          professionalId: this.authUser.user.professional_docline_api_id,
          patientId: this.patient.uuid,
          templateId: this.templateId,
          name: prescriptionFormData.name,
          data: prescriptionFormData,
        };

        await emitPrescription(body);

        this.$toast.success(this.$t('parapharmacy.emit_success'));
        this.modalValue = false;
        this.$emit('emitted');
      } catch (e) {
        this.$toast.error(this.$t('parapharmacy.emit_fail'));
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$greyLight: #f1f1f1;
$greyDark: #757575;

.parapharmacy-form {
  &__content {
    padding: 1rem 0;
    font-size: 0.875rem;
    color: $--text-primary;
  }

  &__container {
    & .v-stepper {
      box-shadow: none !important;
    }

    & .v-stepper__content {
      padding: 0 !important;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.parapharmacy-patient {
  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-row: repeat(1, 1fr);
    row-gap: rem(12);
    column-gap: rem(24);
    justify-content: space-between;
  }

  &__content-title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
  }

  &__edit-patient-container {
    position: absolute;
    right: 0;
    margin-top: -20px;
  }

  &__data {
    display: flex;
    flex-direction: row;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: rem(190);
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: rem(280);
  }

  &__info {
    width: 100%;
  }
}

.pdf-viewer {
  &__slider-container {
    width: rem(234);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(8) rem(16);
    background-color: $greyLight;
    border-radius: 5px;
  }

  &__zoom-slider {
    width: 100%;
  }

  &__zoom-slider-indicators {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: rem(8);
    padding-right: rem(8);
    color: $greyDark;
  }
}

@media only screen and (min-width: 768px) {
  .parapharmacy-patient {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-row: repeat(2, 1fr);
    }
  }

  .parapharmacy-form {
    &__actions {
      width: initial;
    }
  }
}

@media only screen and (min-width: 992px) {
  .parapharmacy-patient {
    &__loader {
      min-height: rem(122);
    }
  }
}

.parapharmacy-form__container {
  &
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: $greyLight;
    border-width: 0.125rem;
  }
}
</style>
