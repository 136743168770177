<template>
  <div id="documentos">
    <div class="cabecera_nuevo_documento pa-4">
      <AccessToTireaButton class="mr-2" :collegiate-number="professionalCollegiateNumber" :professional-id="professionalId" :patient-id="patientId" />
      <v-btn data-test="patient-edit-button-new-document" color="primary" :loading="loading" :disabled="loading" depressed @click="seleccionarDocumento()">{{
        $t('documentos.nuevo')
      }}</v-btn>
      <input
        id="filenuevo"
        ref="filenew"
        type="file"
        accept="application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*, .pdf, video/mp4,video/x-m4v,video/avi, .pptx, .ppt"
        style="display: none"
        @change="onFileChangeNuevo"
      />
    </div>
    <div v-if="loading" class="loading_documento">
      <v-progress-linear indeterminate value="15" color="primary" />
    </div>
    <v-data-table
      :headers="headers"
      :items="documentos"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        itemsPerPageText: $t('datatable.rows_per_page'),
        itemsPerPageAllText: $t('datatable.all'),
        pageText: '',
      }"
    >
      <template v-slot:item="props">
        <tr>
          <td class="hidden-xs-only clickable" @click="openDocument(props.item)">
            <v-icon v-if="!props.item.edit" :class="[files[props.item.tipo].iconClass]">{{ props.item.icon }}</v-icon>
          </td>
          <td>
            <span v-if="!props.item.edit" class="clickable" @click="openDocument(props.item)">
              {{ props.item.fileName }}
            </span>
            <span v-else>
              <v-text-field
                v-model="props.item.nombreFile"
                class="editTextField"
                :label="$t('mensajeria.nombre_file')"
                single-line
                full-width
                hide-details
              />
            </span>
          </td>
<!--          <td :class="{'text-center': !props.item.videoconsulta}">-->
<!--            <div v-if="!props.item.videoconsulta">-</div>-->
<!--            <div v-else class="d-flex flex-column flex-centered">-->
<!--              <div v-for="(text, index) in linkedVc(props.item)" :key="index">-->
<!--                {{ text }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </td>-->
          <td>
            {{ $t(`documents.${props.item.category}`) }}
          </td>
          <td class="clickable" @click="openDocument(props.item)">
            {{ props.item.fecha }}
          </td>
<!--          <td>-->
<!--            {{ formatSizeUnits(props.item.fileSize) }}-->
<!--          </td>-->
          <td class="action_col">
            <v-btn
              v-if="!isEnabledChatV2Integration"
              slot="activator"
              v-tooltip="!props.item.edit ? $t('mensajeria.modificar_name_file') : $t('common.save')"
              :disabled="disabledButton"
              icon
              ripple
              @click="editarNombreAdjunto(props.item)"
            >
              <v-icon :color="!props.item.edit ? 'grey' : 'green lighten-1'">{{
                !props.item.edit ? 'mdi-pencil' : 'mdi-content-save'
              }}</v-icon>
            </v-btn>
            <v-btn
              v-if="!props.item.edit"
              slot="activator"
              v-tooltip="$t('mensajeria.delete_file')"
              icon
              ripple
              @click="eliminarDocumento(patient.dni, props.item.id ? props.item.id : props.item.uuid, props.index)"
            >
              <v-icon color="grey">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-if="!props.item.edit"
              slot="activator"
              v-tooltip="$t('mensajeria.ver_file')"
              icon
              ripple
              @click="openDocument(props.item)"
            >
              <v-icon color="grey">mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template slot="nallowsInlineMediaPlaybacko-data">
        <div class="grey--text text-center pa-5">
          {{ $t('patient.sin_documentos') }}
        </div>
      </template>
    </v-data-table>

    <!-- Modal Confirmar Subir Documento -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ dialog_confirm.title }}</span>
          </v-card-title>
          <v-card-text>
            <p>{{ dialog_confirm.text }}</p>
            <p v-if="isEnabledChatV2Integration && dialog_confirm.type === 'subir'">
              <v-select
                v-model="newDocument.category"
                filled
                dense
                outlined
                :items="categoryTypes"
                :label="$t('common.category')"
                auto
                required
                item-text="text"
                item-value="value"
              />
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="disabledButton" color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              :disabled="disabledButton"
              color="primary"
              text
              @click.native="
                dialog_confirm.dialog = false;
                confirmadaAccion();
              "
              ><v-icon>{{ dialog_confirm.btn_confirm_icono }}</v-icon> {{ dialog_confirm.btn_confirm_texto }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <WarningModal
        v-model="requiredDataModal"
        title="Ausencia de datos personales"
        continue-text="Completar datos"
        cancel-text="Salir"
        :on-continue="continueRequiredModal"
        :on-cancel="closeRequiredModal"
      >
        Para poder continuar con este proceso es necesario que completes todos los campos obligatorios
        de los datos personales del paciente.
      </WarningModal>
      <UploadDocumentModal
        v-model="uploadDocumentModal"
        :patient-id="patient.id"
        :professional-id="authUser.user.id"
        :submitting="loading"
        @submit="newUpload"
      />
    </span>
  </div>
</template>

<script>
import { uploadDocumentUrl, modificarFileUrl, eliminarFileUrl, getHeaderMultimedia, getHeader } from '@/config/config';
import DownloadFile from '@/mixins/DownloadFile';
import { mapActions, mapGetters } from 'vuex';
import { ALLOWED_FILE_TYPES } from '@/utils/AllowedFileTypes';
import WarningModal from "@/components/organisms/WarningModal/WarningModal.vue";
import UploadDocumentModal from "@/DocumentsManagement/components/UploadDocumentModal.vue";
import AccessToTireaButton from "@/Tirea/AccessToTirea/components/AccessToTireaButton.vue";

export default {
  name: 'Documentos',
  components: {
    AccessToTireaButton,
    WarningModal,
    UploadDocumentModal,
  },
  mixins: [DownloadFile],
  props: {
    documentos: {
      type: Array,
      default: () => [],
    },
    pacientes: {
      type: Array,
      default: () => [],
    },
    patient: {
      type: Object,
      default: null,
    },
    isRequiredData: {
      type: Boolean,
      default: false,
    },
    onRequiredData: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      headers: [
        { text: '', value: '', sortable: false, class: 'hidden-xs-only' },
        { text: this.$t('common.name'), value: 'title' },
        // { text: this.$t('videoconsulta.singular'), value: 'videoconsulta' },
        { text: this.$t('common.category'), value: 'category' },
        { text: this.$t('common.date'), value: 'created_at' },
        // { text: this.$t('common.size'), value: 'size' },
        { text: this.$t('common.actions'), value: '', sortable: false },
      ],
      numColumns: 4,
      dialog_nuevomensaje: false,
      disabledButton: false,
      selected: {
        estado: '',
        video: {},
        indice: 0,
      },
      files: {
        imagen: { icon: 'mdi-image', iconClass: 'blue white--text' },
        pdf: { icon: 'mdi-pdf', iconClass: 'deep-orange darken-3 white--text' },
        movie: { icon: 'mdi-movie', iconClass: 'deep-purple white--text' },
        word: { icon: 'doc.png', iconClass: 'blue darken-4 white--text' },
        xls: { icon: 'xls.png', iconClass: 'green white--text' },
        ppt: { icon: 'ppt.png', iconClass: 'red darken-4 white--text' },
        otro: { icon: 'mdi-note', iconClass: 'blue-grey darken-1 white--text' },
      },
      categoryTypes: [
        { text: this.$t('documents.other'), value: 'other' },
        { text: this.$t('documents.video'), value: 'video' },
        { text: this.$t('documents.chat'), value: 'chat' },
        { text: this.$t('documents.prescription'), value: 'prescription' },
        { text: this.$t('documents.triage'), value: 'triage' },
        { text: this.$t('documents.diagnosis'), value: 'diagnosis' },
        { text: this.$t('documents.medical_report'), value: 'medical_report' },
        { text: this.$t('documents.electronic_prescription'), value: 'electronic_prescription' },
      ],
      newDocument: {
        selected: false,
        file: '',
        category: 'other',
        videoconsultationId: null,
      },
      dialog_confirm: {
        dialog: false,
        type: '',
        title: '',
        text: '',
        btn_confirm_texto: '',
        btn_confirm_icono: '',
        btn_confirm_color: '',
        paciente_id: 0,
        documento_id: 0,
        documento_indice: 0,
      },
      loading: false,
      requiredDataModal: false,
      uploadDocumentModal: false
    };
  },
  computed: {
    ...mapGetters({
      isEnabledChatV2Integration: 'app/getChatV2IntegrationFeatureFlag',
    }),
    professionalCollegiateNumber() {
      return this.authUser.collegiateNumber;
    },
    professionalId() {
      return this.authUser.user.professional_docline_api_id;
    },
    patientId() {
      return this.patient.docline_api_id;
    },
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    linkedVc(doc) {
      return [
        doc.videoconsulta.fecha,
        doc.videoconsulta.especialidad?.[`name_${this.$i18n.locale}`]??""
      ]
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },

    async openDocument(document) {
      await this.download({
        url: document.url ? document.url : document.file,
        name: document.name ? document.name : document.fileName,
        type: document.type ? document.type : document.fileType,
        open: true,
        openInNewTab: true,
        download: false,
      });
    },

    eliminarDocumento(paciente_id, documento_id, indice) {
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.type = 'eliminar';
      this.dialog_confirm.title = this.$t('mensajeria.delete_file');
      this.dialog_confirm.text = this.$t('mensajeria.confirmar_delete_file');
      this.dialog_confirm.btn_confirm_texto = this.$t('common.confirm');
      this.dialog_confirm.btn_confirm_icono = 'mdi-delete';
      this.dialog_confirm.btn_confirm_color = 'red';
      this.dialog_confirm.paciente_id = paciente_id;
      this.dialog_confirm.documento_id = documento_id;
      this.dialog_confirm.documento_indice = indice;
    },

    onNuevoChild(respuesta) {
      if (respuesta.estado === '1') {
        this.videoconsultas['pendientes'].push(respuesta.videoconsulta);
      }
      this.showAlertMsg(
        'success',
        'mdi-video',
        respuesta.estado === '1'
          ? this.$t('videoconsulta.enviado_correctamente')
          : this.$t('videoconsulta.enviado_recordatorio')
      );
      this.dialog_nuevavideo = false;
    },

    continueRequiredModal() {
      this.requiredDataModal = false;
      this.onRequiredData();
    },

    closeRequiredModal() {
      this.requiredDataModal = false;
    },

    openUploadDocumentModal() {
      this.uploadDocumentModal = true;
    },

    seleccionarDocumento() {
      this.requiredDataModal = false;
      if (this.isRequiredData) {
        this.requiredDataModal = true;
        return;
      }

      if (!this.disabledButton) {
        this.openUploadDocumentModal();
      }
    },

    onFileChangeNuevo() {
      this.newDocument.selected = true;
      this.newDocument.file = this.$refs.filenew.files[0];
      if (typeof this.newDocument.file !== 'undefined' && this.isValid(this.newDocument.file)) {
        const tam_max = 50000000;
        if (this.newDocument.file.size > tam_max) {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.file_max_upload', { tam: tam_max / 1000000 }));
        } else {
          this.dialog_confirm.dialog = true;
          this.dialog_confirm.type = 'subir';
          this.dialog_confirm.title = this.$t('documentos.confirmar_subida_titulo');
          this.dialog_confirm.text = this.$t('documentos.confirmar_subida_texto');
          this.dialog_confirm.btn_confirm_texto = this.$t('common.confirm');
          this.dialog_confirm.btn_confirm_icono = 'mdi-cloud-upload';
          this.dialog_confirm.btn_confirm_color = 'green';
        }
      }
    },

    isValid(file) {
      const res = true;
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.file_uploaded_invalid'),
        });
        return false;
      }
      return res;
    },

    newUpload(toUpload) {
      this.loading = true;
      const { files, videoconsultationId, category } = toUpload;

      this.newDocument.file = files[0];
      this.newDocument.videoconsultationId = videoconsultationId;
      if (this.newDocument.category) {
        this.newDocument.category = category;
      }

      this.upload();
    },

    upload() {
      const formData = new FormData();
      formData.append('file', this.newDocument.file);
      formData.append('category', this.newDocument.category);
      formData.append('videoconsultationId', this.newDocument.videoconsultationId);
      formData.append('patientId', this.patient.id);
      this.$http
        .post(uploadDocumentUrl, formData, { headers: getHeaderMultimedia() })
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.showAlertMsg('success', 'mdi-check', this.$t('documentos.subido_correctamente'));
            this.$emit("reload");
          } else {
            this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          }
          this.loading = false;
          this.disabledButton = false;
          this.uploadDocumentModal = false;
        })
        .catch(e => {
          this.showAlertMsg(
            'error',
            'mdi-alert-circle',
            e.data && e.data.error ? this.$t(e.data.error) : this.$t('errors.try_again')
          );
          this.loading = false;
          this.disabledButton = false;
        });
    },

    delete() {
      const indice_doc = this.dialog_confirm.documento_indice;
      this.$http
        .post(
          eliminarFileUrl,
          { id: this.authUser.id, paciente_id: this.patient.id, documento_id: this.dialog_confirm.documento_id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.showAlertMsg('success', 'mdi-delete', this.$t('success.file_deleted'));
            this.documentos.splice(indice_doc, 1);
          } else {
            this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          }
          this.loading = false;
          this.disabledButton = false;
        });
    },

    confirmadaAccion() {
      this.loading = true;
      this.disabledButton = true;
      if (this.dialog_confirm.type === 'subir') {
        this.upload();
      }
      else if (this.dialog_confirm.type === 'eliminar') {
        this.delete();
      }
    },

    editarNombreAdjunto(file) {
      if (!this.disabledButton) {
        if (file.edit) {
          this.disabledButton = true;
          this.$http
            .post(
              modificarFileUrl,
              { id: this.authUser.id, paciente_id: this.patient.dni, file: file },
              { headers: getHeader() }
            )
            .then(response => {
              if (response.status === 200 && response.data.estado === 1) {
                this.showAlertMsg('success', 'mdi-file', this.$t('success.name_file_modificado'));
                file.title = response.data.filename;
              } else {
                this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
              }
              this.disabledButton = false;
              file.edit = false;
            });
        } else {
          file.edit = true;
        }
      }
    },
    formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + ' GB';
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + ' MB';
      } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + ' KB';
      } else if (bytes > 1) {
        bytes = bytes + ' bytes';
      } else if (bytes === 1) {
        bytes = bytes + ' byte';
      } else {
        bytes = '0 bytes';
      }
      return bytes;
    },
  },
};
</script>

<style>
.cabecera_nuevo_documento {
  text-align: right;
}

.item_adjunto:hover {
  cursor: pointer;
  background: rgba(182, 218, 255, 0.25);
}

.clickable {
  cursor: pointer;
}

.action_col {
  min-width: 160px;
}

.editTextField {
  border-bottom: solid;
  color: #0d4080;
}
</style>
