<template>
  <div>
    <div class="category-selector__label">
      {{ $t('document_category_selector.category') }}*
    </div>
    <Select
      v-model="categoryValue"
      :items="categoryTypes"
      :placeholder="$t('document_category_selector.select')"
      item-text="text"
      item-value="value"
    />
  </div>
</template>

<script>

import Select from '@/components/form/Select/Select.vue'

export default {
  components: {
    Select
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
  },
  data: function () {
    return {
      categoryTypes: [
        { text: this.$t('documents.other'), value: 'other' },
        { text: this.$t('documents.video'), value: 'video' },
        { text: this.$t('documents.chat'), value: 'chat' },
        { text: this.$t('documents.prescription'), value: 'prescription' },
        { text: this.$t('documents.triage'), value: 'triage' },
        { text: this.$t('documents.diagnosis'), value: 'diagnosis' },
        { text: this.$t('documents.medical_report'), value: 'medical_report' },
        { text: this.$t('documents.electronic_prescription'), value: 'electronic_prescription' },
      ],
    };
  },
  computed: {
    categoryValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  }
}

</script>

<style>
.category-selector__label {
  font-size: 15px;
  color: #222222;
  margin-bottom: 5px;
}
</style>
