import { mapGetters } from 'vuex';
import { COVID_PACKAGE_LIMIT, NO_COVID_PACKAGE_LIMIT } from '@/services/medicineComputedData';

export default {
  name: 'PrescriptionFieldsCheck',

  data() {
    return {
      errorUnits: false,
      errorDuration: false,
      errorFrecuency: false,
      errorMedicine: false,
      errorPackages: false,
    };
  },

  computed: {
    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
  },

  methods: {
    failCheckMinimumFields() {
      let errors = false;
      this.fields.prescription.medicines.forEach(med => {
        if (!med.guideline) {
          this.errorUnits = true;
          errors = true;
        }
        if (!med.posology) {
          this.errorFrecuency = true;
          errors = true;
        }
        if (!med.duration) {
          this.errorDuration = true;
          errors = true;
        }
        if (!med.medicineId) {
          this.errorMedicine = true;
          errors = true;
        }
      });
      return errors;
    },
    failCheckMaxPackages() {
      const maxPackages = this.repHomologation ? NO_COVID_PACKAGE_LIMIT : COVID_PACKAGE_LIMIT;
      let errors = false;
      this.fields.prescription.medicines.forEach(med => {
        if (maxPackages < med.units) {
          this.errorPackages = true;
          errors = true;
        }
      });
      return errors;
    },
  },
};
