interface MedicineUnitType {
  id: number;
  code: number;
  name: string;
}

const medicineUnitTypes: MedicineUnitType[] = [
  {
    id: 1,
    code: 1,
    name: 'ampolla para inyección',
  },
  {
    id: 2,
    code: 5,
    name: 'aplicación rectal',
  },
  {
    id: 3,
    code: 6,
    name: 'aplicación uretral',
  },
  {
    id: 4,
    code: 8,
    name: 'cápsula inhalación/alveolo',
  },
  {
    id: 5,
    code: 9,
    name: 'chicle',
  },
  {
    id: 6,
    code: 10,
    name: 'comprimido',
  },
  {
    id: 7,
    code: 11,
    name: 'cápsula/comprimido vaginal',
  },
  {
    id: 8,
    code: 12,
    name: 'dispositivo intrauterino',
  },
  {
    id: 9,
    code: 13,
    name: 'dosis oral en envase multidosis',
  },
  {
    id: 10,
    code: 14,
    name: 'enema',
  },
  {
    id: 11,
    code: 16,
    name: 'envase uso oftalmico unidosis',
  },
  {
    id: 12,
    code: 17,
    name: 'envase uso oral unidosis',
  },
  {
    id: 13,
    code: 18,
    name: 'envase uso vaginal unidosis',
  },
  {
    id: 14,
    code: 19,
    name: 'esponja',
  },
  {
    id: 15,
    code: 21,
    name: 'g',
  },
  {
    id: 16,
    code: 23,
    name: 'implante',
  },
  {
    id: 17,
    code: 25,
    name: 'l',
  },
  {
    id: 18,
    code: 26,
    name: 'ml',
  },
  {
    id: 19,
    code: 27,
    name: 'óvulo',
  },
  {
    id: 20,
    code: 28,
    name: 'parche',
  },
  {
    id: 21,
    code: 29,
    name: 'pastilla',
  },
  {
    id: 22,
    code: 30,
    name: 'pulverización inhalada',
  },
  {
    id: 23,
    code: 31,
    name: 'pulverización nasal',
  },
  {
    id: 24,
    code: 32,
    name: 'pulverización sublingual',
  },
  {
    id: 25,
    code: 33,
    name: 'supositorio',
  },
  {
    id: 26,
    code: 34,
    name: 'vial para inyección',
  },
  {
    id: 27,
    code: 35,
    name: 'vial para inyección/nebulización',
  },
  {
    id: 28,
    code: 36,
    name: 'N/A (más de 4 PA)',
  },
  {
    id: 29,
    code: 37,
    name: 'envase uso nasal unidosis',
  },
  {
    id: 30,
    code: 40,
    name: 'ampolla nebulización',
  },
  {
    id: 31,
    code: 41,
    name: 'gota',
  },
  {
    id: 32,
    code: 43,
    name: 'cápsula',
  },
  {
    id: 33,
    code: 44,
    name: 'envase uso tópico unidosis',
  },
  {
    id: 34,
    code: 45,
    name: 'dosis inyectable en envase multidosis',
  },
  {
    id: 35,
    code: 48,
    name: 'frasco perfusión',
  },
  {
    id: 36,
    code: 49,
    name: 'pluma precargada',
  },
  {
    id: 37,
    code: 50,
    name: 'generador',
  },
  {
    id: 38,
    code: 51,
    name: 'envase uso ótico unidosis',
  },
  {
    id: 39,
    code: 53,
    name: 'ampolla bebible',
  },
  {
    id: 40,
    code: 54,
    name: 'envase irrigación vesical',
  },
  {
    id: 41,
    code: 57,
    name: 'cartucho precargado',
  },
  {
    id: 42,
    code: 58,
    name: 'mg',
  },
  {
    id: 43,
    code: 59,
    name: 'vial bebible',
  },
  {
    id: 44,
    code: 60,
    name: 'vial nebulización',
  },
  {
    id: 45,
    code: 61,
    name: 'jeringa precargada',
  },
  {
    id: 46,
    code: 63,
    name: 'envase uso oral/rectal unidosis',
  },
  {
    id: 47,
    code: 64,
    name: 'sobre',
  },
  {
    id: 48,
    code: 65,
    name: 'bolsa perfusión',
  },
  {
    id: 49,
    code: 69,
    name: 'apósito',
  },
  {
    id: 50,
    code: 70,
    name: 'envase',
  },
  {
    id: 51,
    code: 71,
    name: 'pulverización transdérmica',
  },
  {
    id: 52,
    code: 73,
    name: 'pulverización bucal',
  },
  {
    id: 53,
    code: 76,
    name: 'vial para inyección/Instilación intravesical',
  },
  {
    id: 54,
    code: 77,
    name: 'inyector precargado',
  },
  {
    id: 55,
    code: 78,
    name: 'aplicación',
  },
  {
    id: 56,
    code: 79,
    name: 'aplicación cutánea',
  },
];

export default medicineUnitTypes;
