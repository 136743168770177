<template>
  <section v-if="allergies && allergies.length" class="allergy-info-box">
    <v-alert border="right" colored-border type="error" elevation="2">
      {{ $t('patient.allergyInfoBox') }}
      <ul class="allergy-info-box__list">
        <li v-for="allergy in allergies" :key="allergy.id" class="allergy-info-box__list-item">
          <v-chip color="red" label outlined small class="mr-2">{{ allergy.name | capitalize }}</v-chip>
        </li>
      </ul>
    </v-alert>
  </section>
</template>

<script>
import AllergiesMixin from '@/mixins/Allergies';

export default {
  name: 'AllergyPatientInfoBox',

  mixins: [AllergiesMixin],

  props: {
    patient: {
      type: [Number, Boolean],
      default: false,
    },
  },

  mounted() {
    this.getPatientAllergies(this.patient || this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.allergy-info-box {
  padding-top: 1px;
  padding-left: 1px;

  &__list {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }
}
</style>
