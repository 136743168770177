<template>
  <div class="posology-field">
    <span class="posology-field-title" :style="'color:' + titleColor">
      {{ $t('recetas.posologia') }}
    </span>

    <div class="posology-field-items">
      <v-text-field type="number" min="1" max="100" :value="amount" @change="update(parseInt($event), unit)" />

      <v-select
        item-value="name"
        item-text="name"
        :items="medicineUnitTypes"
        :value="unit"
        @change="update(amount, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import medicineUnitTypes from './medicine-unit-types';
import { reorderOptions } from '@/store/helpers';

export default {
  name: 'PosologyField',

  props: {
    amount: {
      type: Number,
      default: 1,
    },

    unit: {
      type: String,
      default: '',
    },

    error: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      medicineUnitTypes,
    };
  },

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),

    titleColor() {
      return this.appThemePrimary || '#1976d2';
    },
  },

  created() {
    if (this.unit === null) {
      this.update(this.amount, this.$t('recipe.hours'));
    }
    this.medicineUnitTypes = reorderOptions(this.medicineUnitTypes);
  },

  methods: {
    update(amount, unit) {
      this.$emit('update', { amount, unit });
    },
  },
};
</script>

<style lang="scss" scoped>
.posology-field-title {
  margin-bottom: 0.8em;
  font-weight: bold;
  font-size: 1rem;
}

.posology-field-items {
  display: flex;

  > div:not(:first-child) {
    margin-left: 2rem;
  }
}
</style>
