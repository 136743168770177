<template>
  <div id="anulate-medicament-form">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('recipe.anulate_medicament') }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeCancelForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-flex xs12 class="container">
        <v-layout xs12 wrap>
          <v-flex xs12 class="posology-content">
            <v-textarea v-model="comment" rows="3" :label="$t('recipe.anulate_indications')" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-toolbar flat>
        <v-btn depressed color="primary" :disabled="!comment" @click="anulateMedicament">{{
          $t('recipe.anulate_medicament')
        }}</v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AnulateMedicamentForm',
  props: {
    medicamentId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
    };
  },
  methods: {
    closeCancelForm() {
      this.$emit('clickedReturn', true);
    },
    anulateMedicament() {
      this.$emit('clickedAnulateMedicament', this.medicamentId, this.comment, this.type);
    },
  },
};
</script>
