<template>
  <div id="cuidar">
    <v-layout wrap>
      <v-flex v-if="es_tutelado" xs12>
        <br clear="all" />
        <v-card>
          <v-card-title class="titulo_card"
            ><b>{{ $t('patient.datos_tutor') }}</b></v-card-title
          >
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="paciente.tutor.fullName"
                  class="mr-2"
                  :label="$t('common.name')"
                  type="text"
                  readonly
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="paciente.relacion"
                  class="mr-2"
                  :label="$t('patient.relacion')"
                  type="text"
                  readonly
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="paciente.tutor.dni"
                  class="mr-2"
                  :label="$t('patient.document_id')"
                  type="text"
                  readonly
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="paciente.tutor.email"
                  class="mr-2"
                  :label="$t('message.email')"
                  type="text"
                  readonly
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex v-else-if="paciente.cuidados && paciente.cuidados.length > 0" xs12>
        <br clear="all" />
        <v-card>
          <v-card-title class="titulo_card"
            ><b>{{ $t('patient.pacientes_tutelados') }}</b></v-card-title
          >
          <v-card-text>
            <v-list v-for="(item, index) in paciente.cuidados" :key="item.id" two-line>
              <v-list-item>
                <v-list-item-avatar v-if="!item.edit" @click="abrirPaciente(item.id)">
                  <v-avatar :color="item.sexo === '1' ? 'blue lighten-4' : 'pink lighten-4'"
                    ><img src="/static/paciente_icon.png"
                  /></v-avatar>
                </v-list-item-avatar>
                <v-list-item-content @click="abrirPaciente(item.id)">
                  <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.relacion }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn slot="activator" :disabled="disabledButton" icon ripple @click="edit(item, index)">
                        <v-icon v-on="on">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('patient.edit') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider inset />
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="mostrar_nuevo" max-width="600px" persistent scrollable>
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 class="container">
                <div class="headline grey--text text--darken-3">
                  {{ accion === 'nuevo' ? $t('patient.new') : $t('patient.edit') }}
                </div>
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="nuevopaciente.nombre"
                  class="mr-2"
                  :label="$t('patient.first_name') + ' *'"
                  type="text"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="nuevopaciente.apellidos"
                  class="mr-2"
                  :label="$t('patient.last_name') + ' *'"
                  type="text"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="nuevopaciente.relacion"
                  class="mr-2"
                  :label="$t('patient.relacion') + ' *'"
                  type="text"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-menu
                  ref="fNacimientoMenu"
                  v-model="fNacimientoMenu"
                  :close-on-content-click="false"
                  :return-value.sync="nuevopaciente.fechaNacimiento"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateFormatted"
                      :label="$t('patient.birthdate') + ' *'"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    ref="pickerCuidar"
                    v-model="date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :min="datePickerMinDate"
                    :max="new Date().toISOString().substr(0, 10)"
                    @input="$refs.fNacimientoMenu.save(date)"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-select
                  v-model="tipoDocumento"
                  :items="tiposDocumento"
                  :label="$t('patient.tipo_documento')"
                  menu-props="auto"
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <v-text-field
                  v-model="nuevopaciente.dni"
                  class="mr-2"
                  :label="$t('patient.document_id')"
                  type="text"
                  @change="verificarDocumentoIdentidad"
                />
              </v-flex>
              <v-flex xs12 sm6 lg6>
                <label class="label_genero">{{ $t('patient.gender') }} *:</label>
                <v-radio-group v-model="nuevopaciente.sexo" class="radio_genero" row>
                  <v-radio key="0" value="0" :label="$t('patient.female')" color="pink" />
                  <v-radio key="1" value="1" :label="$t('patient.male')" color="info" />
                  <v-radio key="2" value="2" :label="$t('patient.other')" />
                </v-radio-group>
              </v-flex>
              <v-flex v-if="showHealthCardNumber" xs12 sm6 lg6>
                <v-text-field
                  v-model="nuevopaciente.numsegsocial"
                  class="mr-2"
                  :label="$t('common.numero_seg_social')"
                  type="text"
                  required
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" text @click.native="close">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="primary" :disabled="disabledButton" text @click.native="save">{{ $t('common.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cuidarPacienteUrl, getHeader } from '../../../config/config';
import { datePickerMinDate } from '@/services/dateHelper';
import { setSelectorsDocumentType } from '@/utils';

export default {
  name: 'Cuidar',
  props: {
    paciente: {
      type: Object,
      default: () => ({}),
    },
    es_tutelado: {
      type: Boolean,
      default: false,
    },
    mostrar_nuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      nuevopaciente: {
        nombre: '',
        apellidos: '',
        relacion: '',
        fechaNacimiento: '',
        sexo: '',
        id: 0,
        indice: 0,
        numsegsocial: '',
        dni: '',
        tipoDocumento: 0,
      },
      fNacimientoMenu: false,
      dateFormatted: null,
      date: null,
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      disabledButton: false,
      accion: 'nuevo',
      showHealthCardNumber: false,
      tipoDocumento: 0,
      tiposDocumento: [
        { id: 1, name: 'DNI' },
        { id: 2, name: 'NIE' },
        { id: 3, name: 'Pasaporte' },
        { id: 4, name: 'Otro' },
      ],
      isValidDocument: false,

      datePickerMinDate: datePickerMinDate(),
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    fNacimientoMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.pickerCuidar.activePicker = 'YEAR';
          return;
        });
    },
    tipoDocumento() {
      this.verificarDocumentoIdentidad();
    },
  },

  mounted() {
    this.personalizacionesEntorno();
    if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
      this.showHealthCardNumber = this.permissions.profilesFormWithInsuredNumber;
    }
  },
  methods: {
    close() {
      this.nuevopaciente.nombre = '';
      this.nuevopaciente.apellidos = '';
      this.nuevopaciente.relacion = '';
      this.nuevopaciente.sexo = '';
      this.nuevopaciente.fechaNacimiento = '';
      this.nuevopaciente.id = 0;
      this.nuevopaciente.indice = 0;
      this.nuevopaciente.numsegsocial = '';
      this.nuevopaciente.dni = '';
      this.nuevopaciente.tipoDocumento = 0;
      this.date = null;
      this.accion = 'nuevo';
      this.tipoDocumento = 0;
      this.$emit('dialogNuevoCuidar', false);
    },

    edit(paciente, indice) {
      this.accion = 'editar';
      this.nuevopaciente.nombre = paciente.nombre;
      this.nuevopaciente.apellidos = paciente.apellidos;
      this.nuevopaciente.relacion = paciente.relacion;
      this.nuevopaciente.sexo = paciente.sexo === 1 ? '1' : '0';
      this.nuevopaciente.fechaNacimiento = paciente.fechaNacimiento;
      this.nuevopaciente.id = paciente.id;
      this.nuevopaciente.indice = indice;
      this.nuevopaciente.numsegsocial = typeof paciente.num_seg_social !== 'undefined' ? paciente.num_seg_social : '';
      this.nuevopaciente.dni = typeof paciente.dni !== 'undefined' ? paciente.dni : '';
      this.nuevopaciente.tipoDocumento = typeof paciente.tipoDocumento !== 'undefined' ? paciente.tipoDocumento : 0;
      this.tipoDocumento =
        typeof paciente.tipoDocumento !== 'undefined' && paciente.tipoDocumento !== null
          ? this.nuevopaciente.tipoDocumento
          : 0;
      this.date = paciente.fechaNacimiento;
      this.$emit('dialogNuevoCuidar', true);
    },

    abrirPaciente(idpaciente) {
      this.$router.push({ path: '/buscadopaciente/' + idpaciente });
    },

    validate() {
      this.alerta.texto = '';

      if (this.nuevopaciente.nombre.length < 3 || this.nuevopaciente.apellidos.length < 3) {
        this.alerta.texto = this.$t('patient.full_name_required');
      } else if (this.nuevopaciente.relacion === '') {
        this.alerta.texto = this.$t('errors.relation_required');
      } else if (this.nuevopaciente.fechaNacimiento === '' || this.nuevopaciente.fechaNacimiento === null) {
        this.alerta.texto = this.$t('errors.date_required');
      } else if (
        (this.nuevopaciente.tipoDocumento === 1 || this.nuevopaciente.tipoDocumento === 2) &&
        this.nuevopaciente.dni === ''
      ) {
        this.alerta.texto = this.$t('patient.dni_invalid');
      } else if (
        (this.nuevopaciente.tipoDocumento === 1 || this.nuevopaciente.tipoDocumento === 2) &&
        this.nuevopaciente.dni !== ''
      ) {
        this.verificarDocumentoIdentidad();
        if (!this.isValidDocument) {
          this.alerta.texto = this.$t('patient.dni_invalid');
        }
      } else if (this.nuevopaciente.sexo === '') {
        this.alerta.texto = this.$t('errors.gender_required');
      }

      return this.alerta.texto !== '' ? false : true;
    },
    save() {
      const is_valid = this.validate();

      if (!is_valid) {
        this.alerta.icono = 'mdi-alert-circle';
        this.alerta.color = 'warning';
        this.$emit('mostrarAlert', this.alerta);
      } else {
        if (this.nuevopaciente.nombre !== '' && this.nuevopaciente.apellidos !== '') {
          this.disabledButton = true;
          const param = {
            pacienteCuidado: this.nuevopaciente,
            idPaciente: this.paciente.id,
            accion: this.accion,
            id: this.authUser.id,
          };
          this.$http.post(cuidarPacienteUrl, param, { headers: getHeader() }).then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.alerta.texto = this.$t('success.new_patient');
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
              if (this.accion === 'nuevo') {
                this.paciente.cuidados.push(response.data.paciente);
              } else {
                this.paciente.cuidados[this.nuevopaciente.indice] = response.data.paciente;
              }
              this.close();
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.$emit('mostrarAlert', this.alerta);
            this.disabledButton = false;
          });
        } else {
          this.alerta.texto = this.$t('patient.full_name_required');
          this.alerta.color = 'warning';
          this.alerta.icono = 'mdi-alert-circle';
          this.$emit('mostrarAlert', this.alerta);
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    personalizacionesEntorno() {
      if (this.es_dingdoc) {
        this.tiposDocumento = [
          { id: 0, name: this.$t('patient.cedula') },
          { id: 3, name: this.$t('patient.pasaporte') },
        ];
      } else {
        this.tiposDocumento[2]['name'] = this.$t('patient.pasaporte');
        this.tiposDocumento[3]['name'] = this.$t('common.otro');
      }
      this.tiposDocumento = setSelectorsDocumentType(this.tiposDocumento);
    },

    verificarDocumentoIdentidad() {
      this.isValidDocument = false;
      this.nuevopaciente.tipoDocumento = this.tipoDocumento;
      if (
        (this.nuevopaciente.tipoDocumento === 1 || this.nuevopaciente.tipoDocumento === 2) &&
        this.nuevopaciente.dni !== null &&
        this.nuevopaciente.dni.length > 1
      ) {
        let dni = this.nuevopaciente.dni;
        dni = dni.replace('-', '');
        const expresion_regular =
          this.nuevopaciente.tipoDocumento === 1
            ? /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
            : /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

        this.isValidDocument = expresion_regular.test(dni);

        if (expresion_regular.test(dni) !== true) {
          this.alerta.texto = this.$t('patient.dni_invalid');
          this.alerta.color = 'warning';
          this.$emit('mostrarAlert', this.alerta);
        } else if (this.nuevopaciente.tipoDocumento === 1) {
          let numero = dni.substr(0, dni.length - 1);
          const letr = dni.substr(dni.length - 1, 1);
          numero = numero % 23;
          let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
          letra = letra.substring(numero, numero + 1);
          if (letra !== letr.toUpperCase()) {
            this.nuevopaciente.dni = '';
            this.alerta.texto = this.$t('patient.dni_invalid_letra');
            this.alerta.color = 'warning';
            this.$emit('mostrarAlert', this.alerta);
          }
        }
      }
    },
  },
};
</script>

<style>
.titulo_card {
  text-transform: uppercase;
}
</style>
