import { getHeader, newPatientsUrl } from '@/config/config';
import axios from 'axios';

export const createDiagnose = async (diagnose, patientId) => {
  const response = await axios.post(`${newPatientsUrl}/${patientId}/diagnoses`, diagnose, {
    headers: getHeader(),
  });
  return response.data;
};

export const editDiagnose = async (diagnoseId, diagnoseData, patientId) => {
  const response = await axios.put(`${newPatientsUrl}/${patientId}/diagnoses/${diagnoseId}`, diagnoseData, {
    headers: getHeader(),
  });

  return response.data;
};
export const deleteDiagnose = (diagnoseId, patientId) => {
  return axios.delete(`${newPatientsUrl}/${patientId}/diagnoses/${diagnoseId}`, {
    headers: getHeader(),
  });
};
