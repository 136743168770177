import {
  getHeader,
  tireaServiceUrl,
} from '@/config/config';
import axios from 'axios';

export const fetchAccessTireaUrl = async (
  collegiateNumber,
  professionalId,
  patientId
) => {
  const url = new URL(tireaServiceUrl(collegiateNumber, professionalId, patientId));

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;
};
