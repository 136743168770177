import { getHeader, generateClinicalReportUrl } from '@/config/config';
import axios from 'axios';
import i18n from '../../../engine/framework/modules/i18n';

export const BY_RANGE = 'by_range';
export const SPECIFIC_DAY = 'specific_day';
export const FULL = 'full';

export const REPORT_TYPES = [
  {
    type: BY_RANGE,
    name: i18n.t('patient.clinical_report_type_by_range'),
  },
  {
    type: SPECIFIC_DAY,
    name: i18n.t('patient.clinical_report_type_specific_day'),
  },
  {
    type: FULL,
    name: i18n.t('patient.clinical_report_type_full'),
  },
];

export const generateClinicalReport = async (patientId, startDate, endDate, videoconsultationId) => {
  const url = new URL(`${generateClinicalReportUrl}/${patientId}`);

  const params = {};

  if (startDate) {
    params.startDate = startDate;
  }

  if (endDate) {
    params.endDate = endDate;
  }

  if (videoconsultationId) {
    params.videoconsultationId = videoconsultationId;
  }

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    responseType: 'arraybuffer',
    headers: {
      ...getHeader(),
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
  });
  return raw.data;
};
