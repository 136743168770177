<template>
  <div>
    <v-autocomplete
      v-model="currentPersonalDoctor"
      :items="availablePersonalDoctors"
      :loading="isLoadingAvailablePersonalDoctors"
      clearable
      @click:clear="deassingPersonalDoctor(currentPersonalDoctor)"
      @change="personalDoctorChanged"
      class="mx-1"
      filled
      outlined
      dense
      hide-details
      hide-selected
      :placeholder="isNetworkError ? null : $t('personal_doctor.pending_assignment')"
      :label="isNetworkError ? $t('personal_doctor.network_error_short') : $t('personal_doctor.personal_doctor')"
      item-value="professionalId"
    >
      <template slot="label">
        <div class="d-flex align-baseline">
          <template v-if="isNetworkError">
            <v-icon color="error" small class="mr-2">
              mdi-alert-circle
            </v-icon>
            <span class="error--text text-body-1">
              {{ $t('personal_doctor.network_error_short') }}
            </span>
          </template>
          <template v-else>
            <span>{{ $t('personal_doctor.personal_doctor') }}</span>
          </template>
        </div>
      </template>

      <template slot="selection" slot-scope="{ item }">
        <div>{{ item.firstName }} {{ item.lastName }}</div>
      </template>

      <template slot="item" slot-scope="{ item }">
        <div class="py-1">
          <span class="primary--text text-body-1">{{ item.firstName }} {{ item.lastName }}</span>
          <div>
            <span v-for="specialty in item.specialties" :key="specialty.id" class="caption mr-2">{{
              specialtyLanguage(specialty)
            }}</span>
          </div>
        </div>
      </template>
      <template slot="no-data">
        <div class="personal-doctors-no-data__container primary--text text-body-1 px-4 py-2">
          <PersonalDoctorsSelectorNetworkError
            v-if="isNetworkError"
            :retry="retrieveAvailablePersonalDoctors(companyId)"
            :loading="isLoadingAvailablePersonalDoctors"
          />
          <div v-else>
            <v-progress-circular :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
              isLoadingAvailablePersonalDoctors
                ? $t('personal_doctor.loading_personal_doctors')
                : $t('personal_doctor.not_found_personal_doctors')
            }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { LOCALES, formatToShortDate } from '@/utils/dateer';
import { useAssignmentPersonalDoctor } from '@/use/PersonalDoctors/useAssignmentPersonalDoctor';
import PersonalDoctorsSelectorNetworkError from '@/components/PersonalDoctors/PersonalDoctorsSelectorNetworkError';

export default {
  components: {
    PersonalDoctorsSelectorNetworkError,
  },
  props: {
    patientId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const {
      availablePersonalDoctors,
      currentPersonalDoctor,
      isPendingAssignment,
      isLoadingCurrentPersonalDoctor,
      isLoadingAvailablePersonalDoctors,
      isNetworkError,
      retrieveAvailablePersonalDoctors,
      retrievePatientPersonalDoctor,
    } = useAssignmentPersonalDoctor();

    if (props.patientId) {
      retrievePatientPersonalDoctor(props.patientId, props.companyId);
    }

    retrieveAvailablePersonalDoctors(props.companyId);

    return {
      availablePersonalDoctors,
      currentPersonalDoctor,
      isPendingAssignment,
      isLoadingCurrentPersonalDoctor,
      isLoadingAvailablePersonalDoctors,
      isNetworkError,
      retrieveAvailablePersonalDoctors,
    };
  },

  methods: {
    startedAtPersonalDoctor(startedAt) {
      return formatToShortDate(startedAt, LOCALES.spain);
    },
    endedAtPersonalDoctor(endedAt) {
      return formatToShortDate(endedAt, LOCALES.spain) ?? this.$t('personal_doctor.personal_doctor_present');
    },
    specialtyLanguage(specialty) {
      return this.$i18n.locale === 'es' ? specialty.nameEs : specialty.nameEn;
    },
    personalDoctorChanged() {
      if (this.currentPersonalDoctor) {
        this.$emit('personalDoctorSelected', this.currentPersonalDoctor);
      }
    },
    deassingPersonalDoctor(current) {
      this.$emit('deassignedPersonalDoctor', current);
    },
  },
};
</script>

<style>
.personal-doctors-no-data__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
