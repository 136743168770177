export const COVID_PACKAGE_LIMIT = 9;
export const NO_COVID_PACKAGE_LIMIT = 99;

const calculateTakingMedicinePerDay = (taking, frecuencyAmount, frecuencyUnit) => {
  if (frecuencyUnit === 'hours') {
    return taking * Math.ceil(24 / frecuencyAmount);
  }

  if (frecuencyUnit === 'days') {
    return taking / frecuencyAmount;
  }

  if (frecuencyUnit === 'weeks') {
    return taking / (frecuencyAmount * 7);
  }

  if (frecuencyUnit === 'months') {
    return taking / (frecuencyAmount * 30);
  }

  if (frecuencyUnit === 'years') {
    return taking / (frecuencyAmount * 365);
  }
};

const calculateMedicineUnits = (takingMedicinePerDay, durationDays) => {
  return Math.ceil(takingMedicinePerDay * durationDays);
};

const convertDurationToDays = (durationAmount, durationUnit) => {
  if (durationUnit === 'years') {
    return durationAmount * 365;
  }

  if (durationUnit === 'months') {
    return durationAmount * 30;
  }

  if (durationUnit === 'weeks') {
    return durationAmount * 7;
  }

  return durationAmount;
};

const calculateDateStart = () => {
  return new Date();
};

const calculateDateEnd = durationDays => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + durationDays);

  return currentDate;
};

const calculatePackagesNumber = (medicineUnits, medicineUnitsPerPackage) => {
  return Math.ceil(medicineUnits / medicineUnitsPerPackage);
};

export default ({
  medicineUnitsPerPackage,
  taking,
  frecuencyAmount,
  frecuencyUnit,
  durationAmount,
  durationUnit,
  isHomologation,
}) => {
  const takingMedicinePerDay = calculateTakingMedicinePerDay(taking, frecuencyAmount, frecuencyUnit);
  const durationDays = convertDurationToDays(durationAmount, durationUnit);
  const medicineUnits = calculateMedicineUnits(takingMedicinePerDay, durationDays);
  const packagesNumber = calculatePackagesNumber(medicineUnits, medicineUnitsPerPackage);

  return {
    dateStart: calculateDateStart(),
    dateEnd: calculateDateEnd(durationDays),
    packagesNumber: packagesNumber,
    useHomologationLimit: !isHomologation
      ? packagesNumber > COVID_PACKAGE_LIMIT
      : packagesNumber > NO_COVID_PACKAGE_LIMIT,
  };
};
