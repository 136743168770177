<template>
  <div class="parapharmacy-product-selector__container">
    <p class="parapharmacy-product-selector__label">
      {{ $t('parapharmacy_product_selector.select_product') }} {{ required ? '*' : '' }}
    </p>
    <v-autocomplete
      v-model="currentProduct"
      :items="products"
      :loading="isLoadingProducts"
      outlined
      hide-details
      hide-selected
      item-text="name"
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('parapharmacy_product_selector.search_product')"
      item-value="id"
      :error-messages="validationError"
      :disabled="disabled"
      @update:search-input="onChangeSearchVal($event)"
    >
      <template slot="no-data">
        <div class="parapharmacy-product-selector__no-data primary--text text-body-1 px-4 py-2">
          <ParapharmacyProductSelectorNetworkError
            v-if="isNetworkError"
            :retry="retrieveProducts"
            :loading="isLoadingProducts"
          />
          <div v-else>
            <v-progress-circular v-if="isLoadingProducts" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
              isLoadingProducts
                ? $t('parapharmacy_product_selector.loading_products')
                : $t('parapharmacy_product_selector.not_found_products')
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:item="{ item, on }">
        <v-list-item :ripple="false" v-on="on">
          <ParapharmacyProductItem :item="item" @openedInfo="openInfo" />
          <ParapharmacyProductItemInfo v-model="item.infoOpened" :item="item" />
        </v-list-item>
      </template>
    </v-autocomplete>
    <div v-if="currentProduct" class="parapharmacy-product-selector__selection">
      <ParapharmacyProductSelected :item="selectedProduct" @undo="undo" @openedInfo="openInfoSelected" />
      <ParapharmacyProductItemInfo v-model="selectedInfo" :item="selectedProduct" />
    </div>
  </div>
</template>

<script>
import { useParapharmacyProductsSelector } from '@/use/Parapharmacy/useParapharmacyProductsSelector';
import ParapharmacyProductSelectorNetworkError from './ParapharmacyProductSelectorNetworkError.vue';
import debounce from '@/utils/debounce';
import ParapharmacyProductItem from '@/components/Parapharmacy/EmitForm/ParapharmacyProductSelector/ParapharmacyProductItem.vue';
import ParapharmacyProductItemInfo from '@/components/Parapharmacy/EmitForm/ParapharmacyProductSelector/ParapharmacyProductItemInfo.vue';
import ParapharmacyProductSelected from '@/components/Parapharmacy/EmitForm/ParapharmacyProductSelector/ParapharmacyProductSelected.vue';

export default {
  name: 'ParapharmacyProductSelector',
  components: {
    ParapharmacyProductSelected,
    ParapharmacyProductItemInfo,
    ParapharmacyProductItem,
    ParapharmacyProductSelectorNetworkError,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  setup() {
    const {
      products,
      isLoadingProducts,
      isLoadingCurrentProduct,
      isNetworkError,
      retrieveProducts,
      openInfo,
      selectedInfo,
      openInfoSelected,
      closeInfo,
      getProductById,
    } = useParapharmacyProductsSelector();

    return {
      products,
      isLoadingProducts,
      isLoadingCurrentProduct,
      isNetworkError,
      retrieveProducts,
      openInfo,
      selectedInfo,
      openInfoSelected,
      closeInfo,
      getProductById,
    };
  },

  data() {
    return {
      currentProduct: null,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    countryCode() {
      if (this.authUser.company?.id) {
        return this.authUser.company.countryCode;
      }

      return this.authUser.user.professional_country_code;
    },
    selectedProduct() {
      if (!this.currentProduct) {
        return null;
      }

      return this.getProductById(this.currentProduct);
    },
  },

  watch: {
    currentProduct(val) {
      if (val) {
        this.$emit('input', this.selectedProduct);
      }
    },
  },

  created() {
    this.retrieveProducts(this.authUser.user.professional_docline_api_id);
  },
  methods: {
    onChangeSearchVal(searchString) {
      if (!searchString) {
        return;
      }

      // debounce(() => {
      //   this.retrieveProducts(this.authUser.user.professional_docline_api_id, searchString);
      // }, 500);
    },
    undo() {
      this.currentProduct = null;
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;

.parapharmacy-product-selector {
  &__container {
    margin-bottom: rem(22);
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__selection {
    padding: rem(8) rem(16);
    margin-top: rem(8);
    background-color: $grey;
    border-radius: 5px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}

@media only screen and (min-width: 992px) {
  .parapharmacy-product-selector {
    &__container {
      padding-top: rem(28);
    }
  }
}
</style>
