<template>
  <div id="evoluciones">
    <div>
      <v-toolbar flat color="white">
        <v-spacer />
        <v-dialog v-model="dialog" max-width="600px" scrollable>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              ><v-icon dark>mdi-plus</v-icon> {{ $t('patient.nueva_evolucion') }}</v-btn
            >
          </template>
          <v-card>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h3 style="margin-top: -30px">
                      <strong>{{ formTitle }}</strong>
                    </h3>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.anamnesis" :label="$t('patient.anamnesis')" />
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.exploracion" :label="$t('patient.exploracion')" />
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.juicioclinico" :label="$t('patient.juicioclinico')" />
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.tratamiento" :label="$tc('patient.tratamiento', 1)" />
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.fecha"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.fecha"
                          :label="$t('common.date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedItem.fecha"
                        no-title
                        scrollable
                        locale="es"
                        first-day-of-week="1"
                        @change="$refs.menu.save(editedItem.fecha)"
                      >
                        <v-spacer />
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(editedItem.fecha)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click.native="close">{{ $t('common.cancel') }}</v-btn>
              <v-btn color="blue darken-1" text @click.native="save">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="evolutions"
        class="elevation-1"
        :items-per-page="5"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td class="hidden-xs-only">{{ props.item.anamnesis_tabla }}</td>
            <td class="hidden-xs-only">{{ props.item.exploracion_tabla }}</td>
            <td class="hidden-xs-only">{{ props.item.juicioclinico_tabla }}</td>
            <td class="hidden-xs-only">{{ props.item.tratamiento_tabla }}</td>
            <td @click="editItem(props.item)">{{ props.item.fecha_formato }}</td>
            <td class="action_col">
              <v-btn slot="activator" icon ripple @click="editItem(props.item)">
                <v-icon color="grey">mdi-pencil</v-icon>
              </v-btn>
              <v-btn slot="activator" icon ripple @click="deleteItem(props.item)">
                <v-icon color="grey">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template slot="no-data">
          <div class="grey--text text-center pa-5">
            {{ $t('patient.sin_evoluciones') }}
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar Diagnostico -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('patient.delete_evolucion') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('patient.confirm_delete_evolucion') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false">
              <v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}
            </v-btn>
            <v-btn color="red darken-1" text @click.native="removeEvolution">
              <v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { evolucionPacienteUrl, getHeader } from '@/config/config';

export default {
  name: 'Evoluciones',
  props: {
    patientId: {
      type: String,
      default: '',
      required: true,
    },
    evolutions: {
      type: Array,
      default: () => [],
      required: true,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    contentBg: {
      type: String,
      default: 'white',
    },
  },

  data() {
    return {
      dialog: false,
      fecha_actual: new Date(),
      headers: [
        { text: this.$t('patient.anamnesis'), value: 'anamnesis_tabla', sortable: false, class: 'hidden-xs-only' },
        { text: this.$t('patient.exploracion'), value: 'exploracion_tabla', sortable: false, class: 'hidden-xs-only' },
        {
          text: this.$t('patient.juicioclinico'),
          value: 'juicioclinico_tabla',
          sortable: false,
          class: 'hidden-xs-only',
        },
        {
          text: this.$tc('patient.tratamiento', 1),
          value: 'tratamiento_tabla',
          sortable: false,
          class: 'hidden-xs-only',
        },
        { text: this.$t('common.date'), value: 'fecha', sortable: true },
        { text: this.$t('common.actions'), value: '', sortable: false },
      ],
      items: this.evolutions,
      editedIndex: -1,
      editedItem: {
        id: 0,
        evolucion: '',
        anamnesis: '',
        exploracion: '',
        juicioclinico: '',
        tratamiento: '',
        fecha: '',
        fecha_formato: '',
      },
      defaultItem: {
        id: 0,
        evolucion: '',
        anamnesis: '',
        exploracion: '',
        juicioclinico: '',
        tratamiento: '',
        fecha: '',
        fecha_formato: '',
      },
      dialog_delete: {
        name: '',
        dialog: false,
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        patient_id: this.patientId,
        action: '',
        indice: 0,
        evolucion: {},
      },
      menu: false,
    };
  },
  computed: {
    formTitle() {
      // TODO: FIEXME!!!!
      if (this.editedIndex === -1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.editedItem.fecha =
          this.fecha_actual.getFullYear() +
          '-' +
          (this.fecha_actual.getMonth() + 1) +
          '-' +
          this.fecha_actual.getDate();
      }
      return this.editedIndex === -1 ? this.$t('patient.nueva_evolucion') : this.$t('patient.editar_evolucion');
    },
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    editItem(item) {
      this.editedIndex = this.evolutions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.evolutions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_delete.dialog = true;
      // confirm('Are you sure you want to delete this item?') && ;
    },

    removeEvolution() {
      this.dialog_delete.dialog = false;
      this.dataPost.action = 'eliminar';
      this.dataPost.evolucion = this.editedItem.id;
      this.$http.post(evolucionPacienteUrl, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data === '1') {
          this.evolutions.splice(this.editedIndex, 1);
          this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.delete_evolucion') });
        } else {
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
        }
      });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    validateForm() {
      let result = true;
      if (
        this.editedItem.anamnesis === '' &&
        this.editedItem.evolucion === '' &&
        this.editedItem.exploracion === '' &&
        this.editedItem.juicioclinico === '' &&
        this.editedItem.tratamiento === ''
      ) {
        this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.form_empty') });
        result = false;
      }
      return result;
    },
    save() {
      if (!this.validateForm()) {
        return false;
      }
      this.dataPost.action = this.editedIndex > -1 ? 'editar' : 'nuevo';
      this.dataPost.evolucion = this.editedItem;
      this.dataPost.indice = this.editedIndex;
      this.$http.post(evolucionPacienteUrl, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data.estado === 1) {
          const msg =
            this.dataPost.action === 'editar'
              ? this.$t('success.save_edit_evolucion')
              : this.$t('success.save_new_evolucion');
          this.showAlert({ color: 'success', icon: 'mdi-check', message: msg });
          if (this.dataPost.indice > -1) {
            this.dataPost.evolucion.anamnesis_tabla = this.dataPost.evolucion.anamnesis.substr(0, 50);
            this.dataPost.evolucion.exploracion_tabla = this.dataPost.evolucion.exploracion.substr(0, 50);
            this.dataPost.evolucion.juicioclinico_tabla = this.dataPost.evolucion.juicioclinico.substr(0, 50);
            this.dataPost.evolucion.tratamiento_tabla = this.dataPost.evolucion.tratamiento.substr(0, 50);
            this.dataPost.evolucion.fecha_formato = response.data.evolucion.fecha_front;
            Object.assign(this.evolutions[this.dataPost.indice], this.dataPost.evolucion);
          } else {
            this.dataPost.evolucion.id = response.data.id;
            this.dataPost.evolucion.anamnesis_tabla = this.dataPost.evolucion.anamnesis.substr(0, 50);
            this.dataPost.evolucion.exploracion_tabla = this.dataPost.evolucion.exploracion.substr(0, 50);
            this.dataPost.evolucion.juicioclinico_tabla = this.dataPost.evolucion.juicioclinico.substr(0, 50);
            this.dataPost.evolucion.tratamiento_tabla = this.dataPost.evolucion.tratamiento.substr(0, 50);
            this.dataPost.evolucion.fecha_formato = response.data.evolucion.fecha_front;
            this.dataPost.evolucion.fecha = response.data.evolucion.fecha;
            this.evolutions.unshift(this.dataPost.evolucion);
          }
        } else {
          this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
        }
      });
      this.close();
    },
  },
};
</script>

<style>
.action_col {
  min-width: 120px;
}
</style>
