<template>
  <v-card>
    <v-card-text>
      <v-row dense align="center">
        <v-col cols="9" md="5" sm="5">
          <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
            <div class="subtitle-1 font-weight-bold cursor-pointer" @click="goToPatient(videoconsultation.paciente_id)">
              {{ videoconsultation.fullName }}
              {{ isHrDepartment || isReceptionist ? ' [' + videoconsultation.nombreMarket + ']' : '' }}
              <div v-if="showReasonConsultation" class="body-2">
                {{
                  videoconsultation.motivo_corto || `${$t('videoconsulta.singular')} ${videoconsultation.tipo.nombre}`
                }}
              </div>
            </div>
            <div class="caption text-no-wrap">
              <v-icon small light class="mr-1">mdi-clock-outline</v-icon>
              {{ videoconsultation.fecha }}
            </div>
          </div>
        </v-col>
        <v-col cols="7" md="4" sm="4" class="pl-4 text-center d-flex flex-column flex-md-row">
          <v-col class="hidden-xs-only">
            <v-chip small color="red lighten-3" text-color="white" class="mx-2">
              {{ $t('videoconsulta.sin_confirmar') }}
            </v-chip>
            <tag-info v-if="videoconsultation.metadata.length > 0" :metadata="videoconsultation.metadata" />
          </v-col>
        </v-col>
        <v-col cols="12" sm="3" md="3" align="right" class="align-self-md-center">
          <v-btn depressed color="primary" small class="mr-3" @click="confirmVideoconsultation">
            {{ $t('common.confirm') }}
          </v-btn>
          <v-btn icon small elevation="0" @click="showVideoconsultationMenu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-if="!isMobileVersion"
        v-model="showMenu"
        :position-x="menuPositionX"
        :position-y="menuPositionY"
        offset-overflow
        absolute
      >
        <v-list>
          <v-list-item v-for="(option, index) in menuOptions" :key="index" @click="option.onClick()">
            <v-list-item-title :class="option.color ? `${option.color}--text` : ''">
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-bottom-sheet v-if="isMobileVersion" v-model="showMenu">
        <v-list class="pa-0 rounded-t-xl">
          <v-list-item v-for="(option, index) in menuOptions" :key="index" class="py-3" @click="option.onClick()">
            <v-list-item-title
              class="text-button text-center"
              :class="option.color ? `${option.color}--text` : ''"
              @click="option.onClick()"
            >
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import TagInfo from '@/views/videoconsultation/components/TagInfo';
import {navigateToPatient} from "@/services/navigation";
export default {
  name: 'UnconfirmedVideoconsultationItem',
  components: { TagInfo },
  props: {
    videoconsultation: {
      type: Object,
      required: true,
    },
    showReasonConsultation: {
      type: Boolean,
      required: true,
    },
    isHrDepartment: {
      type: Boolean,
      required: true,
    },
    isReceptionist: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showMenu: false,
      menuPositionX: 0,
      menuPositionY: 0,

      menuOptions: [
        {
          title: `${this.$t('common.edit')} ${this.$t('common.videoconference').toLowerCase()}`,
          onClick: this.editVideoconsultation,
        },
        { title: this.$t('common.delete'), onClick: this.deleteVideoconsultation },
      ],
    };
  },
  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    goToPatient(id) {
      navigateToPatient(id, this.$router);
    },
    isJSON(str) {
      try {
        return JSON.parse(str) && !!str;
      } catch (e) {
        return false;
      }
    },
    confirmVideoconsultation() {
      this.$emit('confirm-videoconsultation');
    },
    showVideoconsultationMenu(event) {
      if (!this.isMobileVersion) {
        this.menuPositionX = event.clientX;
        this.menuPositionY = event.clientY;
      }
      this.showMenu = true;
    },
    editVideoconsultation() {
      this.$emit('edit-videoconsultation');
      this.showMenu = false;
    },
    deleteVideoconsultation() {
      this.showMenu = false;
      this.$emit('delete-videoconsultation');
    },
  },
};
</script>
<style lang="css">
.body-2 {
  margin-top: -4px;
}
</style>
