<template>
  <div>
    <modal :title="titleTreatmentForm" :show="show">
      <div>
        <patient-display
          :key="componentKey"
          :patient="patient"
          :show-change-patient="showChangePatient"
          @changePatient="openSelPatientModal()"
          @editPatient="openEditPatient()"
          @patient-must-complete-email="handlePatientMustCompleteEmail"
        />

        <template v-if="!patientMustCompleteEmail">
          <treatment-form
            v-if="Object.keys(fields).length > 0"
            v-model="fields.treatment"
            :title="titleTreatmentForm"
            :error-diagnostic="error_diagnostic"
          />
          <prescription-form
            v-if="!isEdit"
            v-model="fields.prescription"
            :title="$t('recipe.recipe')"
            :error-units="errorUnits"
            :error-duration="errorDuration"
            :error-frecuency="errorFrecuency"
            :error-medicine="errorMedicine"
            :patient="patient"
            :show-change-patient="showChangePatient"
            @changePatient="openSelPatientModal()"
            @editPatient="openEditPatient()"
            @patient-must-complete-email="handlePatientMustCompleteEmail"
          />

          <dropdown-box-section v-if="!isEdit" :title="$t('videoconsultations.link')">
            <VideoconsultationSelector
              v-model="fields.treatment.videoconsultationId"
              :label="$t('videoconsultation_selector.select_videoconsultation')"
              :patient-id="patientId"
              :professional-id="professionalId"
            />
          </dropdown-box-section>

          <!-- patient confirmation checkbox -->
          <div>
            <v-checkbox
              v-model="patientConfirmation"
              :error="patientConfirmationError"
              :label="`${$t('prescriptions.patientIndentityConfirmationText')}*`"
              @change="$event && (patientConfirmationError = false)"
            />
          </div>
          <!-- actions -->
          <div class="actions row">
            <v-btn class="col-xs-6" color="primary" dark text @click="close">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn :color="butColor" :text="!isEdit" class="col-xs-6" @click="checkForPatientConfirmationBeforeSubmit">
              {{ $t('common.save') }}
            </v-btn>
            <span class="mb-12 hidden-sm-and-up" />
            <v-btn
              v-if="!editTreatmentId && isValidCollegiateNumber"
              class="col-xs-12 white--text"
              :color="butColor"
              depressed
              @click="showEmitConfirm"
            >
              {{ $t('common.emitSignPrescription') }}
            </v-btn>
          </div>
        </template>

        <template v-else>
          <div class="actions">
            <v-btn color="primary" dark text @click="close">
              {{ $t('common.cancel') }}
            </v-btn>
          </div>
        </template>
      </div>
      <!-- Snackbar para mostrar avisos -->
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="alert.color"
        top="top"
        :multi-line="mode === 'multi-line'"
        :vertical="mode === 'vertical'"
        :class="{ 'avoid-snackbar-scroll': patientMustCompleteEmail }"
      >
        <v-icon v-if="alert.icon !== ''">{{ alert.icon }}</v-icon
        >&nbsp;{{ alert.text }}
      </v-snackbar>

      <!-- confirm dialog -->
      <v-dialog v-model="emitPresc.dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">{{ $t('recipe.confirm_emit_prescription') }}</v-card-title>
          <v-card-text>
            {{ $t('recipe.confirm_emmit_prescription_text') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click="emitPresc.dialog = false">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              :color="butColor"
              dark
              text
              @click="saveAndEmit(emitPresc.prescription_id, emitPresc.user_id, emitPresc.patient_id)"
            >
              {{ $t('common.emitir_receta') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editPatient.dialog" max-width="600px" persistent>
        <v-card>
          <edit-patient :patient_id="patient" @close-dialog="closeEditPatient" @reloadPatient="reloadPatientData" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDurationConfirmBeforeSave" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            {{ $t('common.notice') }}
          </v-card-title>
          <v-card-text>
            <span class="text-subtitle-1">
              {{ $t('prescriptions.long_duration_prescription_confirmation_notice') }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click="showDurationConfirmBeforeSave = false">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn :color="butColor" dark text @click="acceptDurationBeforeSave">
              {{ $t('common.aceptar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 2fas pincode dialog -->
      <two-fa-pincode-dialog
        :show="showPincodePrompt"
        :text="$t('2fa.verifyCodePrompText')"
        :accept-button-text="$t('2fa.verify')"
        @close="showPincodePrompt = false"
        @pincode="handlePincode"
      />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TreatmentForm from '@/components/treatments/TreatmentForm.vue';
import PrescriptionForm from '@/components/treatments/PrescriptionForm.vue';
import PatientDisplay from '@/components/treatments/PatientDisplay.vue';
import EditPatient from '@/components/ehealth/paciente/Edit.vue';
import { prescriptionSaveUrl, treatmentsUrl, emitPrescriptionUrl, getTreatmentUrl } from '@/config/config';
import { signText } from '@/utils/AutoFirma';
import { featureToggleHandler } from '@/utils/featureToggle';
import { MAXIMUM_NARCOTIC_PACKAGES } from '@/config/constants';
import TwoFaPrescriptionInterceptDialogMixin from '@/mixins/TwoFaPrescriptionInterceptDialogMixin.js';
import PrescriptionFieldsCheck from '@/mixins/PrescriptionFieldsCheck';
import { COVID_PACKAGE_LIMIT, NO_COVID_PACKAGE_LIMIT } from '@/services/medicineComputedData';
import VideoconsultationSelector
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationSelector.vue";

export default {
  name: 'TreatmentModal',

  components: {
    VideoconsultationSelector,
    TreatmentForm,
    PrescriptionForm,
    PatientDisplay,
    EditPatient,
  },

  mixins: [TwoFaPrescriptionInterceptDialogMixin, PrescriptionFieldsCheck],

  model: {
    prop: 'show',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Number,
      required: true,
    },

    patient: {
      type: Number,
      required: true,
    },

    showChangePatient: {
      type: Boolean,
      default: true,
    },

    editTreatmentId: {
      type: Number,
      required: false,
      default: 0,
    },

    butColor: {
      type: String,
      default: 'primary',
      required: false,
    },

    recipeRenewData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      patientConfirmationError: false,
      patientConfirmation: false,
      sending: false,
      error: null,
      fields: this.getFieldsInitialData(),
      isEdit: false,
      snackbar: false,
      timeout: 5000,
      mode: '',
      alert: {
        text: '',
        icon: 'mdi-message-text',
        color: 'info',
      },
      emitPresc: {
        dialog: false,
        prescription_id: null,
        user_id: null,
        patient_id: null,
      },
      editPatient: {
        dialog: false,
      },
      error_diagnostic: false,
      errorUnits: false,
      errorDuration: false,
      errorFrecuency: false,
      errorMedicine: false,
      isValidCollegiateNumber: this.setIsValidCollegiateNumber(),
      componentKey: 0,

      showDurationConfirmBeforeSave: false,
      isCompanyEmittingPrescriptionsBySMS: JSON.parse(window.localStorage.getItem('smsNotificationsEnabled')),
      patientMustCompleteEmail: false
    };
  },

  computed: {
    recipeEmittedMessage() {
      const emitMethodsArray = [this.$t('success.emit_method_email')];
      this.isCompanyEmittingPrescriptionsBySMS && emitMethodsArray.push(this.$t('success.emit_method_sms'));
      const emitMethods = emitMethodsArray.join(` ${this.$t('common.and')} `);
      return this.$t('success.recipe_emmited', { methods: emitMethods });
    },
    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      twoFaEnabled: 'account/twoFaEnabled',
    }),

    maxPackageLimitHomologation() {
      return this.repHomologation ? NO_COVID_PACKAGE_LIMIT : COVID_PACKAGE_LIMIT;
    },

    isRenew() {
      return this.recipeRenewData !== null;
    },
    titleTreatmentForm() {
      if (this.isRenew) {
        return this.$t('recipe.renew_prescription');
      } else if (this.isEdit) {
        return this.$t('common.edit') + ' ' + this.$t('videoconsulta.tratamiento').toLowerCase();
      } else {
        return this.$t('recipe.create_treatment');
      }
    },
    patientId() {
      return this.patient;
    },
    professionalId() {
      return this.user;
    }
  },

  watch: {
    editTreatmentId() {
      if (typeof this.editTreatmentId !== 'undefined' && this.editTreatmentId !== 0) {
        this.getTreatmentEditData(this.editTreatmentId);
        this.isEdit = true;
      }
    },
    recipeRenewData(val) {
      this.isEdit = false;
      this.fields.treatment = {
        ...val.treatment,
        treatmentStart: new Date(),
        treatmentEnd: null,
      };
      delete this.fields.treatment.id;
      delete this.fields.treatment.createdAt;
      delete this.fields.treatment.updatedAt;
      this.fields.prescription = {
        ...val.prescription,
        medicines: val.prescription.medicines.map(medicine => {
          const dateStart = this.$moment(new Date()).format('YYYY-MM-DD');
          const dateEnd = this.$moment(new Date())
            .add(medicine.duration, medicine.duration_measure)
            .format('YYYY-MM-DD');

          return {
            ...medicine,
            date_start: dateStart,
            date_end: dateEnd,
          };
        }),
      };
    },
  },

  methods: {
    ...mapActions('layout', ['showAlert']),
    ...mapActions('prescription', ['masterFormulasValidate']),
    ...mapActions('overlay', ['handleOverlay']),

    checkForPatientConfirmationBeforeSubmit() {
      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return;
      }

      this.onSubmit();
    },

    handlePatientMustCompleteEmail(mustCompleteEmail) {
      this.patientMustCompleteEmail = mustCompleteEmail;
    },

    getFieldsInitialData() {
      return {
        treatment: {
          diagnostic: '',
          chronic: false,
          confidential: false,
          treatmentStart: new Date(),
          treatmentEnd: null,
          comments: '',
          videoconsultationId: null,
        },

        prescription: {
          type: 1,
          tinCode: '',
          masterFormula: '',
          medicines: [
            {
              medicineId: null,
              units: 0,
              posology: '',
              posology_measure: 'hours',
              duration: '',
              duration_measure: 'days',
              guideline: '',
              comments: '',
            },
          ],

          masterFormulas: [],
        },
        isSaveAndEmit: false,
      };
    },

    setIsValidCollegiateNumber() {
      const collegiateNumber =
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['collegiateNumber'];
      return collegiateNumber && collegiateNumber.length === 9;
    },

    showEmitConfirm(prescription_id, user_id, patient_id) {
      if (this.failCheckMinimumFields()) {
        const textAlert = this.$t('errors.form_invalid');
        this.$toast.error(textAlert);
        return;
      }

      if (this.failCheckMaxPackages()) {
        const textAlert = this.$t('prescriptions.maxPackagesWarningNoAdjust', {
          limit: this.maxPackageLimitHomologation,
        });
        this.$toast.error(textAlert);
        return;
      }

      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return;
      }

      if (this.useNewAccountPage && this.twoFaEnabled) {
        this.showPincodePrompt = true;

        this.methodToProceedWith = {
          name: 'saveAndEmit',
          params: [],
        };
        return;
      }

      this.emitPresc.dialog = true;
      this.emitPresc.prescription_id = prescription_id;
      this.emitPresc.user_id = user_id;
      this.emitPresc.patient_id = patient_id;
    },

    saveAndEmit() {
      this.isSaveAndEmit = true;
      this.emitPresc.dialog = false;
      this.onSubmit();
    },

    async emmitRecipe(prescription_id, user_id, patient_id, dataSign) {
      this.emitPresc.dialog = false;
      this.showPincodePrompt = false;
      this.sending = true;
      await this.handleOverlay(true);

      const signData = featureToggleHandler('signature_enabled') ? await signText(JSON.stringify(dataSign)) : [];

      this.$http
        .post(
          emitPrescriptionUrl,
          { id: prescription_id, user_id: user_id, patient_id: patient_id, sign_data: signData },
          { headers: this.getRequestHeaders() }
        )
        .then(response => {
          if (response.status === 200 && parseInt(response.body.status) === 1) {
            this.showMessageWithLink(this.recipeEmittedMessage);
          } else if (response.status === 200 && parseInt(response.body.status) === 2) {
            this.$toast.error(this.$t('errors.emit_e_prescription'));
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          if (err?.data?.error?.code === 'invalid_treatment_status') {
            this.$toast.error(this.$t('common.alreadyDiscardedPrescription'), {
              timeout: 10000,
            });
          } else {
            const textAlert = this.$t(err?.data?.error?.message || 'errors.try_again');
            this.$toast.error(textAlert);
          }
        })
        .finally(async () => {
          this.loadTreatment();
          this.reset();
          await this.handleOverlay(false);
        });
    },

    onSubmit() {
      if (!this.validateBeforeSave()) {
        return;
      }
      if (this.someMedicineDurationNeedsConfirmation() && !this.repHomologation) {
        this.showDurationConfirmBeforeSave = true;
      } else {
        this.save();
      }
    },
    validateBeforeSave() {
      if (this.someMedicineExceedsMaxDuration()) {
        this.$toast.error(this.$t('prescriptions.some_medicine_exceeds_max_duration_error'));
        return false;
      }
      if (this.someNarcoticExceedsMaxPackages()) {
        this.$toast.error(
          this.$t('prescriptions.narcotic_number_of_packages_exceeded_error', { num: MAXIMUM_NARCOTIC_PACKAGES })
        );
        return false;
      }
      if (this.isMixingNarcoticWithOtherMedicines()) {
        this.$toast.error(this.$t('prescriptions.cant_mix_narcotics_with_others_error'));
        return false;
      }

      if (!this.isEdit && this.failCheckMinimumFields()) {
        this.$toast.error(this.$t('errors.form_invalid'));
        return false;
      }

      if (this.failCheckMaxPackages()) {
        const textAlert = this.$t('prescriptions.maxPackagesWarningNoAdjust', {
          limit: this.maxPackageLimitHomologation,
        });
        this.$toast.error(textAlert);
        return;
      }

      return true;
    },

    someMedicineExceedsMaxDuration() {
      // A medicine duration cannot exceed 365 days
      const hasDurationError = this.fields.prescription.medicines.some(item => {
        const startDate = this.$moment(item.date_start);
        const endDate = this.$moment(item.date_end);
        return startDate.add(365, 'days') < endDate;
      });
      return hasDurationError;
    },

    someNarcoticExceedsMaxPackages() {
      // A narcotic cannot be prescribed in a quantity exceeding 4 packages.
      const hasPackagesError = this.fields.prescription.medicines.some(item => {
        return item.narcotic && item.units > 4;
      });
      return hasPackagesError;
    },

    isMixingNarcoticWithOtherMedicines() {
      if (this.fields.prescription.medicines.length + this.fields.prescription.masterFormulas.length > 1) {
        return this.fields.prescription.medicines.some(item => item.narcotic);
      }
      return false;
    },

    someMedicineDurationNeedsConfirmation() {
      // A medicine needs to be confirmed when it isn't a narcotic and its duration exceeds 1 month.
      const needsConfirmation = this.fields.prescription.medicines.some(item => {
        const startDate = this.$moment(item.date_start);
        const endDate = this.$moment(item.date_end);
        return !item.narcotic && startDate.add(1, 'months') < endDate;
      });
      return needsConfirmation;
    },

    acceptDurationBeforeSave() {
      this.showDurationConfirmBeforeSave = false;
      this.save();
    },

    async save() {
      let response;

      try {
        this.error = null;
        this.validate();
        this.sending = true;
        await this.handleOverlay(true);
        let signData = {};

        const data = {
          userId: this.user,
          tinCode: this.fields.prescription.tinCode,
          patientId: this.patient,
          patientConfirmed: this.patientConfirmation,
          ...this.fields.treatment,
        };

        if (data.treatmentStart instanceof Date) {
          data.treatmentStart = this.formatDate(data.treatmentStart);
        }

        if (data.treatmentEnd instanceof Date) {
          data.treatmentEnd = this.formatDate(data.treatmentEnd);
        }

        const arrData = this.convertObjectToArray(data);

        if (this.isEdit) {
          response = await this.sendPutRequest(treatmentsUrl + this.editTreatmentId, arrData);
          this.isEdit = false;
        } else {
          let arrPresc = this.convertObjectToArray({
            tinCode: this.fields.prescription.tinCode,
            patientConfirmed: this.patientConfirmation,
            ...this.fields.prescription,
          });

          signData = arrPresc;
          arrPresc = this.deleteMedicinesKeyIfHasNotMedicines(arrPresc);
          arrPresc = this.masterFormulasRemoveEmpty(arrPresc);
          response = await this.sendRequest(prescriptionSaveUrl, { treatment: arrData, prescriptions: arrPresc });
        }

        if (this.isSaveAndEmit) {
          await this.emmitRecipe(response.id, this.user, this.patient, signData);
          this.isSaveAndEmit = false;
        } else {
          this.loadTreatment();
          this.showMessageWithLink(this.$t('recipe.treatment_created'));
          await this.handleOverlay(false);
          this.reset();
        }
      } catch (e) {
        this.error = e.message;
        await this.handleOverlay(false);
      } finally {
        this.close();
      }
    },

    validate() {
      let emptyMedicine = false;
      this.error_diagnostic = false;
      this.errorUnits = false;
      this.errorDuration = false;
      this.errorFrecuency = false;

      const defaultPosologyMeasure = 'hours';
      const defaultDurationMeasure = 'days';

      // if (this.fields.treatment.diagnostic === '') {
      //   this.error_diagnostic = true;

      //   this.$toast.error(this.$t('recipe.err_empty_diagnostic'));
      //   throw new Error(this.$t('recipe.err_empty_diagnostic'));
      // }

      if (!this.isEdit) {
        if (this.fields.prescription.type === 1 && this.fields.prescription.tinCode === '') {
          this.fields.prescription.tinCode = 0;
        }

        try {
          this.masterFormulasValidate(this.fields.prescription.masterFormulas);
          // Validate master formulas composition
          const compositionNotCompleted = this.fields.prescription.masterFormulas.some(mf => !mf.composition);
          if (compositionNotCompleted) {
            const errMessage = this.$t('recipe.err_empty_composition');
            this.$toast.error(errMessage);
            throw new Error(errMessage);
          }
        } catch (e) {
          this.$toast.error(e.message);
          throw new Error(e.message);
        }

        if (this.fields.prescription.masterFormula === '') {
          this.fields.prescription.medicines.forEach(medicine => {
            if (
              medicine.medicineId === null &&
              medicine.guideline === '' &&
              medicine.posology === '' &&
              medicine.duration === ''
            ) {
              return;
            }

            if (medicine.guideline === '') {
              this.errorUnits = true;
              this.$toast.error(this.$t('recipe.err_empty_guideline'));
              throw new Error(this.$t('recipe.err_empty_guideline'));
            }

            if (medicine.posology === '') {
              this.errorFrecuency = true;
              this.$toast.error(this.$t('recipe.err_empty_posology'));
              throw new Error(this.$t('recipe.err_empty_posology'));
            }

            if (medicine.duration === '') {
              this.errorDuration = true;
              this.$toast.error(this.$t('recipe.err_empty_duration'));
              throw new Error(this.$t('recipe.err_empty_duration'));
            }

            if (typeof medicine.posology_measure !== 'undefined' && medicine.posology_measure === '') {
              medicine.posology_measure = defaultPosologyMeasure;
            }
            if (typeof medicine.duration_measure !== 'undefined' && medicine.duration_measure === '') {
              medicine.duration_measure = defaultDurationMeasure;
            }

            if (medicine.medicineId === null && !emptyMedicine) {
              emptyMedicine = true;
            }
          });
        }

        if (this.fields.prescription.masterFormula === '' && emptyMedicine) {
          this.$toast.error(this.$t('recipe.err_empty_medicine'));
          throw new Error(this.$t('recipe.err_empty_medicine'));
        }
      }
    },

    async sendRequest(url, data) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: this.getRequestHeaders(),
        }).then(res => res.json());

        this.showPincodePrompt = false;

        if (response?.errors) {
          this.$toast.error(response.errors[Object.keys(response.errors)[0]][0] ?? '');
          throw new Error(response.errors[Object.keys(response.errors)[0]][0] ?? '');
        }

        if (response?.error?.message || !response.success) {
          const msg = response?.error?.message ?? response.message;
          this.$toast.error(this.$t(msg) || msg);
          throw new Error(msg);
        }

        return response.data;
      } catch (e) {
        throw new Error(this.$t(e.message) || e.message);
      }
    },

    async sendPutRequest(url, data) {
      try {
        const response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: this.getRequestHeaders(),
        }).then(res => res.json());

        this.showPincodePrompt = false;

        if (!response.success) {
          throw new Error(response.message);
        }
        this.loadTreatment();

        return response.data;
      } catch (e) {
        throw new Error(e.message);
      }
    },

    async sendGetRequest(url) {
      try {
        const response = await fetch(url).then(res => res.json());

        if (!response.success) {
          throw new Error(response.message);
        }

        return response.data;
      } catch (e) {
        throw new Error(e.message);
      }
    },

    reset() {
      this.sending = false;
      this.error = null;
      this.fields = this.getFieldsInitialData();
    },

    close() {
      this.$emit('input', false);
      this.reset();
    },

    openSelPatientModal() {
      this.$emit('openSelectPatient');
    },

    openEditPatient() {
      this.editPatient.dialog = true;
    },

    closeEditPatient() {
      this.editPatient.dialog = false;
    },

    loadTreatment() {
      this.$emit('reloadTreatment');
    },

    formatDate(date) {
      if (!date) return null;

      return [date.getFullYear(), `0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2)].join('-');
    },

    convertObjectToArray(data) {
      const arr = {};
      Object.keys(data).map(function(key) {
        if (typeof data[key] !== 'undefined') {
          arr[key] = data[key];
        }
      });

      return arr;
    },

    async getTreatmentEditData(treatmentId) {
      const treatmentEdit = await this.sendRequest(getTreatmentUrl, {
        id: treatmentId,
        userId: this.user,
        patientId: this.patient,
      });

      const fields = this.getFieldsInitialData();
      fields.treatment = {
        diagnostic: treatmentEdit.diagnostic,
        chronic: treatmentEdit.chronic,
        confidential: treatmentEdit.confidential,
        treatmentStart: new Date(treatmentEdit.treatmentStart),
        treatmentEnd: new Date(treatmentEdit.treatmentEnd),
        comments: treatmentEdit.comments,
      };

      this.fields = fields;
    },

    showMessage(message, color, icon) {
      this.showAlert({
        color: color,
        icon: icon,
        message: message,
      });
    },

    deleteMedicinesKeyIfHasNotMedicines(arrPresc) {
      arrPresc.medicines = arrPresc.medicines.filter(medicine => {
        return (
          medicine.medicineId !== null ||
          medicine.guideline !== '' ||
          medicine.posology !== '' ||
          medicine.duration !== ''
        );
      });

      return arrPresc;
    },

    masterFormulasRemoveEmpty(arrPresc) {
      arrPresc.masterFormulas = arrPresc.masterFormulas.filter(masterFormula => {
        return masterFormula.composition !== '' || masterFormula.dosage !== '' || masterFormula.takingUnit !== '';
      });

      return arrPresc;
    },

    showMessageWithLink(message) {
      // showMessageWithLink(message, color, icon, patientId, prescription_id) {
      // const link = '/paciente/' + patientId + '?receta=' + prescription_id;
      // TODO: mostrar este mensaje y desplegar en paciente -> recetas el desplegable de recetas electrónicas
      // message + '<br><a href="' + link + '" class="text-uppercase">' + this.$t('recipe.see_prescription') + '</a>'
      this.$toast.success(message);
    },

    reloadPatientData() {
      this.componentKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}

.content-center {
  text-align: center;
}

::v-deep .v-application--wrap {
  min-height: unset !important;
}

.v-card__text,
.v-card__title {
  word-break: normal;
}

.isSending ::v-deep .modal-box {
  max-height: 10em !important;
  overflow-y: unset;
}

.avoid-snackbar-scroll {
  height: unset;
}
</style>
