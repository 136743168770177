<template>
  <div class="parapharmacy-product-selected__container">
    <div class="parapharmacy-product-selected__data">
      <div class="parapharmacy-product-selected__info">
        <span class="parapharmacy-product-selected__name">{{ item.name }}</span
        ><span class="parapharmacy-product-selected__dosage">, {{ item.dosage }} {{ item.administrationUnit }}</span>
      </div>
      <div class="parapharmacy-product-selected__lab">
        {{ item.laboratory }}
        <div>
          <span class="parapharmacy-product-selected__code">({{ item.nationalCode }})</span>
        </div>
      </div>
    </div>
    <div class="parapharmacy-product-selected__actions">
      <div class="parapharmacy-product-selected__action" @click="undo">
        <div class="img-svg__container">
          <RemoveIcon />
        </div>
      </div>
      <div class="parapharmacy-product-selected__action" @click="openInfo">
        <div class="img-svg__container">
          <ProductInfoIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RemoveIcon from "@/assets/icons/eliminar.svg";
import ProductInfoIcon from "@/assets/icons/eliminar.svg";

export default {
  name: 'ParapharmacyProductSelected',
  components: {RemoveIcon, ProductInfoIcon},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openInfo(item) {
      this.$emit('openedInfo');
    },
    undo() {
      this.$emit('undo');
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$greyLight: #fafafa;
$greyDark: #757575;

.parapharmacy-product-selected {
  &__container {
    border: solid 2px $grey;
    border-radius: 3px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
  }

  &__data {
    flex-grow: 1;
    padding: rem(8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info {
    text-transform: uppercase;
  }

  &__lab {
    color: $greyDark;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__code {
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }

  &__name {
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: bold;
  }

  &__dosage {
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action {
    background-color: #fafafa;
    border-left: solid 2px $grey;
    width: 50px;
    min-height: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__action:first-of-type {
    border-bottom: solid 2px $grey;
  }
}

@media only screen and (min-width: 992px) {
  .parapharmacy-product-selected {
    &__actions {
      flex-direction: row;
    }

    &__action {
      width: 60px;
      border-left: solid 2px $grey;
    }

    &__action:first-of-type {
      border-bottom: none;
    }

    &__data {
      flex-direction: row;
      justify-content: space-between;
    }

    &__info {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__lab {
      min-width: 234px;
    }
  }
}

.parapharmacy-product-selected__container {
  &
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
