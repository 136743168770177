<template>
  <div class="duration-field">
    <span class="duration-field-title" :style="'color:' + titleColor">
      {{ $t('common.duracion') }}
    </span>

    <div class="duration-field-items">
      <v-text-field v-model="durationAmount" type="number" step="1" min="1" max="100" @blur="checkNumber" />

      <v-select v-model="durationUnit" item-value="value" :items="units" @blur="checkNumber" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    amount: {
      type: Number,
      default: 1,
    },

    unit: {
      type: [String, null],
      default: null,
    },
  },

  data: () => ({
    durationAmount: 1,
    durationUnit: null,
  }),

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),

    titleColor() {
      return this.appThemePrimary || '#1976d2';
    },

    units() {
      return [
        { text: this.$t('recipe.days'), value: this.$t('recipe.days') },
        { text: this.$t('recipe.weeks'), value: this.$t('recipe.weeks') },
        { text: this.$t('recipe.months'), value: this.$t('recipe.months') },
      ];
    },
  },

  watch: {
    amount() {
      this.durationAmount = this.amount;
    },
    unit() {
      this.durationUnit = this.unit;
    },
  },

  created() {
    if (this.unit === null) {
      this.durationUnit = this.$t('recipe.days');
      this.update(this.durationAmount, this.durationUnit);
    }
  },

  methods: {
    update(amount, unit) {
      this.$emit('update', { amount, unit });
    },

    checkNumber() {
      const min = 1;
      const max = 100;

      if (!isNaN(this.durationAmount) && this.durationAmount !== '') {
        this.durationAmount = parseInt(String(this.durationAmount));
      } else {
        this.durationAmount = 1;
      }

      if (0 > max && this.durationAmount > max) {
        this.durationAmount = max;
      }
      if (this.durationAmount < min) {
        this.durationAmount = min;
      }
      this.update(this.durationAmount, this.durationUnit);
    },
  },
};
</script>

<style lang="scss" scoped>
.duration-field-title {
  margin-bottom: 0.8em;
  font-weight: bold;
  font-size: 1rem;
}

.duration-field-items {
  display: flex;

  > div:not(:first-child) {
    margin-left: 2rem;
  }
}
</style>
