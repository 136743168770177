<template>
  <div>
    <div v-if="isLoadingCurrentPersonalDoctor" class="mt-4 pt-1">
      <v-progress-circular indeterminate :width="2" size="22" color="primary" class="mr-2" />
      <span class="personal-doctors-assignment-checkbox__label">{{
        $t('personal_doctor.is_your_personal_doctor')
      }}</span>
    </div>
    <v-checkbox v-else v-model="isYourPersonalDoctor" hide-detail @click="checked">
      <template v-slot:label>
        <div>
          {{ $t('personal_doctor.is_your_personal_doctor') }}
        </div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import { useAssignmentPersonalDoctor } from '@/use/PersonalDoctors/useAssignmentPersonalDoctor';

export default {
  props: {
    patientId: {
      type: String,
      default: null,
    },
    professionalId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {
      currentPersonalDoctor,
      isLoadingCurrentPersonalDoctor,
      isNetworkError,
      retrievePatientPersonalDoctor,
      isYourPersonalDoctor,
    } = useAssignmentPersonalDoctor();

    return {
      currentPersonalDoctor,
      isLoadingCurrentPersonalDoctor,
      isNetworkError,
      isYourPersonalDoctor,
      retrievePatientPersonalDoctor,
    };
  },

  data: function() {
    return {
      isYourPersonalDoctor: false,
    };
  },

  watch: {
    isYourPersonalDoctor(val) {
      this.$emit('personalDoctorChanged', val);
    },
    currentPersonalDoctor(val) {
      this.isYourPersonalDoctor = val === this.professionalId;
    },
  },

  created() {
    if (this.patientId) {
      this.retrievePatientPersonalDoctor(this.patientId, this.companyId);
    }
  },

  methods: {
    checked() {
      this.$emit('personalDoctorChecked');
    },
  },
};
</script>

<style>
.personal-doctors-assignment-checkbox__label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
</style>
