import { mapGetters } from 'vuex';
import TwoFaPincodeDialog from '@/components/2fa/TwoFaPincodeDialog';
import { TWO_FA_HEADER } from '@/config/constants';
import { getHeader } from '@/config/config';

export default {
  name: 'TwoFaPrescriptionInterceptDialogMixin',

  components: {
    TwoFaPincodeDialog,
  },

  data() {
    return {
      showPincodePrompt: false,
      pincode: '',
      methodToProceedWith: null,
      pincodeKey: 0,
    };
  },

  computed: {
    ...mapGetters({
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      twoFaEnabled: 'account/twoFaEnabled',
    }),
  },

  methods: {
    handlePincode({ code }) {
      this.pincode = code;
      this[this.methodToProceedWith.name](...this.methodToProceedWith.params);
    },

    getRequestHeaders() {
      const defaultHeaders = getHeader();

      if (this.useNewAccountPage && this.twoFaEnabled) {
        defaultHeaders[[TWO_FA_HEADER]] = this.pincode;
      }

      return defaultHeaders;
    },

    resetPincodeDialog() {
      this.hidePincodeDialog();
      this.pincode = '';
      this.pincodeKey++;
    },

    hidePincodeDialog() {
      this.showPincodePrompt = false;
    },
  },
};
