import { ref } from 'vue';
import { getTemplate } from '@/services/parapharmacy';

export function useParapharmacyTemplate(id) {
  const fields = ref([]);
  const isLoadingTemplate = ref(false);
  const isNetworkError = ref(false);

  const retrieveTemplate = async () => {
    try {
      isNetworkError.value = false;
      isLoadingTemplate.value = true;
      const response = await getTemplate(id);
      fields.value = response.fields.map(field => {
        return { ...field, value: null };
      });
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingTemplate.value = false;
    }
  };

  return {
    fields,
    isLoadingTemplate,
    isNetworkError,
    retrieveTemplate,
  };
}
