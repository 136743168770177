<template>
  <div id="videoconsultas">
    <div class="cabecera_nuevo_mensaje mb-3">
      <v-btn data-test="patient-edit-button-new-video" color="primary" depressed dark @click.stop="abrirNuevaVideo('video')">
        {{ $t('videoconsulta.nueva_video') }}
      </v-btn>
    </div>
    <v-layout wrap class="px-5 pb-4">
      <v-flex sm12 lg12>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4 v-if="videoconsultas.porconfirmar.length > 0" class="header_mensajes">
                <v-badge right color="red lighten-1">
                  <span slot="badge">{{ videoconsultas.porconfirmar.length }}</span>
                  <span>{{ $t('videoconsulta.pendientes') }}</span>
                </v-badge>
              </h4>
              <h4 v-else>{{ $t('videoconsulta.pendientes') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(item, index) in videoconsultas.porconfirmar"
                :key="item.title"
                class="item_porconfirmar"
                ripple
              >
                <div class="item_row_porconfirmar">
                  <v-layout wrap>
                    <v-flex xs7>
                      <h5>
                        <strong>
                          <span>
                            {{
                              item.paciente_id === 0
                                ? $t('videoconsulta.multiconferencia')
                                : $t('videoconsulta.singular')
                            }}
                            -
                          </span>
                          {{ item.motivo_corto || item.motivo }}
                        </strong>
                      </h5>
                    </v-flex>
                    <v-flex xs5 class="text-right pr-md-5">
                      <span class="fecha_video">{{ item.fecha }}</span>
                    </v-flex>
                    <v-flex xs5 class="motivo_video pl-md-2">
                      <span class="mb-1">
                        <v-chip color="red lighten-3" text-color="white" class="mr-1" label>
                          {{ $t('videoconsulta.sin_confirmar') }}
                        </v-chip>
                        <tag-info
                          v-if="item.metadata.length > 0"
                          :metadata="item.metadata"
                          :label="true"
                          :small="false"
                        />
                      </span>
                    </v-flex>
                    <v-flex xs7 class="text-right pr-md-5 botones_video">
                      <v-btn depressed color="primary" small class="mr-3" @click="mostrarConfirmDialog(item, 1, index)">
                        {{ $t('common.confirm') }}
                      </v-btn>
                      <v-btn icon @click="mostrarConfirmDialog(item, 2, index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </div>
              </div>

              <div v-for="(item, index) in videoconsultas.pendientes" :key="item.title" class="item_video_div" ripple>
                <v-layout wrap>
                  <v-flex xs7 class="pl-md-2">
                    <h5>
                      <strong>
                        <span>
                          {{
                            item.paciente_id === 0 ? $t('videoconsulta.multiconferencia') : $t('videoconsulta.singular')
                          }}
                          -
                        </span>
                        {{ item.motivo_corto || item.motivo }}
                      </strong>
                    </h5>
                  </v-flex>
                  <v-flex xs5 class="text-right pr-md-5">
                    <span class="fecha_video">{{ item.fecha }}</span>
                  </v-flex>
                  <v-flex xs8 class="motivo_video pl-md-2 d-flex flex-column flex-md-row">
                    <SpecificationsVideo :item="item" :tiene-video="tieneVideo" class="mr-1" :small="false" />
                    <tag-info v-if="item.metadata.length > 0" :metadata="item.metadata" :label="true" :small="false" />
                  </v-flex>
                  <v-flex xs4 class="text-right pr-md-5 botones_video">
                    <v-menu v-if="item.archives !== null" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(archive, i) in item.archives"
                          :key="i"
                          @click="downloadArchive(archive, item.id)"
                        >
                          <v-list-item-title>
                            <v-icon>mdi-radiobox-marked</v-icon>
                            {{ archive.createdAt | moment($t('common.short_date_format')) }} ({{
                              archive.durationSeconds
                            }}
                            seg)
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn
                      v-else-if="
                        item.confirmado === 1 &&
                          item.tipo.presencial !== 2 &&
                          tieneVideo &&
                          !is_receptionist &&
                          !is_admin
                      "
                      icon
                      @click="abrirVideoConsulta(item.id, item.rand)"
                    >
                      <v-icon>mdi-video</v-icon>
                    </v-btn>
                    <v-btn icon @click.stop="verVideoconsulta(item, index, 'pendientes')">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4 :style="videoconsultas.terminadas.length === 0 ? 'color: #909c9c' : ''">
                {{ $t('videoconsulta.terminadas') }}
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(item, index) in videoconsultas.terminadas" :key="item.title" class="item_video_div" ripple>
                <v-layout wrap>
                  <v-flex xs7 class="pl-md-2">
                    <h5>
                      <strong>
                        <span>
                          {{
                            item.paciente_id === 0 ? $t('videoconsulta.multiconferencia') : $t('videoconsulta.singular')
                          }}
                          -
                        </span>
                        {{ item.motivo_corto || item.motivo }}
                      </strong>
                    </h5>
                  </v-flex>
                  <v-flex xs5 class="text-right pr-md-5">
                    <span class="fecha_video">{{ item.fecha }}</span>
                  </v-flex>
                  <v-flex xs8 class="motivo_video pl-md-2">
                    <span>
                      <v-chip v-if="item.confirmado === 1" label outlined color="primary lighten-3">
                        <v-icon left>mdi-label</v-icon>{{ $te(item.moreInfo) ? $t(item.moreInfo) : item.moreInfo }}
                      </v-chip>
                      <v-chip v-else label color="amber darken-3" outlined>
                        <v-icon left>mdi-credit-card-outline</v-icon>{{ $t('videoconsulta.esperando_pago') }}
                      </v-chip>
                      &nbsp;
                      <tag-info
                        v-if="item.metadata.length > 0"
                        :metadata="item.metadata"
                        :label="true"
                        :small="false"
                      />
                    </span>
                  </v-flex>
                  <v-flex xs4 class="text-right pr-md-5 pr-md-5 botones_video">
                    <v-menu v-if="item.archives !== null" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(archive, i) in item.archives"
                          :key="i"
                          @click="downloadArchive(archive, item.id)"
                        >
                          <v-list-item-title>
                            <v-icon>mdi-radiobox-marked</v-icon
                            >{{ archive.createdAt | moment($t('common.short_date_format')) }} ({{
                              archive.durationSeconds
                            }}
                            seg)
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn icon @click.stop="verVideoconsulta(item, index, 'terminadas')">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>

    <!-- Modal Nuevo Servicio Videoconsulta -->
    <v-dialog
      v-model="dialog_nuevavideo"
      :fullscreen="tamVideo"
      max-width="640px"
      transition="dialog-bottom-transition"
      :overlay="false"
    >
      <nuevavideo
        :pacientes="pacientes"
        :tipos="videoconsultas.tipos"
        :pack="{}"
        :tipo="tipoNuevaVideo"
        :profesionales="profesionales"
        :especialidades="especialidades"
        :specialties="specialties"
        @clickedVolver="dialog_nuevavideo = false"
        @clickedNuevo="onNuevoChild"
        @reloadvideos="reloadVideos"
      />
    </v-dialog>
    <!-- Fin Modal Nuevo Servicio Videoconsulta -->
    <!-- Modal Ver Videoconsulta -->
    <v-dialog v-model="dialog_video" :fullscreen="tamVideo" max-width="640px" scrollable>
      <videoconsulta
        :videoconsulta="selected.video"
        :tipos="videoconsultas.tipos"
        :tipo="tipoNuevaVideo"
        :tiene-video="tieneVideo"
        @clickedVolver="dialog_video = false"
        @clickedCancelado="canceladoVideoconsultaChild"
        @clickedModificado="onModificadoChild"
      />
    </v-dialog>
    <!-- Fin Modal Ver -->
    <!-- Modal Confirmar/Eliminar -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog_confirm.titulo }}</span>
          </v-card-title>
          <v-card-text>
            <p>{{ dialog_confirm.texto }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="disabledButton" color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              :disabled="disabledButton"
              :color="dialog_confirm.color + ' darken-1'"
              text
              @click.native="
                dialog_confirm.dialog = false;
                confirmadaAccion();
              "
              ><v-icon>{{ dialog_confirm.icono }}</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }} </v-icon>&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import Videoconsulta from '@/components/ehealth/videoconsulta/Videoconsulta.vue';
import Nuevavideo from '@/components/ehealth/videoconsulta/Nueva.vue';
import {
  nameEnv,
  appVideoUrl,
  cancelarVideoconsultaUrl,
  confirmarVideoconsultaUrl,
  downloadVideoconsultationUrl,
  getHeader,
} from '@/config/config';
import SpecificationsVideo from '../videoconsulta/SpecificationsVideo.vue';
import { mapActions } from 'vuex';
import TagInfo from '@/views/videoconsultation/components/TagInfo';

export default {
  name: 'Videoconsultas',
  components: {
    TagInfo,
    SpecificationsVideo,
    Videoconsulta,
    Nuevavideo,
  },
  props: {
    idpaciente: {
      type: String,
      default: '',
    },
    videoconsultas: {
      type: Object,
      default: () => [],
    },
    pacientes: {
      type: Array,
      default: () => [],
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
    tieneVideo: {
      type: Boolean,
      default: true,
    },
    profesionales: {
      type: Array,
      default: () => [],
    },
    specialties: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      fecha_actual: new Date(),
      isLoadingDownload: false,
      snackbar: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      disabledButton: false,
      dialog_nuevavideo: false,
      dialog_video: false,
      dialog_confirm: {
        dialog: false,
        tipo: 0,
        titulo: '',
        texto: '',
        icono: '',
        color: '',
      },
      selected: {
        estado: '',
        video: {},
        indice: 0,
      },
      tamVideo: window.innerWidth <= 800,
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      tipoNuevaVideo: 'video',
      tiposvideo: [],
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      is_admin:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
    };
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    downloadArchive(archive, videoId) {
      if (!this.isLoadingDownload) {
        this.isLoadingDownload = true;
        const params = {
          id: this.authUser.id,
          videoId: videoId,
          archiveId: archive.id,
        };
        this.$http
          .post(downloadVideoconsultationUrl, params, { headers: getHeader() })
          .then(response => {
            window.open(response.data.url);
          })
          .catch(err => {
            this.$log.error(err);
            let msg = 'Ocurrió un error!';
            if (err && err.body && err.body.error) {
              msg = this.$t(err.body.error);
            }
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
          })
          .finally(() => {
            this.isLoadingDownload = false;
          });
      }
    },

    abrirNuevaVideo(tipo) {
      this.dialog_nuevavideo = true;
      this.tipoNuevaVideo = tipo;
      if (this.authUser !== null) {
        const intercom = document.getElementsByClassName('intercom-launcher')[0];
        if (typeof intercom !== 'undefined' && intercom !== null) {
          intercom.style.display = 'none';
        }
      }
    },
    abrirVideoConsulta(id, code) {
      const lang = this.$i18n.locale !== 'es' ? '?lang=' + this.$i18n.locale : '';
      const lang_index = this.$i18n.locale !== 'es' ? '&lang=' + this.$i18n.locale : '';
      let ruta = '';
      if (appVideoUrl === 'https://docline.io' || nameEnv === 'ev2x') {
        ruta = appVideoUrl + '/?code=' + code + lang_index;
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Fakeeh') {
        ruta = 'https://app_fakeeh.docline.io/#/codigo/' + code + '/ar';
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Your Brand') {
        ruta = 'https://appyourbrand.docline.io/#/codigo/' + code + lang;
      } else {
        ruta = appVideoUrl + '/c/' + code + lang;
      }
      window.open(ruta, '_blank');
    },
    verVideoconsulta(video, index, tipo) {
      let volcadotipo = false;
      this.videoconsultas.tipos.forEach(function(t) {
        if (t.id === video.tipo && !volcadotipo) {
          video.tipo = t;
          volcadotipo = true;
        }
      });
      this.selected.video = video;
      this.selected.indice = index;
      this.selected.tipo = tipo;
      this.dialog_video = true;
    },
    mostrarConfirmDialog(video, tipo, indice) {
      this.dialog_confirm.titulo =
        tipo === 1
          ? this.$t('common.confirm') + ' ' + this.$t('videoconsulta.singular')
          : this.$t('common.cancel') + ' ' + this.$t('videoconsulta.singular');
      this.dialog_confirm.texto =
        tipo === 1 ? this.$t('videoconsulta.confirm_video') : this.$t('videoconsulta.confirm_delete');
      this.dialog_confirm.icono = tipo === 1 ? 'mdi-check' : 'mdi-delete';
      this.dialog_confirm.color = tipo === 1 ? 'green' : 'red';
      this.selected.video = video;
      this.selected.indice = indice;
      this.dialog_confirm.tipo = tipo;
      this.dialog_confirm.dialog = true;
    },
    onModificadoChild(respuesta) {
      this.alerta.texto = this.$t('success.save_common');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-video-wireless-outline';
      this.snackbar = true;
      this.dialog_video = false;
      this.videoconsultas.pendientes[this.selected.indice] = respuesta.videoconsulta;
    },
    canceladoVideoconsultaChild(_respuesta) {
      this.dialog_video = false;
      this.alerta.texto = this.$t('videoconsulta.cancelado_correctamente');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-delete';
      this.snackbar = true;
      if (this.selected.tipo === 'pendientes') {
        this.videoconsultas.pendientes.splice(this.selected.indice, 1);
      } else if (this.selected.tipo === 'terminadas') {
        this.videoconsultas.terminadas.splice(this.selected.indice, 1);
      }
    },
    onNuevoChild(respuesta) {
      if (respuesta.estado === '1') {
        this.videoconsultas['pendientes'].push(respuesta.videoconsulta);
      }
      this.alerta.texto =
        respuesta.estado === '1'
          ? this.$t('videoconsulta.enviado_correctamente')
          : this.$t('videoconsulta.enviado_recordatorio');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-video-wireless-outline';
      this.snackbar = true;
      this.dialog_nuevavideo = false;
    },

    confirmadaAccion() {
      this.disabledButton = true;
      this.loading = true;
      const url = this.dialog_confirm.tipo === 1 ? confirmarVideoconsultaUrl : cancelarVideoconsultaUrl;
      this.$http
        .post(
          url,
          { user_id: this.authUser.id, videoconsulta_id: this.selected.video.id, paciente_id: this.idpaciente },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            if (this.dialog_confirm.tipo === 1) {
              this.alerta.texto = this.$t('videoconsulta.confirmado_correctamente');
              this.alerta.icono = 'mdi-check';
            } else {
              this.alerta.texto = this.$t('videoconsulta.cancelado_correctamente');
              this.alerta.icono = 'mdi-delete';
            }
            this.alerta.color = 'success';
            window.localStorage.setItem('mensaje_ehealth', JSON.stringify(this.alerta));
            this.$router.go(this.$router.currentRoute);
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
            this.loading = false;
            this.snackbar = true;
            this.disabledButton = false;
          }
        });
    },

    reloadVideos() {
      this.dialog_nuevavideo = false;
      this.$emit('loadvideos');
    },
  },
};
</script>

<style>
.cabecera_nuevo_mensaje {
  margin: 20px 20px -10px 0px;
  text-align: right;
}

.header_mensajes .v-badge__badge {
  margin-top: 4px !important;
  margin-right: 3px !important;
}

.fecha_video {
  text-align: right;
}

.item_video_div {
  margin: 10px;
}

.item_porconfirmar {
  background-color: #f7f7f3;
}

.item_row_porconfirmar {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 8px;
}

.motivo_video {
  color: #5d5d5d;
}

.botones_video {
  margin-top: -4px;
  margin-bottom: 4px;
}
</style>
