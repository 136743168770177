<template>
  <div>
    <v-data-table :headers="headers" :items="masterFormulas" hide-default-footer class="elevation-1">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.composition }}
            <v-chip small color="grey" class="white--text mx-1">
              {{ getMedicamentStatus(item.status) }}
            </v-chip>
            <span v-show="item.status === medicamentStatusCode('LOCKED')">
              {{ getMedicamentLockedReasons(item.block_status_code) }}
              {{ item.block_observations }}
            </span>
          </td>
          <td>{{ item.dosage }}</td>
          <td>{{ item.takingAmount + ' ' + item.takingUnit }}</td>
          <td>{{ item.durationAmount + ' ' + item.durationUnit }}</td>
          <td>{{ item.observations }}</td>

          <v-btn
            v-if="item.status === medicamentStatusCode('LOCKED')"
            :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
            :disabled="!isValidCollegiateNumber"
            depressed
            small
            style="margin-left: 4px"
            @click.native.stop
            @click="$emit('anulateMedicament', item.id, 'formula')"
          >
            {{ $t('recipe.anulate_medicament') }}
          </v-btn>
          <v-btn
            v-if="item.status === medicamentStatusCode('LOCKED')"
            :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
            :disabled="!isValidCollegiateNumber"
            depressed
            small
            style="margin-left: 4px"
            @click.native.stop
            @click="$emit('unlockMedicament', item.id, 'formula')"
          >
            {{ $t('recipe.unlock_medicament') }}
          </v-btn>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getMedicamentStatus, getMedicamentStatusCode, getMedicamentLockedReasons } from '@/utils/getMedicamentStatus';

export default {
  name: 'MasterFormulaTable',
  props: {
    masterFormulas: {
      type: Array,
      default: () => [],
    },
    isValidCollegiateNumber: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('recipe.master_formula') + ' - ' + this.$t('recipe.composition'),
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('recipe.dosage'), value: 'type', align: 'left', sortable: false },
        { text: this.$t('recetas.posologia'), value: 'posology', align: 'left', sortable: false },
        { text: this.$t('common.duracion'), value: 'duration', align: 'left', sortable: false },
        { text: this.$t('recipe.observations'), value: 'comments', align: 'left', sortable: false },
      ],
    };
  },
  methods: {
    getMedicamentStatus(status) {
      return getMedicamentStatus(status);
    },
    medicamentStatusCode(code) {
      return getMedicamentStatusCode(code);
    },
    getMedicamentLockedReasons(block_status_code) {
      return getMedicamentLockedReasons(block_status_code);
    },
  },
};
</script>
<style scoped></style>
