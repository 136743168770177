<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="9" md="5" sm="5">
          <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
            <div class="subtitle-1 font-weight-bold cursor-pointer" @click="goToPatient(videoconsultation.paciente_id)">
              {{
                videoconsultation.paciente_id === 0 ? $t('videoconsulta.multiconferencia') : videoconsultation.fullName
              }}
              {{
                (isHrDepartment && !isDoctorClic) || isReceptionist ? ' [' + videoconsultation.nombreMarket + ']' : ''
              }}
              <div v-if="showReasonConsultation" class="body-2">
                {{
                  videoconsultation.motivo_corto || `${$t('videoconsulta.singular')} ${videoconsultation.tipo.nombre}`
                }}
              </div>
            </div>
            <div class="caption text-no-wrap">
              <v-icon small light class="mr-1">mdi-clock-outline</v-icon>
              {{ videoconsultation.fecha }}
            </div>
          </div>
        </v-col>

        <v-col class="pr-md-4 align-self-md-center hidden-xs-only" cols="7" md="4" sm="4" align="end">
          <v-chip v-if="videoconsultation.es_pack" label color="cyan darken-2" text-color="white" class="mx-2 mb-1">
            <v-icon left>mdi-image-filter-none</v-icon>PACK
          </v-chip>
          <template v-else class="mx-2 mb-1">
            <v-chip
              v-if="videoconsultation.confirmado === 1"
              label
              small
              :color="confirmedVideoconsultationColor"
              text-color="white"
            >
              {{ $te(videoconsultation.moreInfo) ? $t(videoconsultation.moreInfo) : videoconsultation.moreInfo }}
            </v-chip>
            <v-chip v-else label small color="amber darken-3" text-color="white" class="long-chip">
              {{ $t('videoconsulta.esperando_pago') }}
            </v-chip>
          </template>
          &nbsp;
          <tag-info v-if="videoconsultation.metadata.length > 0" :metadata="videoconsultation.metadata" />
        </v-col>

        <v-col cols="12" sm="3" md="3" align="right" class="align-self-end align-self-md-center">
          <VideoTableRowMenu
            v-if="videoconsultation.archives"
            :archives-list="videoconsultation.archives"
            :videoconsultation-id="videoconsultation.id"
            :on-download-archive="onDownloadArchive"
          />
          <v-btn icon small elevation="0" @click="showVideoconsultationMenu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-if="!isMobileVersion"
        v-model="showMenu"
        :position-x="menuPositionX"
        :position-y="menuPositionY"
        offset-overflow
        absolute
      >
        <v-list>
          <v-list-item v-for="(option, index) in menuOptions" :key="index" @click="option.onClick()">
            <v-list-item-title :class="option.color ? `${option.color}--text` : ''">
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-bottom-sheet v-if="isMobileVersion" v-model="showMenu">
        <v-list class="pa-0 rounded-t-xl">
          <v-list-item v-for="(option, index) in menuOptions" :key="index" class="py-3" @click="option.onClick()">
            <v-list-item-title
              class="text-button text-center"
              :class="option.color ? `${option.color}--text` : ''"
              @click="option.onClick()"
            >
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import VideoTableRowMenu from '@/views/videoconsultation/components/VideoTableRowMenu.vue';
import TagInfo from '@/views/videoconsultation/components/TagInfo';
import {navigateToPatient} from "@/services/navigation";

export default {
  name: 'FinishedVideoconsultationItem',
  components: {
    TagInfo,
    VideoTableRowMenu,
  },
  props: {
    videoconsultation: {
      type: Object,
      required: true,
    },
    showReasonConsultation: {
      type: Boolean,
      required: true,
    },
    isHrDepartment: {
      type: Boolean,
      required: true,
    },
    isReceptionist: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showMenu: false,
      menuPositionX: 0,
      menuPositionY: 0,
      confirmedVideoconsultationColor: '#7DBC00',

      menuOptions: [
        { title: this.$t('common.show_details'), onClick: this.showVideoconsultationDetails },
        { title: this.$t('common.delete'), onClick: this.deleteVideoconsultation },
      ],
    };
  },
  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    goToPatient(id) {
      navigateToPatient(id, this.$router);
    },
    showVideoconsultationMenu(event) {
      if (!this.isMobileVersion) {
        this.menuPositionX = event.clientX;
        this.menuPositionY = event.clientY;
      }
      this.showMenu = true;
    },
    cancelMenuOptions() {
      this.showMenu = false;
    },
    showVideoconsultationDetails() {
      this.$emit('show-videoconsultation-details');
      this.showMenu = false;
    },
    deleteVideoconsultation() {
      this.showMenu = false;
      this.$emit('delete-videoconsultation');
    },
  },
};
</script>

<style lang="css" scoped>
.long-chip {
  height: auto;
  white-space: normal;
}
</style>
