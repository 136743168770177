<template>
  <div>
    {{ $t('common.version_trial') }}
    <div class="actions">
      <v-btn color="primary" dark text @click="$emit('closeModal')">
        {{ $t('common.ok') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TrialMsg',

  data: () => ({
    butColor: 'info',
  }),

  methods: {},
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
