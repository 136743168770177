<template>
  <div id="dialogForm">
    <span slot="widget-content">
      <v-dialog v-model="dialog.show" dialog_delete max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog.title }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text v-if="dialog.type === 'form'">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex v-for="(input, index) in dialog.form" :key="'input_' + index" xs12 sm6 md6>
                  <v-textarea
                    v-if="input.type === 'textarea'"
                    v-model="input.model.value"
                    :label="input.label"
                    rows="2"
                  />
                  <span v-else-if="input.type === 'select'">
                    <v-select
                      v-model="input.model.value"
                      :items="input.items"
                      :label="input.label"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </span>
                  <span v-else-if="input.type === 'datepicker'">
                    <v-menu
                      ref="dateMenu"
                      v-model="dateMenu"
                      class="mr-2"
                      lazy
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      :return-value.sync="input.model.value"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateFormatted"
                          :label="input.label"
                          append-icon="mdi-calendar"
                          readonly
                          class="input_fnacimiento"
                          v-on="on"
                          @blur="date = parseDate(dateFormatted)"
                        />
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="date"
                        min="2000-01-01"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :locale="$i18n.locale"
                        @input="dateMenu = false"
                      />
                    </v-menu>
                  </span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-text v-else>
            <p>{{ dialog.text }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog.show = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              :color="dialog.button.color"
              text
              @click.native="
                dialog.show = false;
                confirmAction(dialog.button.action);
              "
              ><v-icon>{{ dialog.button.icon }}</v-icon> {{ dialog.button.text }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DialogForm',

  props: {
    dialog: {
      type: Object,
      default: () => ({
        show: false,
        title: '',
        text: '',
        type: '',
        form: [],
        button: {},
      }),
    },
  },

  data() {
    return {
      date: null,
      dateFormatted: null,
      dateMenu: false,
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    dateMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  methods: {
    confirmAction(action) {
      let validAction = true;
      if (this.dialog.type === 'form') {
        this.dialog.form.forEach(input => {
          if (input.type === 'datepicker') {
            input.model.value = this.date;
          }
          if (input.required && input.model.value === '') {
            validAction = false;
          }
        });
      }
      if (validAction) {
        this.$emit(action, true);
      } else {
        this.$emit('showSnackbar', {
          text: this.$t('errors.form_invalid'),
          color: 'warning',
          icon: 'mdi-alert-circle',
        });
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
};
</script>
