import { ref } from 'vue';

import { computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, minLength } from '@vuelidate/validators';
import { createDiagnose, editDiagnose, deleteDiagnose } from '@/services/diagnoses';

export const MIN_CHARACTERS = 3;

export function useDiagnoses() {
  const submittedWithErrors = ref(false);
  const submittingForm = ref(false);
  const isNetworkError = ref(false);

  const diagnoseId = ref(null);
  const subject = ref(null);
  const anamnesis = ref(null);
  const diagnoses = ref(null);
  const treatments = ref(null);
  const recommendations = ref(null);
  const videoconsultationId = ref(null);

  const validationRules = computed(() => ({
    subject: {
      required: helpers.withMessage('Falta el Motivo de la consulta', required),
      minLength: helpers.withMessage(`El mínimo de caracteres son ${MIN_CHARACTERS}`, minLength(MIN_CHARACTERS)),
      $autoDirty: true,
    },
    anamnesis: {
      required: helpers.withMessage('Falta Anamnesis', required),
      minLength: helpers.withMessage(`El mínimo de caracteres son ${MIN_CHARACTERS}`, minLength(MIN_CHARACTERS)),
      $autoDirty: true,
    },
    diagnoses: {
      required: helpers.withMessage('Faltan los Diagnósticos', required),
      minLength: helpers.withMessage(`El mínimo de caracteres son ${MIN_CHARACTERS}`, minLength(MIN_CHARACTERS)),
      $autoDirty: true,
    },
    treatments: {
      required: helpers.withMessage('Falta el Tratamiento/Tratamientos', required),
      minLength: helpers.withMessage(`El mínimo de caracteres son ${MIN_CHARACTERS}`, minLength(MIN_CHARACTERS)),
      $autoDirty: true,
    },
    recommendations: {
      required: helpers.withMessage('Faltan las Recomendaciones', required),
      minLength: helpers.withMessage(`El mínimo de caracteres son ${MIN_CHARACTERS}`, minLength(MIN_CHARACTERS)),
      $autoDirty: true,
    },
  }));

  const v$ = useVuelidate(validationRules, { subject, anamnesis, diagnoses, treatments, recommendations }, { $scope: false });

  const validateDiagnoseForm = () => {
    submittedWithErrors.value = false;
    v$.value.$touch();
    submittedWithErrors.value = v$.value.$invalid;
  };

  const resetForm = () => {
    diagnoseId.value = null;
    subject.value = null;
    anamnesis.value = null;
    diagnoses.value = null;
    treatments.value = null;
    recommendations.value = null;
    videoconsultationId.value = null;
  };

  const submitCreateDiagnoseForm = async patientId => {
    submittingForm.value = true;
    try {
      await createDiagnose(
        {
          subject: subject.value,
          anamnesis: anamnesis.value,
          diagnostics: diagnoses.value,
          treatments: treatments.value,
          recommendations: recommendations.value,
          videoconsultationId: videoconsultationId.value
        },
        patientId
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      submittingForm.value = false;
    }
  };

  const submitEditDiagnoseForm = async patientId => {
    submittingForm.value = true;
    try {
      await editDiagnose(
        diagnoseId.value,
        {
          subject: subject.value,
          anamnesis: anamnesis.value,
          diagnostics: diagnoses.value,
          treatments: treatments.value,
          recommendations: recommendations.value,
          videoconsultationId: videoconsultationId.value
        },
        patientId
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      submittingForm.value = false;
    }
  };

  const submitDeleteDiagnose = async patientId => {
    submittingForm.value = true;

    try {
      await deleteDiagnose(diagnoseId.value, patientId);
    } catch (e) {
      throw new Error(e);
    } finally {
      submittingForm.value = false;
    }
  };

  const fillDiagnoseForm = diagnose => {
    diagnoseId.value = diagnose.id;
    subject.value = diagnose.subject;
    anamnesis.value = diagnose.anamnesis;
    diagnoses.value = diagnose.diagnosticos;
    treatments.value = diagnose.tratamientos;
    recommendations.value = diagnose.recomendaciones;
    videoconsultationId.value = diagnose.videoconsultationId;
  };

  const subjectValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.subject.$errors.map(error => error.$message) : null;
  });

  const anamnesisValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.anamnesis.$errors.map(error => error.$message) : null;
  });

  const diagnosesValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.diagnoses.$errors.map(error => error.$message) : null;
  });

  const treatmentsValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.treatments.$errors.map(error => error.$message) : null;
  });

  const recommendationsValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.recommendations.$errors.map(error => error.$message) : null;
  });

  return {
    diagnoseId,
    subject,
    anamnesis,
    diagnoses,
    treatments,
    recommendations,
    videoconsultationId,

    isNetworkError,
    v$,
    submittingForm,
    submittedWithErrors,

    resetForm,
    validateDiagnoseForm,

    submitCreateDiagnoseForm,
    submitEditDiagnoseForm,
    submitDeleteDiagnose,
    fillDiagnoseForm,

    subjectValidationError,
    anamnesisValidationError,
    diagnosesValidationError,
    treatmentsValidationError,
    recommendationsValidationError,
  };
}
