import { getHeader, parapharmacyTemplatesUrl, parapharmacyTemplateUrl } from '@/config/config';
import axios from 'axios';

export const getTemplates = async (professionalId, page, size) => {
  const url = new URL(parapharmacyTemplatesUrl(professionalId));
  const params = {};

  if (page) {
    params.page = page;
  }

  if (size) {
    params.page = size;
  }

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;

  // return {
  //   data: [
  //     {
  //       id: '4701b7bf-0327-4f18-97c6-e2662907cb24',
  //       name: 'Plantilla ejemplo 1',
  //     },
  //     {
  //       id: 'example2',
  //       name: 'Plantilla ejemplo 2',
  //     },
  //   ],
  // };
};

export const getTemplate = async templateId => {
  const url = new URL(parapharmacyTemplateUrl(templateId));
  url.search = new URLSearchParams().toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;

  // const fullFields = [
  //   {
  //     key: 'parapharmacy_product',
  //     type: 'ParapharmacyProductSelector',
  //     label: {
  //       es_ES: 'Selecciona el producto',
  //       en_EN: 'Select product',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'parapharmacy_start',
  //     type: 'Date',
  //     label: {
  //       es_ES: 'Inicio del tratamiento',
  //       en_EN: 'Treatment Start',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'parapharmacy_posology',
  //     type: 'PosologyText',
  //     label: {
  //       es_ES: 'Posología',
  //       en_EN: 'Posology',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'parapharmacy_each',
  //     type: 'Text',
  //     label: {
  //       es_ES: 'Cada',
  //       en_EN: 'Cada',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'parapharmacy_duration',
  //     type: 'Text',
  //     label: {
  //       es_ES: 'Duración del tratamiento',
  //       en_EN: 'Duración del tratamiento',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'parapharmacy_diagnostic',
  //     type: 'Textarea',
  //     label: {
  //       es_ES: 'Diagnóstico',
  //       en_EN: 'Diagnóstico',
  //     },
  //     required: false,
  //     maxLength: 320,
  //   },
  //   {
  //     key: 'parapharmacy_patient_instructions',
  //     type: 'Textarea',
  //     label: {
  //       es_ES: 'Instrucciones para el paciente',
  //       en_EN: 'Instrucciones para el paciente',
  //     },
  //     required: false,
  //     maxLength: 320,
  //   },
  //   {
  //     key: 'parapharmacy_patient_advisor',
  //     type: 'Textarea',
  //     label: {
  //       es_ES: 'Advertencias para el farmacéutico',
  //       en_EN: 'Advertencias para el farmacéutico',
  //     },
  //     required: false,
  //     maxLength: 320,
  //   },
  // ];
  //
  // const someFields = [
  //   {
  //     key: 'prescription_start',
  //     type: 'Date',
  //     label: {
  //       es_ES: 'Inicio del tratamiento',
  //       en_EN: 'Treatment Start',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'prescription_posology',
  //     type: 'Text',
  //     label: {
  //       es_ES: 'Posología',
  //       en_EN: 'Posololgy',
  //     },
  //     required: true,
  //   },
  //   {
  //     key: 'patient_instructions',
  //     type: 'Textarea',
  //     label: {
  //       es_ES: 'Instrucciones para el paciente',
  //       en_EN: 'Instrucciones para el paciente',
  //     },
  //     required: false,
  //     maxLength: 320,
  //   },
  //   {
  //     key: 'patient_advisor',
  //     type: 'Textarea',
  //     label: {
  //       es_ES: 'Advertencias para el farmacéutico',
  //       en_EN: 'Advertencias para el farmacéutico',
  //     },
  //     required: false,
  //     maxLength: 320,
  //   },
  // ];
  //
  // if (templateId === 'example2') {
  //   return {
  //     fields: someFields,
  //   };
  // }
  //
  // return {
  //   fields: fullFields,
  // };
};
