<template>
  <div class="medicine-form">
    <v-layout column>
      <v-row align="center" class="mb-2" dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="value.medicineId"
            :items="searchData"
            item-text="text"
            item-value="id"
            :label="$t('recipe.medicine') + ' *'"
            :placeholder="$t('recipe.search_medicine')"
            :loading="isSearching"
            :no-data-text="isSearching ? '' : $vuetify.noDataText"
            outlined
            dense
            hide-details
            clearable
            :error="checkMedicineError"
            :filter="() => true"
            @update:search-input="onChangeSearchVal($event)"
            @change="changeValue($event)"
            @click:clear="handleClearAutocomplete"
          >
            <template slot="selection" slot-scope="{ item }">
              <v-row class="medicine-option my-0">
                <v-col class="medicine-option__item my-0 py-0">
                  <div class="d-flex flex-row align-items-center">
                    <v-btn text icon small color="red lighten-2" class="mr-2" @click.stop="handleFav(item)">
                      <v-icon>{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                    </v-btn>
                    <span class="primary--text text-body-1">
                      {{ item.text }}, {{ item.unitAmount }} {{ getUnitTypeName(item) }}
                    </span>
                    <span v-if="item.laboratory && item.laboratory.name" class="grey--text text--darken-2 text-body-2">
                      / {{ item.laboratory.name }}
                    </span>
                    <span class="black--text text-body-1"> ({{ item.nationalCode }})</span>
                    <v-chip v-if="item.narcotic" class="ml-1" small color="secondary">
                      {{ $t('recipe.narcotic') }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-row class="mb-0">
                <v-col class="medicine-option">
                  <v-btn text icon small color="red lighten-2" class="mr-2">
                    <v-icon>{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                  </v-btn>
                  <span class="primary--text text-body-1">
                    {{ item.text }}, {{ item.unitAmount }} {{ getUnitTypeName(item) }}
                  </span>
                  <span class="grey--text text--darken-2 text-body-2"> / {{ getLaboratoryName(item) }}</span>
                  <span class="black--text text-body-1"> ({{ item.nationalCode }})</span>
                  <v-chip v-if="item.narcotic" class="ml-1" small color="secondary">
                    {{ $t('recipe.narcotic') }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>
            <template slot="append-outer" class="m-0">
              <v-btn class="ml-2" align-self="center" @click="removeMedicine">
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </template>
            <template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-alert
            v-show="matchAllergies && !forceHideAllergiesMatch"
            elevation="2"
            dense
            border="right"
            colored-border
            type="error"
            class="my-2"
          >
            {{ $t('patient.allergyDetected') }}
          </v-alert>
        </v-col>

        <v-col v-if="med_has_been_selected && value.medicineId" cols="12">
          <v-btn text color="primary" @click="showMedicineDialog = true">
            {{ $t('recipe.medicine_detail') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-flex xs12>
        <v-layout xs12 wrap class="container">
          <v-flex xs12 sm12 md4 class="posology-content">
            <span class="subtitle" :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
              {{ $t('recetas.posologia') }}
            </span>
            <v-layout xs12 wrap class="mb-1">
              <v-flex xs4>
                <v-text-field
                  v-model="value.guideline"
                  type="number"
                  :disabled="!value.medicineId"
                  min="0.25"
                  max="100"
                  step="0.25"
                  name="guideline"
                  :label="$t('recipe.units') + ' *'"
                  :error="errorUnits && value.guideline === ''"
                  @blur="checkGuidelineNumberValue"
                  @input="resetRecalculateByLimit"
                />
              </v-flex>
              <v-layout xs8 wrap class="box-unit-type" align-center justify-center>
                <div class="text-unit-type">
                  <span
                    v-show="value.guideline <= 1"
                    v-tooltip="administrationUnit.singular"
                    class="posology-column text"
                  >
                    {{ administrationUnit.singular }}
                  </span>
                  <span v-show="value.guideline > 1" v-tooltip="administrationUnit.plural" class="posology-column text">
                    {{ administrationUnit.plural }}
                  </span>
                </div>
              </v-layout>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md4 class="posology-content">
            <span
              class="subtitle text-capitalize"
              :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'"
            >
              {{ $t('recipe.every') }}
            </span>
            <v-layout xs12 wrap class="mb-1">
              <v-flex xs6>
                <div class="width-80">
                  <v-text-field
                    v-model="value.posology"
                    :label="$t('recipe.frequency') + ' *'"
                    type="number"
                    :disabled="!value.medicineId"
                    min="1"
                    max="100"
                    name="posology"
                    :error="errorFrecuency && value.posology === ''"
                    @input="resetRecalculateByLimit"
                  />
                </div>
              </v-flex>

              <v-flex xs6>
                <div class="width-80">
                  <v-select
                    v-model="default_sel_posology_measure"
                    class="select-frec fs-small"
                    :items="types"
                    item-value="value"
                    :disabled="!value.medicineId"
                    @input="resetRecalculateByLimit"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md4 class="duration-column">
            <span class="subtitle" :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
              {{ $t('common.duracion') }}
            </span>
            <v-layout xs12>
              <v-flex xs6>
                <div class="width-80">
                  <v-text-field
                    v-model="value.duration"
                    type="number"
                    :disabled="!value.medicineId"
                    min="1"
                    step="1"
                    :max="maxDuration"
                    name="duration"
                    class="posology-input"
                    :label="$t('recipe.during') + ' *'"
                    :error="errorDuration && value.duration === ''"
                    :rules="repHomologation ? durationRules : []"
                    @input="resetRecalculateByLimit"
                  />
                </div>
              </v-flex>
              <v-flex xs6 wrap>
                <div>
                  <v-select
                    v-model="default_sel_duration_measure"
                    :items="types_duration"
                    item-value="value"
                    :disabled="!value.medicineId"
                    class="select-frec fs-small"
                    @blur="resetRecalculateByLimit"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex v-if="medicineExceedsOneYearDuration" xs12>
        <v-alert type="error">
          {{ medicineExceedsOneYearDurationError }}
        </v-alert>
      </v-flex>

      <v-flex v-if="medicineExceedsMaximumDuration" xs12>
        <v-alert type="error">
          {{ medicineDurationExceededError }}
        </v-alert>
      </v-flex>

      <v-flex v-else-if="narcoticExceedsMaximumPackages" xs12>
        <v-alert type="error">
          {{ narcoticNumberOfPackagesExceededError }}
        </v-alert>
      </v-flex>

      <v-flex xs12>
        <v-layout xs12 wrap>
          <v-flex xs12 md4>
            <div class="field-display-date">
              <div class="display-title" :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
                {{ $t('recetas.num_envases') }}
              </div>
              <div class="display-text">{{ value.units }}</div>
            </div>
          </v-flex>

          <v-flex xs6 md3>
            <div class="field-display-date">
              <div class="display-title" :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
                {{ $t('common.fecha_inicio') }}
              </div>
              <div class="display-text">{{ ISODateStart | moment('DD/MM/YYYY') }}</div>
            </div>
          </v-flex>
          <v-flex xs6 md4>
            <div class="field-display-date">
              <div class="display-title" :style="appThemePrimary ? 'color:' + appThemePrimary : 'color: #1976d2'">
                {{ $t('common.fecha_fin') }}
              </div>
              <div class="display-text">{{ ISODateEnd | moment('DD/MM/YYYY') }}</div>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-row>
      <v-col cols="12">
        <v-alert
          v-show="showMaxPackagesWarning"
          elevation="2"
          dense
          border="right"
          colored-border
          type="error"
          class="my-2"
        >
          {{ $t('prescriptions.maxPackagesWarningNoAdjust', { limit: maxPackageLimitHomologation }) }}
        </v-alert>
        <v-alert
          v-show="showMedicineAlreadyPrescribe"
          elevation="2"
          dense
          border="right"
          colored-border
          type="error"
          class="my-2"
        >
          {{ $t('prescriptions.medicineAlreadyPrescribed', { date: alertMedicineDate }) }}
        </v-alert>
      </v-col>
    </v-row>

    <v-flex xs12 class="container">
      <v-layout xs12 wrap>
        <v-flex xs12 class="posology-content">
          <v-text-field
            v-model="value.diagnostic"
            type="text"
            name="diagnostic"
            maxlength="50"
            :label="$t('recipe.diagnostic')"
            autocomplete="off"
          />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 class="container">
      <v-layout xs12 wrap>
        <v-flex xs12 class="posology-content">
          <v-textarea v-model="value.comments" rows="3" :label="$t('recipe.patient_instructions')" />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 class="container">
      <v-layout xs12 wrap>
        <v-flex xs12 class="posology-content">
          <v-textarea v-model="value.pharmacist_warnings" rows="3" :label="$t('recipe.pharmacist_warnings')" />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog v-model="showMedicineDialog" max-width="750px" :fullscreen="$vuetify.breakpoint.xsOnly">
      <MedicineDetails :medicine="medicineSelected" @close="showMedicineDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapModelFields } from '@/utils';
import Alerts from '@/mixins/Alerts';
import { checkCurrentMedicineForPatient, searchMedicinesUrl, getMedicinesAmount, getHeader } from '@/config/config';
import AllergiesMixin from '@/mixins/Allergies';
import {
  NARCOTIC_DEADLINE_IN_MONTHS,
  MEDICINE_DEADLINE_IN_MONTHS,
  MAXIMUM_NARCOTIC_PACKAGES,
} from '@/config/constants';
import { COVID_PACKAGE_LIMIT, NO_COVID_PACKAGE_LIMIT } from '@/services/medicineComputedData';
import debounce from '@/utils/debounce';
import { checkFav, uncheckFav } from '@/services/favorite-med';
import environment from "@/environment";

const DEFAULT_SEARCH_STRING = '_default_';
const MAX_MEDICINES_PER_PAGE = 100;

export default {
  name: 'MedicineForm',

  components: {
    MedicineDetails: () => import('@/components/treatments/MedicineDetails'),
  },

  mixins: [Alerts, AllergiesMixin],

  props: {
    value: {
      type: Object,
      default: () => ({
        medicineId: null,
        units: 0,
        posology: '',
        posology_measure: '',
        duration: '',
        duration_measure: '',
        guideline: '',
        comments: '',
        diagnostic: '',
        pharmacist_warnings: '',
        date_start: '',
        date_end: '',
        dcsa: null,
      }),
    },
    isEditPrescription: {
      type: Boolean,
      default: false,
    },
    errorUnits: {
      type: Boolean,
      default: false,
    },
    errorDuration: {
      type: Boolean,
      default: false,
    },
    errorFrecuency: {
      type: Boolean,
      default: false,
    },
    errorMedicine: {
      type: Boolean,
      default: false,
    },
    medicationsAdded: {
      type: [Number, String],
      default: 0,
    },
    hasAddedNarcotic: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: [Number, Boolean],
      default: false,
    },
    newDateStartForSameMedicine: {
      type: [String, Boolean],
      default: false,
    },
    filterByFav: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchData: [],
      isSearching: false,
      loading: false,
      types: [],
      types_duration: [],
      default_sel_posology_measure: 'hours',
      default_sel_duration_measure: 'days',
      aux_med_name: '',
      med_has_been_selected: false,
      changeMedicine: false,
      isCheckingAmount: false,
      medicineSelected: null,
      showMedicineDialog: false,
      ISODateStart: '',
      ISODateEnd: '',
      forceHideAllergiesMatch: false,
      showMaxPackagesWarning: false,
      showMedicineAlreadyPrescribe: false,
      alertMedicineDate: '',
      covidPackageLimit: COVID_PACKAGE_LIMIT,
      hasBeenRecalculateByLimit: false,
      noCovidPackageLimit: NO_COVID_PACKAGE_LIMIT,
      hasChangeEndDate: false,
      administrationUnit: [],
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      searchText: DEFAULT_SEARCH_STRING,
      medicinesPage: 1,
      lastMedicinePage: 1,
    };
  },

  computed: {
    ...mapModelFields([
      'medicineId',
      'units',
      'posology',
      'posology_measure',
      'duration',
      'duration_measure',
      'guideline',
      'comments',
      'diagnostic',
      'pharmacist_warnings',
      'date_start',
      'date_end',
    ]),
    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),

    matchAllergies() {
      return this.allergies.some(item => {
        const elementToMatch = this.medicineSelected || this.value;

        if (item?.dcsa === elementToMatch?.dcsa) {
          return true;
        }

        const normalizedName = item?.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return elementToMatch?.text?.toLowerCase().includes(normalizedName);
      });
    },

    isNarcotic() {
      return this.value?.medicineId && this.searchData.some(item => item.id === this.value.medicineId && item.narcotic);
    },

    medicineExceedsMaximumDuration() {
      if (!this.ISODateEnd) {
        return false;
      }
      const startDate = this.$moment(this.ISODateStart);
      const deadline = this.isNarcotic
        ? startDate.add(NARCOTIC_DEADLINE_IN_MONTHS, 'months')
        : startDate.add(MEDICINE_DEADLINE_IN_MONTHS, 'months');
      return this.$moment(this.ISODateEnd) > deadline;
    },

    narcoticExceedsMaximumPackages() {
      if (!Number.isInteger(this.value.units)) {
        return false;
      }
      return this.isNarcotic && this.value.units > MAXIMUM_NARCOTIC_PACKAGES;
    },

    maxDuration() {
      return this.calcMaxDuration(environment.ePrescription.maxDurationInDays, this.duration_measure);
    },
    medicineDurationExceededError() {
      const maxMonths = this.isNarcotic ? NARCOTIC_DEADLINE_IN_MONTHS : MEDICINE_DEADLINE_IN_MONTHS;
      return this.$t('prescriptions.medicine_duration_exceeded_error', { num: maxMonths });
    },
    narcoticNumberOfPackagesExceededError() {
      return this.$t('prescriptions.narcotic_number_of_packages_exceeded_error', { num: MAXIMUM_NARCOTIC_PACKAGES });
    },

    maxPackageLimitHomologation() {
      return this.repHomologation ? NO_COVID_PACKAGE_LIMIT : COVID_PACKAGE_LIMIT;
    },

    medicineExceedsOneYearDuration() {
      if (this.hasChangeEndDate) {
        return true;
      } else if (!this.ISODateEnd) {
        return false;
      }

      const maxDate = this.$moment().add(1, 'year');
      const diffDays = this.$moment(this.ISODateEnd).diff(maxDate, 'days');
      const exceedsOneYearDuration = this.$moment(this.ISODateEnd) > maxDate;

      if (exceedsOneYearDuration) {
        const daysCurrentDuration = this.$moment(this.ISODateEnd).diff(this.ISODateStart, 'days');

        this.changeDuration(daysCurrentDuration - parseInt(diffDays) - 1);
      }

      return exceedsOneYearDuration;
    },

    medicineExceedsOneYearDurationError() {
      return this.$t('prescriptions.medicine_duration_exceeded_one_year', {
        date: this.$moment()
          .add(1, 'year')
          .format('DD/MM/YYYY'),
      });
    },

    durationRules() {
      const max = this.calcMaxDuration(environment.ePrescription.maxDurationInDays, this.duration_measure);;
      const trans = this.duration_measure;

      return [
        value => {
          return (
            !value ||
            value <= max ||
            this.$t('prescriptions.limited', { num: max, measure: this.$t(`recipe.${trans}`) })
          );
        },
      ];
    },

    checkMedicineError() {
      return this.errorMedicine && (this.value.medicineId === '' || this.value.medicineId === null);
    },

    isLastMedicinePage() {
      return this.medicinesPage === this.lastMedicinePage;
    }
  },

  watch: {
    default_sel_posology_measure(val) {
      this.posology_measure = val;
    },

    default_sel_duration_measure(val) {
      this.duration_measure = val;
    },

    isCheckingAmount(val) {
      this.$emit('onCheckingAmount', val);
    },

    'value.posology': function(val, oldVal) {
      if (val !== '' && val !== oldVal) {
        this.calculateAmount(this.value);
      }
    },

    'value.medicineId': function() {
      if (this.medicationsAdded > 1 && (this.isNarcotic || this.hasAddedNarcotic)) {
        this.$nextTick(() => {
          this.toastError(this.$t('prescriptions.cant_mix_narcotics_with_others_error'));
          this.med_has_been_selected = false;
          this.value.medicineId = null;
          this.$emit('selMedicine', {});
          this.forceHideAllergiesMatch = true;
        });
      } else {
        this.calculateAmount(this.value);
      }

      if (this.value.name) {
        this.searchText = this.value.name;
        this.searchMedicine();
      }
    },

    'value.guideline': function(val, oldVal) {
      if (val !== '' && val !== oldVal) {
        this.calculateAmount(this.value);
      }
    },

    'value.duration': function(val, oldVal) {
      debounce(() => {
        if (val !== '' && val !== oldVal) {
          this.calculateAmount(this.value);
          return;
        }

        !val && (this.showMaxPackagesWarning = false);
      }, 600);
    },

    'value.duration_measure': function(val, oldVal) {
      if (val !== '' && val !== oldVal) {
        this.calculateAmount(this.value);
      }
      this.default_sel_duration_measure = val;
    },

    'value.posology_measure': function(val, oldVal) {
      if (val !== '' && val !== oldVal) {
        this.calculateAmount(this.value);
      }
      this.default_sel_posology_measure = val;
    },

    isNarcotic(val) {
      this.value.narcotic = val;
    },

    ISODateStart(val) {
      this.value.date_start = val;
    },

    ISODateEnd(val) {
      this.value.date_end = val;
    },

    filterByFav() {
      this.searchText = this.value.name;
      this.searchMedicine();
    },
  },

  async mounted() {
    this.types = [
      { text: this.$t('recipe.hours'), value: 'hours' },
      { text: this.$t('recipe.days'), value: 'days' },
      { text: this.$t('recipe.weeks'), value: 'weeks' },
      { text: this.$t('recipe.months'), value: 'months' },
    ];

    this.types_duration = [
      { text: this.$t('recipe.days'), value: 'days' },
      { text: this.$t('recipe.weeks'), value: 'weeks' },
      { text: this.$t('recipe.months'), value: 'months' },
    ];
    if (this.value?.medicineId) {
      this.searchText = this.value.name;
      await this.searchMedicine();

      if (this.value.posology_measure !== '') {
        this.default_sel_posology_measure = this.value.posology_measure;
      }

      if (this.value.duration_measure !== '') {
        this.default_sel_duration_measure = this.value.duration_measure;
      }
    } else {
      this.loadDefaultMed();
    }

    this.posology_measure = this.default_sel_posology_measure;
    this.duration_measure = this.default_sel_duration_measure;
    this.calculateAmount(this.value);
    this.getPatientAllergies(this.patient || this.$route.params.id);
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isLastMedicinePage) {
        this.medicinesPage += 1;
        this.searchMedicine();
      }
    },

    handleClearAutocomplete() {
      this.medicineSelected = null;
      this.forceHideAllergiesMatch = true;
      this.showMaxPackagesWarning = false;
      this.default_sel_duration_measure = 'days';
      this.default_sel_posology_measure = 'hours';
      this.value.duration_measure = this.default_sel_duration_measure;
      this.value.posology_measure = this.default_sel_posology_measure;
    },

    onChangeSearchVal(searchString) {
      if (!searchString || searchString.length < 2) {
        this.searchText = DEFAULT_SEARCH_STRING;
      } else {
        this.searchText = searchString.trim();
      }

      debounce(() => {
        this.searchMedicine();

        if (this.med_has_been_selected) {
          this.value.name = searchString;
          this.changeMedicine = false;
        }
      }, 500);
    },

    searchMedicine() {
      const data = {
        searchString: this.searchText,
        professionalUuid: this.authUser.user.professional_docline_api_id,
        page: this.medicinesPage,
        limit: MAX_MEDICINES_PER_PAGE
      };

      if (this.filterByFav) {
        data.favorites = this.filterByFav;
      }

      this.isSearching = true;
      try {
        this.$http.post(searchMedicinesUrl, data, { headers: getHeader() }).then(response => {
          this.isSearching = false;
          if (response.status === 200 && response.data) {
            this.searchData = (this.medicinesPage === 1) ? response.data.data : [
              ...this.searchData,
              ...response.data.data
            ];
            this.lastMedicinePage = response.data.meta.lastPage;
          }
        });
      } catch (e) {
        this.error = e.message;
        this.isSearching = false;
      }
    },

    changeValue(id) {
      if (id && typeof id !== 'undefined') {
        this.forceHideAllergiesMatch = false;
        this.medicineSelected = this.searchData.find(item => item.id === id);
        this.value.dcsa = this.medicineSelected.dcsa;
        this.med_has_been_selected = true;
        this.$emit('selMedicine', { id, medicineSelected: this.medicineSelected });
        this.administrationUnit = this.medicineSelected.administrationUnit;
      } else {
        this.administrationUnit = [];
      }
    },

    changeMedicin() {
      this.changeMedicine = true;
      this.med_has_been_selected = false;
      this.changeMedicine = true;
      this.loadDefaultMed();
    },

    getEnglishValue(val) {
      if (val === this.$t('recipe.hours') || val === 'hours') {
        return 'hours';
      }
      if (val === this.$t('recipe.days') || val === 'days') {
        return 'days';
      }
      if (val === this.$t('recipe.weeks') || val === 'weeks') {
        return 'weeks';
      }
      if (val === this.$t('recipe.months') || val === 'months') {
        return 'months';
      }
      if (val === this.$t('recipe.year') || val === 'year') {
        return 'year';
      }
    },

    calculateAmount(medicine) {
      if (
        medicine.medicineId !== null &&
        medicine.duration !== '' &&
        medicine.posology !== '' &&
        medicine.guideline !== '' &&
        medicine.duration_measure !== '' &&
        !this.isCheckingAmount
      ) {
        if (medicine.posology_measure === '') {
          medicine.posology_measure = this.$t('recipe.hours');
        }
        this.setAdministrationUnitOnRenew(medicine);

        this.isCheckingAmount = true;

        this.$http.post(getMedicinesAmount, { medicine: medicine }, { headers: getHeader() }).then(async response => {
          if (response.status === 200 && response.data && response.body.medicine.unit_amount) {
            const amount = response.body.medicine.unit_amount;
            const takingMedicine = response.body.takingMedicine;

            if (typeof amount !== 'undefined') {
              medicine.units = takingMedicine.packagesNumber;

              let _dateStart = this.$moment(takingMedicine.dateStart);
              let _dateEnd = this.$moment(takingMedicine.dateEnd);

              if (this.repHomologation) {
                this.handleMedicineAlreadyPrescribeAlert({ date: null });

                const dateDiff = this.$moment(_dateEnd).diff(this.$moment(_dateStart), 'days');
                const data = await this.checkIfThereIsCurrentMedicine(medicine.medicineId);

                if (data?.nextDate && typeof data?.nextDate !== 'undefined') {
                  const nextDate = this.dateToYYMMDD(data.nextDate);
                  const _dateStartMoment = this.$moment(nextDate);
                  const _dateEndMoment = this.$moment(nextDate)
                    .add({ days: dateDiff })
                    .format('YYYY-MM-DD');

                  _dateStart = new Date(_dateStartMoment);
                  _dateEnd = new Date(_dateEndMoment);
                  this.handleMedicineAlreadyPrescribeAlert({
                    date: this.$moment(new Date(data.endDate)).format('DD/MM/YYYY'),
                  });
                }

                if (this.newDateStartForSameMedicine) {
                  const _dateEndMoment = this.$moment(this.newDateStartForSameMedicine)
                    .add({ days: dateDiff })
                    .format('YYYY-MM-DD');

                  _dateStart = this.newDateStartForSameMedicine;
                  _dateEnd = _dateEndMoment;
                }
              }

              if (this.useHomologationLimit(medicine.units)) {
                this.showMaxPackagesWarning = true;
                this.isCheckingAmount = false;
                return;
              } else {
                if (!this.hasBeenRecalculateByLimit) {
                  this.showMaxPackagesWarning = false;
                }
              }

              medicine.date_start = this.dateToYYMMDD(_dateStart);
              medicine.date_end = this.dateToYYMMDD(_dateEnd);
              this.ISODateStart = this.dateToYYMMDD(_dateStart);
              this.ISODateEnd = this.dateToYYMMDD(_dateEnd);

              this.repHomologation && this.$emit('handle-same-medicines-total-days');
            }
            this.isCheckingAmount = false;
          }
        });
      }
    },

    useCovidHomologationRestrictions({ maxDurationInDays, dateStart }) {
      this.hasBeenRecalculateByLimit = true;
      this.ISODateStart = this.$moment(dateStart);
      this.ISODateEnd = this.$moment(this.ISODateStart).add(maxDurationInDays, 'days');
      this.value.duration = parseInt(maxDurationInDays);
      this.default_sel_duration_measure = 'days';
      this.value.duration_measure = this.$t('recipe.days');
    },

    dateToYYMMDD(date) {
      return this.$moment(new Date(date)).format('YYYY-MM-DD');
    },

    loadDefaultMed() {
      this.searchMedicine();
    },

    removeMedicine() {
      this.$emit('remove');
      this.loadDefaultMed();
      this.calculateAmount(this.value);
      this.handleClearAutocomplete();
      this.showMaxPackagesWarning = false;
    },

    checkGuidelineNumberValue() {
      const min = 0.25;
      const max = 100;
      this.checkNumberValue('guideline', min, max);

      const num = (this.value['guideline'] = Math.round(this.value['guideline'] * 100) / 100);
      this.value.guideline = Math.ceil(num / min) * min;
    },

    checkNumberValue(field, min = 1, max = 100) {
      if (this.value[field] !== '' && field !== 'guideline') {
        this.value[field] = parseInt(String(this.value[field]));
      }

      if (0 > max && this.value[field] > max) {
        this.value[field] = max;
      }
      if (this.value[field] < min) {
        this.value[field] = min;
      }
    },

    resetRecalculateByLimit() {
      this.hasBeenRecalculateByLimit = false;
    },

    showMedicineDetails() {
      this.$nextTick(() => {
        this.showMedicineDialog = true;
      });
    },

    getUnitTypeName(item) {
      return item.unitType?.name || '';
    },

    getLaboratoryName(item) {
      return item.laboratory?.name || '';
    },

    checkIfThereIsCurrentMedicine(medicine_id) {
      const patientId = this.patient || parseInt(this.$route?.params?.id);
      const data = {
        medicineId: medicine_id,
        patientId: patientId,
      };
      try {
        return this.$http.post(checkCurrentMedicineForPatient, data, { headers: getHeader() }).then(res => {
          if (res.data?.nextDate) {
            return res.data;
          }
        });
      } catch (e) {
        this.error = e.message;
      }
      return null;
    },

    handleMedicineAlreadyPrescribeAlert({ date } = {}) {
      this.showMedicineAlreadyPrescribe = date || false;
      this.alertMedicineDate = date || '';
    },

    changeDuration(val) {
      val = val < 1 ? 0 : val;
      this.value.duration = val;
      this.default_sel_duration_measure = 'days';
      this.value.duration_measure = this.default_sel_duration_measure;
      this.hasChangeEndDate = true;
    },

    setAdministrationUnitOnRenew(medicine) {
      if (this.administrationUnit.length === 0) {
        this.administrationUnit = medicine.administrationUnit;
      }
    },

    useHomologationLimit(packagesNumber) {
      return !this.repHomologation
        ? packagesNumber > this.covidPackageLimit
        : packagesNumber > this.noCovidPackageLimit;
    },

    async handleFav(checked) {
      const fav = await this.toggleFav(checked);

      try {
        if (fav) {
          await checkFav(checked.nationalCode, this.authUser.user.professional_docline_api_id);
        } else {
          await uncheckFav(checked.nationalCode, this.authUser.user.professional_docline_api_id);
        }
      } catch (e) {
        await this.toggleFav(checked);
      }
    },

    async toggleFav(checked) {
      const checkedIndex = this.searchData.indexOf(this.searchData.find(item => item.id === checked.id));
      const fav = !this.searchData[checkedIndex].isFavorite ?? true;
      this.searchData[checkedIndex].isFavorite = fav;
      await this.$forceUpdate();
      return fav;
    },

    calcMaxDuration(days, type) {
      switch (type) {
        case 'days':
          return days;
        case 'weeks':
          return Math.trunc(days / 7);
        case 'months':
          return Math.trunc(days / 30);
        default:
          return days;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.align-items-center {
  align-items: center;
}

.medicine-form {
  margin-bottom: 1rem;
  border-bottom: 1px solid #cccccc;
}

.posology-column.text {
  align-self: center;
  margin: 0 auto;
  font-size: 1.1em;
}

.text-unit-type {
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  text-overflow: ellipsis;
}

.box-unit-type {
  max-width: 65%;
}

.fs-small {
  font-size: 0.9em;
}

@media only screen and (max-width: 660px) {
  .posology-column.text {
    margin: 0 auto;
  }
}

.subtitle {
  margin-bottom: 0.8em;
  font-weight: bold;
}

.title-sub {
  font-size: 0.9em;
}

.width-80 {
  width: 80% !important;
}

.duration {
  max-width: 8rem;
}
.duration-content {
  max-width: 6rem;
}

@media (min-width: 1055px) {
  .duration-column {
    padding-left: 1em;
  }
}

.field-display-date {
  padding: 0.5rem;

  .display-title {
    margin-bottom: 0.5rem;
  }

  .display-text {
    color: #4d6563;
  }
}

@media (min-width: 1260px) {
  .posology-input {
    width: 80%;
  }
}

::v-deep .v-chip__content {
  white-space: pre-wrap !important; /* css-3 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap !important; /* Opera 7 */
  word-wrap: break-word !important; /* Internet Explorer 5.5+ */
}

::v-deep .v-select__selections input:not(:first-child) {
  display: none; /* Hides input when a medicine is selected. */
}

::v-deep .v-input__append-outer {
  margin-top: 0 !important;
}
</style>
