export const LOCALES = {
  spain: 'es-ES',
  uk: 'en-GB',
};

export function substractDaysFromDate(date, days) {
  date = date.setDate(date.getDate() + days);
  return new Date(date);
}

export function formatDate(date, locale) {
  if (!date) {
    return null;
  }
  return new Date(date).toLocaleDateString(locale);
}

export function formatToShortDate(date, locale) {
  if (!date) {
    return null;
  }

  return new Date(date).toLocaleDateString(locale, {
    month: 'short',
    year: 'numeric',
  });
}
