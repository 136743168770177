<template>
  <div>
    <div v-for="field in fields" :key="field.key">
      <ParapharmacyProductSelector
        v-if="field.type === 'ParapharmacyProductSelector'"
        v-model="formValue[`${field.key}`].value"
        :required="true"
        :validation-error="getValidationErrors(field.key)"
      />
      <DDateField
        v-if="field.type === 'Date'"
        v-model="formValue[`${field.key}`].value"
        :required="field.required"
        :label="field.label[`${locale}`]"
        :validation-error="getValidationErrors(field.key)"
      />
      <DTextField
        v-if="field.type === 'PosologyText'"
        v-model="formValue[`${field.key}`].value"
        :max-length="formValue[`${field.key}`].maxLength"
        :required="field.required"
        :label="field.label[`${locale}`]"
        type="number"
        :validation-error="getValidationErrors(field.key)"
      >
        <template v-if="formValue['parapharmacy_product'].value" v-slot:append>
          <div class="parapharmacy-posology__text">
            {{ formValue['parapharmacy_product'].value.administrationUnit }}
          </div>
        </template>
      </DTextField>
      <DTextField
        v-else-if="field.type === 'Text'"
        v-model="formValue[`${field.key}`].value"
        :max-length="formValue[`${field.key}`].maxLength"
        :required="field.required"
        :label="field.label[`${locale}`]"
        :validation-error="getValidationErrors(field.key)"
      />
      <DTextareaField
        v-if="field.type === 'Textarea'"
        v-model="formValue[`${field.key}`].value"
        :max-length="formValue[`${field.key}`].maxLength"
        :required="field.required"
        :label="field.label[`${locale}`]"
        :validation-error="getValidationErrors(field.key)"
      />
    </div>
  </div>
</template>

<script>
import ParapharmacyProductSelector from '@/components/Parapharmacy/EmitForm/ParapharmacyProductSelector/ParapharmacyProductSelector.vue';
import DTextField from '@/components/atoms/Form/DTextField.vue';
import DDateField from '@/components/atoms/Form/DDateField.vue';
import arrayToObject from '@/utils/arrayToObject';
import DTextareaField from '@/components/atoms/Form/DTextareaField.vue';

export default {
  name: 'DynamicForm',
  components: { DTextareaField, DTextField, DDateField, ParapharmacyProductSelector },
  props: {
    fields: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validations: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formValue: arrayToObject(this.fields, 'key'),
    };
  },
  computed: {
    locale() {
      let locale = 'en_EN';

      switch (this.$i18n.locale) {
        case 'es':
          locale = 'es_ES';
          break;
        case 'en':
          locale = 'en_EN';
          break;
      }

      return locale;
    },
  },
  watch: {
    formValue: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    getValidationErrors(fieldKey) {
      if (!this.validations || !this.validations[`${fieldKey}`]) {
        return [];
      }
      return this.validations[`${fieldKey}`].$errors.map(error => error.$message);
    },
  },
};
</script>

<style lang="scss">
$grey: #aaaaaa;

.parapharmacy-posology {
  &__text {
    color: $grey;
    font-size: rem(16);
    font-weight: 500;
  }
}
</style>
