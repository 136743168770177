import {
  getHeader,
  parapharmacyEmitUrl,
  parapharmacyProfessionalPrescriptionsUrl
} from '@/config/config';
import axios from 'axios';

export const emitPrescription = async (body) => {
  const url = new URL(parapharmacyEmitUrl);
  const raw = await axios.post(url.toString(), body, {
    headers: getHeader(),
  });
  return raw.data;
};

export const retrievePrescriptions = async (professionalId, patientId, page, size) => {
  const url = new URL(parapharmacyProfessionalPrescriptionsUrl(professionalId));
  const params = {
    patientId: patientId
  };
  if (page) {
    params.page = page;
  }
  if (size) {
    params.size = size;
  }

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;
};
