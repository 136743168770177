import { computed, ref } from 'vue';
import {
  deassign,
  assign,
  fetchAvailablePersonalDoctors,
  fetchPatientPersonalDoctor,
} from '@/services/personal-doctor';

export function useAssignmentPersonalDoctor() {
  const availablePersonalDoctors = ref([]);
  const currentPersonalDoctor = ref(null);

  const isLoadingAvailablePersonalDoctors = ref(false);
  const isLoadingCurrentPersonalDoctor = ref(false);
  const isNetworkError = ref(false);

  const retrieveAvailablePersonalDoctors = async companyId => {
    try {
      isNetworkError.value = false;
      isLoadingAvailablePersonalDoctors.value = true;
      const response = await fetchAvailablePersonalDoctors(companyId);
      availablePersonalDoctors.value = response.data;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingAvailablePersonalDoctors.value = false;
    }
  };

  const isPendingAssignment = computed(() => {
    return !currentPersonalDoctor.value;
  });

  const retrievePatientPersonalDoctor = async (patientId, companyId) => {
    isLoadingCurrentPersonalDoctor.value = true;

    const personalDoctors = await fetchPatientPersonalDoctor(patientId, companyId);

    if (personalDoctors.data.length) {
      currentPersonalDoctor.value = personalDoctors.data[0].professionalId;
    }

    isLoadingCurrentPersonalDoctor.value = false;
  };

  const deassignPersonalDoctor = (patientId, professionalId) => {
    return deassign(patientId, professionalId);
  };

  const assignPersonalDoctor = (patientId, professionalId) => {
    return assign(patientId, professionalId);
  };

  return {
    availablePersonalDoctors,
    currentPersonalDoctor,
    isPendingAssignment,
    isLoadingAvailablePersonalDoctors,
    isLoadingCurrentPersonalDoctor,
    isNetworkError,
    retrieveAvailablePersonalDoctors,
    retrievePatientPersonalDoctor,
    assignPersonalDoctor,
    deassignPersonalDoctor,
  };
}
