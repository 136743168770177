<template>
  <div class="dtext-field">
    <p class="dtext-field__label">{{ label }} {{ required ? '*' : '' }}</p>
    <v-text-field
      v-model="inputValue"
      outlined
      :type="type"
      :placeholder="
        maxLength
          ? $t('common.max_n_characters', {
              chars: maxLength,
            })
          : null
      "
      :error-messages="validationError"
    >
      <template v-slot:append>
        <div class="dtext-field__append">
          <slot name="append" />
          <div v-if="validationError.length" class="img-svg__container dtext-field__error-icon">
            <ErrorIcon />
          </div>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import ErrorIcon from "@/assets/icons/error.svg";

export default {
  name: 'DTextField',
  components: {ErrorIcon},
  props: {
    value: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$danger: #eb445a;

.dtext-field {
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__append {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(32);
  }

  &__error-icon {
    width: rem(22);
  }

  & .v-text-field__details {
    padding: 0 !important;
    font-size: rem(12);
    font-weight: 500;
    color: $danger;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
