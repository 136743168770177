<template>
  <div>
    <treatment-filters
      @onChangeFilters="changeFilters($event)"
      @load-treatment="reloadTreatment"
      @resetFilter="resetFil"
    />
    <div v-if="loading">
      <v-progress-linear indeterminate value="15" color="primary" />
    </div>
    <treatments-patient :treatments="treatments" @load-treatment="reloadTreatment" />
    <v-alert v-if="emptyTreatments" color="light-blue" icon="mdi-note-text" :value="true">
      {{ $t('patient.sin_recetas') }}
    </v-alert>
  </div>
</template>

<script>
import { getTreatmentsUrl, getHeader } from '@/config/config';
import TreatmentsPatient from '@/components/ehealth/paciente/TreatmentsPatient.vue';
import TreatmentFilters from './TreatmentFilters.vue';

export default {
  name: 'TreatmentSection',
  components: {
    TreatmentFilters,
    TreatmentsPatient,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    treatments: [],
    emptyTreatments: false,
    filters: {
      dateStart: null,
      dateEnd: null,
      status: null,
    },
    loading: false,
  }),

  mounted() {
    this.getTreatments();
  },

  methods: {
    async getTreatments() {
      const data = {
        userId: this.authUser.id,
        patientId: parseInt(this.$route.params.id),
      };

      if (this.filters.dateStart !== null) {
        data.dateStart = this.filters.dateStart;
      }

      if (this.filters.dateEnd !== null) {
        data.dateEnd = this.filters.dateEnd;
      }

      if (this.filters.status !== null) {
        data.status = this.filters.status;
      }

      const treatments = await this.sendRequest(getTreatmentsUrl, data);
      this.treatments = treatments;
      this.emptyTreatments = this.treatments.length === 0 ? true : false;
      this.addExpandProperty(); // TODO: eliminar cuando se cambie la vista de las recetas electrónicas
    },

    async sendRequest(url, data) {
      this.loading = true;
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: getHeader(),
        }).then(res => res.json());
        this.loading = false;

        if (!response.success) {
          throw new Error(response.message);
        }

        return response.data;
      } catch (e) {
        throw new Error(e.message);
      }
    },

    changeFilters({ dateStart, dateEnd, status }) {
      if (dateStart !== null) {
        this.filters.dateStart = this.dateToYYYYMMDD(dateStart);
      }

      if (dateEnd !== null) {
        this.filters.dateEnd = this.dateToYYYYMMDD(dateEnd);
      }

      if (status !== null) {
        this.filters.status = status;
      }

      this.getTreatments();
    },

    dateToYYYYMMDD(date) {
      const d = new Date(date);
      const mm = d.getMonth() + 1; // getMonth() is zero-based
      const dd = d.getDate();

      return [d.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
    },

    reloadTreatment() {
      this.getTreatments();
    },

    resetFil() {
      this.filters.dateStart = null;
      this.filters.dateEnd = null;
      this.getTreatments();
    },
    addExpandProperty() {
      if (!this.emptyTreatments) {
        this.treatments.forEach(function(treatment) {
          treatment.expand = [];
          let cont = 0;
          treatment.prescriptions.forEach(function() {
            treatment.expand.push(cont);
            cont++;
          });
        });
      }
    },
  },
};
</script>

<style scoped></style>
