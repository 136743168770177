<template>
  <div id="dialogConfirm">
    <v-dialog v-model="dialog.show" :max-width="dialog.maxwidth" :persistent="dialog.persistent">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <v-card-text v-if="dialog.showHtml" v-html="dialog.text" />
        <v-card-text v-else>{{ dialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <span v-for="(btn, key) in dialog.buttons" :key="key">
            <v-btn :color="btn.color" text @click="$emit('btnDialog', btn.click)">
              {{ btn.text }}
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogConfirm',

  props: {
    dialog: {
      type: Object,
      default: () => ({
        show: false,
        title: '',
        text: '',
        maxwidth: '290',
        buttons: [],
        showHtml: false,
        persistent: false,
      }),
    },
  },
};
</script>
