<template>
  <div id="medicineTable">
    <v-data-table :headers="headers" :items="medicines" hide-default-footer class="elevation-1">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.medicine.name }}
            <v-chip small color="grey" class="white--text mx-1">
              {{ getMedicamentStatus(item.status) }}
            </v-chip>
            <span v-show="item.status === medicamentStatusCode('LOCKED')">
              {{ getMedicamentLockedReasons(item.block_status_code) }}
              {{ item.block_observations }}
            </span>
            <span v-if="item.medicine_replacement" style="font-size: 12px">
              ({{ item.medicine_replacement.name }})
            </span>
          </td>
          <td>{{ getType(item.medicine) }}</td>
          <td>{{ item.units }}</td>
          <td>{{ item.units_dispensed }}</td>
          <td>
            {{ item.guideline }}
            {{ getAdministrationUnit(item.medicine.administrationUnit, item.guideline) }}
            {{ $t('recipe.every') }} {{ item.posology }}
            {{ translateDurationPosology(item.posology_measure) }}
          </td>
          <td>{{ item.duration }} {{ translateDurationMeasure(item.duration_measure) }}</td>
          <td>{{ formatDate(item.date_start) }}</td>
          <td>{{ formatDate(item.date_end) }}</td>

          <v-btn
            v-if="item.status === medicamentStatusCode('LOCKED')"
            :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
            :disabled="!isValidCollegiateNumber"
            depressed
            small
            style="margin-left: 4px"
            @click.native.stop
            @click="$emit('anulateMedicament', item.id, 'medicine')"
          >
            {{ $t('recipe.anulate_medicament') }}
          </v-btn>
          <v-btn
            v-if="item.status === medicamentStatusCode('LOCKED')"
            :class="isValidCollegiateNumber ? 'btn-emit-recipe' : ''"
            :disabled="!isValidCollegiateNumber"
            depressed
            small
            style="margin-left: 4px"
            @click.native.stop
            @click="$emit('unlockMedicament', item.id, 'medicine')"
          >
            {{ $t('recipe.unlock_medicament') }}
          </v-btn>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getMedicamentStatus, getMedicamentStatusCode, getMedicamentLockedReasons } from '@/utils/getMedicamentStatus';

export default {
  name: 'MedicineTable',
  props: {
    medicines: {
      type: Array,
      default: () => [],
    },
    isValidCollegiateNumber: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('recipe.medicine'),
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('recipe.typology'), value: 'type', align: 'left', sortable: false },
        { text: this.$t('recetas.num_envases'), value: 'unit', align: 'left', sortable: false },
        { text: this.$t('recipe.units_dispensed'), value: 'units_dispensed', align: 'left', sortable: false },
        { text: this.$t('recetas.posologia'), value: 'posology', align: 'left', sortable: false },
        { text: this.$t('common.duracion'), value: 'duration', align: 'left', sortable: false },
        { text: this.$t('common.fecha_inicio'), value: 'date_start', align: 'left', sortable: false },
        { text: this.$t('common.fecha_fin'), value: 'date_end', align: 'left', sortable: false },
      ],
    };
  },

  methods: {
    deleteMedicine() {
      // this.$emit('delMedicine');
    },

    getType(medicine) {
      const aTexts = [];

      if (medicine.narcotic) {
        aTexts.push(this.$t('recipe.narcotic'));
      }

      if (medicine.generic) {
        aTexts.push(this.$t('recipe.generic'));
      }

      return aTexts.length > 0 ? aTexts.join(', ') : '-';
    },

    getMedicamentStatus(status) {
      return getMedicamentStatus(status);
    },
    medicamentStatusCode(code) {
      return getMedicamentStatusCode(code);
    },
    getMedicamentLockedReasons(block_status_code) {
      return getMedicamentLockedReasons(block_status_code);
    },

    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },

    translateDurationMeasure(duration_measure) {
      return this.$te(`recipe.${duration_measure}`) ? this.$t(`recipe.${duration_measure}`) : duration_measure;
    },

    translateDurationPosology(duration_posology) {
      return this.$te(`recipe.${duration_posology}`) ? this.$t(`recipe.${duration_posology}`) : duration_posology;
    },

    getAdministrationUnit(administrationUnit, units) {
      return units <= 1 ? administrationUnit.singular : administrationUnit.plural;
    },
  },
};
</script>
<style scoped></style>
