<template>
  <div class="d-select">
    <v-autocomplete
      v-model="selectValue"
      :items="items"
      :loading="loading"
      outlined
      :dense="dense"
      hide-selected
      hide-details
      :clearable="clearable"
      :item-text="itemText"
      :placeholder="placeholder"
      :item-value="itemValue"
      :error-messages="errorMessages"
      :disabled="disabled"
    >
      <template v-slot:no-data>
        <div class="select-no-data__container primary--text text-body-1 px-4 py-2">
          <SelectNetworkError
            v-if="isNetworkError"
            :retry="retry"
            :loading="loading"
            :network-error-text="networkErrorText"
          />
          <div v-else>
            <v-progress-circular v-if="loading" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
                loading
                  ? loadingText
                  : notFoundText
              }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SelectNetworkError from './SelectNetworkError';
export default {
  components: {
    SelectNetworkError,
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isNetworkError: {
      type: Boolean,
      default: false,
    },

    retry: {
      type: Function,
      default: null,
    },

    itemValue: {
      type: [String, Number],
      default: null,
    },

    itemText: {
      type: String,
      default: null,
    },

    networkErrorText: {
      type: String,
      default: null,
    },

    networkErrorTextShort: {
      type: String,
      default: null,
    },

    loadingText: {
      type: String,
      default: null,
    },

    notFoundText: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    errorMessages: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  }
};
</script>

<style lang="scss">

.d-select {
  .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: 2px solid currentColor;
  }

  .v-input__slot fieldset {
    border: 2px solid #F1F1F1;
  }

  .v-input__slot {
    min-height: 68px !important;
  }

  .v-input__append-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
  }
}

.select-no-data__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
