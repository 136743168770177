<template>
  <v-card>
    <v-card-title class="title lighten-1">{{ $t('common.notice') }}</v-card-title>
    <v-card-text class="pa-4">
      {{ $t('errors.invalid_patient_document') }}
      <div class="actions">
        <v-btn color="primary" dark text @click="$emit('close')">
          {{ $t('common.close') }}
        </v-btn>
        <v-btn v-if="patient_id" :color="butColor" depressed class="white--text" @click="goToProfile">
          {{ $t('common.go_to_profile') }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { professionalsNamePaths } from '@/router/routes';

export default {
  name: 'PatientDocumentMsg',

  props: {
    patient_id: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data: () => ({
    butColor: 'info',
  }),

  methods: {
    goToProfile() {
      this.$router.push({ path: professionalsNamePaths.patientById + '/' + this.patient_id });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
