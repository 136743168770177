<template>
  <div id="v-widget-historia">
    <v-card style="max-width: 98%;margin-top: 10px;" color="transparent">
      <v-toolbar v-if="enableHeader" color="transparent" flat dense>
        <v-toolbar-title
          ><h4 class="titulo_tarjeta">{{ title }}</h4></v-toolbar-title
        >
        <v-spacer />
        <slot name="widget-header-action" />
      </v-toolbar>
      <v-divider v-if="enableHeader" />
      <v-card-text :class="contentBg + ' pa-3'">
        <slot name="widget-content" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'VWidgetHistoria',
  props: {
    title: {
      type: String,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    contentBg: {
      type: String,
      default: 'white',
    },
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<style>
.titulo_tarjeta {
  font-family: Roboto;
  font-weight: 300 !important;
  margin-top: 20px;
}
</style>
