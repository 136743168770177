<template>
  <div :style="{ [`${(direction === 'vertical') ? 'height' : 'width'}`]: `${size}px` }" />
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "vertical"
    },
    size: {
      type: Number,
      default: 0,
    }
  },

}
</script>
