import Vue from 'vue';
import { apiPath, getHeader } from '@/config/config';

export default Vue.extend({
  name: 'AllergiesMixin',

  data() {
    return {
      loading: false,
      allergies: [],
    };
  },

  methods: {
    async getPatientAllergies(patientId) {
      this.loading = true;
      try {
        const id = this.patientId || patientId;
        const { data } = await this.$http.get(apiPath(`/patient/${id}/allergies`), {
          headers: getHeader(),
        });
        this.allergies = data;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
