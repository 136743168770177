import i18n from '../../engine/framework/modules/i18n';

export const getMedicamentStatus = id => {
  const options = {
    0: i18n.t('medicament_status.0'),
    1: i18n.t('medicament_status.1'),
    2: i18n.t('medicament_status.2'),
    3: i18n.t('medicament_status.3'),
    4: i18n.t('medicament_status.4'),
    5: i18n.t('medicament_status.5'),
    6: i18n.t('medicament_status.6'),
    7: i18n.t('medicament_status.7'),
    8: i18n.t('medicament_status.8'),
    9: i18n.t('medicament_status.9'),
    10: i18n.t('medicament_status.10'),
    101: i18n.t('medicament_status.101'),
  };
  return options[id];
};

export const getMedicamentStatusCode = text => {
  const options = {
    DISPENSABLE_IN_FUTURE: 0,
    DISPENSABLE: 1,
    LOCKED: 2,
    DISPENSED: 3,
    REPLACED: 4,
    EXPIRED: 5,
    VISA_PENDING: 6,
    VISA_REJECT: 7,
    PARTIAL_DISPENSED: 8,
    ELABORATING_MASTER_FORMULA: 9,
    PARTIAL_DISPENSED_WITH_REPLACED: 10,
    CANCELED: 101,
  };
  return options[text];
};

export const getMedicamentLockedReasons = id => {
  const options = {
    0: i18n.t('medicament_lock_reason.0'),
    1: i18n.t('medicament_lock_reason.1'),
    2: i18n.t('medicament_lock_reason.2'),
    3: i18n.t('medicament_lock_reason.3'),
    4: i18n.t('medicament_lock_reason.4'),
  };
  return options[id];
};
