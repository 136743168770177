<template>
  <div id="diagnosticos">
    <div>
      <v-toolbar v-if="signature !== null" flat color="white">
        <v-spacer />
        <v-dialog v-model="dialog" max-width="1024px" scrollable @click:outside="resetForm">
          <template v-slot:activator="{ on }">
            <v-btn data-test="patient-edit-button-new-diagnosis" depressed color="primary" dark class="mb-2" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
              {{ $t('patient.nuevo_diagnostico') }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container id="diagnoseForm" grid-list-md>
                <div class="mt-4">
                  <div class="mb-1">{{ $t('patient.motivo_consulta') }} *</div>
                  <v-text-field data-test="patient-edit-input-reason" v-model="subject" :error-messages="subjectValidationError" dense outlined />
                </div>
                <div class="mt-4">
                  <div class="mb-1">{{ $t('patient.anamnesis') }} *</div>
                  <v-textarea data-test="patient-edit-input-anamnesis" v-model="anamnesis" :error-messages="anamnesisValidationError" dense outlined rows="4" />
                </div>
                <div class="mt-4">
                  <div class="mb-1">{{ $t('patient.diagnosticos') }} *</div>
                  <v-textarea data-test="patient-edit-input-diagnoses" v-model="diagnoses" :error-messages="diagnosesValidationError" dense outlined rows="4" />
                </div>
                <div class="mt-4">
                  <div class="mb-1">{{ $t('patient.tratamiento') }} *</div>
                  <v-textarea
                    data-test="patient-edit-input-treatments"
                    v-model="treatments"
                    :error-messages="treatmentsValidationError"
                    dense
                    outlined
                    rows="4"
                  />
                </div>
                <div class="mt-4">
                  <div class="mb-1">{{ $t('patient.recomendaciones') }} *</div>
                  <v-textarea
                    data-test="patient-edit-input-recommendations"
                    v-model="recommendations"
                    :error-messages="recommendationsValidationError"
                    dense
                    outlined
                    rows="4"
                  />
                </div>
                <Spacer :size="isMobileVersion ? 12 : 36"/>
                <VideoconsultationLinkDivider />
                <Spacer :size="isMobileVersion ? 12 : 36"/>
                <VideoconsultationSelector
                  v-model="videoconsultationId"
                  :label="$t('videoconsultation_selector.select_videoconsultation')"
                  :patient-id="patientId"
                  :professional-id="professionalId"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn data-test="patient-edit-button-cancel-diagnosis" color="primary" text @click.native="close">{{ $t('common.cancel') }}</v-btn>
              <v-btn data-test="patient-edit-button-save-diagnosis" color="primary" @click.native="save">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-alert
        v-else
        elevation="2"
        dense
        border="right"
        colored-border
        type="error"
        class="mt-2 mb-4"
        style="cursor: pointer"
        @click="$router.push('/ajustes/firma')"
      >
        {{ $t('patient.diagnoses_require_signature') }}<br />
        {{ $t('tour.go_to_signature') }}
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="diagnosticos"
        :hide-default-footer="diagnosticos.length < 10"
        class="pb-4"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.subject }}</td>
            <td>{{ props.item.anamnesis }}</td>
            <td>{{ props.item.diagnosticos }}</td>
            <td>{{ props.item.tratamientos }}</td>
            <td>{{ props.item.recomendaciones }}</td>
            <td>{{ props.item.fecha_formato }}</td>
            <td v-if="signature !== null" class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="editItem(props.item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(props.item)">
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>

        <template slot="no-data">
          <div class="py-12">
            <p>{{ $t('patient.sin_diagnosticos', { name: $t('patient.diagnostico') }) }}</p>
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar Diagnostico -->
    <span slot="widget-content">
      <v-dialog v-model="dialogDelete" max-width="500px" scrollable @click:outside="resetForm">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('patient.delete_diagnostico') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <span>{{ $t('patient.confirm_delete_diagnostico') }}</span>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="closeDelete"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn color="red darken-1" text @click.native="borrarDiagnostico"
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useDiagnoses } from '@/use/Patients/useDiagnoses';
import Spacer from "@/components/Spacer/Spacer.vue";
import VideoconsultationSelector
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationSelector.vue";
import VideoconsultationLinkDivider
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationLinkDivider.vue";

export default {
  name: 'Diagnosticos',
  components: {VideoconsultationLinkDivider, VideoconsultationSelector, Spacer},
  props: {
    idpaciente: {
      type: [String, Number],
      default: '',
    },
    diagnosticos: {
      type: Array,
      default: () => [],
    },
    signature: {
      type: String,
      default: null,
    },
  },

  setup() {
    const {
      diagnoseId,
      subject,
      anamnesis,
      diagnoses,
      treatments,
      recommendations,
      videoconsultationId,

      isNetworkError,
      v$,
      submittingForm,
      submittedWithErrors,

      resetForm,
      validateDiagnoseForm,

      submitCreateDiagnoseForm,
      submitEditDiagnoseForm,
      submitDeleteDiagnose,
      fillDiagnoseForm,

      subjectValidationError,
      anamnesisValidationError,
      diagnosesValidationError,
      treatmentsValidationError,
      recommendationsValidationError,
    } = useDiagnoses();

    return {
      diagnoseId,
      subject,
      anamnesis,
      diagnoses,
      treatments,
      recommendations,
      videoconsultationId,

      isNetworkError,
      v$,
      submittingForm,
      submittedWithErrors,

      resetForm,
      validateDiagnoseForm,

      submitCreateDiagnoseForm,
      submitEditDiagnoseForm,
      submitDeleteDiagnose,
      fillDiagnoseForm,

      subjectValidationError,
      anamnesisValidationError,
      diagnosesValidationError,
      treatmentsValidationError,
      recommendationsValidationError,
    };
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: this.$t('patient.motivo_consulta'), value: 'subject' },
        { text: this.$t('patient.anamnesis'), value: 'anamnesis' },
        { text: this.$t('patient.diagnosticos'), value: 'diagnosticos' },
        { text: this.$tc('patient.tratamiento', 2), value: 'tratamientos' },
        { text: this.$t('patient.recomendaciones'), value: 'recomendaciones' },
        { text: this.$t('common.date'), value: 'created_at' },
        {
          text: this.$t('common.actions'),
          value: '',
          sortable: false,
        },
      ],
      dialogDelete: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    formTitle() {
      return this.diagnoseId ? this.$t('patient.editar_diagnostico') : this.$t('patient.nuevo_diagnostico');
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    patientId() {
      return this.idpaciente;
    },
    professionalId() {
      return this.authUser.user.id;
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    editItem(item) {
      this.fillDiagnoseForm(item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.fillDiagnoseForm(item);
      this.dialogDelete = true;
    },

    async borrarDiagnostico() {
      try {
        await this.submitDeleteDiagnose(this.idpaciente);
        this.showAlert({
          color: 'success',
          icon: 'mdi-check',
          message: this.$t('success.delete_diagnosis'),
        });
        this.closeDelete();
        this.reloadDiagnoses();
      } catch (e) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.try_again'),
        });
      } finally {
        this.isDeleting = false;
      }
    },

    close() {
      this.dialog = false;
      this.resetForm();
      this.formScrollToTop();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.resetForm();
    },

    formScrollToTop() {
      document.getElementById('diagnoseForm').scrollIntoView();
    },

    async save() {
      try {
        this.validateDiagnoseForm();
        if (this.submittedWithErrors) {
          this.showAlert({
            color: 'warning',
            icon: 'mdi-alert-circle',
            message: this.$t('errors.form_invalid'),
          });
          return;
        }

        if (this.diagnoseId) {
          await this.submitEditDiagnoseForm(this.idpaciente);
        } else {
          await this.submitCreateDiagnoseForm(this.idpaciente);
        }

        const msgAlert = this.diagnoseId
          ? this.$t('success.save_edit_diagnosis')
          : this.$t('success.save_new_diagnosis');
        this.showAlert({
          color: 'success',
          icon: 'mdi-check',
          message: msgAlert + ' ' + this.$t('success.new_diagnosis_report'),
        });

        this.close();
        this.reloadDiagnoses();
      } catch (e) {
        this.showAlert({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: this.$t('errors.try_again'),
        });
      }
    },

    reloadDiagnoses() {
      this.$emit('reloadDiagnoses');
    },
  },
};
</script>
