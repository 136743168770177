import { getHeader, parapharmacyProductsUrl } from '@/config/config';
import axios from 'axios';

export const getProducts = async professionalId => {
  const url = new URL(parapharmacyProductsUrl(professionalId));
  const params = {};

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });

  return {
    items: raw.data.data,
  };
};
