<template>
  <div class="ddate-field">
    <p class="ddate-field__label">{{ label }} {{ required ? '*' : '' }}</p>

    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          outlined
          readonly
          :placeholder="$t('common.date_placeholder')"
          v-bind="attrs"
          :error-messages="validationError"
          v-on="on">
          <template v-slot:append>
            <div class="ddate-field__icon">
              <div class="icon-svg__container">
                <BDayIcon />
              </div>
            </div>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        color="primary"
        :min="minDate"
        :locale="this.$i18n.locale"
        @click:date="dateChanged"
      />
    </v-menu>
  </div>
</template>

<script>

import BDayIcon from "@/assets/icons/info_paciente-fecha-nacimiento.svg"

export default {
  name: 'DTextField',
  components: { BDayIcon },
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
    minDate: {
      type: Date,
      default: null,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
      formattedDate: null,
    };
  },

  watch: {
    formattedDate(val) {
      this.$emit('input', val);
    },
  },

  methods: {
    dateChanged() {
      this.$refs.menu.save(this.date);
      this.formattedDate = this.$moment(this.date).format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$danger: #eb445a;

.ddate-field {
  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__icon {
    border-left: solid 1px $grey;
    padding-left: rem(10);
    padding-top: 2px;
    padding-bottom: 2px;
  }

  & .v-text-field__details {
    padding: 0 !important;
    font-size: rem(12);
    font-weight: 500;
    color: $danger;
  }
}
</style>
