<template>
  <GenericModal v-model="modalValue" :title="$t('parapharmacy_product_selector.product_info')" :max-width="748">
    <div class="parapharmacy-product-item-info">
      <DCard :inverted="true">
        <span class="parapharmacy-product-item-info__name">{{ item.name }}</span
        ><span class="parapharmacy-product-item-info__dosage">, {{ item.dosage }} {{ item.administrationUnit }}</span
        ><span class="parapharmacy-product-item-info__lab">{{ item.laboratory }} ({{ item.nationalCode }})</span>
      </DCard>

      <div class="parapharmacy-product-item-info__grid">
        <div class="parapharmacy-product-item__thumbnail-container">
          <DCard :dense="true">
            <div class="parapharmacy-product-item__thumbnail" :style="{'background-image': `url(${item.images[0]})`}">
<!--              <div class="parapharmacy-product-item__thumbnail-maginify">-->
<!--                <img src="@/assets/icons/ampliar-img-producto.svg"/>-->
<!--              </div>-->
            </div>
          </DCard>
        </div>
        <div class="parapharmacy-product-item__specs">
          <DCard>
            <div class="parapharmacy-product-item__specs-content">
              <div>
                <span class="parapharmacy-product-item__specs-first-line">{{ $t('parapharmacy.administration_unit').toUpperCase() }}:</span>
                <span>{{ item.administrationUnit.toUpperCase() }}</span>
              </div>
              <div>
                <span class="parapharmacy-product-item__specs-first-line">{{ $t('parapharmacy.creation_date').toUpperCase() }}:</span>
                <span>{{ item.createdAt | moment('DD/MM/YYYY') }}</span>
              </div>
            </div>
          </DCard>
        </div>
        <div class="parapharmacy-product-item__tags">
          <DChip v-if="item.generic">
            {{ $t('parapharmacy.generic_product') }}
          </DChip>
          <DChip v-if="item.containsNarcotics">
            {{ $t('parapharmacy.narcotic_product') }}
          </DChip>
          <DChip v-if="item.containsPsychotropics">
            {{ $t('parapharmacy.psychotropic_product') }}
          </DChip>
        </div>
      </div>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/atoms/GenericModal.vue';
import DCard from '@/components/atoms/DCard.vue';
import DChip from "@/components/atoms/DChip.vue";

export default {
  name: 'ParapharmacyProductItemInfo',
  components: {DChip, DCard, GenericModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$greyLight: #fafafa;
$greyDark: #757575;
$greySubtitle: #666666;

.parapharmacy-product-item-info {
  padding-top: rem(20);
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  row-gap: rem(28);

  &__name {
    font-size: rem(18);
    font-weight: bold;
    color: $black;
    text-transform: uppercase;
  }

  &__dosage {
    font-size: rem(18);
    font-weight: 400;
    color: $black;
    text-transform: uppercase;
  }

  &__lab {
    font-size: rem(18);
    font-weight: 400;
    color: $greyDark;
  }

  &__lab:before {
    content: ' / ';
    white-space: normal;
  }

  &__grid {
    display: grid;
    grid-template-areas:
      'thumbnail specs'
      'tags tags';
    grid-template-columns: rem(116) repeat(1, 1fr);
    grid-template-rows: rem(116) repeat(1, 1fr);
    row-gap: rem(14);
    column-gap: rem(4);
  }
}

.parapharmacy-product-item {
  &__thumbnail-container {
    grid-area: thumbnail;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  &__thumbnail {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 6px;
  }

  &__thumbnail-maginify {
    padding-right: rem(8);
  }

  &__specs {
    grid-area: specs;
    color: $greySubtitle;

    &-first-line {
      font-weight: bold;
    }

    &-first-line::after {
      content: '\a';
      white-space: pre;
    }
  }

  &__specs-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;
  }

  &__tags {
    grid-area: tags;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: rem(14);
    row-gap: rem(14);
  }
}

@media only screen and (min-width: 768px) {
  .parapharmacy-product-item-info {
    &__dosage::after {
      content: '\a';
      white-space: pre;
    }

    &__lab:before {
      content: '';
      white-space: normal;
    }

    &__grid {
      grid-template-areas:
      'thumbnail specs'
      'thumbnail tags';
      grid-template-columns: rem(170) repeat(1, 1fr);
      grid-template-rows: rem(105) repeat(1, 1fr);
      row-gap: 43px;
      column-gap: 35px;
    }
  }

  .parapharmacy-product-item {
    &__thumbnail-container {
      width: 100%;
      height: 100%;
    }

    &__specs {
      &-first-line::after {
        content: ' ';
        white-space: normal;
      }
    }

    &__specs-content {
      row-gap: rem(16);
      padding-left: rem(22);
      padding-right: rem(22);
    }
  }
}
</style>
