import { ref } from 'vue';
import { getProducts } from '@/services/parapharmacy';

export function useParapharmacyProductsSelector() {
  const products = ref([]);

  const isLoadingProducts = ref(false);
  const isLoadingCurrentProduct = ref(false);
  const isNetworkError = ref(false);

  const selectedInfo = ref(false);

  const retrieveProducts = async (professionalId, search) => {
    try {
      isNetworkError.value = false;
      isLoadingProducts.value = true;
      let response;

      if (search) {
        response = await getProducts(professionalId, search);
      } else {
        response = await getProducts(professionalId);
      }
      products.value = response.items.map((item) => {
        item.infoOpened = false;
        return item;
      });
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingProducts.value = false;
    }
  };

  const getProductById = id => {
    return products.value.find((product) => product.id === id);
  };

  const openInfo = item => {
    products.value[products.value.findIndex(product => product.id === item.id)].infoOpened = true;
  };

  const openInfoSelected = () => {
    selectedInfo.value = true;
  };

  const closeInfo = item => {
    products.value[products.value.findIndex(product => product.id === item.id)].infoOpened = false;
  };

  return {
    products,
    isLoadingProducts,
    isLoadingCurrentProduct,
    isNetworkError,
    retrieveProducts,
    closeInfo,
    getProductById,
    openInfo,
    openInfoSelected,
    selectedInfo,
  };
}
