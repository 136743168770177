<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        color="primary lighten-3"
        dark
        v-bind="attrs"
        :small="small"
        class="text-uppercase"
        pill
        :label="label"
        :outlined="outlined"
        v-on="on"
      >
        <v-avatar left>
          <v-icon>mdi-information</v-icon>
        </v-avatar>
        {{ $t('videoconsulta.metadata_list_text') }}
      </v-chip>
    </template>
    <span>
      <v-layout v-for="(data, key) in metadata" :key="key" class="metadata">
        <strong>{{ data.name }}:</strong>
        <span class="ml-1">{{ data.value.length > 40 ? data.value.substr(0, 40) + '...' : data.value }}</span>
      </v-layout>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TagInfo',
  props: {
    metadata: {
      type: Array,
      default: () => [],
    },
    label: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
