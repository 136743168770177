<template>
  <div>
    <GenericModal v-model="modalValue" :title="$t('common.emitir_receta')">
      <div class="new-parapharmacy-prescription-modal__container">
        <ParapharmacyTemplateSelector v-if="modalValue" @templateSelected="templateSelected" @loadedTemplates="loadedTemplates" />
      </div>
    </GenericModal>
  </div>
</template>

<script>
import ParapharmacyTemplateSelector from '@/components/Parapharmacy/ParapharmacyTemplateSelector/ParapharmacyTemplateSelector.vue';
import GenericModal from '@/components/atoms/GenericModal.vue';

export default {
  name: 'NewParapharmacyPrescription',
  components: { GenericModal, ParapharmacyTemplateSelector },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    templateSelected(template) {
      this.$emit('newPrescription', template)
      this.modalValue = false;
    },
    loadedTemplates(templates) {
      if (templates.length === 1) {
        this.templateSelected(templates[0].id);
      }
    }
  },
};
</script>

<style lang="scss">
.new-parapharmacy-prescription-modal {
  &__content {
    padding-top: 2.25rem;
  }
  &__divider {
    margin-top: 2.25rem;
    border-color: var(--v-primary-base);
  }
}


.new-parapharmacy-prescription-modal__container {
  & .v-stepper {
    box-shadow: none !important;
  }

  & .v-stepper__content {
    padding: 0 !important;
  }
}
</style>
