import { getHeader, getPacienteUrl } from '@/config/config';
import axios from 'axios';

export const getVideoconsultations = async (patientId, professionalId) => {
  const params = {
    id: professionalId,
    patient_id: patientId,
    tab: 5,
    tabkey: "videoconsultas",
    idioma: "es"
  };

  const raw = await axios.post(getPacienteUrl, params,{
    headers: getHeader(),
  });

  return [...raw.data.videoconsultas.pendientes, ...raw.data.videoconsultas.terminadas];
};
