<template>
  <v-row>
    <v-col class="px-1 py-1 text-right">
      <v-btn v-if="showCancelButton" color="primary" outlined class="mr-4" @click="handleCancel">
        {{ cancelText }}
      </v-btn>
      <v-btn color="primary" :disabled="disableAcceptButton" depressed @click="handleAccept">
        {{ acceptText }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ActionButtons',

  props: {
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    disableAcceptButton: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default() {
        return this.$t('common.cancel');
      },
    },
    acceptText: {
      type: String,
      default() {
        return this.$t('common.aceptar');
      },
    },
  },

  methods: {
    handleAccept() {
      this.$emit('on-click:accept');
    },
    handleCancel() {
      this.$emit('on-click:cancel');
    },
  },
};
</script>
