<template>
  <div>
    <v-text-field
      v-if="showCurrentIngredientInput"
      v-model="currentIngredient.name"
      filled
      dense
      outlined
      type="text"
      class="mx-1"
      @focus="handleCurrentIngredientEdit"
    />
    <input-search-data
      v-else
      v-model="activeIngredient.id"
      :title="$t('recipe.active_ingredient') + ' *'"
      :placeholder="$t('recipe.search_active_ingredient')"
      :result-data="searchData"
      :loading="isSearching"
      :dense="true"
      :disabled="disabled"
      :default-value="currentIngredient.id"
      @changeSearchVal="onChangeSearchVal($event)"
      @seloption="changeValue($event)"
    />
  </div>
</template>

<script>
import { getHeader, searchActiveIngredientsUrl } from '@/config/config';

export default {
  name: 'ActiveIngredientsFinder',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    currentIngredient: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      activeIngredient: {
        id: null,
        name: '',
      },
      isSearching: false,
      searchData: [],
      showCurrentIngredientInput: !!this.currentIngredient.name,
    };
  },

  mounted() {
    this.loadDefaultActiveIngredients();
  },

  methods: {
    handleCurrentIngredientEdit() {
      this.loadDefaultActiveIngredients();
      this.showCurrentIngredientInput = false;
    },

    onChangeSearchVal({ searchString }) {
      if (searchString.length > 1) {
        this.searchActiveIngredients(searchString);
      }

      if (this.med_has_been_selected) {
        this.activeIngredient.name = searchString;
        this.changeMedicine = false;
      }
    },

    changeValue(id) {
      if (!id) return;

      this.med_has_been_selected = true;
      this.$emit(
        'activeIngredientSelected',
        this.searchData.find(e => e.id === id)
      );
    },

    loadDefaultActiveIngredients() {
      this.searchActiveIngredients('_default_');
    },

    async searchActiveIngredients(searchString) {
      if (this.isSearching) return;

      try {
        const {
          body: { data },
        } = await this.$http.post(searchActiveIngredientsUrl, { searchString }, { headers: getHeader() });

        this.searchData = data;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isSearching = false;
      }
    },
  },
};
</script>
