<template>
  <div>
    <h1 v-if="showList" class="headline px-4 pb-4">{{ $t('personal_doctor.personal_doctors_historical') }}</h1>
    <v-simple-table v-if="showList">
      <template v-slot:default>
        <tbody>
          <tr
            v-for="personalDoctor in paginatedHistoric"
            v-if="paginatedHistoric.length"
            :key="personalDoctor.id"
            data-test="historic-personal-doctors"
          >
            <td class="py-2">
              <div style="display: flex; flex-direction: row;">
                <div class="mr-4">
                  <d-avatar
                    :text="personalDoctor.firstName + personalDoctor.lastName"
                    size="48"
                    rounded
                    :two-letter="true"
                    :src="personalDoctor.avatar"
                    data-test="historic-personal-doctors_avatar"
                  />
                </div>
                <div>
                  <div class="subtitle-1" data-test="historic-personal-doctors_name">
                    {{ personalDoctor.firstName }} {{ personalDoctor.lastName }}
                  </div>
                  <div>
                    <span
                      v-for="specialty in personalDoctor.specialties"
                      :key="specialty.id"
                      class="caption mr-2"
                      data-test="historic-personal-doctors_specialty"
                      >{{ specialtyLanguage(specialty) }}</span
                    >
                  </div>
                </div>
              </div>
            </td>
            <td class="py-2 text-center" data-test="historic-personal-doctors_started-at">
              {{ startedAtPersonalDoctor(personalDoctor.startedAt) }}
            </td>
            <td class="py-2 text-center" data-test="historic-personal-doctors_ended-at">
              {{ endedAtPersonalDoctor(personalDoctor.endedAt) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      v-if="isLoadingHistoricPersonalDoctors"
      class="d-flex flex-column align-center justify-center historic-personal-doctors__slot"
    >
      <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
    </div>
    <div v-if="isNetworkError" class="d-flex flex-column align-center justify-center historic-personal-doctors__slot">
      <HistoricPersonalDoctorsNetworkError :retry="retrieveHistoricalPersonalDoctors" />
    </div>
    <div v-if="showPagination" class="text-center mb-2">
      <v-pagination v-model="page" :length="paginationLength" circle />
    </div>
  </div>
</template>

<script>
import { LOCALES, formatToShortDate } from '@/utils/dateer';
import { usePersonalDoctors } from '@/use/PersonalDoctors/usePersonalDoctors';

import DAvatar from '@/components/DAvatar.vue';
import HistoricPersonalDoctorsNetworkError from '@/components/PersonalDoctors/HistoricPersonalDoctorsNetworkError';

export default {
  components: {
    HistoricPersonalDoctorsNetworkError,
    DAvatar,
  },
  props: {
    patientId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const {
      paginationLength,
      page,
      showPagination,
      paginatedHistoric,
      isNetworkError,
      isLoadingHistoricPersonalDoctors,
      showList,
      retrieveHistoricalPersonalDoctors,
    } = usePersonalDoctors(props.patientId, props.companyId);

    retrieveHistoricalPersonalDoctors();

    return {
      paginatedHistoric,
      paginationLength,
      page,
      showPagination,
      isNetworkError,
      isLoadingHistoricPersonalDoctors,
      showList,
      retrieveHistoricalPersonalDoctors,
    };
  },
  methods: {
    startedAtPersonalDoctor(startedAt) {
      return formatToShortDate(startedAt, LOCALES.spain);
    },
    endedAtPersonalDoctor(endedAt) {
      return formatToShortDate(endedAt, LOCALES.spain) ?? this.$t('personal_doctor.personal_doctor_present');
    },
    specialtyLanguage(specialty) {
      return this.$i18n.locale === 'es' ? specialty.nameEs : specialty.nameEn;
    },
  },
};
</script>

<style>
.historic-personal-doctors__slot {
  min-height: 354px;
}
</style>
