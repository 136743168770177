<template>
  <v-card id="videoconsulta">
    <v-toolbar flat>
      <v-toolbar-title>{{ textos.toolbar }}</v-toolbar-title>
      <v-spacer />
      <template>
        <v-btn text dark color="grey darken-2" class="mr-2" @click="deleteAppointment">
          <v-icon>mdi-delete</v-icon> {{ $t('common.delete') }}
        </v-btn>
      </template>
    </v-toolbar>
    <v-card-text class="pt-2 pb-0 px-4">
      <v-layout wrap>
        <v-flex v-if="videoconsulta.paciente_id === 0" xs2>
          <v-subheader>{{ $t('mensajeria.para') }}</v-subheader>
        </v-flex>
        <v-flex v-if="videoconsulta.paciente_id === 0" xs10>
          <v-chip v-for="(item, index) in videoconsulta.pacientes" :key="index" color="blue lighten-5">
            <v-avatar color="blue lighten-4">
              <img src="/static/paciente_icon.png" alt="patient_icon" />
            </v-avatar>
            {{ item.fullName }}
          </v-chip>
        </v-flex>
        <v-flex v-if="tipos.length > 1" xs12>
          <template>
            <v-select
              v-model="videoconsulta.tipo.id"
              outlined
              filled
              dense
              :clearable="videoconsulta.estado !== 2"
              :disabled="videoconsulta.estado === 2"
              :label="$t('videoconsulta.tipo')"
              item-value="value"
              item-text="label"
              :items="tipos"
              @change="modificado = true"
            />
          </template>
          <v-chip v-if="videoconsulta.confirmado === 0" color="red lighten-3" text-color="white">{{
            $t('videoconsulta.sin_confirmar')
          }}</v-chip>
        </v-flex>
        <template v-if="videoconsulta.estado !== 2">
          <v-flex xs6>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-top="24"
              :return-value.sync="videoconsulta.date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  filled
                  outlined
                  dense
                  :value="formatDate(videoconsulta.date)"
                  :label="$t('common.date')"
                  readonly
                  class="mr-2"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="videoconsulta.date"
                no-title
                scrollable
                locale="es"
                first-day-of-week="1"
                :min="new Date().toISOString().substr(0, 10)"
                @change="
                  $refs.menu.save(videoconsulta.date);
                  activaModificarVideo();
                "
              >
                <v-spacer />
                <v-btn text color="primary" @click="menu = false">{{ $t('common.cancel') }}</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(videoconsulta.date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="videoconsulta.hora"
              dense
              filled
              outlined
              type="time"
              :label="$t('common.hora')"
              :placeholder="$t('common.hora')"
              @change="modifyHour"
            />
          </v-flex>
        </template>
        <v-flex v-if="videoconsulta.estado === 2" xs12 class="mb-4">
          <v-text-field
            v-model="videoconsulta.fecha"
            outlined
            dense
            filled
            :label="$t('common.date')"
            single-line
            full-width
            hide-details
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="videoconsulta.motivo"
            filled
            outlined
            dense
            :label="$t('patient.motivo_consulta')"
            @keyup="activaModificarVideo"
          />
        </v-flex>

        <v-flex v-if="videoconsulta.confirmado === 1 && videoconsulta.rand !== '' && tieneVideo" xs12 class="mb-2">
          <v-text-field
            dense
            filled
            outlined
            readonly
            :label="$t('videoconsulta.codigo')"
            :placeholder="$t('videoconsulta.codigo')"
            hide-details
            :value="!is_receptionist ? videoconsulta.rand : '**********'"
          >
            <v-chip slot="append" small label color="success" text-color="white">
              {{ $te(videoconsulta.moreInfo) ? $t(videoconsulta.moreInfo) : videoconsulta.moreInfo }}
            </v-chip>
          </v-text-field>
          <div
            v-if="
              videoconsulta.confirmado === 1 &&
                !esta_terminada &&
                mostrar_btn_acceder &&
                tieneVideo &&
                !is_receptionist &&
                !is_admin
            "
            class="px-2 pt-2"
          >
            {{ getVideoUrl(videoconsulta.rand) }}
            <v-btn
              v-tooltip="$t('common.go')"
              icon
              small
              color="primary"
              @click="abrirVideoconsulta(videoconsulta.rand)"
              ><v-icon size="16">mdi-open-in-new</v-icon></v-btn
            >
          </div>
          <div v-else class="mb-2" />
        </v-flex>
        <v-flex v-if="videoconsulta.hash !== null && videoconsulta.hash !== '' && authUser.id === 7" xs2>
          <v-subheader v-if="videoconsulta.hash !== ''">Hash</v-subheader>
        </v-flex>
        <v-flex v-if="videoconsulta.hash !== null && videoconsulta.hash !== '' && authUser.id === 7" xs10>
          <v-chip label color="teal" text-color="teal" outlined>
            <span style="font-size: 12px">
              <strong>{{ videoconsulta.hash }}</strong>
            </span>
          </v-chip>
        </v-flex>

        <v-flex v-if="videoconsulta.diagnostico" xs2>
          <v-subheader>{{ $t('videoconsulta.informe') }}</v-subheader>
        </v-flex>
        <v-flex v-if="videoconsulta.diagnostico" xs10 class="informe">
          <v-flex xs10>
            <span class="informe_title">{{ $t('videoconsulta.diagnostico') }}:</span>
            <span class="informe_part"> {{ videoconsulta.diagnostico.diagnosticos }}</span>
          </v-flex>
          <v-flex v-if="videoconsulta.diagnostico.tratamientos" xs10>
            <span class="informe_title">{{ $t('videoconsulta.tratamiento') }}:</span>
            <span class="informe_part"> {{ videoconsulta.diagnostico.tratamientos }}</span>
          </v-flex>
          <v-flex v-if="videoconsulta.diagnostico.anamnesis" xs10>
            <span class="informe_title"> {{ $t('patient.anamnesis') }}:</span>
            <span class="informe_part"> {{ videoconsulta.diagnostico.anamnesis }}</span>
          </v-flex>
          <v-flex v-if="videoconsulta.diagnostico.recomendaciones" xs10>
            <span class="informe_title"> {{ $t('patient.recomendaciones') }}:</span>
            <span class="informe_part"> {{ videoconsulta.diagnostico.recomendaciones }}</span>
          </v-flex>
        </v-flex>
        <v-flex v-for="(data, key) in videoconsulta.metadata" :key="key" xs12 class="metadata">
          <v-textarea
            :rows="data.value.length > 75 ? parseInt(data.value.length / 75) + 1 : 1"
            :value="data.value"
            :label="data.name"
            dense
            outlined
            disabled
            readonly
          />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-toolbar v-if="videoconsulta.estado !== 2" flat class="pt-0">
      <v-spacer />
      <v-bottom-sheet v-if="videoconsulta.confirmado === 0" v-model="showConfirm" inset>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-2" color="success" outlined="" v-on="on">
            {{ $t('common.confirm') }}
          </v-btn>
        </template>

        <v-card tile>
          <v-progress-linear color="green" height="3" :value="100" class="my-0" />
          <v-card-text>{{ $t('videoconsulta.confirm_video') }}</v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="showConfirm = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn color="green darken-1" text @click.native="confirmarVideoconsulta"
              ><v-icon>mdi-check</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>

      <v-btn
        v-if="!esta_terminada"
        :disabled="disabledButton"
        :loading="loading_envio"
        depressed
        color="primary"
        @click="modificarVideoconsulta"
      >
        {{ $t('common.save') }}
      </v-btn>
      <br /><br />
    </v-toolbar>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { appVideoUrl, guardarVideoconsultaUrl, cancelarVideoconsultaUrl, getHeader } from '@/config/config';
import { isValidDate, isValidHour, isDateAndHourGreaterThanNow } from '@/utils';

export default {
  name: 'Videoconsulta',
  props: {
    videoconsulta: {
      type: Object,
      default: () => [],
    },
    tipos: {
      type: Array,
      default: () => [],
    },
    tipo: {
      type: String,
      default: '',
    },
    tieneVideo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      modificado: false,
      showSheet: false,
      showConfirm: false,
      menu: false,
      modal2: false,
      disabledButton: false,
      snackbar: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      textos: {
        toolbar: vm.$t('videoconsulta.singular'),
      },
      estadolabel: false,
      color_enviar: 'info',
      loading_envio: false,
      esta_terminada: false,
      confirmando_video: false,
      tipoVideoTrans: 'Videoconsulta',
      mostrar_btn_acceder: true,
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      is_admin:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
    };
  },
  watch: {
    videoconsulta() {
      this.tipoVideoTrans =
        !this.tieneVideo || (this.videoconsulta.tipo && this.videoconsulta.tipo.presencial === 1)
          ? this.$t('common.cita')
          : this.$t('videoconsulta.singular');
      this.estadolabel = true;
      const dateActual = new Date();
      const dateVideo = new Date(this.videoconsulta.date.replace(/-/g, '/'));
      const diff = dateVideo - dateActual;
      this.esta_terminada = diff < 0 ? true : false;
      this.confirmando_video = false;
      if (this.videoconsulta.estado === 2) {
        this.textos.toolbar = this.$t('videoconsulta.terminada');
      } else if (this.videoconsulta.paciente_id === 0) {
        this.textos.toolbar = this.$t('videoconsulta.multiconferencia');
      } else {
        this.textos.toolbar =
          this.$t('videoconsulta.videoconsulta_para', {
            tipoVideo: this.tipoVideoTrans,
          }) +
          ' ' +
          this.videoconsulta.fullName;
      }
    },
    tipo: function(val) {
      this.tipoVideoTrans = val === 'tele' ? this.$t('videoconsulta.teleconsulta') : this.$t('videoconsulta.singular');
      this.mostrar_btn_acceder = val !== 'tele';
    },
  },
  methods: {
    confirmarVideoconsulta() {
      this.showConfirm = false;
      this.confirmando_video = true;
      this.modificarVideoconsulta();
    },

    getVideoUrl(code) {
      const lang = this.$i18n.locale !== 'es' ? '?lang=' + this.$i18n.locale : '';
      const lang_index = this.$i18n.locale !== 'es' ? '&lang=' + this.$i18n.locale : '';
      let ruta = '';
      if (appVideoUrl === 'https://video-iconnectat.iconcologia.net' || appVideoUrl === 'https://docline.io') {
        ruta = appVideoUrl + '/ ?code=' + code + lang_index;
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Fakeeh') {
        ruta = 'https://app_fakeeh.docline.io/#/codigo/' + code + '/ar';
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Your Brand') {
        ruta = 'https://appyourbrand.docline.io/#/codigo/' + code + lang;
      } else {
        ruta = appVideoUrl + '/c/' + code + lang;
      }
      return ruta;
    },

    abrirVideoconsulta(code) {
      window.open(this.getVideoUrl(code), '_blank');
      this.$emit('clickedVolver', true);
    },

    activaModificarVideo() {
      this.modificado = true;
    },
    cancelarVideoconsulta() {
      this.showSheet = false;
      this.$http
        .post(
          cancelarVideoconsultaUrl,
          {
            user_id: this.authUser.id,
            videoconsulta_id: this.videoconsulta.id,
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.$emit('clickedCancelado', this.videoconsulta.id);
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.snackbar = true;
            this.disabledButton = false;
          }
        });
    },
    modificarVideoconsulta() {
      if (!this.modificado) {
        if (this.confirmando_video) {
          this.$emit('clickedConfirmado', this.videoconsulta);
        } else {
          this.$emit('clickedVolver', true);
        }
      } else {
        this.alerta.texto = '';
        // validaciones
        this.videoconsulta.fecha = this.videoconsulta.date;
        this.videoconsulta.hora =
          this.videoconsulta.hora.length > 5 ? this.videoconsulta.hora.substring(0, 5) : this.videoconsulta.hora;

        if (this.videoconsulta.fecha === null || this.videoconsulta.hora === null) {
          this.alerta.texto = this.$t('videoconsulta.add_fechahora');
        } else if (this.videoconsulta.tipo === '') {
          this.alerta.texto = this.$t('videoconsulta.add_tiposervicio');
        } else if (!isValidDate(this.videoconsulta.fecha)) {
          this.alerta.texto = this.$t('errors.incorrect_date');
        } else if (!isValidHour(this.videoconsulta.hora)) {
          this.alerta.texto = this.$t('errors.incorrect_hour');
        } else if (!isDateAndHourGreaterThanNow(this.$moment(this.videoconsulta.fecha).format('MM/DD/YYYY'), this.videoconsulta.hora)) {
          this.alerta.texto = this.$t('errors.date_must_be_greater_than_now');
        }
        // si tiene errores muestra el snackbar y si no tiene envia la petición post
        if (this.alerta.texto !== '') {
          this.alerta.color = 'warning';
          this.snackbar = true;
        } else if (!this.disabledButton) {
          this.dialog = false;
          this.color_enviar = 'blue-grey lighten-4';
          this.loading_envio = true;
          const destinatario = [];
          if (this.videoconsulta.paciente_id === 0) {
            destinatario.push(this.videoconsulta.paciente_id);
          } else {
            for (let i = 0; i < this.videoconsulta.pacientes; i = i + 1) {
              destinatario.push(this.videoconsulta.pacientes[i].id);
            }
          }
          this.videoconsulta.destinatario = destinatario;
          // this.disabledButton = true;
          this.$http
            .post(
              guardarVideoconsultaUrl,
              { user_id: this.authUser.id, videoconsulta: this.videoconsulta },
              { headers: getHeader() }
            )
            .then(response => {
              if (response.status === 200 && response.data.estado === 1) {
                if (this.confirmando_video) {
                  this.$emit('clickedConfirmado', this.videoconsulta);
                } else {
                  this.$emit('clickedModificado', response.data);
                }
              } else if (response.status === 200 && response.data.estado === 2) {
                this.alerta.texto = this.$t('videoconsulta.solapamiento');
                this.alerta.color = 'warning';
                this.snackbar = true;
              } else if (response.status === 200 && parseInt(response.data.estado) === 0) {
                this.alerta.texto = this.$t(response.data.error);
                this.alerta.color = 'error';
                this.snackbar = true;
              } else {
                this.alerta.texto = this.$t('errors.try_again');
                this.alerta.color = 'error';
                this.snackbar = true;
              }
              this.color_enviar = 'info';
              this.loading_envio = false;
              this.disabledButton = false;
            })
            .catch(e => {
              let msg = '';
              if (e.data.errors) {
                const errors = e.data.errors;
                if (typeof errors['videoconsulta.fecha'] !== 'undefined') {
                  msg += errors['videoconsulta.fecha'][0];
                } else if (typeof errors['videoconsulta.hora'] !== 'undefined') {
                  msg += errors['videoconsulta.hora'][0];
                }
              } else {
                msg = e.data && e.data.error ? this.$t(e.data.error) : this.$t('errors.try_again');
              }
              this.$toast.error(msg);
              this.disabledButton = false;
            });
        }
      }
    },

    async deleteAppointment() {
      const res = await this.$dialog.confirm({
        title: this.$t('videoconsulta.confirm_delete'),
        text: '',
        actions: [
          {
            text: this.$t('common.close'),
            key: false,
          },
          {
            text: this.$t('common.confirm'),
            color: 'error',
            key: true,
          },
        ],
      });
      if (res === true) {
        this.cancelarVideoconsulta();
      }
    },

    modifyHour() {
      const step = 5;
      const fullTime = this.videoconsulta.hora.split(':');
      const exceedsMaxTime = Math.ceil(fullTime[1] / step) * step >= 60;
      const minuteTime = exceedsMaxTime ? 0 : Math.ceil(fullTime[1] / step) * step;
      const minute = minuteTime < 10 ? '0' + minuteTime : minuteTime;
      const hourTime = exceedsMaxTime && fullTime[0] < '23' ? parseInt(fullTime[0]) + 1 : fullTime[0];
      const hour = hourTime < 10 ? '0' + parseInt(hourTime) : hourTime;
      this.videoconsulta.hora = hour + ':' + minute;
      this.activaModificarVideo();
    },

    formatDate(date) {
      return this.$moment(date).format('DD MMMM YYYY');
    },
  },
};
</script>

<style lang="css">
.hora_nueva_video {
  width: 92%;
}
.listado_motivos_cortesia {
  margin-left: 30px;
}
.como_comunicar_card {
  margin-top: -20px;
  margin-bottom: -10px;
}

.informe_title {
  font-weight: 500;
}

.informe_part {
  margin-left: 0.3em;
}

.informe {
  margin-top: 15px;
}
</style>
