<template>
  <dropdown-box-section :title="$t('videoconsulta.tratamiento')">
    <v-text-field
      v-model="diagnostic"
      type="text"
      name="diagnostic"
      maxlength="50"
      :label="$t('recipe.diagnostic')"
      autocomplete="off"
      :error="errorDiagnostic"
    />

    <v-layout column wrap>
      <v-flex xs12>
        <v-layout xs12 wrap class="mb-1 ml-1">
          <v-flex xs6 sm6>
            <v-checkbox
              v-model="chronic"
              :label="$t('recipe.chronic')"
              :color="custom_template ? 'primary' : 'info'"
              hide-details
            />
          </v-flex>
          <v-flex xs6 sm6>
            <v-checkbox
              v-model="confidential"
              :label="$t('recipe.confidential')"
              :color="custom_template ? 'primary' : 'info'"
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-textarea v-model="comments" rows="3" :label="$t('recipe.comments')" />
  </dropdown-box-section>
</template>

<script>
import { mapModelFields } from '@/utils';

export default {
  name: 'TreatmentForm',

  props: {
    value: {
      type: Object,
      default: () => ({
        diagnostic: '',
        chronic: false,
        confidential: false,
        treatmentStart: new Date(),
        treatmentEnd: new Date(),
        comments: '',
      }),
    },
    errorDiagnostic: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
    };
  },

  computed: {
    ...mapModelFields(['diagnostic', 'chronic', 'confidential', 'treatmentStart', 'treatmentEnd', 'comments']),
  },
};
</script>

<style lang="scss" scoped>
.group-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  > div {
    width: 250px;
  }
}
</style>
