<template>
  <div class="upload-document__container">
    <CategorySelector v-model="category"/>
    <Spacer :size="isMobileVersion ? 12 : 36"/>
    <FileSelectorDropzone
      id="upload-document-dropzone"
      v-model="files"
      :label="$t('documents.upload_document')"
      :placeholder="$t('documents.drag_and_drop')"
      :error="fileUploadError"
      required />
    <Spacer :size="isMobileVersion ? 12 : 36"/>
    <VideoconsultationLinkDivider />
    <Spacer :size="isMobileVersion ? 12 : 36"/>
    <VideoconsultationSelector
      v-model="videoconsultationId"
      :label="$t('videoconsultation_selector.select_videoconsultation')"
      :patient-id="patientId"
      :professional-id="professionalId"
    />
  </div>
</template>
<script>
import FileSelectorDropzone from "@/components/form/FileSelectorDropzone/FileSelectorDropzone.vue";
import Spacer from "@/components/Spacer/Spacer.vue";
import VideoconsultationSelector
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationSelector.vue";
import VideoconsultationLinkDivider
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationLinkDivider.vue";
import CategorySelector from "@/DocumentsManagement/CategorySelector/CategorySelector.vue";
import {mapGetters} from "vuex";

const MAX_FILE_SIZE = 10000000;
const MIME_TYPES_ALLOWED = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/softgrid-pdf',
  'application/vnd.ms-excel',
  'image/jpeg',
  'text/plain',
  'message/rfc822',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'video/mp4',
  'video/quicktime',
  'video/avi'
];

export default {
  name: "UploadDocument",
  components: {
    CategorySelector,
    VideoconsultationLinkDivider,
    VideoconsultationSelector,
    Spacer,
    FileSelectorDropzone,
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    patientId: {
      type: Number,
      default: null
    },
    professionalId: {
      type: Number,
      default: null
    },
  },
  data: function () {
    return {
      files: null,
      category: null,
      videoconsultationId: null
    };
  },
  computed: {
    uploadValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    fileUploadError() {
      if (this.uploadValue?.files?.length > 1) {
        return this.$t('documents.not_multiple');
      }

      if (this.uploadValue?.files?.[0] && !MIME_TYPES_ALLOWED.includes(this.uploadValue?.files?.[0].type)) {
        return this.$t('documents.invalid_format');
      }

      if (this.uploadValue?.files?.[0]?.size > MAX_FILE_SIZE) {
        return this.$t('documents.exceed_size_limit', { num: MAX_FILE_SIZE / 1000000});
      }

      return null;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.files = null;
        this.videoconsultationId = null;
        this.category = null;
      } else {
        this.files = val.files;
        this.videoconsultationId = val.videoconsultationId;
        this.category = val.category;
      }
    },
    fileUploadError(val) {
      this.uploadValue = {
        ...this.uploadValue,
        error: val
      }
    },
    files(val) {
      this.uploadValue = {
        ...this.uploadValue,
        files: val
      }
    },
    videoconsultationId(val) {
      this.uploadValue = {
        ...this.uploadValue,
        videoconsultationId: val,
      }
    },
    category(val) {
      this.uploadValue = {
        ...this.uploadValue,
        category: val
      }
    }
  }
}
</script>

<style lang="scss">
  .upload-document {
    &__container {
      padding-top: 17px;

      @media only screen and (min-width: 768px) {
        & {
          padding-top: 38px;
        }
      }
    }
  }
</style>
