import { getHeader, favoriteUrl } from '@/config/config';
import axios from 'axios';

export const checkFav = async (medicineId, professionalId) => {
  const response = await axios.post(
    favoriteUrl(medicineId, professionalId),
    {},
    {
      headers: getHeader(),
    }
  );

  return response.data;
};

export const uncheckFav = async (medicineId, professionalId) => {
  const response = await axios.delete(favoriteUrl(medicineId, professionalId), {
    headers: getHeader(),
  });

  return response.data;
};
