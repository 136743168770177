<template>
  <GenericModal v-model="modalValue" :max-width="748" :title="title">
    <div class="warning-modal__container">
      <div class="warning-modal__card">
        <div class="warning-modal__header">
          <div class="warning-modal__icon-container">
            <div class="icon-svg__container">
              <ErrorIcon class="warning-modal__icon" />
            </div>
          </div>
        </div>
        <div class="warning-modal__content">
          <slot />
        </div>
      </div>
    </div>
    <template v-slot:actions>
      <DButton color="#eb445a" @click.native="onCancel">{{ cancelText}}</DButton>
      <DButton :primary="true" @click.native="onContinue">{{ continueText }}</DButton>
    </template>
  </GenericModal>
</template>

<script>
  import GenericModal from "@/components/atoms/GenericModal.vue";
  import DButton from "@/components/atoms/DButton.vue";

  import ErrorIcon from "@/assets/icons/error.svg";

  export default {
    name: "WarningModal",
    components: {
      GenericModal,
      DButton,
      ErrorIcon
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: null
      },
      content: {
        type: String,
        default: null
      },
      cancelText: {
        type: String,
        default: null,
      },
      continueText: {
        type: String,
        default: null,
      },
      onCancel: {
        type: Function,
        default: null,
      },
      onContinue: {
        type: Function,
        default: null,
      },
    },
    computed: {
      modalValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    }
  }
</script>

<style lang="scss">
$black: #212121;
$dangerLight: #ffe3e7;
$danger: #eb445a;

.warning-modal {
  &__container {
    padding-top: rem(16);
  }

  &__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px $danger;
    border-radius: 5px;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $dangerLight;
    border-bottom: solid 1px $danger;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: rem(22);
    padding-bottom: rem(22);
  }

  &__icon {
    width: rem(24);
    height: rem(22);
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    padding: rem(16);
    font-size: rem(16);
    font-weight: 500;
    color: $black;
  }
}

@media only screen and (min-width: 640px) {
  .warning-modal {
    &__container {
      padding-top: rem(16);
    }

    &__card {
      display: flex;
      flex-direction: row;
    }

    &__header {
      width: rem(77);
      height: rem(77);
      border-right: solid 1px $danger;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      padding: 0;
    }

    &__icon-container {
      width: rem(77);
    }
  }
}
</style>
