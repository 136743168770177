import { getHeader, parapharmacyPreviewUrl } from '@/config/config';
import axios from 'axios';

export const generatePdfPreview = async (templateId, body) => {
  const url = new URL(parapharmacyPreviewUrl(templateId));
  const raw = await axios.post(url.toString(), body, {
    headers: getHeader(),
    responseType: 'blob',
  });
  return window.URL.createObjectURL(raw.data);

  // const url = new URL("https://docline-dev-backend.docline.eu/api/ehealth/files/fe372956-c9e2-49d5-8069-516ec06eba26/download");
  // const params = {};
  // url.search = new URLSearchParams(params).toString();
  // const raw = await axios.get(url.toString(), {
  //   headers: getHeader(),
  //   responseType: 'blob',
  // });
  // return window.URL.createObjectURL(raw.data)
};
