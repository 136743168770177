<template>
  <v-container>
    <div v-if="!isEhealthPersonnel">
      <client-history/>
    </div>

    <form v-else-if="!showDontHaveService" class="form_historial_clinico">
      <v-layout wrap>
        <v-flex sm12 lg12>
          <span
            v-if="historial.id > 0 || (anestesia && anestesia.id > 0 && anestesia.prof_anestesista)"
            class="container text-right"
            style="float: right;"
          >
            <v-btn
              v-if="anestesia && anestesia.id > 0 && anestesia.prof_anestesista"
              depressed
              color="primary"
              :loading="loadingInforme"
              class="mb-3"
              @click="generateReport('preanesthesia')"
            >{{ $t('anestesia.informe_preanestesia') }}</v-btn
            >
            <v-dialog v-model="showReportDialog" max-width="750px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed color="primary" v-bind="attrs" v-on="on">
                  {{ $t('patient.generarinforme') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('patient.generarinforme') }}
                </v-card-title>
                <v-card-text>
                  <v-alert
                    v-if="!signature"
                    elevation="2"
                    dense
                    border="right"
                    colored-border
                    type="error"
                    class="mt-2 mb-4"
                    style="cursor: pointer"
                    @click="$router.push('/ajustes/firma')"
                  >
                    {{ $t('patient.diagnoses_require_signature') }}<br/>
                    {{ $t('tour.go_to_signature') }}
                  </v-alert>
                  <div class="report--selector mt-4">
                    <div class="mb-1">{{ $t('patient.clinical_report_type') }}</div>
                    <v-select
                      v-model="reportType"
                      :items="reportTypes"
                      outlined
                      dense
                      item-value="type"
                      item-text="name"
                      hide-details
                    />
                  </div>
                  <div class="report--container mt-8">
                    <div v-if="showStartDate" class="report--start-date">
                      <div class="mb-1">{{ (showEndDate) ? $t('common.fecha_inicio') : $t('common.date') }}</div>
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startDateFormatted"
                            :placeholder="$t('common.date_placeholder')"
                            dense
                            outlined
                            v-bind="attrs"
                            :error-messages="startDateValidationError"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker v-model="startDate" no-title @input="startDateMenu = false"/>
                      </v-menu>
                    </div>
                    <div v-if="showEndDate" class="report--end-date">
                      <div class="mb-1">{{ $t('common.fecha_fin') }}</div>
                      <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDateFormatted"
                            :placeholder="$t('common.date_placeholder')"
                            dense
                            outlined
                            v-bind="attrs"
                            :error-messages="endDateValidationError"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker v-model="endDate" :min="minEndDate" no-title @input="endDateMenu = false"/>
                      </v-menu>
                    </div>
                  </div>

                  <Spacer :size="isMobileVersion ? 12 : 36"/>
                  <VideoconsultationLinkDivider />
                  <Spacer :size="isMobileVersion ? 12 : 36"/>
                  <VideoconsultationSelector
                    v-model="videoconsultationId"
                    :label="$t('videoconsultation_selector.select_videoconsultation')"
                    :patient-id="patientId"
                    :professional-id="professionalId"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="primary" text :disabled="submittingForm" @click.native="closeReportDialog"
                  >{{ $t('common.close') }}</v-btn
                  >
                  <v-btn
                    color="primary"
                    :disabled="submittingForm || !signature"
                    :loading="submittingForm"
                    @click.native="generateNewReport"
                  >{{ $t('patient.generarinforme') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
          <v-expansion-panels accordion class="v-expansion-panels--no-elevation">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>{{ $t('patient.history_clinic') }}</h4>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card flat>
                  <v-layout wrap class="box-layout">
                    <v-flex sm12 lg12 class="box-observations">
                      <v-textarea
                        data-test="patient-edit-input-observations"
                        v-model="historial.observaciones"
                        filled
                        outlined
                        class="textarea_historia"
                        :rules="rules.maxLengthHistory"
                        :label="$t('patient.observations')"
                        @change="modifyForm"
                      />
                      <div
                        :class="32734 - historial.observaciones.length < 0 ? 'font-red' : ''"
                        style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                      >
                        {{ 32734 - historial.observaciones.length }}
                      </div>
                    </v-flex>

                    <v-flex sm6 lg6>
                      <v-widget-historia :title="$t('patient.antecedentes_familiares')">
                        <div slot="widget-content">
                          <div class="basic">
                            <v-switch
                              data-test="patient-edit-switch-maternal-history"
                              v-model="check.antecedentes_maternos"
                              inset
                              :label="$t('patient.antecedentes_maternos')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.antecedentes_maternos" class="multile">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex
                                      v-for="antecedente in antecedentes.familiares"
                                      :key="antecedente.id"
                                      xs12
                                      sm6
                                      md6
                                    >
                                      <v-checkbox
                                        v-model="antecedentes_maternos"
                                        :label="antecedente[getNameKey]"
                                        color="info"
                                        :value="antecedente.id"
                                        hide-details
                                      />
                                      <v-flex
                                        v-if="
                                          antecedente.mas_info === 1 &&
                                            antecedentes_maternos.indexOf(antecedente.id) > -1
                                        "
                                        xs12
                                      >
                                        <v-textarea
                                          v-model="historial.detalles_antecedentes[0][antecedente.id]"
                                          filled
                                          outlined
                                          rows="1"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>

                            <br clear="all"/>

                            <v-switch
                              data-test="patient-edit-switch-paternal-history"
                              v-model="check.antecedentes_paternos"
                              inset
                              :label="$t('patient.antecedentes_paternos')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.antecedentes_paternos" class="multile">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex
                                      v-for="antecedente in antecedentes.familiares"
                                      :key="antecedente.id"
                                      xs12
                                      sm6
                                      md6
                                    >
                                      <v-checkbox
                                        v-model="antecedentes_paternos"
                                        :label="antecedente[getNameKey]"
                                        color="info"
                                        :value="antecedente.id"
                                        hide-details
                                      />
                                      <v-flex
                                        v-if="
                                          antecedente.mas_info === 1 &&
                                            antecedentes_paternos.indexOf(antecedente.id) > -1
                                        "
                                        xs12
                                      >
                                        <v-textarea
                                          v-model="historial.detalles_antecedentes[1][antecedente.id]"
                                          filled
                                          outlined
                                          rows="1"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-flex>
                                    <v-flex v-if="mostrar.mas_info_antecedentes_paternos" xs12>
                                      <v-textarea
                                        v-model="historial.antecedentes_paternos_otras"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                            <br clear="all"/>
                            <v-switch
                              data-test="patient-edit-switch-family-history"
                              v-model="check.antecedentes_hermanos"
                              inset
                              :label="$t('patient.antecedentes_hermanos')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.antecedentes_hermanos" class="multile">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex
                                      v-for="antecedente in antecedentes.familiares"
                                      :key="antecedente.id"
                                      xs12
                                      sm6
                                      md6
                                    >
                                      <v-checkbox
                                        v-model="antecedentes_hermanos"
                                        :label="antecedente[getNameKey]"
                                        color="info"
                                        :value="antecedente.id"
                                        hide-details
                                      />
                                      <v-flex
                                        v-if="
                                          antecedente.mas_info === 1 &&
                                            antecedentes_hermanos.indexOf(antecedente.id) > -1
                                        "
                                        xs12
                                      >
                                        <v-textarea
                                          v-model="historial.detalles_antecedentes[2][antecedente.id]"
                                          rows="1"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-flex>
                                    <v-flex v-if="mostrar.mas_info_antecedentes_hermanos" xs12>
                                      <v-textarea
                                        v-model="historial.antecedentes_hermanos_otras"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </v-widget-historia>
                    </v-flex>

                    <v-flex sm6 lg6>
                      <v-widget-historia :title="$t('patient.antecedentes_personales')">
                        <div slot="widget-content">
                          <div class="basic">
                            <v-switch
                              data-test="patient-edit-switch-surgery-history"
                              v-model="check.antecedentes_cirugias"
                              inset
                              :label="$t('patient.antecedentes_cirugias')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.antecedentes_cirugias" class="multile">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex
                                      v-for="antecedente in antecedentes.cirugias"
                                      :key="antecedente.id"
                                      xs12
                                      sm6
                                      md6
                                    >
                                      <v-checkbox
                                        v-model="cirugias"
                                        :label="antecedente[getNameKey]"
                                        color="info"
                                        :value="antecedente.id"
                                        hide-details
                                      />
                                      <v-flex
                                        v-if="antecedente.mas_info === 1 && cirugias.indexOf(antecedente.id) > -1"
                                        xs12
                                      >
                                        <v-textarea
                                          v-model="historial.detalles_antecedentes[3][antecedente.id]"
                                          filled
                                          outlined
                                          rows="1"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-flex>
                                    <v-flex v-if="mostrar.mas_info_antecedentes_cirugias" xs12>
                                      <v-textarea
                                        v-model="historial.cirugias_otras"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                            <br clear="all"/>
                            <v-switch
                              data-test="patient-edit-switch-alergy-medicines-history"
                              v-model="check.alergias_medicamentos"
                              inset
                              :label="$t('patient.alergias_medicamentos')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.alergias_medicamentos">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <v-textarea
                                        v-model="historial.alergias_medicamentos"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                            <br clear="all"/>
                            <v-switch
                              data-test="patient-edit-switch-alergy-other-history"
                              v-model="check.otras_alergias"
                              inset
                              :label="$t('patient.otras_alergias')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.otras_alergias">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <v-textarea
                                        v-model="historial.otras_alergias"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                            <br clear="all"/>
                            <v-switch
                              data-test="patient-edit-switch-chronic-diseases-history"
                              v-model="check.cronicas"
                              inset
                              :label="$t('patient.enfermedades_cronicas')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.cronicas" class="multile">
                                <v-container fluid class="pa-0">
                                  <v-layout wrap>
                                    <v-flex
                                      v-for="antecedente in antecedentes.familiares"
                                      :key="antecedente.id"
                                      xs12
                                      sm6
                                      md6
                                    >
                                      <v-checkbox
                                        v-model="enfermedades_cronicas"
                                        :label="antecedente[getNameKey]"
                                        color="info"
                                        :value="antecedente.id"
                                        hide-details
                                      />
                                      <v-flex
                                        v-if="
                                          antecedente.mas_info === 1 &&
                                            enfermedades_cronicas.indexOf(antecedente.id) > -1
                                        "
                                        xs12
                                      >
                                        <v-textarea
                                          v-model="historial.detalles_antecedentes[4][antecedente.id]"
                                          filled
                                          outlined
                                          rows="1"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-flex>
                                    <v-flex v-if="mostrar.mas_info_enfermedades_cronicas" xs12>
                                      <v-textarea
                                        v-model="historial.enfermedades_cronicas_otras"
                                        filled
                                        outlined
                                        class="textarea_historia"
                                        @change="modifyForm"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </div>
                            </transition>
                            <br clear="all"/>
                            <v-switch
                              data-test="patient-edit-switch-toxic-habits-history"
                              v-if="!check.perfil_psiquiatria"
                              v-model="check.toxicos"
                              inset
                              :label="$t('patient.habitos_toxicos')"
                              color="info"
                            />
                            <transition name="slide-fade">
                              <div v-if="check.toxicos && !check.perfil_psiquiatria" class="multile">
                                <transition name="slide-fade">
                                  <v-container fluid class="pa-0">
                                    <v-layout wrap>
                                      <v-flex v-for="toxico in toxicos" :key="toxico.id" xs12 sm4 md4>
                                        <v-checkbox
                                          v-model="historial.h_toxicos_ids"
                                          :label="$i18n.locale === 'es' ? toxico.nombre : toxico.name"
                                          color="info"
                                          :value="toxico.id"
                                          hide-details
                                        />
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-textarea
                                          v-model="historial.h_toxicos_info"
                                          filled
                                          outlined
                                          rows="2"
                                          class="textarea_historia"
                                          @change="modifyForm"
                                        />
                                      </v-flex>
                                    </v-layout>
                                  </v-container>
                                </transition>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </v-widget-historia>
                    </v-flex>

                    <v-flex xs12 class="box-button text-center">
                      <v-btn data-test="patient-edit-button-save-history" depressed :disabled="disabledButton" color="primary" @click="saveHistoria()"
                      >{{ $t('common.save') }}
                        {{ $t('patient.history_clinical') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="check.perfil_psiquiatria">
              <v-expansion-panel-header>
                <h4>{{ $t('patient.historial_psiquiatria') }}</h4>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card outlined>
                  <v-divider/>
                  <br/>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-textarea
                        v-model="historial.psiqui_motivo"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.motivo_consulta')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.psiqui_somaticos"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.antecedentes_somaticos')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.psiqui_antecendentes"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.antecedentes_psiquiatricos')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.psiqui_familiares"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.antecedentes_familiares')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.psiqui_genograma"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.genograma')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.psicobiografia"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.psicobiografia')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-textarea
                        v-model="historial.h_toxicos_info"
                        filled
                        outlined
                        class="textarea_historia"
                        :label="$t('patient.habitos_toxicos')"
                        @change="modifyForm"
                      />
                    </v-flex>
                    <v-flex xs12 class="text-md-center">
                      <v-btn depressed :disabled="disabledButton" color="primary" @click="saveHistoria()"
                      >{{ $t('common.save') }}
                        {{ $t('patient.history_clinical') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="check.perfil_psiquiatria">
              <v-expansion-panel-header>
                <h4>{{ $t('patient.evoluciones') }}</h4>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card>
                  <v-divider/>
                  <v-layout wrap>
                    <v-flex sm12 lg12>
                      <br/>
                      <evoluciones :patient-id="$route.params.id" :evolutions="historial.evoluciones"/>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>{{ $t('patient.allergy_management') }}</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-layout wrap>
                    <v-flex sm12 lg12>
                      <allergies :patient-id="$route.params.id"/>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>{{ $t('patient.diagnosticos') }}</h4>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card flat>
                  <v-layout wrap>
                    <v-flex sm12 lg12>
                      <diagnosticos
                        :idpaciente="$route.params.id"
                        :diagnosticos="historial.diagnosticos"
                        :signature="signature"
                        @reloadDiagnoses="reloadDiagnoses"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="permissions.anesthesia !== false && paciente.anestesista === 1">
              <v-expansion-panel-header>
                <h4>{{ $t('patient.preanestesia') }}</h4>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card flat>
                  <v-divider/>
                  <v-layout wrap>
                    <v-flex sm12 lg12>
                      <br/>
                      <preanestesia
                        :anestesia="anestesia"
                        :idpaciente="$route.params.id"
                        @successAlert="successAlert"
                        @errorAlert="errorAlert"
                        @modifyForm="modifyForm"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </form>
    <div v-if="showDontHaveService">
      <no-service-granted view="historial"/>
    </div>

    <v-dialog v-model="dialogClinicalHistory.show" eager>
      <v-card>
        <div id="pageContainer"/>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormValidator from '@/mixins/FormValidation';
import {mapGetters, mapState} from 'vuex';
import {editPacienteUrl, getHeader, generateClinicalReportUrl} from '@/config/config';
import VWidgetHistoria from '@/components/VWidgetHistoria.vue';
import Evoluciones from '@/components/ehealth/paciente/Evoluciones.vue';
import Diagnosticos from '@/components/ehealth/paciente/Diagnosticos.vue';
import Allergies from '@/components/ehealth/paciente/allergies/Allergies.vue';
import Preanestesia from '@/components/ehealth/paciente/Preanestesia.vue';
import ClientHistory from '@/components/ehealth/paciente/ClientHistory.vue';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import * as pdfjsLib from 'pdfjs-dist';
import {useClinicalReport} from '@/use/Patients/useClinicalReport';
import Spacer from "@/components/Spacer/Spacer.vue";
import VideoconsultationSelector
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationSelector.vue";
import VideoconsultationLinkDivider
  from "@/components/Videoconsultations/VideoconsultationSelector/VideoconsultationLinkDivider.vue";

export default {
  name: 'History',
  components: {
    VideoconsultationLinkDivider, VideoconsultationSelector, Spacer,
    Evoluciones,
    Diagnosticos,
    Allergies,
    VWidgetHistoria,
    Preanestesia,
    ClientHistory,
    NoServiceGranted,
  },
  mixins: [FormValidator],
  props: {
    paciente: {
      type: Object,
      default: () => ({}),
    },
    historial: {
      type: Object,
      default: () => ({
        antecedentes_maternos: [],
      }),
    },
    showDontHaveService: {
      type: Boolean,
      default: false,
    },
    anestesia: {
      type: Object,
      default: () => {
        /** */
      },
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
    antecedentes: {
      type: Array,
      default: () => [],
    },
    clinicalRecords: {
      type: Object,
      default: () => {
        /** */
      },
    },
    checkStatus: {
      type: Object,
      default: () => {
        /** */
      },
    },
    signature: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const {
      reportTypes,
      reportType,
      startDate,
      endDate,
      videoconsultationId,
      startDateFormatted,
      endDateFormatted,
      minEndDate,
      startDateValidationError,
      endDateValidationError,
      submittingForm,
      submittedWithErrors,
      validateClinicalReportForm,
      submitClinicalReportForm,
      showStartDate,
      showEndDate,
      resetForm,
    } = useClinicalReport(props.paciente.id);

    return {
      reportTypes,
      reportType,
      startDate,
      endDate,
      videoconsultationId,
      startDateFormatted,
      endDateFormatted,
      minEndDate,
      startDateValidationError,
      endDateValidationError,
      submittedWithErrors,
      submittingForm,
      validateClinicalReportForm,
      submitClinicalReportForm,
      showStartDate,
      showEndDate,
      resetForm,
    };
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loadingInforme: false,
      mostrar: {
        mas_info_antecedentes_maternos: false,
        mas_info_antecedentes_paternos: false,
        mas_info_antecedentes_hermanos: false,
        mas_info_antecedentes_cirugias: false,
        mas_info_enfermedades_cronicas: false,
        mas_info_habitos_toxicos: false,
      },
      antecedentes_maternos: [],
      antecedentes_paternos: [],
      antecedentes_hermanos: [],
      enfermedades_cronicas: [],
      cirugias: [],
      toxicos: [],
      disabledButton: false,
      isEhealthPersonnel:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['is_health_personnel'] === 1,
      dialogClinicalHistory: {
        show: false,
        url: '',
      },
      showReportDialog: false,
      startDateMenu: false,
      endDateMenu: false,
      showReportPdfDialog: false,
      generatedPdf: null,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      appDisplayName: state => state.app.displayName,
    }),
    ...mapGetters({
      isEnabledPdfInformInsideHtmlContainer: 'app/getPDFInformInsideHtmlContainerFeatureFlag',
    }),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    check() {
      // TODO: FIXME!!!!
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      this.antecedentes_maternos = this.clinicalRecords.maternal;
      this.antecedentes_paternos = this.clinicalRecords.paternal;
      this.antecedentes_hermanos = this.clinicalRecords.brothers;
      this.cirugias = this.clinicalRecords.surgeries;
      this.toxicos = this.clinicalRecords.toxics;
      this.enfermedades_cronicas = this.clinicalRecords.chronic_diseases;
      /* eslint-enable vue/no-side-effects-in-computed-properties */
      return this.checkStatus;
    },

    getNameKey() {
      const lang = this.$i18n.locale;
      const keys = {
        es: 'nombre',
        en: 'name',
        fr: 'nom',
      };
      return keys[lang] || keys['es'];
    },

    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    patientId() {
      return this.paciente.id;
    },
    professionalId() {
      return this.authUser.user.id;
    }
  },
  watch: {
    antecedentes_maternos(val) {
      this.mostrar.mas_info_antecedentes_maternos = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.familiares.length && !localizado; j++) {
          if (val[i] === this.antecedentes.familiares[j].id) {
            localizado = true;
            if (this.antecedentes.familiares[j].mas_info === 1) {
              this.mostrar.mas_info_antecedentes_maternos = true;
            }
          }
        }
      }
    },
    antecedentes_paternos(val) {
      this.mostrar.mas_info_antecedentes_paternos = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.familiares.length && !localizado; j++) {
          if (val[i] === this.antecedentes.familiares[j].id) {
            localizado = true;
            if (this.antecedentes.familiares[j].mas_info === 1) {
              this.mostrar.mas_info_antecedentes_paternos = true;
            }
          }
        }
      }
    },
    antecedentes_hermanos(val) {
      this.mostrar.mas_info_antecedentes_hermanos = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.familiares.length && !localizado; j++) {
          if (val[i] === this.antecedentes.familiares[j].id) {
            localizado = true;
            if (this.antecedentes.familiares[j].mas_info === 1) {
              this.mostrar.mas_info_antecedentes_hermanos = true;
            }
          }
        }
      }
    },
    cirugias(val) {
      this.mostrar.mas_info_antecedentes_cirugias = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.cirugias.length && !localizado; j++) {
          if (val[i] === this.antecedentes.cirugias[j].id) {
            localizado = true;
            if (this.antecedentes.cirugias[j].mas_info === 1) {
              this.mostrar.mas_info_antecedentes_cirugias = true;
            }
          }
        }
      }
    },
    enfermedades_cronicas(val) {
      this.mostrar.mas_info_enfermedades_cronicas = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.familiares.length && !localizado; j++) {
          if (val[i] === this.antecedentes.familiares[j].id) {
            localizado = true;
            if (this.antecedentes.familiares[j].mas_info === 1) {
              this.mostrar.mas_info_enfermedades_cronicas = true;
            }
          }
        }
      }
    },
    habitos_toxicos(val) {
      this.mostrar.mas_info_habitos_toxicos = false;
      for (let i = 0; i < val.length; i++) {
        let localizado = false;
        for (let j = 0; j < this.antecedentes.toxicos.length && !localizado; j++) {
          if (val[i] === this.antecedentes.toxicos[j].id) {
            localizado = true;
            if (this.antecedentes.toxicos[j].mas_info === 1) {
              this.mostrar.mas_info_habitos_toxicos = true;
            }
          }
        }
      }
    },
  },

  methods: {
    cambiarCheckbox() {
      if (this.historial) {
        if (this.historial.alergias_medicamentos.trim() === '') {
          this.check.alergias_medicamentos = false;
        }
        if (this.historial.otras_alergias.trim() === '') {
          this.check.otras_alergias = false;
        }
        this.check.antecedentes_maternos = this.historial.antecedentes_maternos.length !== 0;
        this.check.antecedentes_paternos = this.historial.antecedentes_paternos.length !== 0;
        this.check.antecedentes_hermanos = this.historial.antecedentes_hermanos.length !== 0;
        this.check.cirugias = this.historial.cirugias.length !== 0;
        this.check.cronicas = this.historial.enfermedades_cronicas.length !== 0;
        if (this.historial.id !== null && this.historial.id > 0) {
          this.check.toxicos =
            (this.historial.h_toxicos_info !== null && this.historial.h_toxicos_info !== '') ||
            (this.historial.h_toxicos_ids !== null && this.historial.h_toxicos_ids.length > 0);
        }
      }
    },

    showAlert(alert) {
      this.$emit('mostrarAlert', alert);
    },
    modifyForm() {
      this.$emit('modificaDato', true);
    },
    errorAlert(mensaje) {
      this.$emit('mostrarAlert', {
        texto: mensaje,
        icono: '',
        color: 'error',
      });
    },
    successAlert(mensaje) {
      this.esta_modificando = false;
      this.$emit('mostrarAlert', {
        texto: mensaje,
        icono: 'mdi-check',
        color: 'success',
      });
    },

    generateReport(type) {
      this.loadingInforme = true;
      this.$http
        .get(generateClinicalReportUrl + '/' + type + '/' + this.paciente.id, {headers: getHeader()})
        .then(response => {
          if (this.isEnabledPdfInformInsideHtmlContainer) {
            this.dialogClinicalHistory.show = true;
            this.dialogClinicalHistory.url =
              response.data.url + '&share=' + response.data.canShareWithPatient + '&type=' + type;

            this.showDialogPDF(this.dialogClinicalHistory.url);
          } else {
            window.open(response.data.url + '&share=' + response.data.canShareWithPatient + '&type=' + type);
          }
          this.$emit('mostrarAlert', {
            texto: this.$t('success.generado_informe'),
            icono: 'mdi-cloud-check',
            color: 'success',
          });
        })
        .catch(err => {
          this.$log.error(err);
          this.$emit('mostrarAlert', {
            texto: this.$t('errors.try_again'),
            icono: 'mdi-alert-circle',
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingInforme = false;
        });
    },

    async generateNewReport() {
      try {
        this.validateClinicalReportForm();
        if (this.submittedWithErrors) {
          return;
        }
        await this.submitClinicalReportForm();
        this.closeReportDialog();
      } catch (e) {
        this.$emit('mostrarAlert', {
          texto: this.$t('errors.try_again'),
          icono: 'mdi-alert-circle',
          color: 'error',
        });
      }
    },

    closeReportDialog() {
      this.showReportDialog = false;
      this.resetForm();
    },

    async showDialogPDF(url) {
      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
      const loadingTask = pdfjsLib.getDocument(url);

      const outputScale = window.devicePixelRatio || 1;

      loadingTask.promise.then(pdf => {
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function (page) {
            const desiredWidth = document.getElementById('pageContainer').clientWidth;
            const viewport = page.getViewport({scale: 1});

            const scale = desiredWidth / viewport.width;
            const scaledViewport = page.getViewport({scale: scale});
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            document.getElementById('pageContainer').appendChild(canvas);
            document.getElementById('pageContainer').appendChild(document.createElement('br'));

            canvas.height = scaledViewport.height * outputScale;
            canvas.width = scaledViewport.width * outputScale;
            canvas.style.width = Math.floor(scaledViewport.width) + 'px';
            canvas.style.height = Math.floor(scaledViewport.height) + 'px';

            const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            const renderContext = {
              canvasContext: context,
              transform: transform,
              viewport: scaledViewport,
            };

            page.render(renderContext);
          });
        }
      });
    },

    saveHistoria() {
      this.disabledButton = true;
      if (!this.check.alergias_medicamentos) {
        this.historial.alergias_medicamentos = '';
      }
      if (!this.check.otras_alergias) {
        this.historial.otras_alergias = '';
      }
      this.historial.antecedentes_maternos = this.antecedentes_maternos;
      this.historial.antecedentes_paternos = this.antecedentes_paternos;
      this.historial.antecedentes_hermanos = this.antecedentes_hermanos;
      this.historial.cirugias = this.cirugias;
      this.historial.enfermedades_cronicas = this.enfermedades_cronicas;

      this.$http
        .post(
          editPacienteUrl,
          {
            id: this.authUser.id,
            patient_id: this.paciente.id,
            historia: this.historial,
            action: 'historial',
          },
          {headers: getHeader()}
        )
        .then(response => {
          if (response.status === 200 && response.data) {
            if (response.data >= 0) {
              this.$emit('mostrarAlert', {
                texto: this.$t('success.save_history'),
                icono: 'mdi-check',
                color: 'success',
              });
              this.cambiarCheckbox();
              if (this.historial.id === 0) {
                this.historial.id = response.data;
              }
            }
          } else {
            this.$emit('mostrarAlert', {
              texto: this.$t('errors.save_history'),
              icono: '',
              color: 'error',
            });
          }
          this.loading = false;
          this.disabledButton = false;
          this.$emit('modificaDato', false);
        });
    },

    reloadDiagnoses() {
      this.$emit('reloadDiagnoses');
    },
  },
};
</script>
<style>
.box-layout {
  padding: 16px;
}

.box-button {
  margin-top: 20px;
}

#pageContainer {
  margin: auto;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  #pageContainer {
    margin: auto;
    width: 100%;
  }
}

#pageContainer canvas {
  display: inline-block;
  width: 100%;
}

.report--container {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 480px) {
  .report--container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .report--container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .report--container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
