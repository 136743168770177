<template>
 <div>
   <div v-if="label" class="drop-label">{{ label }}{{required ? "*" : ""}}</div>
   <div :id="id" :class="{'drop-area': true, 'drop-area--selected': hasFiles, 'drop-area--invalid': error}" >
     <div class="drop-area__content">
      <div class="drop-area__input">
        <div v-if="hasFiles" class="drop-area__files">
          <template>
            <div v-for="({name}, index) in inputValue" :key="index" class="drop-file">
              <div>
                <component :is="fileTypeIcon(name)" fill="#AAAAAA"/>
              </div>
              <div class="drop-file__name">{{ name }}</div>
              <div class="drop-file__remove" @click="removeFile(index)">
                <CloseIcon />
              </div>
            </div>
          </template>
        </div>
        <div v-else class="drop-area__placeholder">
          {{ placeholder }}
        </div>
      </div>
      <div class="drop-area__divider"/>
      <div class="drop-area__icon">
        <AttachDocumentIcon fill="#AAAAAA" width="22" height="28"/>
      </div>
     </div>
     <input name="drop-files" class="drop-files" type="file" :multiple="multiple">
   </div>
   <div class="drop-area__error" :class="{'is-invisible': !error}">{{ error }}</div>
 </div>
</template>

<script>
  import AttachDocumentIcon from '@/assets/icons/attach-document.svg';
  import TextIcon from '@/assets/icons/text.svg';
  import AudioIcon from '@/assets/icons/audio.svg';
  import VideoIcon from '@/assets/icons/video.svg';
  import CloseIcon from '@/assets/icons/close.svg';
  import { initializeDropArea } from "@/components/form/FileSelectorDropzone/Droparea";
  import audioExtensions from "audio-extensions"
  import videoExtensions from "video-extensions"

  export default {
    components: {
      AttachDocumentIcon,
      TextIcon,
      AudioIcon,
      VideoIcon,
      CloseIcon,
    },
    props: {
      value: {
        type: FileList,
        default: null
      },
      required: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      error: {
        type: String,
        default: null
      }
    },

    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      hasFiles() {
        return this.inputValue?.length > 0;
      }
    },

    created() {
      this.$nextTick(() => {
        initializeDropArea(this.id, (files) => {
          console.log(files);
          this.inputValue = files;
        });
      });
    },

    methods: {
      fileTypeIcon(name) {
        const ext = name.split(".").pop();

        if (videoExtensions.includes(ext)) {
          return VideoIcon;
        } else if (audioExtensions.includes(ext)) {
          return AudioIcon;
        } else {
          return TextIcon;
        }
      },
      removeFile(index) {
        const dt = new DataTransfer()

        for (let i = 0; i < this.inputValue.length; i++) {
          const file = this.inputValue[i]
          if (index !== i)
            dt.items.add(file)
        }

        this.inputValue = dt.files
      }
    }
  }
</script>

<style lang="scss">
$error-color: #ED576B;

.drop-label {
  font-size: 15px;
  color: #222222;
  margin-bottom: 5px;
}

.drop-area {
  position: relative;
  display: flex;
  width: 100%;
  height: 68px;
  border: solid 2px #F1F1F1;
  border-radius: 5px;
  transition: 0.2s;

  @media only screen and (min-width: 768px) {
    height: 126px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px 8px;

    @media only screen and (min-width: 768px) {
      & {
        padding: 18px;
      }
    }
  }

  &--selected {
    border: solid 2px var(--v-primary-base);
  }

  &--invalid {
    border: solid 2px $error-color;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex-grow: 1;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (min-width: 768px) {
      justify-content: start;
    }
  }

  &__files {
    position: absolute;
    display: flex;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 9;
    width: 100%;
  }

  &__placeholder {
    font-size: 15px;
    color: #AAAAAA;
  }

  &__divider {
    height: 100%;
    width: 2px;
    background-color: #F1F1F1;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    width: 42px;
    height: 100%;
    padding-right: 4px;

    @media only screen and (min-width: 768px) {
      & {
        padding-right: 0;
      }
    }
  }

  &__error {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: $error-color;
  }
}

.drop-file {
  width: 241px;
  height: 51px;
  background-color: #F1F1F1;
  border-radius: 3px;
  padding: 15px 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &__icon {

  }
  &__name {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--v-primary-base);
  }
  &__remove {
    display: grid;
    place-content: center;
    width: 12px;
    height: 12px;

    & svg {
      width: 12px;
      height: 12px;
      fill: var(--v-primary-base);
    }
  }
}

.drop-files {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
</style>


