<template>
  <div id="preanestesia" style="padding: 2px 15px">
    <v-layout wrap>
      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.operacion"
          class="mr-2"
          :label="$t('anestesia.de_que_opera')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.quien_opera"
          class="mr-2"
          :label="$t('anestesia.quien_opera')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm4 lg4>
        <v-text-field
          v-model="anestesia.edad"
          class="mr-2"
          :label="$t('anestesia.edad')"
          type="number"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm4 lg4>
        <v-text-field
          v-model="anestesia.peso"
          class="mr-2"
          suffix="KG"
          :label="$t('anestesia.peso')"
          step="0.01"
          type="number"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm4 lg4>
        <v-text-field
          v-model="anestesia.talla"
          class="mr-2"
          suffix="CM"
          :label="$t('anestesia.talla')"
          step="0.01"
          type="number"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.alergicoMedicacion"
          :items="sino"
          :label="$t('anestesia.alergico_medicacion')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.alergicoMedicacion === 1" xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.alergicoMedicacionText"
          class="mr-2"
          :label="$t('anestesia.indique_a_que')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.tensionAlta"
          :items="sino"
          :label="$t('anestesia.tension_alta')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.diabetico"
          :items="sino"
          :label="$t('anestesia.diabetico')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.fumador"
          :items="sino"
          :label="$t('anestesia.fumador')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.alergicoMedicacion === 1" xs12 sm6 lg6>
        <!-- Si activo alergicoMedicamente, crear hueco para evitar descuadre -->
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadActual"
          :items="sino"
          :label="$t('anestesia.enfemedad_actual')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.enfermedadActual === 1" xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.enfermedadActualText"
          class="mr-2"
          :label="$t('anestesia.indique_a_que')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadPrevia"
          :items="sino"
          :label="$t('anestesia.enfermedad_previa')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.enfermedadPrevia === 1" xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.enfermedadPreviaText"
          class="mr-2"
          :label="$t('anestesia.indique_a_que')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="anestesia.medicacion"
          class="mr-2"
          :label="$t('anestesia.medicacion')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.operadoAnterior"
          :items="sino"
          :label="$t('anestesia.operado_anterior')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.operadoAnterior === 1" xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.operadoAnteriorText"
          class="mr-2"
          :label="$t('anestesia.indique_a_que')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.problemasAntestesia"
          :items="sino"
          :label="$t('anestesia.problema_anestesia')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex v-if="anestesia.problemasAntestesia === 1" xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.problemasAntestesiaText"
          class="mr-2"
          :label="$t('anestesia.indique_a_que')"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12>
        <br />
        <h5>{{ $t('anestesia.indique_si_ha_sufrido') }}</h5>
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadCorazon"
          :items="sino"
          :label="$t('anestesia.corazon')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadPulmon"
          :items="sino"
          :label="$t('anestesia.pulmon')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadHigado"
          :items="sino"
          :label="$t('anestesia.higado')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-select
          v-model="anestesia.enfermedadRinon"
          :items="sino"
          :label="$t('anestesia.enfermedad_rinon')"
          class="selectPreanestesia"
          auto
          required
          item-text="name"
          item-value="id"
        />
      </v-flex>

      <v-flex sm12 lg12>
        <br />
        <v-textarea
          v-model="anestesia.observaciones"
          :readonly="!anestesia.prof_anestesista"
          class="textarea_historia"
          :label="$t('patient.observations')"
          @change="modificaDato"
        />
        <br />
      </v-flex>

      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.nombrePaciente"
          class="mr-2"
          :label="$t('common.name')"
          readonly="readonly"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.telefono"
          class="mr-2"
          :label="$t('patient.phone')"
          readonly="readonly"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.email"
          class="mr-2"
          :label="$t('message.email')"
          readonly="readonly"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 sm6 lg6>
        <v-text-field
          v-model="anestesia.fechaIntervencion"
          class="mr-2"
          :label="$t('anestesia.fecha_intevencion') + '* '"
          type="text"
          required
          @change="modificaDato"
        />
      </v-flex>
      <v-flex xs12 class="text-sm-center">
        <v-btn :disabled="disabledButton" color="primary" large @click="save()"
          >{{ $t('common.save') }} {{ $t('patient.preanestesia') }}</v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { preanestesiaPacienteUrl, getHeader } from '../../../config/config';

export default {
  name: 'Preanestesia',
  props: {
    idpaciente: {
      type: String,
    },
    anestesia: {
      type: Object,
    },
  },

  data() {
    return {
      sino: [
        { id: 0, name: 'No' },
        { id: 1, name: 'Sí' },
      ],
      disabledButton: false,
      puede_editar: '',
    };
  },
  computed: {},
  methods: {
    modificaDato() {
      this.$emit('modificaDato', true);
    },
    save() {
      if (
        this.anestesia.fechaIntervencion === '' ||
        this.anestesia.email === '' ||
        this.anestesia.nombrePaciente === '' ||
        this.anestesia.telefono === ''
      ) {
        this.$emit('errorAlert', this.$t('errors.form_invalid'));
      } else if (!this.disabledButton) {
        this.disabledButton = true;
        const param = {
          accion: 'save',
          id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
          anestesia: this.anestesia,
          paciente_id: this.idpaciente,
        };
        this.$http.post(preanestesiaPacienteUrl, param, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.anestesia.id = response.data.id;
            this.$emit('successAlert', this.$t('success.save_common'));
          } else {
            this.$emit('errorAlert', this.$t('errors.try_again'));
          }
          this.disabledButton = false;
        });
      }
    },
  },
};
</script>

<style>
.selectPreanestesia {
  width: 98.5%;
}
</style>
