import {ref} from 'vue';
import {fetchAccessTireaUrl} from "../services";


export function useAccessToTirea() {
  const tireaUrl = ref(null);
  const isLoading = ref(false);
  const isNetworkError = ref(false);

  const retrieveAccessTireaUrl = async (collegiateNumber, professionalId, patientId) => {
    try {
      isNetworkError.value = false;
      isLoading.value = true;

      const response = await fetchAccessTireaUrl(collegiateNumber, professionalId, patientId);

      if (response.url) {
        tireaUrl.value = response.url;
      }
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    tireaUrl,
    isLoading,
    retrieveAccessTireaUrl
  };
}
