<template>
  <div>
    <div class="parapharmacy-prescriptions__new">
      <v-btn data-test="patient-edit-button-new-parapharmacy-prescription" depressed color="primary" @click="openPrescriptionModal">{{ $t('recetas.nueva') }}</v-btn>
    </div>
    <div class="parapharmacy-prescriptions__content">
      <v-simple-table v-if="!isLoadingPrescriptions && prescriptions.length" class="parapharmacy-prescriptions__table">
        <template v-slot:default>
          <tbody>
            <tr v-for="item in prescriptions" :key="item.id">
              <td class="parapharmacy-prescriptions__icon">
                <div class="parapharmacy-prescriptions__list-element">

                  <div class="icon-svg__container">
                    <ParapharmacyPrescriptionIcon />
                  </div>
                </div>
              </td>
              <td>
                <div class="prescription__data">
                  <div class="parapharmacy-prescription__name">{{ item.name }}</div>
                  <div class="parapharmacy-prescription__created-at">{{ item.created_at }}</div>
                </div>
              </td>
              <td>
                <div class="parapharmacy-prescriptions__list-element justify-content-end">
                  <div class="icon-svg__container" @click="prescriptionPreview(item.id)">
                    <GoToPrescriptionIcon />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-linear v-if="isLoadingPrescriptions" indeterminate />
      <div v-if="!isLoadingPrescriptions && prescriptions.length === 0" class="text-center grey--text pa-4">
        {{ $t('patient.sin_recetas') }}
      </div>
    </div>
    <NewParapharmacyPrescription
      v-model="newPrescriptionModal"
      :patient-id="patientId"
      @newPrescription="openPrescriptionForm"
    />
    <EmitParapharmacyForm
      v-if="dialogEmitParapharmacyModal"
      v-model="dialogEmitParapharmacyModal"
      :template-id="template"
      :patient-id="patientId"
      @emitted="parapharmacyPrescriptionEmitted"
    />
  </div>
</template>

<script>
import NewParapharmacyPrescription from '@/components/Parapharmacy/New/NewParapharmacyPrescription.vue';
import { useParapharmacyPatientPrescriptions } from '@/use/Parapharmacy/useParapharmacyPatientPrescriptions';
import EmitParapharmacyForm from '@/components/Parapharmacy/EmitForm/EmitParapharmacyForm.vue';
import { getHeader, parapharmacyPrescriptionPdfUrl, professionalHasSign } from '@/config/config';
import { baseNameRoutes } from '@/router/paths';
import DownloadFile from '@/mixins/DownloadFile';

import ParapharmacyPrescriptionIcon from "@/assets/icons/receta-parafarmacia.svg";
import GoToPrescriptionIcon from "@/assets/icons/boton-ver-receta.svg";

export default {
  name: 'ParapharmacyPrescriptions',
  components: { EmitParapharmacyForm, NewParapharmacyPrescription, ParapharmacyPrescriptionIcon, GoToPrescriptionIcon },

  mixins: [DownloadFile],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    patientUuid: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {
      prescriptions,
      isLoadingPrescriptions,
      isNetworkError,
      retrievePrescriptions,
    } = useParapharmacyPatientPrescriptions();

    return {
      prescriptions,
      isLoadingPrescriptions,
      isNetworkError,
      retrievePrescriptions,
    };
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      newPrescriptionModal: false,
      template: null,
      dialogEmitParapharmacyModal: false,
    };
  },

  created() {
    this.retrievePrescriptions(this.authUser.user.professional_docline_api_id, this.patientUuid);
  },
  methods: {
    async prescriptionPreview(id) {
      await this.download({
        url: parapharmacyPrescriptionPdfUrl(id),
        name: id + '.pdf',
        type: 'application/pdf',
        download: false,
        open: true,
        openInNewTab: true,
      });
    },
    async professionalHasSign() {
      try {
        const response = await this.$http.get(professionalHasSign, { headers: getHeader() });
        return response.status === 200;
      } catch (e) {
        await this.$router.push({ name: baseNameRoutes.signed });
      }
    },
    async openPrescriptionModal() {
      await this.professionalHasSign();
      this.newPrescriptionModal = true;
    },
    openPrescriptionForm(template) {
      this.template = template;
      this.dialogEmitParapharmacyModal = true;
    },
    parapharmacyPrescriptionEmitted() {
      this.retrievePrescriptions(this.authUser.user.professional_docline_api_id, this.patientUuid);
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #9e9e9e;

.justify-content-end {
  justify-content: end;
}

.parapharmacy-prescriptions__new {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.parapharmacy-prescriptions__content {
  margin-top: 0.5rem;
}

.parapharmacy-prescriptions__table td:not(:first-child) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.parapharmacy-prescriptions__icon {
  width: 2rem;

  & img {
    width: 2rem;
  }
}

.parapharmacy-prescriptions__list-element {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.prescription__data {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parapharmacy-prescription__name {
  color: $black;
  font-weight: 500;
  font-size: 1rem;
}

.parapharmacy-prescription__created-at {
  color: $grey;
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .parapharmacy-prescriptions__new {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
}
</style>
