if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
}

const AFIRMA_CONNECTION_RETRIES = 15;
const AFIRMA_JS_LOCATION = window.location.origin + '/afirma/miniapplet_v1_6.js';
const AFIRMA_JAR_LOCATION = window.location.origin + '/afirma/miniapplet-full_1_6.jar';

export function signText(text) {
  return new Promise((resolve, reject) => {
    const miniAppletSign = () => {
      try {
        const dataB64 = window.MiniApplet.getBase64FromText(text, 'auto');
        window.MiniApplet.sign(
          dataB64,
          'SHA512withRSA',
          'CAdES',
          'filter=nonexpired:',
          (signatureB64, certificateB64) => {
            resolve({
              text: text,
              signatureB64: signatureB64,
              certificateB64: certificateB64,
            });
          },
          (type, message) => {
            reject({
              type: type,
              message: message,
            });
          }
        );
      } catch (err) {
        let type;
        switch (window.MiniApplet.getErrorType()) {
          case 'es.gob.afirma.core.AOCancelledOperationException':
            type = 'CancelledOperation';
            break;
          case 'es.gob.afirma.core.AOCertificatesNotFoundException':
            type = 'CertificatesNotFound';
            break;
          case 'es.gob.afirma.core.OutOfMemoryError':
            type = 'OutOfMemoryError';
            break;
          case 'java.lang.Exception':
            type = 'Exception';
            break;
          default:
            type = 'UnknownError';
        }

        reject({
          type: type,
          message: window.MiniApplet.getErrorMessage(),
        });
      }
    };

    if (window.MiniApplet) {
      miniAppletSign();
    } else {
      const script = document.createElement('script');
      script.setAttribute('id', 'miniapplet');
      script.onload = () => {
        window.MiniApplet.AUTOFIRMA_CONNECTION_RETRIES = AFIRMA_CONNECTION_RETRIES;
        window.MiniApplet.cargarMiniApplet(AFIRMA_JAR_LOCATION);
        miniAppletSign();
      };
      script.src = AFIRMA_JS_LOCATION;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });
}
