<template>
  <div>
    <div v-if="label" class="videoconsultation-selector__label">{{ label }}{{required ? "*" : ""}}</div>
    <div class="videoconsultation-selector__container">
      <div v-for="video in videoConsultations" :key="video.id" :class="{'videoconsultation-selectable__container': true, 'videoconsultation-selectable--selected': isSelected(video.id)}" @click.stop="toggleSelection(video.id)">
        <div class="videoconsultation-selectable__label">{{ video.fecha }} · {{ video[`especialidad_nombre_${$i18n.locale}`] }}</div>
        <div v-if="isSelected(video.id)" class="icon-svg__container videoconsultation-selectable__close" @click.stop="removeSelection">
          <CloseIcon />
        </div>
      </div>
    </div>
    <div
      v-if="!empty"
      :class="{'is-invisible': !isLoadingVideoconsultations}"
      class="videoconsultation-selector__loader">
      {{ $t('videoconsultation_selector.loading_videoconsultations')}}
    </div>
    <div
      v-if="!isLoadingVideoconsultations"
      :class="{'is-invisible': !empty}"
      class="videoconsultation-selector__empty">
      {{ $t('videoconsultation_selector.not_found_videoconsultations')}}
    </div>
  </div>
</template>

<script>

import {
  useVideoconsultationSelector
} from "@/components/Videoconsultations/VideoconsultationSelector/useVideoconsultationSelector";
import CloseIcon from "@/assets/icons/close.svg";

export default {
  name: "VideoconsultationSelector",
  components: { CloseIcon },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null
    },
    patientId: {
      type: [String, Number],
      default: null
    },
    professionalId: {
      type: [String, Number],
      default: null
    }
  },
  setup() {
    const {
      videoConsultations,
      isLoadingVideoconsultations,
      isNetworkError,
      retrieveVideoconsultations,
      selected,
      isSelected,
      select,
      unSelect,
      empty,
    } = useVideoconsultationSelector();

    return {
      videoConsultations,
      isLoadingVideoconsultations,
      isNetworkError,
      retrieveVideoconsultations,
      selected,
      isSelected,
      select,
      unSelect,
      empty
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
  watch: {
    selected(val) {
      this.inputValue = val;
    },
    inputValue(val) {
      this.selected = val;
    }
  },
  created() {
    this.retrieveVideoconsultations(this.patientId, this.professionalId);
  },
  methods: {
    toggleSelection(videoconsultationId) {
      if (this.isSelected(videoconsultationId)) {
        this.unSelect();
      } else {
        this.select(videoconsultationId);
      }
    },
    removeSelection() {
     this.unSelect();
    }
  }
}

</script>

<style lang="scss">
.videoconsultation-selector {
  &__label {
    font-size: 15px;
    color: #222222;
    margin-bottom: 5px;
  }

  &__loader, &__empty {
    font-size: 12px;
    color: #DEDEDE;
    margin-top: 5px;
  }

  &__container {
    width: 100%;
    height: 328px;
    background-color: #DEDEDE;
    border-radius: 5px;
    padding: 6px;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 6px;

    overflow-y: auto;

    @media only screen and (min-width: 768px) {
      & {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        padding: 18px;
        height: 216px;
      }
    }
  }
}

.videoconsultation-selectable {
  &__container {
    display: flex;
    width: 100%;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &--selected {
    background-color: #DAF2F8;
  }

  &__label {
    font-size: 15px;
    color: #212121;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__close {
    width: 12px;
    height: 12px;
    padding-right: 10px;

    & svg {
      width: 12px;
      height: 12px;
      fill: var(--v-primary-base);
    }
  }
}

</style>
