let dropArea;
let dropFiles;

const initilializeElements = (id) => {
  dropArea = document.getElementById(id);
  dropFiles = document.querySelector(`#${id} input[name='drop-files']`);
}
const preventDefaults = () => {
  function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false)
    dropFiles.addEventListener(eventName, preventDefaults, false)
  })

  dropFiles.addEventListener('click', (e) => {
    e.stopPropagation();
  });
}

const addHighlight = () => {
  function highlight(e) {
    dropArea.classList.add('highlight')
  }

  function unhighlight(e) {
    dropArea.classList.remove('highlight')
  }

  ;['dragenter', 'dragover'].forEach(eventName => {
    dropFiles.addEventListener(eventName, highlight, false)
  })

  ;['dragleave', 'drop'].forEach(eventName => {
    dropFiles.addEventListener(eventName, unhighlight, false)
  })
}

export const initializeDropArea = (id, handler) => {
  initilializeElements(id);
  preventDefaults();
  addHighlight();

  function handleDrop(e) {
    const dt = e.dataTransfer
    const files = dt.files

    handler(files)
  }

  function handleChange(e) {
    handler(e.target.files)
  }

  dropFiles.addEventListener('drop', handleDrop, false)
  dropFiles.addEventListener('change', handleChange, false)
}
