<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-tooltip="maxExpireAt.format(DATE_FORMAT.LONG)"
        class="mr-2"
        :class="{
          'red--text': isExpiredInOneDay,
        }"
        >{{ $t('videoconsultations.expire_at') }} {{ expireAtFromNow }}</span
      >
      <v-btn v-tooltip="$t('videoconsultations.download_recording')" icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-download</v-icon></v-btn
      >
    </template>
    <v-list>
      <v-list-item
        v-for="archive in archivesList"
        :key="archive.id"
        @click="onDownloadArchive(archive, videoconsultationId)"
      >
        <v-list-item-title class="text-body-1">
          <v-icon class="mr-3">mdi-radiobox-marked</v-icon>{{ archive.createdAt | moment(DATE_FORMAT.SHORT) }} ({{
            archive.durationSeconds
          }}
          {{
            $t('videoconsultations.seconds')
              .toLowerCase()
              .slice(0, 3)
          }}) - {{ $t('videoconsultations.expire_at') }} {{ archive.expiredAt | moment(DATE_FORMAT.LONG) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const DATE_FORMAT = {
  SHORT: 'DD/MM/YYYY',
  LONG: 'DD/MM/YYYY hh:mm',
};

export default {
  name: 'VideoTableRowMenu',
  props: {
    archivesList: {
      type: Array,
      required: true,
    },
    videoconsultationId: {
      type: Number,
      required: true,
    },
    onDownloadArchive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    DATE_FORMAT() {
      return DATE_FORMAT;
    },
    maxExpireAt() {
      return this.$moment.min(this.archivesList.map(file => this.$moment(file.expiredAt)));
    },
    expireAtFromNow() {
      return this.maxExpireAt.fromNow(true);
    },
    isExpiredInOneDay() {
      return this.maxExpireAt.diff(new this.$moment(), 'days') <= 1;
    },
  },
};
</script>
