<template>
  <dropdown-box-section :title="title">
    <AllergyPatientInfoBox :patient="patient" class="mb-8" />

    <!--    <v-radio-group v-show="!isRenewPrescription && !isModifyPrescription" v-model="showSection" row>-->
    <!--      <v-radio :value="1" :label="$t('recipe.medicines')" color="grey" />-->
    <!--      <v-radio :value="2" :label="$t('recipe.master_formulas')" color="grey" />-->
    <!--    </v-radio-group>-->

    <div v-if="showSection === 1">
      <div class="d-flex flex-row justify-space-between">
        <p class="text-h5 mb-3">{{ $t('recipe.medicines') }}</p>
        <v-checkbox v-model="byFav" :label="$t('medicine.filter_by_fav')" class="mt-0" />
      </div>

      <medicine-form
        v-for="(medicine, index) in value.medicines"
        :key="index"
        v-model="medicines[index]"
        :patient="patient"
        :is-edit-prescription="isEditPrescription"
        :error-units="errorUnits"
        :error-duration="errorDuration"
        :error-frecuency="errorFrecuency"
        :error-medicine="errorMedicine"
        :medications-added="medicationsAdded"
        :has-added-narcotic="hasAddedNarcotic"
        :new-date-start-for-same-medicine="newDateStartForSameMedicine"
        :filter-by-fav="byFav"
        @onCheckingAmount="onCheckingAmount"
        @selMedicine="selectedMedicine($event, index)"
        @remove="removeMedicine(index)"
        @handle-same-medicines-total-days="handleSameMedicinesTotalDays"
      />
      <v-btn :disabled="isCheckingAmount" color="primary" text @click="addMedicine">
        {{ $t('recipe.add_medicines') }}
      </v-btn>
    </div>

    <master-formula-section v-if="showSection === 2" :items="value.masterFormulas" />
  </dropdown-box-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapModelFields, hideIntercom } from '@/utils';
import MasterFormulaSection from '@/components/treatments/MasterFormulaSection';
import MedicineForm from './MedicineForm.vue';
import AllergyPatientInfoBox from '@/components/ehealth/paciente/allergies/AllergyPatientInfoBox';
import { LIMIT_IN_DAYS_FOR_SAME_MEDICINE } from '@/config/constants';

export default {
  components: {
    MasterFormulaSection,
    MedicineForm,
    AllergyPatientInfoBox,
  },

  props: {
    patient: {
      type: [Number, Boolean],
      default: false,
    },
    value: {
      type: Object,
      default: () => ({
        type: 1,
        tinCode: '',
        masterFormula: '',
        medicines: [],
        masterFormulas: [],
      }),
    },
    title: {
      type: String,
      default: '',
    },
    isEditPrescription: {
      type: Boolean,
      default: false,
    },
    isRenewPrescription: {
      type: Boolean,
      default: false,
    },
    isModifyPrescription: {
      type: Boolean,
      default: false,
    },
    errorUnits: {
      type: Boolean,
      default: false,
    },
    errorDuration: {
      type: Boolean,
      default: false,
    },
    errorFrecuency: {
      type: Boolean,
      default: false,
    },
    errorMedicine: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSection: 1,
      showTinCode: false,
      types: [
        { text: this.$t('recipe.sel_type'), value: 0 },
        { text: this.$t('recipe.type_electronic'), value: 1 },
        { text: this.$t('recipe.type_pdf'), value: 2 },
      ],
      currentMedicine: null,
      newDateStartForSameMedicine: false,
      isCheckingAmount: false,
      byFav: false,
    };
  },

  computed: {
    ...mapModelFields(['type', 'tinCode', 'medicines', 'masterFormulas']),
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),
    ...mapGetters({
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
    hasAddedNarcotic() {
      return this.medicines.some(item => item.narcotic);
    },
    medicationsAdded() {
      return (this.value.medicines?.length || 0) + (this.value.masterFormulas?.length || 0);
    },
  },

  watch: {
    type(value) {
      this.showTincode(value);
    },

    tinCode(val) {
      this.value.tinCode = val;
    },

    value() {
      this.showMasterFormulas();
    },

    showSection(val) {
      if (1 === val) {
        this.value.masterFormulas = [];
        this.addMedicine();
      } else {
        this.value.medicines = [];
      }
    },
  },

  mounted() {
    this.showMasterFormulas();
    this.showTincode(this.type);
    this.setTitle();
    hideIntercom();
  },

  methods: {
    addMedicine() {
      this.newDateStartForSameMedicine = false;

      this.medicines.push({
        medicineId: null,
        units: 0,
        posology: '',
        posology_measure: 'hours',
        duration: '',
        duration_measure: 'days',
        guideline: '',
        comments: '',
        diagnostic: '',
        date_start: '',
        date_end: '',
      });
    },

    removeMedicine(index) {
      if (this.medicines.length === 1) {
        this.medicines[0].medicineId = null;
        this.medicines[0].units = 0;
        this.medicines[0].posology = '';
        this.medicines[0].posology_measure = this.$t('recipe.hours');
        this.medicines[0].duration = '';
        this.medicines[0].duration_measure = this.$t('recipe.days');
        this.medicines[0].guideline = '';
        this.medicines[0].comments = '';
        this.medicines[0].diagnostic = '';
        this.medicines[0].date_start = '';
        this.medicines[0].date_end = '';
      } else {
        this.medicines.splice(index, 1);
        this.$emit('remove-medicine', [...this.medicines]);
      }
    },

    showTincode(val) {
      if (val === 1) {
        this.showTinCode = true;
      } else if (this.showTinCode) {
        this.showTinCode = false;
      }
    },

    selectedMedicine({ id, medicineSelected } = {}, index) {
      this.currentMedicine = medicineSelected;
      this.value.medicines[index].medicineId = id;

      if (this.repHomologation) {
        const sameMedicines = this.getSameMedicines();
        if (sameMedicines && sameMedicines.length > 1) {
          this.handleSameMedicineNewDateStart(sameMedicines);
        }
      }
    },

    async handleSameMedicinesTotalDays() {
      const sameMedicines = this.getSameMedicines();
      if (!sameMedicines) return;

      const totalDays = sameMedicines.reduce(
        (acc, item) => (acc += this.getDiffDays(item.date_start, item.date_end)),
        0
      );

      if (totalDays >= LIMIT_IN_DAYS_FOR_SAME_MEDICINE) {
        await this.$dialog.confirm({
          text: this.$t('prescriptions.maxDaysForSameMedicineText'),
          title: this.$t('prescriptions.maxDaysForSameMedicineTitle'),
          actions: {
            true: {
              color: 'primary',
              text: this.$t('common.aceptar'),
            },
          },
        });

        this.removeLastMedicine();
      }
    },

    removeLastMedicine() {
      this.value.medicines.pop();
      if (!this.value.medicines.length) {
        this.$nextTick(() => this.addMedicine());
      }
    },

    handleSameMedicineNewDateStart(sameMedicines) {
      const [lastMedicine] = sameMedicines.slice(-2);
      this.newDateStartForSameMedicine = this.$moment(lastMedicine.date_end)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    },

    getSameMedicines() {
      if (this.currentMedicine === null) return false;
      return this.medicines.filter(item => item.medicineId === this.currentMedicine.id);
    },

    getDiffDays(dateStart, dateEnd) {
      return this.$moment(dateEnd).diff(this.$moment(dateStart), 'days');
    },

    setTitle() {
      if (this.title === '') {
        this.title = this.$t('recipe.recipe');
      }
    },

    onCheckingAmount(val) {
      this.isCheckingAmount = val;
    },

    showMasterFormulas() {
      if (this.value.medicines.length === 0 && this.value.masterFormulas.length > 0) {
        this.showSection = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fields-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;

  :nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.add-medicine {
  font-weight: bold;
  cursor: pointer;
}

.div-onerow {
  height: 4em;
}

.master-form {
  height: 2rem;
}

.formula {
  margin-top: -15px;
}
</style>
