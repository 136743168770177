<template>
  <GenericModal v-model="modalValue" :title="$t('documents.upload_document')" :max-width="1080">
    <UploadDocument
      v-model="uploadObject"
      :patient-id="patientId"
      :professional-id="professionalId"
    />
    <template v-slot:header-icon>
      <div class="icon-svg__container">
        <AttachDocumentIcon fill="#FFFFFF"  />
      </div>
    </template>
    <template v-slot:actions>
      <DButton :primary="true" :loading="submitting" :disabled="!isValid" @click.native="submit">{{
          $t('documents.upload_document')
        }}</DButton>
    </template>
  </GenericModal>
</template>


<script>
  import GenericModal from "@/components/atoms/GenericModal.vue";
  import UploadDocument from "@/DocumentsManagement/components/UploadDocument.vue";
  import DButton from "@/components/atoms/DButton.vue";
  import AttachDocumentIcon from "@/assets/icons/attach-document.svg";

  export default {
    name: "UploadDocumentModal",
    components: {AttachDocumentIcon, DButton, UploadDocument, GenericModal },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      patientId: {
        type: Number,
        default: null
      },
      professionalId: {
        type: Number,
        default: null
      },
      submitting: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        uploadObject: null,
      };
    },
    computed: {
      modalValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      isValid() {
        return this.uploadObject?.files?.length > 0 && !this.uploadObject?.error;
      }
    },
    watch: {
      modalValue(val) {
        this.uploadObject = null;
      }
    },
    methods: {
      submit() {
        this.$emit('submit', this.uploadObject);
      }
    }
  }
</script>
