<template>
  <Divider>
    <div class="videoconsultation-link__divider">{{ $t('documents.link_to_vc') }}</div>
  </Divider>
</template>

<script>
  import Divider from "@/components/Divider/Divider.vue";

  export default {
    name: "VideoconsultationLinkDivider",
    components: {Divider},
  }
</script>

<style lang="scss">

.videoconsultation-link {
  &__divider {
    color: #AAAAAA;
    text-align: center;

    @media only screen and (max-width: 768px) {
      text-wrap: wrap;
      width: 180px;
    }
  }
}

</style>
