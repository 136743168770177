<template>
  <v-card flat>
    <v-card-text>
      <v-col cols="12" xs="12" align="right">
        <v-btn v-if="!adding" text color="primary" @click="expandAddObservation">
          <v-icon>mdi-plus</v-icon>
          {{ $t('common.add') }}
        </v-btn>
      </v-col>

      <v-row v-if="adding">
        <v-col cols="12" xs="12">
          <v-textarea v-model="observation.text" outlined :label="$t('patient.observations')" placeholder="" rows="4" />
        </v-col>
        <v-col cols="12" xs="12" align="right">
          <v-btn color="grey darken-1" text @click="closeAddObservation">{{ $t('common.cancel') }} </v-btn>
          <v-btn color="primary" depressed @click="save">{{ $t('common.save') }} </v-btn>
        </v-col>
      </v-row>

      <div v-if="observations.length === 0" class="text-center grey--text pa-4">
        {{ $t('patient.no_observations') }}
      </div>

      <v-row>
        <v-col cols="12" xs="12">
          <v-list-item v-for="(observation, index) in observations" :key="index">
            <v-list-item-avatar class="details-action details-action--image">
              <d-avatar
                :text="observation.prof_name + ' ' + observation.prof_surname"
                size="40"
                rounded
                :two-letter="true"
                :src="observation.prof_avatar"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ observation.prof_name }} {{ observation.prof_surname }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption">{{
                observation.created_at | moment('DD/MM/YYYY')
              }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text--primary no-ellipsis">{{ observation.text }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="details-action details-action--button">
              <v-btn icon @click="delObservation(observation.id)">
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getHeader, savePatientObservation, getPatientObservation, deletePatientObservation } from '@/config/config';
import DAvatar from '../../DAvatar.vue';

export default {
  name: 'ClientHistory',
  components: { DAvatar },
  data() {
    return {
      adding: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      observation: {
        text: '',
        patientId: parseInt(this.$route.params.id),
        professionalId: null,
      },
      observations: [],
    };
  },
  mounted() {
    this.getObservations();
  },

  methods: {
    expandAddObservation() {
      this.adding = true;
    },

    closeAddObservation() {
      this.adding = false;
    },

    clear() {
      this.adding = false;
      this.observation = {
        text: '',
        patientId: parseInt(this.$route.params.id),
        professionalId: null,
      };
      this.getObservations();
    },

    save() {
      const isValid = this.validate();
      if (isValid) {
        this.observation.professionalId = this.authUser.id;
        this.$http
          .post(savePatientObservation, this.observation, { headers: getHeader() })
          .then(res => {
            if (res.status === 200 && res.data.success === 1) {
              this.$toast.success(this.$t('success.save_common'));
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
          })
          .catch(err => {
            const textAlert = err.data && err.data.message ? this.$t(err.data.message) : this.$t('errors.try_again');
            this.$toast.error(textAlert);
          })
          .finally(() => {
            this.clear();
          });
      }
    },

    getObservations() {
      const data = {
        patientId: parseInt(this.$route.params.id),
        professionalId: this.authUser.id,
      };

      this.$http
        .post(getPatientObservation, data, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.data.success === 1 && res.data.observations) {
            this.observations = res.data.observations;
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.message ? this.$t(err.data.message) : this.$t('errors.try_again');
          this.$toast.error(textAlert);
        });
    },

    delObservation(id) {
      const data = {
        professionalId: this.authUser.id,
        deleteId: id,
      };
      this.$http
        .post(deletePatientObservation, data, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.data.success === 1) {
            this.$toast.success(this.$t('success.delete_common'));
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.message ? this.$t(err.data.message) : this.$t('errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(() => {
          this.clear();
        });
    },

    validate() {
      let errorMsj = '';
      if (this.observation.text.trim().length === 0) {
        errorMsj = this.$t('common.fieldIncomplete', {
          field: this.$t('patient.observations').toLowerCase(),
        });
      }

      const isValid = errorMsj !== '' ? false : true;
      if (!isValid) {
        this.$toast.error(errorMsj);
      }

      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-ellipsis {
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
}

.details-action {
  align-self: flex-start;
  &--image {
    top: 12px;
  }
  &--button {
    top: 16px;
  }
}
</style>
