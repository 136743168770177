<template>
  <div class="field-display-box">
    <div class="grey--text text--darken-1 font-weight-bold">{{ title }}</div>
    <div class="grey--text text--darken-3">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'FieldDisplay',
  props: {
    title: {
      type: [String, null],
      default: '',
    },
    text: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.field-display-box {
  padding: 0.5rem;

  .display-title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #7fb5b5;
  }

  .display-text {
    font-weight: bold;
    color: #4d6563;
  }
}
</style>
