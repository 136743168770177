<template>
  <div id="cancel-prescription-form">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('recipe.cancel_prescription') }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeCancelForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-0">
        <v-autocomplete
          v-model="reason"
          item-value="id"
          item-text="name"
          :items="reasons"
          :label="$t('recipe.select_reason')"
          outlined
          clearable
          filled
          dense
        />
      </v-card-text>

      <v-toolbar flat>
        <v-btn depressed color="primary" :disabled="!reason" @click="cancelPrescription">{{
          $t('recipe.cancel_prescription')
        }}</v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CancelPrescriptionForm',
  props: {
    prescriptionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      reason: 0,
      reasons: [
        { id: 1, name: this.$t('recipe.cancel_reason_0') },
        { id: 2, name: this.$t('recipe.cancel_reason_1') },
        { id: 3, name: this.$t('recipe.cancel_reason_2') },
        { id: 4, name: this.$t('recipe.cancel_reason_3') },
      ],
    };
  },
  methods: {
    closeCancelForm() {
      this.$emit('clickedReturn', true);
    },
    cancelPrescription() {
      const reasonDescription = this.reasons[this.reason - 1].name;
      this.$emit('clickedCancelPrescription', this.prescriptionId, reasonDescription);
    },
  },
};
</script>
