import {
  getHeader,
  personalDoctorPatientAssignmentsUrl,
  personalDoctorPatientsUrl,
  personalDoctorUrl,
} from '@/config/config';
import axios from 'axios';

export const PERSONAL_DOCTOR_SELECTOR_PAGE_SIZE = 100;
export const DEFAULT_HISTORIC_PAGE_SIZE = 5;

export const fetchHistoricalPersonalDoctors = async (
  patientId,
  companyId,
  { page = 1, size = DEFAULT_HISTORIC_PAGE_SIZE }
) => {
  const url = new URL(personalDoctorPatientAssignmentsUrl(patientId));
  const params = {
    companyId: companyId,
    page: page,
    size: size,
    order: 'desc',
    sort: 'startedAt',
  };

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;
};

export const fetchAvailablePersonalDoctors = async companyId => {
  const url = new URL(personalDoctorUrl);

  const params = {
    page: 1,
    size: PERSONAL_DOCTOR_SELECTOR_PAGE_SIZE,
    companyId: companyId,
  };

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });

  return raw.data;
};

export const fetchPatientPersonalDoctor = async (patientId, companyId) => {
  const url = new URL(`${personalDoctorPatientsUrl(patientId)}`);

  const params = {
    companyId: companyId,
  };

  url.search = new URLSearchParams(params).toString();

  const raw = await axios.get(url.toString(), {
    headers: getHeader(),
  });
  return raw.data;
};

export const deassign = async (patientId, professionalId) => {
  await axios.delete(`${personalDoctorPatientsUrl(patientId)}/${professionalId}`, {
    headers: {
      ...getHeader(),
      Accept: '*/*',
    },
  });
};

export const assign = async (patientId, professionalId) => {
  await axios.put(`${personalDoctorPatientsUrl(patientId)}/${professionalId}`, {
    headers: getHeader(),
  });
};
