<template>
  <div id="recetas">
    <div class="py-3 text-right mr-4">
      <v-btn depressed color="primary" :to="'/receta/' + idpaciente">{{ $t('recetas.nueva') }}</v-btn>
    </div>
    <v-list v-for="(item, index) in recetas" :key="item.file" two-line>
      <v-list-item href="#">
        <v-list-item-avatar color="green lighten-2">
          <v-icon color="green lighten-5">mdi-note</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.medicamento }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu offset-y top>
            <template v-slot:activator="{ on }">
              <v-btn icon ripple v-on="on">
                <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item color="blue darken-4" @click="showPrescription(item.id)">
                <v-list-item-title>{{ $t('recetas.ver') }}</v-list-item-title>
              </v-list-item>
              <v-list-item color="blue darken-4" @click="reemitir(index)">
                <v-list-item-title>{{ $t('recetas.reemitir') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-divider inset />
    </v-list>
    <div v-if="recetas && recetas.length === 0" class="text-center grey--text pa-4">
      {{ $t('patient.sin_recetas') }}
    </div>
  </div>
</template>

<script>
import { showPrescriptionUrl } from '@/config/config';
import DownloadFile from '@/mixins/DownloadFile';

export default {
  name: 'Recetas',
  mixins: [DownloadFile],
  props: {
    recetas: {
      type: Array,
      default: () => [],
    },
    idpaciente: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  methods: {
    async showPrescription(prescriptionId) {
      await this.download({
        url: showPrescriptionUrl + '/' + prescriptionId,
        name: this.$t('prescriptions.prescription') + prescriptionId + '.pdf',
        type: 'application/pdf',
        open: true,
        openInNewTab: true,
      });
    },

    reemitir(indice) {
      this.$router.push('/reemitir/' + this.recetas[indice].id);
    },
  },
};
</script>

<style>
.cabecera_nueva_receta {
  text-align: right;
}
</style>
