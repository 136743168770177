<template>
  <div id="authorizations">
    <v-data-table
      :headers="headers"
      :items="authorizations"
      class="elevation-1"
      :items-per-page="10"
      :options="{ sortBy: ['createdAt'], sortDesc: [true] }"
      :footer-props="{
        itemsPerPageText: $t('datatable.rows_per_page'),
        itemsPerPageAllText: $t('datatable.all'),
        pageText: '',
      }"
      item-key="id"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            {{ props.item.id }}
          </td>
          <td>
            {{ props.item.specialtyName }}
          </td>
          <td>
            <span v-tooltip="props.item.createdAt">{{ props.item.createdAt | moment('DD/MM/YYYY') }}</span>
          </td>
          <td>
            <span v-if="props.item.acceptedAt !== ''" v-tooltip="props.item.acceptedAt">
              {{ props.item.acceptedAt | moment('DD/MM/YYYY') }}
            </span>
          </td>
          <td>
            {{ props.item.statusName }}
          </td>
          <td>
            <v-btn
              v-if="props.item.acceptedAt !== ''"
              v-tooltip="$t('common.download_pdf')"
              icon
              color="grey"
              :to="props.item.url"
              :disabled="loading"
              @click="downloadInformedConsent(props.item.id)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              v-tooltip="$t('common.view')"
              icon
              color="grey"
              :to="props.item.url"
              @click="showInformedConsent(props.item.template)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template slot="no-data">
        {{ $t('patient.without_authorizations') }}
      </template>
    </v-data-table>

    <dialog-confirm :dialog="dialog" />
  </div>
</template>

<script>
import DialogConfirm from '@/components/common/DialogConfirm.vue';
import { downloadInformedConsentUrl, getHeader } from '../../../config/config';
import { mapActions } from 'vuex';

export default {
  name: 'Authorizations',
  components: {
    DialogConfirm,
  },
  props: {
    patientId: {
      type: String,
      default: '',
    },
    authorizations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: 'Id', align: 'left', value: 'id' },
        { text: vm.$t('agenda.especialidad'), align: 'left', value: 'specialtyName' },
        { text: vm.$t('recipe.created_date'), align: 'left', value: 'createdAt' },
        { text: vm.$t('common.accepted_at'), align: 'left', value: 'acceptedAt' },
        { text: vm.$t('common.status'), align: 'left', value: 'statusName' },
        { text: '', value: 'action', align: 'right', sortable: false },
      ],
      loading: false,
      dialog: {
        showHtml: false,
        show: false,
        title: '',
        text: '',
        maxwidth: 800,
        idSelected: 0,
        index: 0,
      },
    };
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    showInformedConsent(content) {
      this.dialog.text = content;
      this.dialog.show = true;
      this.dialog.showHtml = true;
    },

    downloadInformedConsent(templateId) {
      this.loading = true;
      this.$http
        .get(downloadInformedConsentUrl + '/' + this.patientId + '/' + templateId, { headers: getHeader() })
        .then(response => {
          window.open(response.data.url);
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.showAlert({
            color: 'error',
            icon: '',
            message: textAlert,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
