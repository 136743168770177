<template>
  <v-card v-show="triage" outlined class="my-4 pa-6">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0 headline">{{
      $t('triage.title')
    }}</v-card-title>
    <article class="triage__group">
      <div class="triage__flex">
        <v-card-text class="px-0 py-0 text-body-2 font-weight-regular triage__hint">
          <span class="text--secondary">{{ $t('triage.code') }}</span>
          <p class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
            {{ getTriageCode() }}
          </p>
        </v-card-text>
        <div class="triage__buttons">
          <v-btn color="primary" outlined class="triage__button" @click="generateCode">
            {{ $t('triage.generate_code') }}
          </v-btn>
          <v-btn color="primary" class="triage__button ml-3" :disabled="code === ''" @click="goTriage">
            {{ $t('triage.start') }}
          </v-btn>
        </div>
      </div>
      <div class="account-security__flex">
        <v-card-text class="px-0 py-0 text-body-2 account-security__hint">
          {{ $t('triage.explain') }}
        </v-card-text>
      </div>
    </article>
  </v-card>
</template>

<script>
import environment from '@/environment';
import { generateTriageCodeUrl, getHeader } from '../../config/config';
import { mapActions } from 'vuex';

export default {
  name: 'PatientCode',
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    triageCode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    triage: environment?.triage ? environment?.triage : false,
    code: '',
  }),
  mounted() {
    this.code = this.triageCode;
  },
  methods: {
    ...mapActions('layout', ['showAlert']),
    ...mapActions('overlay', ['handleOverlay']),

    async generateCode() {
      await this.handleOverlay(true);
      const data = {
        patientId: this.patientId,
      };
      this.$http
        .post(generateTriageCodeUrl, JSON.stringify(data), { headers: getHeader() })
        .then(res => {
          if (res.status === 202) {
            this.code = '';
            setTimeout(() => {
              this.code = res.data;
            }, 500);
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error.message) : this.$t('errors.try_again');
          this.showAlertMsg('error', '', textAlert);
        })
        .finally(async () => {
          await this.handleOverlay(false);
        });
    },

    goTriage() {
      if (this.code !== '') {
        this.$router.push(`/triage?code=${this.code}`); // TODO: llamar a nombre ruta, ejemplo { name: baseNameRoutes.login }
      }
    },

    getTriageCode() {
      if (this.code !== '') {
        return this.code;
      } else {
        return '- - - - - -';
      }
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.triage {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__buttons {
    display: contents;
    @include breakpoint(only-phone) {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
</style>
