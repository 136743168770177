<template>
  <div v-show="patientLoaded" id="new-patient">
    <v-toolbar flat>
      <h2 class="title">{{ $t('patient.edit') + ' ' + patient.nombre + ' ' + patient.apellidos }}</h2>
      <v-spacer />
      <v-btn v-tooltip="$t('common.close')" icon @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form ref="form" class="form">
      <v-layout wrap>
        <v-flex sm6 lg6>
          <v-menu
            v-if="ruleRequired"
            ref="birthdate"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            :return-value.sync="patient.fechaNacimiento"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                outlined
                filled
                dense
                class="mx-1"
                :label="$t('patient.birthdate') + ' *'"
                :rules="ruleRequired"
                readonly
                append-icon="mdi-calendar"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="date"
              first-day-of-week="1"
              :locale="locale"
              :min="datePickerMinDate"
              :max="new Date().toISOString().substr(0, 10)"
              no-title
              scrollable
              @input="$refs.birthdate.save(date)"
            />
          </v-menu>
        </v-flex>

        <v-flex sm6 lg6>
          <v-tel-field
            v-model="patient.telefono"
            outlined
            dense
            filled
            class="mx-1"
            v-bind="bindProps"
            @input="onInputPhone"
          />
        </v-flex>

        <v-flex sm6 lg6>
          <v-select
            v-model="documentType"
            outlined
            filled
            dense
            class="mx-1"
            :items="documentsTypes"
            :label="$t('patient.tipo_documento')"
            menu-props="auto"
            required
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            v-model="patient.dni"
            outlined
            filled
            dense
            class="mx-1"
            type="text"
            required
            :label="$t('patient.document_id')"
            @change="verifyDocument"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            v-model="patient.email"
            outlined
            dense
            filled
            class="mx-1"
            :label="$t('message.email') + ' *'"
            :rules="ruleEmail"
            type="mail"
            required
          />
        </v-flex>
        <v-flex xs12 class="text-right">
          <v-btn color="grey darken-1" class="mr-2" text @click="closeDialog">{{ $t('common.cancel') }}</v-btn>
          <v-btn depressed :loading="loading" color="primary" :disabled="disabled" @click="save">{{
            $t('common.save')
          }}</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { getPacienteUrl, getHeader, editPatientPersonalDataUrl } from '../../../config/config';
import { getLocaleFullFormat, isValidDocument } from '@/utils';
import { datePickerMinDate } from '@/services/dateHelper';

export default {
  name: 'EditPatient',
  props: {
    patient_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: false,
      disabled: false,
      patient: [],
      patientLoaded: false,
      rules: {},
      ruleRequired: [],
      ruleRequiredMin: [],
      ruleEmail: [],
      documentType: 0,
      documentsTypes: [
        { id: 1, name: 'DNI' },
        { id: 2, name: 'NIE' },
        { id: 3, name: this.$t('patient.pasaporte') },
        { id: 4, name: this.$t('common.otro') },
      ],
      date: null,
      phone: '',
      bindProps: {
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        mode: 'international',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: this.$t('patient.phone') + '*',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      datePickerMinDate: datePickerMinDate(),
    };
  },

  computed: {
    dateFormatted() {
      return this.formatDate(this.date);
    },

    locale() {
      return getLocaleFullFormat(this.$i18n.locale);
    },
  },

  watch: {
    documentType() {
      this.verifyDocument();
    },
  },

  mounted() {
    this.getPatient();
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    this.rules = {
      required: value => !!value || this.$t('common.obligatorio'),
      min: v => v.length >= 3 || this.$t('common.min_required', { tam: 3 }),
      email: v => regex.test(v) || this.$t('errors.email_invalid'),
    };
    this.ruleRequiredMin = [this.rules.required, this.rules.min];
    this.ruleEmail = [this.rules.required, this.rules.email];
    this.ruleRequired = [this.rules.required];
  },

  methods: {
    getPatient() {
      const param = {
        id: this.authUser.id,
        patient_id: this.patient_id,
        idioma: this.$i18n.locale,
      };
      this.$http
        .post(getPacienteUrl, param, { headers: getHeader() })
        .then(res => {
          this.patient = res.data.d_personales;
          this.date = this.patient.fechaNacimiento;
          this.documentType = this.patient.tipoDocumento;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.patientLoaded = true;
        });
    },

    save() {
      if (this.validateForm()) {
        this.disabled = true;
        this.$http
          .post(editPatientPersonalDataUrl, { id: this.authUser.id, patient: this.patient }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data) {
              if (response.data === '1') {
                this.$toast.success(this.$t('success.edit_patient'));
              } else if (response.data === '2') {
                this.$toast.error(this.$t('errors.form_invalid'));
              } else {
                this.$toast.error(this.$t('errors.edit_patient'));
              }
            } else {
              this.$toast.error(this.$t('errors.edit_patient'));
            }
            this.loading = false;
            this.disabled = false;
            this.$emit('reloadPatient');
            this.$emit('close-dialog', 'new');
          })
          .catch(e => {
            if (e.data && e.data.error) {
              this.$toast.error(this.$t(e.data.error));
            } else {
              this.$toast.error(this.$t('errors.edit_patient'));
            }

            this.loading = false;
            this.disabled = false;
          });
      }
    },

    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },

    clearString(str) {
      return str.replace(/ +/g, '').replace(/-/g, '');
    },

    verifyDocument() {
      this.patient.tipoDocumento = this.documentType;
      if (!isValidDocument(parseInt(this.patient.tipoDocumento), this.patient.dni)) {
        this.$toast.error(this.$t('patient.dni_invalid'));
        return false;
      }

      return true;
    },

    verifyEmail() {
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.patient.email.trim() !== '' && !regex.test(this.patient.email)) {
        this.$toast.error(this.$t('errors.email_invalid'));
        return false;
      }

      return true;
    },

    validateForm() {
      this.$refs.form.validate();
      let is_correct = true;

      if (this.patient.fechaNacimiento === '' || this.patient.fechaNacimiento === null) {
        this.$toast.error(this.$t('errors.date_required'));
        is_correct = false;
      } else if (!this.validatePhone() || !this.verifyDocument() || !this.verifyEmail()) {
        is_correct = false;
      }

      return is_correct;
    },

    validatePhone() {
      if (!this.patient.telefono) {
        this.$toast.error(this.$t('errors.format_phone'));
        return false;
      }
      const phone = this.clearString(this.patient.telefono);
      let regex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
      if (phone.length === 9) {
        regex = /^[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      }
      if (!regex.exec(phone)) {
        this.$toast.error(this.$t('errors.format_phone'));
        return false;
      }
      return true;
    },

    onInputPhone(formattedNumber, phone) {
      let prefixCountry = '+' + phone.country.dialCode;
      if (phone.valid && phone.country.iso2 !== phone.regionCode) {
        prefixCountry = phone.number.international.split(' ')[0];
      }
      this.patient.telefono = phone.number.significant;
      this.patient.codigoTelefono = prefixCountry;
    },

    closeDialog() {
      this.$emit('close-dialog', 'new');
      this.getPatient();
    },
  },
};
</script>

<style>
.form {
  padding: 10px 30px;
}

.vue-tel-input {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid black;
}

@media only screen and (min-width: 660px) {
  .vue-tel-input {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 660px) {
  .vue-tel-input {
    margin-bottom: 1rem;
  }
}
</style>
