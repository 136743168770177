<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="9" md="5" sm="5">
          <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
            <div class="subtitle-1 font-weight-bold cursor-pointer" @click="goToPatient(videoconsultation.paciente_id)">
              <template
                v-if="videoconsultation.paciente_id === -1 && videoconsultation.phone !== '' && !isReceptionist"
              >
                {{ $t('videoconsulta.singular') }}
                <span class="lowercase">
                  {{ $t('mensajeria.para') }}
                </span>
                {{ videoconsultation.phone }}
              </template>
              <template v-else>
                {{
                  videoconsultation.paciente_id === 0
                    ? $t('videoconsulta.multiconferencia')
                    : videoconsultation.fullName
                }}
                {{ isHrDepartment || isReceptionist ? ' [' + videoconsultation.nombreMarket + ']' : '' }}
              </template>
              <div v-if="showReasonConsultation" class="body-2">
                {{
                  videoconsultation.motivo_corto || `${$t('videoconsulta.singular')} ${videoconsultation.tipo.nombre}`
                }}
              </div>
            </div>
            <div class="caption text-no-wrap">
              <v-icon small light class="mr-1">mdi-clock-outline</v-icon>
              {{ videoconsultation.fecha }}
            </div>
          </div>
        </v-col>

        <v-col cols="7" md="4" sm="4" class="pl-4 text-center d-flex flex-column flex-md-row">
          <v-col class="hidden-xs-only">
            <SpecificationsVideo
              :item="videoconsultation"
              :mostrar-motivo-consulta="showReasonConsultation"
              :tiene-video="hasVideo"
              :label="false"
            />
            <tag-info v-if="videoconsultation.metadata.length > 0" :metadata="videoconsultation.metadata" />
          </v-col>
        </v-col>

        <v-col cols="12" md="3" sm="3" align="end" class="mt-2">
          <v-menu v-if="videoconsultation.archives !== null" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-tooltip="$t('videoconsultations.download_recording')" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(archive, i) in videoconsultation.archives"
                :key="i"
                @click="downloadArchive(archive)"
              >
                <v-list-item-title>
                  <v-icon class="mr-3">mdi-radiobox-marked</v-icon>
                  {{ archive.createdAt | moment($t('common.short_date_format')) }} ({{ archive.durationSeconds }} seg)
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            data-test="video-button-enter"
            v-if="
              videoconsultation.confirmado === 1 &&
                videoconsultation.tipo.presencial !== 2 &&
                hasVideo &&
                !isReceptionist
            "
            v-tooltip="$t('videoconsultations.go_to_video')"
            icon
            small
            class="mr-2"
            @click="enterVideoconsultation"
          >
            <v-icon>mdi-video</v-icon>
          </v-btn>
          <v-btn icon small elevation="0" @click="showVideoconsultationMenu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-if="!isMobileVersion"
        v-model="showMenu"
        :position-x="menuPositionX"
        :position-y="menuPositionY"
        offset-overflow
        absolute
      >
        <v-list>
          <v-list-item data-test="video-button-dropdown" v-for="(option, index) in menuOptions" :key="index" @click="option.onClick()">
            <v-list-item-title :class="option.color ? `${option.color}--text` : ''">
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-bottom-sheet v-if="isMobileVersion" v-model="showMenu">
        <v-list class="pa-0 rounded-t-xl">
          <v-list-item v-for="(option, index) in menuOptions" :key="index" class="py-3" @click="option.onClick()">
            <v-list-item-title
              class="text-button text-center"
              :class="option.color ? `${option.color}--text` : ''"
              @click="option.onClick()"
            >
              {{ option.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import SpecificationsVideo from '@/components/ehealth/videoconsulta/SpecificationsVideo.vue';
import TagInfo from '@/views/videoconsultation/components/TagInfo';
import {navigateToPatient} from "@/services/navigation";

export default {
  name: 'PendingVideoconsultationItem',
  components: {
    TagInfo,
    SpecificationsVideo,
  },
  props: {
    videoconsultation: {
      type: Object,
      required: true,
    },
    showReasonConsultation: {
      type: Boolean,
      required: true,
    },
    isHrDepartment: {
      type: Boolean,
      required: true,
    },
    isReceptionist: {
      type: Boolean,
      required: false,
    },
    hasVideo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showMenu: false,
      menuPositionX: 0,
      menuPositionY: 0,
    };
  },
  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    menuOptions() {
      const options = [];
      if (
        this.videoconsultation.confirmado === 1 &&
        this.videoconsultation.tipo.presencial !== 2 &&
        this.hasVideo &&
        !this.isReceptionist
      ) {
        options.push({ title: this.$t('videoconsultations.go_to_video'), onClick: this.enterVideoconsultation });
      }
      options.push({
        title: `${this.$t('common.edit')} ${this.$t('common.videoconference').toLowerCase()}`,
        onClick: this.editVideoconsultation,
      });
      options.push({ title: this.$t('common.delete'), onClick: this.deleteVideoconsultation });
      return options;
    },
  },
  methods: {
    goToPatient(id) {
      navigateToPatient(id, this.$router);
    },
    showVideoconsultationMenu(event) {
      if (!this.isMobileVersion) {
        this.menuPositionX = event.clientX;
        this.menuPositionY = event.clientY;
      }
      this.showMenu = true;
    },
    enterVideoconsultation() {
      this.$emit('enter-videoconsultation');
      this.showMenu = false;
    },
    editVideoconsultation() {
      this.$emit('edit-videoconsultation');
      this.showMenu = false;
    },
    deleteVideoconsultation() {
      this.showMenu = false;
      this.$emit('delete-videoconsultation');
    },
    downloadArchive(archive) {
      this.$emit('download-archive', archive);
    },
  },
};
</script>
