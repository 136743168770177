import { ref, computed } from 'vue';
import { getHeader, getPatientDetailUrl } from '@/config/config';

const PATIENT_DETAIL_ENDPOINT = getPatientDetailUrl;

export function usePatient() {
  const patient = ref(null);
  const loading = ref(true);
  const isSearchPatientModule = ref(false);

  const toggleLoading = force => {
    if (typeof force !== 'undefined') {
      loading.value = force;
    } else {
      loading.value = !loading.value;
    }
  };

  const fetchPatient = async id => {
    toggleLoading(true);

    const raw = await fetch(PATIENT_DETAIL_ENDPOINT + id, {
      headers: getHeader(),
    });
    const body = await raw.json();
    const patientRaw = body.data;

    patient.value = {
      id: id,
      name: patientRaw.fullname,
      uuid: patientRaw.uuid,
      birthdate: patientRaw.birthday,
      gender: patientRaw.gender,
      dni: patientRaw.dni,
      phone: patientRaw.phone,
      email: patientRaw.email,
      phoneCode: patientRaw.phoneCode,
    };

    toggleLoading(false);
  };

  const changePatient = () => {
    return false;
  };

  const genderIcon = computed(() => {
    if (!patient.value) {
      return null;
    }
    return patient.value.gender ? 'gender-male' : 'gender-female';
  });

  const patientAge = computed(() => {
    if (!patient.value) {
      return null;
    }
    const birthday = +new Date(patient.value.birthdate);
    return ~~((Date.now() - birthday) / 31557600000);
  });

  const patientSelected = id => {
    isSearchPatientModule.value = false;
    fetchPatient(id);
  };

  const openSearchPatient = () => {
    isSearchPatientModule.value = true;
  };

  return {
    patient,
    loading,
    isSearchPatientModule,
    genderIcon,
    patientAge,
    fetchPatient,
    changePatient,
    toggleLoading,
    patientSelected,
    openSearchPatient,
  };
}
