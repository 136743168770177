import { ref } from 'vue';

import { generateClinicalReport, REPORT_TYPES } from '@/services/clinical-report';
import { computed, watch } from 'vue';
import { formatDate, substractDaysFromDate } from '@/utils/dateer';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';

export function useClinicalReport(patientId) {
  const reportTypes = Array.from(REPORT_TYPES);
  const reportType = ref(REPORT_TYPES[0].type);
  const startDate = ref(null);
  const endDate = ref(null);
  const videoconsultationId = ref(null);
  const submittedWithErrors = ref(false);
  const submittingForm = ref(false);
  const isNetworkError = ref(false);

  const showStartDate = computed(() => {
    return reportType.value === REPORT_TYPES[0].type || reportType.value === REPORT_TYPES[1].type;
  });

  const showEndDate = computed(() => {
    return reportType.value === REPORT_TYPES[0].type;
  });

  const validationRules = computed(() => ({
    startDate: {
      required: helpers.withMessage(
        'Falta seleccionar la fecha de inicio',
        requiredIf(function() {
          return showStartDate.value;
        })
      ),
      $autoDirty: true,
    },
    endDate: {
      required: helpers.withMessage(
        'Falta seleccionar la fecha de fin',
        requiredIf(function() {
          return showEndDate.value;
        })
      ),
      $autoDirty: true,
    },
  }));

  const v$ = useVuelidate(validationRules, { startDate, endDate }, { $scope: false });

  const validateClinicalReportForm = () => {
    submittedWithErrors.value = false;
    v$.value.$touch();
    submittedWithErrors.value = v$.value.$invalid;
  };

  const resetForm = () => {
    reportType.value = REPORT_TYPES[0].type;
    startDate.value = null;
    endDate.value = null;
    videoconsultationId.value = null;
  };

  const openPDFInNewTab = pdf => {
    const windowUrl = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = windowUrl;
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const submitClinicalReportForm = async () => {
    submittingForm.value = true;

    try {
      const response = await generateClinicalReport(
        patientId,
        startDate.value ? new Date(startDate.value).toISOString() : null,
        endDate.value ? new Date(endDate.value).toISOString() : null,
        videoconsultationId.value
      );
      openPDFInNewTab(response);
      resetForm();
    } catch (e) {
      throw new Error(e);
    } finally {
      submittingForm.value = false;
    }
  };

  const startDateFormatted = computed(() => {
    if (!startDate.value) {
      return null;
    }

    return formatDate(startDate.value);
  });

  const startDateValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.startDate.$errors.map(error => error.$message) : null;
  });

  const endDateFormatted = computed(() => {
    if (!endDate.value) {
      return null;
    }

    return formatDate(endDate.value);
  });

  const endDateValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.endDate.$errors.map(error => error.$message) : null;
  });

  const minEndDate = computed(() => {
    if (!startDate.value) {
      return null;
    }

    return substractDaysFromDate(new Date(startDate.value), 1).toISOString();
  });

  watch(
    () => reportType.value,
    () => {
      startDate.value = null;
      endDate.value = null;
    }
  );

  return {
    reportTypes,
    reportType,
    startDate,
    endDate,
    videoconsultationId,
    isNetworkError,
    startDateFormatted,
    endDateFormatted,
    minEndDate,
    v$,
    startDateValidationError,
    endDateValidationError,
    submittingForm,
    submittedWithErrors,
    validateClinicalReportForm,
    submitClinicalReportForm,
    showStartDate,
    showEndDate,
    resetForm,
  };
}
