import { ref, computed, watch } from 'vue';
import {
  DEFAULT_HISTORIC_PAGE_SIZE,
  fetchHistoricalPersonalDoctors,
} from '@/services/personal-doctor';

export function usePersonalDoctors(patientId, companyId) {
  const historicPersonalDoctors = ref([]);
  const page = ref(1);
  const total = ref(0);

  const isLoadingHistoricPersonalDoctors = ref(false);
  const isNetworkError = ref(false);

  const mapPersonalDoctorsListToVm = rawPersonalDoctors => {
    return rawPersonalDoctors.map(raw => {
      return {
        startedAt: raw.startedAt,
        endedAt: raw.endedAt,
        ...raw.personalDoctor,
      };
    });
  };

  const paginationLength = computed(() => {
    if (total.value < DEFAULT_HISTORIC_PAGE_SIZE) {
      return 1;
    }
    return Math.ceil(total.value / DEFAULT_HISTORIC_PAGE_SIZE);
  });

  const paginatedHistoric = computed(() => {
    return historicPersonalDoctors.value.slice(DEFAULT_HISTORIC_PAGE_SIZE * page, DEFAULT_HISTORIC_PAGE_SIZE);
  });

  const showPagination = computed(() => {
    return paginationLength.value > 1;
  });

  const retrieveHistoricalPersonalDoctors = async () => {
    try {
      isNetworkError.value = false;
      isLoadingHistoricPersonalDoctors.value = true;
      const response = await fetchHistoricalPersonalDoctors(patientId, companyId, {
        page: page.value,
        size: DEFAULT_HISTORIC_PAGE_SIZE,
      });
      total.value = response.meta.total;
      historicPersonalDoctors.value = mapPersonalDoctorsListToVm(response.data);
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingHistoricPersonalDoctors.value = false;
    }
  };

  watch(
    () => page.value,
    async () => {
      await retrieveHistoricalPersonalDoctors();
    }
  );

  const showList = computed(() => {
    return !isLoadingHistoricPersonalDoctors.value && !isNetworkError.value && historicPersonalDoctors.value.length;
  });

  return {
    paginationLength,
    page,
    paginatedHistoric,
    showPagination,
    isNetworkError,
    isLoadingHistoricPersonalDoctors,
    showList,
    retrieveHistoricalPersonalDoctors,
  };
}
