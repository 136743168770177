<template>
  <div class="d-chip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DChip',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f7f7f7;

.d-chip {
  padding: rem(8) rem(20);
  border-radius: 5px;
  background-color: $grey;
  font-size: rem(16);
  font-weight: 400;
  color: $black;
}
</style>
