<template>
  <div class="patientbox">
    <h3 class="title">
      <span>{{ $t('common.paciente') }}</span>
      <span v-if="showChangePatient" class="butChange pointer" @click="changePatient">
        {{ $t('recipe.change_patient') }}
      </span>
    </h3>

    <div class="box">
      <v-layout wrap>
        <v-flex xs3 md3 lg2 d-none d-md-block align-self-center>
          <span class="center-avatar">
            <v-avatar size="60px" class="avatar">
              <img :alt="data.name" src="/static/avatar/avatar-generic.png" />
            </v-avatar>
          </span>
        </v-flex>

        <v-flex xs12 md9 lg10 class="pat-info">
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <span class="name">{{ data.name }}</span>
                  <span class="float-right hidden-sm-and-up">
                    <v-btn v-if="data.name" x-small color="primary" fab small dark @click="editPatientData">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </span>
                </v-flex>
                <v-flex v-if="data.name" sm6 class="hidden-xs-only">
                  <span class="float-right">
                    <v-btn small color="primary" dark @click="editPatientData">
                      <v-icon left>mdi-pencil</v-icon> {{ $t('common.edit') }}
                    </v-btn>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-layout wrap>
                <v-flex v-if="data.birthdate" xs12 sm6>
                  <span class="age same-line">
                    <icon v-if="data.gender_icon" :type="data.gender_icon" :width="20" class="gender-icon" />
                    {{ data.age }} {{ $t('recipe.years') }} ({{ data.birthdate }})</span
                  >
                </v-flex>

                <v-flex v-if="data.phone" xs12 sm6>
                  <span class="same-line">
                    <v-icon size="18" class="mr-1">mdi-phone</v-icon>
                    <span>{{ data.phone }}</span>
                  </span>
                </v-flex>

                <v-flex v-if="data.email" xs12 sm6>
                  <span class="same-line mt-1">
                    <v-icon size="18" class="mr-1">mdi-email</v-icon>
                    <span>{{ data.email }}</span>
                  </span>
                </v-flex>

                <v-flex v-if="data.dni" xs12 sm6>
                  <span class="same-line mt-1">
                    <icon type="document" :width="18" class="gender-icon" />
                    <span>&nbsp;{{ data.dni }}</span>
                  </span>
                </v-flex>

                <v-flex v-if="patientMustCompleteEmail" xs12 class="mt-6">
                  <v-alert type="error">
                    {{ $t('errors.patient_has_no_email_message') }}
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex v-if="data.allergies && data.allergies.length > 0" sm6 xs9 class="pat-info">
          <v-layout wrap>
            <v-flex xs12 class="allerg-title">
              <span>{{ $t('recipe.allergies') }}</span>
            </v-flex>

            <v-flex xs12>
              <span v-for="(aller, index) in data.allergies" :key="index" class="allergy">
                <tag :content="aller.allergy.name" :color="aller.level" />
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { getPatientDetailUrl, getHeader } from '@/config/config';

export default {
  props: {
    patient: {
      type: Number,
      required: true,
      default: null,
    },

    showChangePatient: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      error: null,
      urlGetPatientData: getPatientDetailUrl + this.patient,
      data: {
        name: '',
        gender_icon: '',
        age: '',
        birthdate: '',
        allergies: [],
        phone: '',
        email: '',
        dni: '',
      },
      isCompanyEmittingPrescriptionsBySMS: JSON.parse(window.localStorage.getItem('smsNotificationsEnabled')),
      patientMustCompleteEmail: false,
    };
  },

  computed: {
    patientHasEmail() {
      return !!this.data.email;
    },
  },

  created() {
    this.getPatientData();
  },

  methods: {
    getPatientData() {
      try {
        this.$http.get(this.urlGetPatientData, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data) {
            const patient = response.data.data;
            this.data.name = patient.fullname;
            this.data.birthdate = this.formatDate(patient.birthday);
            this.data.age = this.getAge(patient.birthday);
            this.data.allergies = patient.allergies;
            this.data.phone = patient.phone;
            this.data.email = patient.email;
            this.data.dni = patient.dni;
            this.setGenderIcon(patient.gender);

            this.verifyPatientMustCompleteEmail();
          }
        });
      } catch (e) {
        this.error = e.message;
      }
    },

    formatDate(inputDate) {
      const date = new Date(inputDate);
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      return day + '/' + month + '/' + date.getFullYear();
    },

    getAge(inputDate) {
      const birthday = +new Date(inputDate);
      return ~~((Date.now() - birthday) / 31557600000);
    },

    setGenderIcon(gender) {
      if (gender === 1) {
        this.data.gender_icon = 'gender-male';
      } else if (gender === 0) {
        this.data.gender_icon = 'gender-female';
      } else {
        this.data.gender_icon = null;
      }
    },

    verifyPatientMustCompleteEmail() {
      const mustCompleteEmail = !this.patientHasEmail && !this.isCompanyEmittingPrescriptionsBySMS;
      this.patientMustCompleteEmail = mustCompleteEmail;
      this.$emit('patient-must-complete-email', mustCompleteEmail);
    },

    changePatient() {
      this.$emit('changePatient');
    },

    editPatientData() {
      this.$emit('editPatient');
    },
  },
};
</script>

<style scoped>
.patientbox {
  background-color: #ffffff;
}

.box {
  padding: 0.5rem 1rem;
  border-radius: 2px;
  border: 1px solid #cccccc;
}

.avatar img {
  width: auto;
}

.pat-info {
  padding: 0.5rem 1rem;
}

.name {
  font-weight: bold;
  font-size: larger;
}

.pat-data {
  height: 2em;
  display: flex;
}

.age {
  margin-top: 5px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.75rem 0.25rem;
  color: #3e424a;
  font-weight: bold;
  font-size: 1.5rem;
}

.butChange {
  color: #1976d2;
  font-size: 0.8em;
}

.pointer {
  cursor: pointer;
}

.allerg-title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 1.1rem;
}

.allergy {
  margin-right: 8px;
}

.same-line {
  overflow: hidden;
  white-space: nowrap;
}

.gender-icon {
  vertical-align: sub;
}

.center-avatar {
  display: table;
  margin: 0 auto;
}
</style>
