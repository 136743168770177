<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card flat max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-3">
          <h1 class="headline black--text">
            {{ $t('common.videoconferences') }}
          </h1>
          <v-spacer />
          <v-btn
            v-if="tipos.length > 0 && tieneTele"
            rounded
            depressed
            color="primary"
            dark
            @click="abrirNuevaVideo('tele')"
          >
            {{ $t('videoconsulta.nueva_tele') }}
          </v-btn>
          <v-btn data-test="video-button-add-new" v-if="tipos.length > 0 && videoconsultationEnabled" depressed color="primary" dark @click="abrirNuevaVideo('video')">
            <template>{{ $t('videoconsulta.add_new') }}</template>
          </v-btn>
        </v-toolbar>

        <v-alert
          v-if="tipos.length === 0 && !loading"
          outlined
          color="warning"
          class="mb-5"
          con="priority_high"
          :value="true"
          @click="$router.push(ruta_servicios)"
        >
          {{ $t('videoconsulta.sin_tiposvideo') }}
        </v-alert>

        <v-card flat>
          <v-tabs v-model="tab" background-color="grey lighten-3">
            <v-tab data-test="video-tab-pending">{{ $t('videoconsulta.pendientes') }}</v-tab>
            <v-tab data-test="video-tab-finish">{{ $t('videoconsulta.terminadas') }}</v-tab>
          </v-tabs>
          <template v-if="tab === 0">
             <div v-if="loading" class="text-center py-5">
              <v-progress-circular width="2" color="primary" indeterminate />
            </div>
            <div v-if="!loading">
              <div
                v-if="videoconsultas.porconfirmar.length === 0 && videoconsultas.pendientes.length === 0"
                class="pa-5 text-center grey--text"
              >
                {{
                  $t('videoconsulta.sin_video_pendientes', {
                    name: $t('videoconsulta.singular'),
                  })
                }}
              </div>

              <!-- videoconsultas.porconfirmar && pendientes -->
              <div v-if="videoconsultas.pendientes || videoconsultas.porconfirmar">
                <v-row dense>
                  <template v-if="videoconsultas.porconfirmar">
                    <v-col
                      v-for="(videoconsultation, index) in videoconsultas.porconfirmar"
                      :key="`unconfirmed-${index}`"
                      cols="12"
                    >
                      <UnconfirmedVideoconsultationItem
                        class="videoconsultation-item"
                        :videoconsultation="videoconsultation"
                        :show-reason-consultation="true"
                        :is-receptionist="is_receptionist"
                        :is-hr-department="false"
                        :has-video="tieneVideo"
                        @confirm-videoconsultation="mostrarConfirmDialog(videoconsultation, 1, index)"
                        @edit-videoconsultation="verVideoconsulta(videoconsultation, index, 'porconfirmar')"
                        @delete-videoconsultation="
                          showDeleteVideoconsultationDialog(videoconsultation, index, 'porconfirmar')
                        "
                      />
                      <v-divider />
                    </v-col>
                  </template>

                  <template v-if="videoconsultas.pendientes">
                    <v-col
                      v-for="(videoconsultation, index) in videoconsultas.pendientes"
                      :key="`pending-${index}`"
                      cols="12"
                    >
                      <PendingVideoconsultationItem
                        class="videoconsultation-item"
                        :videoconsultation="videoconsultation"
                        :show-reason-consultation="true"
                        :is-receptionist="is_receptionist"
                        :is-hr-department="false"
                        :has-video="tieneVideo"
                        @enter-videoconsultation="abrirVideoConsulta(videoconsultation.id, videoconsultation.rand)"
                        @edit-videoconsultation="verVideoconsulta(videoconsultation, index, 'pendientes')"
                        @delete-videoconsultation="
                          showDeleteVideoconsultationDialog(videoconsultation, index, 'pendientes')
                        "
                        @download-archive="archive => downloadArchive(archive, videoconsultation.id)"
                      />
                      <v-divider />
                    </v-col>
                  </template>
                </v-row>
              </div>
            </div>
          </template>
          <template v-if="tab === 1">
            <div v-if="videoconsultas.terminadas.length === 0" class="pa-5 text-center grey--text">
              {{
                $t('videoconsulta.sin_video_terminadas', {
                  name: $t('videoconsulta.singular'),
                })
              }}
            </div>

            <!-- videoconsultas.terminadas -->
            <div v-if="videoconsultas.terminadas">
              <v-row dense>
                <v-col
                  v-for="(videoconsultation, index) in videoconsultas.terminadas"
                  :key="`finished-${index}`"
                  cols="12"
                >
                  <FinishedVideoconsultationItem
                    class="videoconsultation-item"
                    :videoconsultation="videoconsultation"
                    :show-reason-consultation="true"
                    :is-hr-department="false"
                    :is-receptionist="is_receptionist"
                    :on-download-archive="downloadArchive"
                    @show-videoconsultation-details="verVideoconsulta(videoconsultation, index, 'terminadas')"
                    @delete-videoconsultation="
                      showDeleteVideoconsultationDialog(videoconsultation, index, 'terminadas')
                    "
                  />
                  <v-divider />
                </v-col>
              </v-row>
            </div>
          </template>
        </v-card>

        <div v-if="!showDontHaveService" id="videoconsultas">
          <!-- Modal Nuevo Servicio Videoconsulta -->
          <v-dialog v-model="dialog_nuevavideo" :fullscreen="tamVideo" max-width="600" persistent scrollable>
            <nuevavideo
              :pacientes="pacientes"
              :tipos="tipos"
              :payment-settings="paymentSettings"
              :especialidades="especialidades"
              :specialties="specialties"
              :pack="{}"
              :tipo="tipoNuevaVideo"
              :profesionales="profesionales"
              @clickedVolver="dialog_nuevavideo = false"
              @clickedNuevo="onNuevoChild"
              @reloadvideos="reloadVideos"
            />
          </v-dialog>
          <!-- Fin Modal Nuevo Servicio Videoconsulta -->
          <!-- Modal Ver Videoconsulta -->
          <v-dialog v-model="dialog_video" :fullscreen="tamVideo" max-width="600" scrollable>
            <videoconsulta
              :tiene-video="tieneVideo"
              :videoconsulta="selected.video"
              :tipos="tipos"
              :tipo="tipoNuevaVideo"
              @clickedVolver="volverVideoconsulta"
              @clickedConfirmado="confirmarVideoChild"
              @clickedCancelado="canceladoVideoconsulta"
              @clickedModificado="onModificadoChild"
            />
          </v-dialog>
          <!-- Fin Modal Ver -->
          <!-- Modal Confirmar/Eliminar -->
          <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="500px">
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>{{ dialog_confirm.titulo }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="dialog_confirm.dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <p>{{ dialog_confirm.texto }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="disabledButton"
                  color="grey darken-2"
                  text
                  @click.native="dialog_confirm.dialog = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  :disabled="disabledButton"
                  color="primary"
                  depressed
                  @click.native="
                    dialog_confirm.dialog = false;
                    confirmadaAccion();
                  "
                >
                  {{ $t('common.confirm') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="alerta.color"
            top="top"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
          >
            <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon>
            &nbsp;{{ alerta.texto }}
          </v-snackbar>
        </div>
        <div v-else>
          <no-service-granted view="videoconsulta" />
        </div>
      </v-card>
    </v-row>

    <v-bottom-sheet v-model="showConfirmDelete" inset>
      <v-card tile>
        <v-progress-linear color="red" height="3" :value="100" class="my-0" />
        <v-card-text>{{ $t('videoconsulta.confirm_delete') }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="showConfirmDelete = false"
            ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
          >
          <v-btn color="red darken-1" text @click.native="confirmDeleteVideoconsultation"
            ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Nuevavideo from '@/components/ehealth/videoconsulta/Nueva.vue';
import Videoconsulta from '@/components/ehealth/videoconsulta/Videoconsulta.vue';
import { haveEprescriptionActive } from '@/utils';
import NoServiceGranted from '../components/common/NoServiceGranted.vue';
import {
  nameEnv,
  appVideoUrl,
  allVideoconsultasUrl,
  listVideoconsultationsUrl,
  downloadVideoconsultationUrl,
  cancelarVideoconsultaUrl,
  confirmarVideoconsultaUrl,
  getHeader,
} from '../config/config';
import UnconfirmedVideoconsultationItem from './videoconsultation/components/UnconfirmedVideoconsultationItem';
import PendingVideoconsultationItem from './videoconsultation/components/PendingVideoconsultationItem';
import FinishedVideoconsultationItem from './videoconsultation/components/FinishedVideoconsultationItem';

export default {
  components: {
    NoServiceGranted,
    Nuevavideo,
    Videoconsulta,
    UnconfirmedVideoconsultationItem,
    PendingVideoconsultationItem,
    FinishedVideoconsultationItem,
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      tab: 0,
      dialog_nuevavideo: false,
      dialog_video: false,
      loading: true,
      isLoadingDownload: false,
      videoconsultas: {
        pendientes: [],
        porconfirmar: [],
        terminadas: [],
      },
      tipos: [],
      typesAll: [],
      tiposResponse: [],
      tiposTele: [],
      tiposVideo: [],
      profesionales: [],
      no_pendientes: false,
      no_terminadas: false,
      snackbar: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      disabledButton: false,
      pacientes: [],
      specialties: [],
      selected: {
        estado: '',
        video: {},
        indice: 0,
        tipo: '',
      },
      dialog_confirm: {
        dialog: false,
        tipo: 0,
        titulo: '',
        texto: '',
        icono: '',
        color: '',
      },
      tamVideo: window.innerWidth <= 800,
      ruta_servicios: 'ajustes/videoconsultas',
      especialidades: [],
      tipoNuevaVideo: 'video',
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      tieneTele: false,
      tieneVideo: true,
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      intervalo: false,
      showDontHaveService: false,
      showConfirmDelete: false,
      videoconsultationToDelete: null,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),
    videoconsultationEnabled() {
      return this.authUser?.servicios_activos?.videoconsulta;
    }
  },

  mounted() {
    const userWithEprescription = haveEprescriptionActive();
    if (userWithEprescription) {
      this.showDontHaveService = true;
    } else {
      this.dataVideoconsultation();

      this.intervalo = setInterval(() => {
        this.listVideoconsultations();
      }, 20000);
    }
  },

  created() {
    const mensaje = JSON.parse(window.localStorage.getItem('mensaje_ehealth'));
    if (mensaje && mensaje.texto !== '') {
      this.alerta.texto = mensaje.texto;
      this.alerta.color = mensaje.color;
      localStorage.removeItem('mensaje_ehealth');
      this.snackbar = true;
    }
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    showDeleteVideoconsultationDialog(videoconsultation, index, videoconsultationType) {
      this.selected.video = videoconsultation;
      this.selected.indice = index;
      this.selected.tipo = videoconsultationType;
      this.showConfirmDelete = true;
    },
    confirmDeleteVideoconsultation() {
      this.showConfirmDelete = false;
      this.$http
        .post(
          cancelarVideoconsultaUrl,
          { user_id: this.authUser.id, videoconsulta_id: this.selected.video.id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.canceladoVideoconsulta();
          } else {
            this.showAlert({
              color: 'error',
              message: this.$t('errors.try_again'),
            });
          }
        })
        .catch(() => {
          this.showAlert({
            color: 'error',
            message: this.$t('errors.try_again'),
          });
        });
    },

    listVideoconsultations() {
      console.log("AQUI OTY")
      this.$http
        .post(listVideoconsultationsUrl, { id: this.authUser.id }, { headers: getHeader() })
        .then(response => {
          this.videoconsultas = response.data;
        })
        .catch(e => {
          this.$toast(this.$t('errors.try_again'), {
            x: 'center',
            y: 'top',
            color: 'primary',
          });
          console.error(e);
        });
    },

    dataVideoconsultation() {
      this.$http.post(allVideoconsultasUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data) {
          this.videoconsultas = response.data.videoconsultas;
          this.typesAll = this.tipos = response.data.tipos;
          this.pacientes = response.data.pacientes;
          this.specialties = response.data.specialties;
          this.profesionales = response.data.profesionales;
          if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
            this.dialog_nuevavideo = true;

            const intercom = document.getElementsByClassName('intercom-launcher')[0];
            if (typeof intercom !== 'undefined') {
              intercom.style.display = 'none';
            }
            // document.getElementsByTagName('body')[0].className = 'noscroll';
          } else if (typeof this.$route.params.id !== 'undefined') {
            const idRoute = parseInt(this.$route.params.id);
            const tiposVideosEstado = ['pendientes', 'porconfirmar', 'terminadas'];
            for (let i = 0; i < tiposVideosEstado.length; i++) {
              this.videoconsultas[tiposVideosEstado[i]].forEach((v, index) => {
                if (v.id === idRoute) {
                  this.verVideoconsulta(v, index, tiposVideosEstado[i]);
                }
              });
            }
          }
        }
        this.loading = false;
      });
    },

    estadoIntercom(estado) {
      const intercom = document.getElementsByClassName('intercom-launcher')[0];
      if (typeof intercom !== 'undefined' && intercom !== null) {
        intercom.style.display = estado;
      }
    },

    volverVideoconsulta() {
      this.dialog_video = false;
      this.estadoIntercom('block');
    },

    abrirNuevaVideo(tipo) {
      this.dialog_nuevavideo = true;
      this.tipoNuevaVideo = tipo;
      this.estadoIntercom('none');
      this.tipos = this.typesAll;
    },

    canceladoVideoconsulta() {
      this.dialog_video = false;
      if (this.selected.tipo === 'porconfirmar') {
        this.videoconsultas.porconfirmar.splice(this.selected.indice, 1);
      } else if (this.selected.tipo === 'terminadas') {
        this.videoconsultas.terminadas.splice(this.selected.indice, 1);
      } else {
        this.videoconsultas.pendientes.splice(this.selected.indice, 1);
      }
      this.alerta.texto = this.$t('videoconsulta.cancelado_correctamente');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-delete';
      this.snackbar = true;
    },

    onNuevoChild(respuesta) {
      if (respuesta.estado === '1') {
        this.videoconsultas['pendientes'].push(respuesta.videoconsulta);
      }
      this.alerta.texto =
        respuesta.estado === '1'
          ? this.$t('videoconsulta.enviado_correctamente')
          : this.$t('videoconsulta.enviado_recordatorio');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-video-wireless-outline';
      this.snackbar = true;
      this.dialog_nuevavideo = false;
    },

    onModificadoChild(respuesta) {
      this.alerta.texto = this.$t('success.save_common');
      this.alerta.color = 'success';
      this.alerta.icono = 'mdi-video-wireless-outline';
      this.snackbar = true;
      this.dialog_video = false;
      if (this.selected.tipo === 'porconfirmar') {
        this.videoconsultas.porconfirmar[this.selected.indice] = respuesta.videoconsulta;
      } else {
        this.videoconsultas.pendientes[this.selected.indice] = respuesta.videoconsulta;
      }
    },

    abrirVideoConsulta(id, code) {
      const lang = this.$i18n.locale !== 'es' ? '?lang=' + this.$i18n.locale : '';
      const lang_index = this.$i18n.locale !== 'es' ? '&lang=' + this.$i18n.locale : '';
      let ruta = '';
      if (appVideoUrl === 'https://docline.io' || nameEnv === 'ev2x') {
        ruta = appVideoUrl + '/?code=' + code + lang_index;
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Fakeeh') {
        ruta = 'https://app_fakeeh.docline.io/#/codigo/' + code + '/ar';
      } else if (this.authUser !== null && this.authUser.nombreEmpresa === 'Your Brand') {
        ruta = 'https://appyourbrand.docline.io/#/codigo/' + code + lang;
      } else {
        ruta = appVideoUrl + '/c/' + code + lang;
      }
      window.open(ruta, '_blank');
    },

    verVideoconsulta(video, index, tipo) {
      let volcadotipo = false;
      this.tipos.forEach(function(t) {
        if (t.id === video.tipo && !volcadotipo) {
          video.tipo = t;
          volcadotipo = true;
        }
      });
      this.estadoIntercom('none');
      this.selected.video = video;
      this.selected.indice = index;
      this.selected.tipo = tipo;
      this.dialog_video = true;
    },

    downloadArchive(archive, videoId) {
      if (!this.isLoadingDownload) {
        this.isLoadingDownload = true;
        const params = {
          id: this.authUser.id,
          videoId: videoId,
          archiveId: archive.id,
        };
        this.$http
          .post(downloadVideoconsultationUrl, params, { headers: getHeader() })
          .then(response => {
            window.open(response.data.url);
          })
          .catch(err => {
            this.$log.error(err);
            let msg = 'Ocurrió un error!';
            console.log(err);
            if (err && err.body && err.body.error) {
              msg = this.$t(err.body.error);
            }
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
          })
          .finally(() => {
            this.isLoadingDownload = false;
          });
      }
    },

    mostrarConfirmDialog(video, tipo, indice) {
      this.dialog_confirm.titulo =
        tipo === 1
          ? this.$t('common.confirm') + ' ' + this.$t('videoconsulta.singular')
          : this.$t('common.cancel') + ' ' + this.$t('videoconsulta.singular');
      this.dialog_confirm.texto =
        tipo === 1 ? this.$t('videoconsulta.confirm_video') : this.$t('videoconsulta.confirm_delete');
      this.dialog_confirm.icono = tipo === 1 ? 'mdi-check' : 'delete';
      this.dialog_confirm.color = tipo === 1 ? 'green' : 'red';
      this.selected.video = video;
      this.selected.indice = indice;
      this.dialog_confirm.tipo = tipo;
      this.dialog_confirm.dialog = true;
    },

    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function(a, b) {
        const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },

    ordenarVideoconsultas() {
      this.videoconsultas.pendientes.sort(this.dynamicSort('date'));
      this.videoconsultas.terminadas.sort(this.dynamicSort('date'));
    },

    confirmarVideoChild(video) {
      this.dialog_video = false;
      this.dialog_confirm.tipo = 1;
      this.selected.video = video;
      this.confirmadaAccion();
    },

    confirmadaAccion() {
      this.disabledButton = true;
      this.loading = true;
      const url = this.dialog_confirm.tipo === 1 ? confirmarVideoconsultaUrl : cancelarVideoconsultaUrl;
      this.$http
        .post(
          url,
          {
            user_id: this.authUser.id,
            videoconsulta_id: this.selected.video.id,
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            if (this.dialog_confirm.tipo === 1) {
              this.alerta.texto = this.$t('videoconsulta.confirmado_correctamente');
              this.alerta.icono = 'mdi-check';
            } else {
              this.alerta.texto = this.$t('videoconsulta.cancelado_correctamente');
              this.alerta.icono = 'mdi-delete';
            }
            this.alerta.color = 'success';
            this.$http.post(allVideoconsultasUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
              if (response.status === 200 && response.data) {
                this.videoconsultas = response.data.videoconsultas;
                this.tipos = response.data.tipos;
                this.pacientes = response.data.pacientes;
              }
              this.loading = false;
            });
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.loading = false;
          this.snackbar = true;
          this.disabledButton = false;
        });
    },

    reloadVideos() {
      this.dialog_nuevavideo = false;
      this.listVideoconsultations();
    },
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalo);
    next();
  },
};
</script>
<style scoped lang="css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.01s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.alerta_notipos {
  padding: 7px;
  width: 97%;
  cursor: pointer;
}
.fecha_video {
  text-align: right;
}
.item_video_div {
  margin: 10px;
}
.item_porconfirmar {
  background-color: #fffec4;
}
.item_row_porconfirmar {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 8px;
}
.motivo_video {
  margin-top: -20px;
  color: #5d5d5d;
}
.botones_video {
  margin-top: -20px;
}
.btn_video {
  margin: 0px;
}

.lowercase {
  text-transform: lowercase;
}

.archive_item {
  font-size: 12px;
  text-decoration: none;
  color: #666;
}
</style>
