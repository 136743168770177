<template>
  <span class="mr-1">
    <template v-if="item.es_pack === true">
      <span v-if="mostrarMotivoConsulta">
        {{ item.motivo_corto !== '' ? item.motivo_corto : $t('videoconsulta.singular') + ' ' + item.tipo.nombre }}
      </span>
      <v-chip :small="small" :label="label" color="primary lighten-3" outlined>
        <v-icon size="16" left>mdi-image-filter-none</v-icon>PACK
      </v-chip>
    </template>
    <template v-else>
      <v-chip
        :small="small"
        :label="label"
        :color="item.confirmado === 1 ? 'primary lighten-3' : 'amber darken-3'"
        outlined
      >
        {{ getTextItem(item) }}
      </v-chip>
    </template>
  </span>
</template>

<script>
export default {
  name: 'SpecificationsVideo',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mostrarMotivoConsulta: {
      type: Boolean,
      default: false,
    },
    tieneVideo: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
    label: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  methods: {
    getTextItem(item) {
      if (item.confirmado === 1) {
        if (item.nombre !== '' && item.nombre !== null) {
          return item.nombre;
        } else {
          return this.$te(item.moreInfo) ? this.$t(item.moreInfo) : item.moreInfo;
        }
      } else {
        return this.$t('videoconsulta.esperando_pago');
      }
    },
  },
};
</script>
