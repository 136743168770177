<template>
  <div class="pt-2">
    {{ $t('errors.invalid_collegiate_number', { email: supportEmail }) }}
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'CollegiateMsg',

  data: () => ({
    butColor: 'info',
  }),
  computed: {
    ...mapState({
      supportEmail: state => state.app.supportEmail,
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
