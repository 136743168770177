<template>
  <div id="treatmentPatient">
    <v-container fluid>
      <v-layout v-for="(treatment, i) in treatments" :key="treatment.id" column class="treatment">
        <v-layout column class="container">
          <dropdown-box
            :title="$t('videoconsulta.tratamiento') + ' ' + (i + 1)"
            :title-delete="$t('common.delete') + ' ' + $t('videoconsulta.tratamiento')"
            :title-edit="$t('common.edit') + ' ' + $t('videoconsulta.tratamiento')"
            :confidential="treatment.confidential"
            @openRecipe="expandRecipe(i)"
            @delete="openDialogDeleteTreatment(treatment.id)"
            @edit="openDialogEditTreatment(treatment.id)"
          />
        </v-layout>
        <v-flex xs12 class="treat-section">
          <v-layout xs12 wrap>
            <v-flex xs12 sm6>
              <div>
                <field-display :title="$t('recipe.diagnostic')" :text="treatment.diagnostic" />
              </div>
            </v-flex>
            <v-flex xs12 sm6>
              <v-layout column>
                <field-display class="" :title="$t('recipe.comments')" :text="treatment.comments" />
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout xs12 wrap class="treat-section">
            <v-flex xs6 sm3 md3>
              <field-display
                class="item"
                :title="$t('recipe.created_date')"
                :text="formatDate(treatment.treatmentStart)"
              />
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-layout column>
                <field-display
                  class="item"
                  :title="$t('recipe.chronic')"
                  :text="treatment.chronic === true ? $t('common.yes') : $t('common.no')"
                />
              </v-layout>
            </v-flex>
            <v-flex xs6 sm3 md3>
              <v-layout column>
                <field-display class="item" :title="$t('recipe.num_recipe')" :text="getTotalRecipes(treatment)" />
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <div :id="'recipe-id_' + i">
          <div v-if="getTotalRecipes(treatment) > 0">
            <prescription-title
              :is-valid-collegiate-number="isValidCollegiateNumber"
              @newPrescription="insertPrescription(treatment.id)"
            />
            <v-expansion-panels v-model="treatment.expand" multiple>
              <v-expansion-panel
                v-for="(prescription, index) in treatment.prescriptions"
                :id="`prescription_${prescription.id}`"
                :key="prescription.id"
                popout
              >
                <v-expansion-panel-header>
                  <dropdown-box
                    :title="$t('recipe.recipe') + ' ' + (index + 1)"
                    :title-delete="$t('common.delete') + ' ' + $t('recipe.recipe')"
                    :title-edit="$t('common.edit') + ' ' + $t('recipe.recipe')"
                    :status="prescription.status"
                    :is-valid-collegiate-number="isValidCollegiateNumber"
                    :show-emit-recipe-button="showEmitRecipeButton(prescription.status)"
                    :status-text="getPrescriptionStatus(prescription.status)"
                    :color="getPrescriptionColor(prescription.status)"
                    :has-download="hasDownload(prescription)"
                    :is-editable="checkIfisEditable(prescription.status)"
                    :show-arrow-down="false"
                    :is-prescription="true"
                    :inactive-medicines="prescription.medicines.some(med => !med.medicine.active)"
                    :loading="loading"
                    :print-loading="printLoading"
                    @openRecipe="showRecipe(i, index)"
                    @emitRecipe="showEmitConfirm(prescription.id, treatment.user_id, treatment.patient_id)"
                    @delete="deletePendingPrescription(prescription.id)"
                    @edit="openDialogEditOrRenewOrModifyPrescription(treatment.id, prescription.id, 'is_edit')"
                    @renew="openDialogRenewPrescription({ treatment: treatment, prescription: prescription })"
                    @modify="openDialogEditOrRenewOrModifyPrescription(treatment.id, prescription.id, 'is_modify')"
                    @cancel="openDialogCancelPrescription(prescription.id)"
                    @print="print(prescription.file)"
                  />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout wrap>
                    <v-flex sm12 lg12>
                      <medicine-table
                        v-if="prescription.medicines.length > 0"
                        class="table-medicines"
                        :medicines="prescription.medicines"
                        :is-valid-collegiate-number="isValidCollegiateNumber"
                        @delMedicine="deleMedicine()"
                        @anulateMedicament="openDialogAnulateMedicament"
                        @unlockMedicament="openDialogUnlockMedicament"
                      />
                      <master-formula-table
                        v-if="prescription.masterFormulas.length > 0"
                        :master-formulas="prescription.masterFormulas"
                        :is-valid-collegiate-number="isValidCollegiateNumber"
                        @anulateMedicament="openDialogAnulateMedicament"
                        @unlockMedicament="openDialogUnlockMedicament"
                      />
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--              </div>-->
            </v-expansion-panels>
          </div>
        </div>
      </v-layout>
    </v-container>

    <v-dialog v-model="deleteTreat.dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">{{ $t('recipe.confirm_delete_treatment') }}</v-card-title>
        <v-card-text />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="deleteTreat.dialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteTreatment()">
            {{ $t('common.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="emitPresc.dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title class="headline">{{ $t('recipe.confirm_emit_prescription') }}</v-card-title>
        <v-card-text />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="emitPresc.dialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            :color="appThemePrimary"
            text
            @click="emmitPrescription(emitPresc.prescription_id, emitPresc.user_id, emitPresc.patient_id)"
          >
            {{ $t('common.emitir_receta') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar alerts -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alert.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alert.icon !== ''">{{ alert.icon }}</v-icon
      >&nbsp;{{ alert.text }}
    </v-snackbar>
    <treatment-modal
      v-model="showTreatmentModal"
      :user="user_id"
      :patient="patient_id"
      :show-change-patient="false"
      :edit-treatment-id="editTreatmentId"
      :but-color="appThemePrimary"
      :recipe-renew-data="renewData"
      @reloadTreatment="$emit('load-treatment')"
    />

    <modal :show="modalPrescriptionFormShow">
      <prescription-form
        v-if="modalPrescriptionFormShow"
        v-model="fields.prescription"
        :error-units="errorUnits"
        :error-duration="errorDuration"
        :error-frecuency="errorFrecuency"
        :error-medicine="errorMedicine"
        :is-edit-prescription="editPrescriptionForm"
        :is-renew-prescription="renewPrescriptionForm"
        :is-modify-prescription="modifyPrescriptionForm"
        :title="titleModal"
      />
      <!-- patient confirmation checkbox -->
      <div>
        <v-checkbox
          v-model="patientConfirmation"
          :error="patientConfirmationError"
          :label="`${$t('prescriptions.patientIndentityConfirmationText')}*`"
          @change="$event && (patientConfirmationError = false)"
        />
      </div>
      <!-- actions -->
      <div class="actions">
        <v-btn color="primary" dark text class="but-prescription" @click="closeAndClear">
          {{ $t('common.cancel') }}
        </v-btn>

        <v-btn
          v-show="!modifyPrescriptionForm"
          class="but-prescription"
          color="primary"
          dark
          text
          @click="checkForPatientConfirmationBeforeSave(false)"
        >
          {{ $t('common.save') }}
        </v-btn>

        <v-btn
          v-show="!modifyPrescriptionForm"
          :color="appThemePrimary"
          class="but-prescription white--text"
          depressed
          @click="showEmmitConfirm"
        >
          {{ $t('common.emitSignPrescription') }}
        </v-btn>

        <v-btn
          v-show="modifyPrescriptionForm"
          :color="appThemePrimary"
          class="but-prescription white--text"
          depressed
          @click="modifyPrescription"
        >
          {{ $t('common.modify_recipe') }}
        </v-btn>
      </div>
    </modal>

    <v-dialog v-model="cancelPrescriptionForm" persistent max-width="640px" scrollable>
      <cancel-prescription-form
        :prescription-id="cancelPrescriptionId"
        @clickedReturn="cancelPrescriptionForm = false"
        @clickedCancelPrescription="cancelPrescription"
      />
    </v-dialog>
    <v-dialog v-model="anulateMedicamentForm" persistent max-width="640px" scrollable>
      <anulate-medicament-form
        :medicament-id="anulateMedicamentId"
        :type="anulateType"
        @clickedReturn="anulateMedicamentForm = false"
        @clickedAnulateMedicament="anulateMedicament"
      />
    </v-dialog>
    <v-dialog v-model="unlockMedicamentForm" persistent max-width="640px" scrollable>
      <unlock-medicament-form
        :medicament-id="unlockMedicamentId"
        :type="unlockType"
        @clickedReturn="unlockMedicamentForm = false"
        @clickedUnlockMedicament="unlockMedicament"
      />
    </v-dialog>

    <v-dialog v-model="emmitNewPresc.dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title class="headline">{{ $t('recipe.confirm_emit_prescription') }}</v-card-title>
        <v-card-text>
          {{ $t('recipe.confirm_emmit_prescription_text') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="emmitNewPresc.dialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn :color="appThemePrimary" dark text @click="savePrescription(true)">
            {{ $t('common.emitir_receta') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 2fas pincode dialog -->
    <two-fa-pincode-dialog
      :key="pincodeKey"
      :show="showPincodePrompt"
      :text="$t('2fa.verifyCodePrompText')"
      :accept-button-text="$t('2fa.verify')"
      @close="showPincodePrompt = false"
      @pincode="handlePincode"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DropdownBox from '@/components/treatments/DropdownBox.vue';
import FieldDisplay from '@/components/treatments/FieldDisplay.vue';
import MedicineTable from '@/components/treatments/MedicineTable.vue';
import MasterFormulaTable from '@/components/treatments/MasterFormulaTable.vue';
import PrescriptionTitle from '@/components/treatments/PrescriptionTitle.vue';
import {
  deleteTreatment,
  emitPrescriptionUrl,
  getHeader,
  hipUrl,
  medicamentAnulateUrl,
  medicamentUnlockUrl,
  prescriptionCancelUrl,
  prescriptionModifyUrl,
  prescriptionSaveUrl,
} from '@/config/config';
import TreatmentModal from '@/components/treatments/TreatmentModal.vue';
import PrescriptionForm from '@/components/treatments/PrescriptionForm.vue';
import { signText } from '@/utils/AutoFirma';
import { featureToggleHandler } from '@/utils/featureToggle';
import { getPrescriptionStatus } from '@/utils/getPrescriptionStatus';
import CancelPrescriptionForm from '@/components/treatments/CancelPrescriptionForm.vue';
import AnulateMedicamentForm from '@/components/treatments/AnulateMedicamentForm.vue';
import UnlockMedicamentForm from '@/components/treatments/UnlockMedicamentForm.vue';
import TwoFaPrescriptionInterceptDialogMixin from '@/mixins/TwoFaPrescriptionInterceptDialogMixin.js';
import PrescriptionFieldsCheck from '@/mixins/PrescriptionFieldsCheck';
import { COVID_PACKAGE_LIMIT, NO_COVID_PACKAGE_LIMIT } from '@/services/medicineComputedData';
import print from 'print-js';
import DownloadFile from '@/mixins/DownloadFile';
import { deletePendingPrescription } from "@/services/electronic-prescriptions";

export default {
  name: 'TreatmentsPatient',
  components: {
    PrescriptionTitle,
    DropdownBox,
    FieldDisplay,
    MedicineTable,
    MasterFormulaTable,
    TreatmentModal,
    PrescriptionForm,
    CancelPrescriptionForm,
    AnulateMedicamentForm,
    UnlockMedicamentForm,
  },

  mixins: [TwoFaPrescriptionInterceptDialogMixin, PrescriptionFieldsCheck, DownloadFile],

  props: {
    type: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      required: false,
      default: 0,
    },
    treatments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      patientConfirmationError: false,
      patientConfirmation: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      dialog: false,
      deleteTreat: {
        dialog: false,
        id_treatment: null,
      },
      deletePresc: {
        dialog: false,
        id_presc: null,
      },
      emitPresc: {
        dialog: false,
        prescription_id: null,
        user_id: null,
        patient_id: null,
      },
      alert: {
        text: '',
        icon: '',
        color: 'info',
      },
      snackbar: false,
      timeout: 5000,
      mode: '',
      user_id: 0,
      showTreatmentModal: false,
      patient_id: 0,
      editTreatmentId: 0,
      editPrescriptionForm: false,
      modifyPrescriptionForm: false,
      showPrescriptionForm: false,
      renewPrescriptionForm: false,
      renewData: null,
      cancelPrescriptionForm: false,
      cancelPrescriptionId: 0,
      anulateMedicamentForm: false,
      anulateMedicamentId: 0,
      anulateType: '',
      unlockMedicamentForm: false,
      unlockMedicamentId: 0,
      unlockType: '',
      idTreatmentPrescription: null,
      editingPrescriptionId: null,
      fields: this.getPrescriptionFieldsInitialData(),
      titleModal: this.$t('recipe.create_prescription'),
      errorMessageMaxDays: '',
      errorUnits: false,
      errorDuration: false,
      errorFrecuency: false,
      errorMedicine: false,
      emmitNewPresc: {
        dialog: false,
      },
      isValidCollegiateNumber: this.setIsValidCollegiateNumber(),
      isCompanyEmittingPrescriptionsBySMS: JSON.parse(window.localStorage.getItem('smsNotificationsEnabled')),
      loading: false,
      printLoading: false,
    };
  },

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),

    ...mapGetters({
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      twoFaEnabled: 'account/twoFaEnabled',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),

    recipeEmittedMessage() {
      const emitMethodsArray = [this.$t('success.emit_method_email')];
      this.isCompanyEmittingPrescriptionsBySMS && emitMethodsArray.push(this.$t('success.emit_method_sms'));
      const emitMethods = emitMethodsArray.join(` ${this.$t('common.and')} `);
      return this.$t('success.recipe_emmited', { methods: emitMethods });
    },

    modalPrescriptionFormShow() {
      return this.showPrescriptionForm || this.editPrescriptionForm || this.modifyPrescriptionForm;
    },

    maxPackageLimitHomologation() {
      return this.repHomologation ? NO_COVID_PACKAGE_LIMIT : COVID_PACKAGE_LIMIT;
    },
  },

  methods: {
    ...mapActions('prescription', ['masterFormulasValidate']),
    ...mapActions('overlay', ['handleOverlay']),

    checkForPatientConfirmationBeforeSave(val) {
      if (this.failCheckMinimumFields()) {
        this.$toast.error(this.$t('errors.form_invalid'));
        return;
      }

      if (this.failCheckMaxPackages()) {
        const textAlert = this.$t('prescriptions.maxPackagesWarningNoAdjust', {
          limit: this.maxPackageLimitHomologation,
        });
        this.$toast.error(textAlert);
        return;
      }

      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return;
      }

      this.savePrescription(val);
    },

    formatDate(inputDate) {
      if (!inputDate) return null;

      const date = new Date(inputDate);
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      return day + '/' + month + '/' + date.getFullYear();
    },

    expandRecipe(item) {
      const element = document.getElementById('recipe-id_' + item);
      if (element.classList.contains('recipeExpand')) {
        element.classList.remove('recipeExpand');
      } else {
        element.classList.add('recipeExpand');
      }
    },

    openDialogDeleteTreatment(id) {
      this.deleteTreat.dialog = true;
      this.deleteTreat.id_treatment = id;
    },

    async deletePendingPrescription(id) {
      try {
        await deletePendingPrescription(id);
        this.$toast.success(this.$t('common.deleted_pending_electronic_recipe'));
        this.$emit('load-treatment');
      } catch (e) {
        this.$toast.error(this.$t('common.failed_delete_pending_electronic_recipe'));
      }
    },

    openDialogCancelPrescription(prescriptionId) {
      this.cancelPrescriptionForm = true;
      this.cancelPrescriptionId = prescriptionId;
    },

    async cancelPrescription(prescriptionId, reasonDescription) {
      this.cancelPrescriptionForm = false;
      await this.handleOverlay(true);
      this.$http
        .post(prescriptionCancelUrl, { id: prescriptionId, reason: reasonDescription }, { headers: getHeader() })
        .then(response => {
          if (response.status === 204) {
            this.cancelPrescriptionId = 0;

            this.$toast.success(this.$t('recipe.canceled_message'));
            this.$emit('load-treatment');
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(async () => {
          await this.handleOverlay(false);
        });
    },

    openDialogAnulateMedicament(external_id, type) {
      this.anulateMedicamentForm = true;
      this.anulateMedicamentId = external_id;
      this.anulateType = type;
    },
    async anulateMedicament(medicamentId, reason, type) {
      this.anulateMedicamentForm = false;
      await this.handleOverlay(true);
      this.$http
        .post(medicamentAnulateUrl, { id: medicamentId, reason: reason, type: type }, { headers: getHeader() })
        .then(response => {
          if (response.status === 204) {
            this.anulateMedicamentId = 0;

            this.$toast.success(this.$t('recipe.anulate_message'));
            this.$emit('load-treatment');
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error.message) : this.$t('errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(async () => {
          await this.handleOverlay(false);
        });
    },
    openDialogUnlockMedicament(external_id, type) {
      this.unlockMedicamentForm = true;
      this.unlockMedicamentId = external_id;
      this.unlockType = type;
    },
    async unlockMedicament(medicamentId, reason, type) {
      this.unlockMedicamentForm = false;
      await this.handleOverlay(true);
      this.$http
        .post(medicamentUnlockUrl, { id: medicamentId, reason: reason, type: type }, { headers: getHeader() })
        .then(response => {
          if (response.status === 204) {
            this.unlockMedicamentId = 0;
            this.$toast.success(this.$t('recipe.unlock_message'));
            this.$emit('load-treatment');
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error.message) : this.$t('errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(async () => {
          await this.handleOverlay(false);
        });
    },

    async openDialogEditOrRenewOrModifyPrescription(treatment_id, prescription_id, action) {
      this.loading = true;
      let is_edit = false;
      let is_renew = false;
      let is_modify = false;

      if ('is_edit' === action) {
        is_edit = true;
      }
      if ('is_renew' === action) {
        is_renew = true;
      }
      if ('is_modify' === action) {
        is_modify = true;
      }

      this.titleModal = is_edit ? this.$t('recipe.edit_prescription') : this.$t('recipe.renew_prescription');
      if (is_modify) {
        this.titleModal = this.$t('common.modify_recipe');
      }
      if (!action) {
        this.titleModal = this.$t('recipe.create_prescription');
      }
      this.idTreatmentPrescription = treatment_id;
      this.editingPrescriptionId = prescription_id;

      const data = this.getPrescriptionFieldsInitialData();
      try {
        const response = await fetch(prescriptionSaveUrl + '/' + prescription_id, {
          method: 'GET',
          headers: getHeader(),
        }).then(res => res.json());

        if (response.success) {
          data.prescription.type = response.data.type;
          data.prescription.tinCode = response.data.tinCode;
          data.prescription.masterFormula = response.data.masterFormula;

          const medicines = [];
          response.data.medicines.forEach(function(medicineData) {
            medicines.push({
              medicineId: medicineData.medicine.id,
              name: medicineData.medicine.name,
              units: medicineData.units,
              posology: medicineData.posology,
              posology_measure: medicineData.posology_measure,
              duration: medicineData.duration,
              duration_measure: medicineData.duration_measure,
              guideline: medicineData.guideline,
              comments: medicineData.comments,
              diagnostic: medicineData.diagnostic,
              pharmacist_warnings: medicineData.pharmacist_warnings,
              date_start: medicineData.date_start,
              date_end: medicineData.date_end,
              dcsa: medicineData.medicine.codeDcsa,
              narcotic: medicineData.medicine.narcotic,
              administrationUnit: medicineData.medicine.administrationUnit,
            });
          });

          data.prescription.medicines = medicines;
          if (is_renew || is_modify) {
            const masterFormulasWithoutId = response.data.masterFormulas.map(item => {
              delete item.id;
              return item;
            });
            data.prescription.masterFormulas = masterFormulasWithoutId;
          } else {
            data.prescription.masterFormulas = response.data.masterFormulas;
          }
          this.masterFormulasValidate(data.prescription.masterFormulas);

          this.editPrescriptionForm = is_edit;
          this.modifyPrescriptionForm = is_modify;

          setTimeout(_ => (this.fields.prescription = data.prescription), 500);
        } else {
          this.$toast.error(this.$t('errors.try_again'));
        }
      } catch (e) {
        throw new Error(e.message);
      }
      this.loading = false;
    },

    async openDialogRenewPrescription(recipe) {
      this.editTreatmentId = 0;
      this.user_id = this.authUser.id;
      this.patient_id = parseInt(this.$route.params.id);

      const data = this.getPrescriptionFieldsInitialData();
      try {
        const response = await fetch(prescriptionSaveUrl + '/' + recipe.prescription.id, {
          method: 'GET',
          headers: getHeader(),
        }).then(res => res.json());

        if (response.success) {
          data.prescription.type = response.data.type;
          data.prescription.tinCode = response.data.tinCode;
          data.prescription.masterFormula = response.data.masterFormula;

          const medicines = [];
          response.data.medicines.forEach(function(medicineData) {
            medicines.push({
              medicineId: medicineData.medicine.id,
              name: medicineData.medicine.name,
              units: medicineData.units,
              posology: medicineData.posology,
              posology_measure: medicineData.posology_measure,
              duration: medicineData.duration,
              duration_measure: medicineData.duration_measure,
              guideline: medicineData.guideline,
              comments: medicineData.comments,
              diagnostic: medicineData.diagnostic,
              pharmacist_warnings: medicineData.pharmacist_warnings,
              date_start: medicineData.date_start,
              date_end: medicineData.date_end,
              dcsa: medicineData.medicine.codeDcsa,
              narcotic: medicineData.medicine.narcotic,
              administrationUnit: medicineData.medicine.administrationUnit,
            });
          });

          data.prescription.medicines = medicines;
          data.prescription.masterFormulas = response.data.masterFormulas.map(item => {
            delete item.id;
            return item;
          });
          this.masterFormulasValidate(data.prescription.masterFormulas);

          this.renewData = { ...recipe, prescription: data.prescription };

          setTimeout(_ => (this.fields.prescription = data.prescription), 500);
        } else {
          this.$toast.error(this.$t('errors.try_again'));
        }
      } catch (e) {
        throw new Error(e.message);
      }

      this.showTreatmentModal = true;
    },

    downloadPdf(url) {
      return this.download({
        url: url,
        name: this.description,
        type: 'application/pdf',
        open: false,
        openInNewTab: false,
        download: false,
      });
    },

    async print(file) {
      this.printLoading = true;
      const url = new URL(file);
      const downloadedPdf = await this.downloadPdf(`${hipUrl}/${url.pathname.split('/')[2]}`);
      print({
        printable: downloadedPdf,
      });
      this.printLoading = false;
    },

    openDialogEditTreatment(id) {
      this.showTreatmentModal = true;
      this.user_id = this.authUser.id;
      this.patient_id = parseInt(this.$route.params.id);
      this.editTreatmentId = id;
    },

    showRecipe(treatmentIndex, id) {
      const elemRecipe = document.getElementById('presc-id_' + treatmentIndex + '_' + id);
      if (typeof elemRecipe !== 'undefined') {
        if (elemRecipe.classList.contains('recipeExpand')) {
          elemRecipe.classList.remove('recipeExpand');
        } else {
          elemRecipe.classList.add('recipeExpand');
        }
      }
    },

    deleMedicine() {
      console.log('TODO: Eliminar Medicina, ahora mismo no sabemos si se podrá eliminar de nodofarma');
    },

    async deleteTreatment() {
      this.deleteTreat.dialog = false;
      const url = deleteTreatment + this.deleteTreat.id_treatment;
      await this.sendDeleteRequest(url, this.$t('success.delete_treatment'));
    },

    async sendDeleteRequest(url, message) {
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: getHeader(),
        }).then(res => res.json());

        if (response.success) {
          this.$toast.success(message);
          this.$emit('load-treatment');
        } else {
          this.$toast.error(this.$t('errors.try_again'));
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },

    getTotalRecipes(treatment) {
      let countRecipes = 0;

      if (typeof treatment.prescriptions !== 'undefined') {
        countRecipes = treatment.prescriptions.length;
      }

      return countRecipes;
    },

    getPrescriptionColor(status) {
      let color = 1;
      if ([0, 1, 2, 3].includes(status)) {
        color = status + 1;
      }
      return color;
    },

    getPrescriptionStatus(status) {
      return getPrescriptionStatus(status);
    },

    getTreatmentStatusText(treatment) {
      let response = this.$t('recipe.inactive');
      const treatmentEnd = Date.parse(treatment.treatmentEnd);
      const now = Date.now();

      if (treatmentEnd > now) {
        response = this.$t('recipe.active');
      }

      return response;
    },

    getTreatmentStatusColor(treatment) {
      let response = 2;
      const treatmentEnd = Date.parse(treatment.treatmentEnd);
      const now = Date.now();

      if (treatmentEnd > now) {
        response = 3;
      }

      return response;
    },

    showAlert(text, color, icon) {
      this.alert.text = text;
      this.alert.color = color;
      this.alert.icon = icon;
      this.snackbar = true;
    },

    insertPrescription(treatmentId) {
      this.reset();
      this.showPrescriptionForm = true;
      this.idTreatmentPrescription = treatmentId;
    },

    getPrescriptionFieldsInitialData() {
      return {
        treatment: {
          diagnostic: '',
          chronic: false,
          confidential: false,
          treatmentStart: new Date(),
          treatmentEnd: null,
          comments: '',
        },

        prescription: {
          type: 1,
          tinCode: '',
          masterFormula: '',
          masterFormulas: [],
          medicines: [
            {
              medicineId: null,
              units: 0,
              posology: '',
              posology_measure: 'hours',
              duration: '',
              duration_measure: 'days',
              guideline: '',
              comments: '',
              diagnostic: '',
              date_start: '',
              date_end: '',
            },
          ],
        },
      };
    },

    setIsValidCollegiateNumber() {
      const collegiateNumber =
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['collegiateNumber'];
      return typeof collegiateNumber !== 'undefined' && collegiateNumber.length === 9;
    },

    reset() {
      this.fields = this.getPrescriptionFieldsInitialData();
    },

    close() {
      this.showPrescriptionForm = false;
      this.editPrescriptionForm = false;
      this.modifyPrescriptionForm = false;
    },

    closeAndClear() {
      this.close();
      this.fields.prescription = {
        type: 1,
        tinCode: '',
        masterFormula: '',
        masterFormulas: [],
        medicines: [
          {
            medicineId: null,
            units: 0,
            posology: '',
            posology_measure: 'hours',
            duration: '',
            duration_measure: 'days',
            guideline: '',
            comments: '',
            diagnostic: '',
            date_start: '',
            date_end: '',
          },
        ],
      };
    },

    async modifyPrescription() {
      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return;
      }

      this.close();
      await this.handleOverlay(true);
      try {
        const newPrescription = this.convertObjectToArray({
          treatmentId: this.idTreatmentPrescription,
          prescriptionId: this.editingPrescriptionId,
          patientConfirmed: this.patientConfirmation,
          ...this.fields.prescription,
        });

        const response = await this.$http.post(prescriptionModifyUrl, newPrescription, { headers: getHeader() });
        if (response.status === 204) {
          this.editingPrescriptionId = null;
          this.reset();
          this.$emit('load-treatment');
          this.showMsgOk();
        } else {
          this.$toast.error(this.$t('errors.try_again'));
        }
      } catch (err) {
        const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
        this.$toast.error(textAlert);
      } finally {
        await this.handleOverlay(false);
      }
    },

    async savePrescription(emmit) {
      this.close();
      this.emmitNewPresc.dialog = false;
      await this.handleOverlay(true);

      let response = [];

      const arrPresc = this.convertObjectToArray({
        treatmentId: this.idTreatmentPrescription,
        ...this.fields.prescription,
        patientConfirmed: this.patientConfirmation,
      });

      if (this.editPrescriptionForm) {
        const defaulPosologyMeasure = 'hours';
        const defaulDurationMeasure = 'days';
        arrPresc.medicines.forEach(function(med) {
          if (med.posology_measure === '') {
            med.posology_measure = defaulPosologyMeasure;
          }
          if (med.duration_measure === '') {
            med.duration_measure = defaulDurationMeasure;
          }
        });
        try {
          response = await this.sendUpdateRequest(prescriptionSaveUrl + '/' + this.editingPrescriptionId, arrPresc);
          this.editPrescriptionForm = false;
          this.editingPrescriptionId = null;
        } catch (e) {
          console.info(e);
          this.$toast.error(this.$t('hiring.apiError'));
        }
      } else {
        try {
          response = await this.sendRequest(prescriptionSaveUrl, { prescriptions: arrPresc });
        } catch (e) {
          console.info(e);
          this.$toast.error(this.$t('hiring.apiError'));
        }
      }

      this.reset();
      if (response?.success) {
        if (emmit) {
          await this.emmitPrescription(response.data.id, this.authUser.id, parseInt(this.$route.params.id));
        } else {
          await this.handleOverlay(false);
          this.showMsgOk();
        }
        this.$emit('load-treatment');
      } else {
        await this.handleOverlay(false);
        if (this.errorMessageMaxDays) {
          this.showPincodePrompt = false;
          this.$toast.error(this.$t(this.errorMessageMaxDays) || this.errorMessageMaxDays);
          return;
        }
        this.showMsgKo();
      }
    },

    async sendRequest(url, data) {
      try {
        const { data: requestData } = await this.$http.post(url, data, { headers: this.getRequestHeaders() });

        return requestData;
      } catch (e) {
        const {
          body: {
            error: { message },
          },
        } = e || {};
        this.errorMessageMaxDays = message;
        return false;
      }
    },

    async sendUpdateRequest(url, data) {
      try {
        const response = await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: this.getRequestHeaders(),
        }).then(res => res.json());

        if (!response.success) {
          throw new Error(response.message);
        }

        return response;
      } catch (e) {
        throw new Error(e.message);
      }
    },

    convertObjectToArray(data) {
      const arr = {};
      Object.keys(data).map(function(key) {
        if (typeof data[key] !== 'undefined') {
          arr[key] = data[key];
        }
      });

      return arr;
    },

    showMsgKo() {
      this.$toast.error(this.$t('errors.try_again'));
    },

    showMsgKoWithMsg(msg) {
      this.$toast.error(msg);
    },

    showMsgOk() {
      this.$toast.success(this.$t('success.save_common'));
    },

    hasDownload(prescription) {
      return prescription.tinCode !== '' && prescription.file !== '' && prescription.file !== null;
    },

    showEmitRecipeButton(status) {
      let response = false;

      if (status === 1) {
        response = true;
      }

      return response;
    },

    checkIfisEditable(status) {
      let response = true;

      if (status === 2) {
        response = false;
      }

      return response;
    },

    showMsgEmmitedOk() {
      this.$toast.success(this.recipeEmittedMessage);
    },

    showEmitConfirm(prescription_id, user_id, patient_id) {
      this.emitPresc.prescription_id = prescription_id;
      this.emitPresc.user_id = user_id;
      this.emitPresc.patient_id = patient_id;

      if (this.useNewAccountPage && this.twoFaEnabled) {
        this.showPincodePrompt = true;
        this.methodToProceedWith = {
          name: 'emmitPrescription',
          params: [],
        };
        return;
      }

      this.emitPresc.dialog = true;
    },

    async emmitPrescription(
      prescription_id = this.emitPresc.prescription_id,
      user_id = this.emitPresc.user_id,
      patient_id = this.emitPresc.patient_id
    ) {
      this.hidePincodeDialog();
      this.emitPresc.dialog = false;
      await this.handleOverlay(true);
      this.getPrescriptionDataSign(prescription_id)
        .then(res => {
          if (res.body.data) {
            return res.body.data;
          }
          return {};
        })
        .then(async dataSign => {
          const signData = featureToggleHandler('signature_enabled') ? await signText(JSON.stringify(dataSign)) : [];

          this.$http
            .post(
              emitPrescriptionUrl,
              { id: prescription_id, user_id: user_id, patient_id: patient_id, sign_data: signData },
              { headers: this.getRequestHeaders() }
            )
            .then(response => {
              if (response.status === 200 && parseInt(response.body.status) === 1) {
                this.showMsgEmmitedOk();
                this.$emit('load-treatment');
              } else if (response.status === 200 && parseInt(response.body.status) === 2) {
                this.showMsgKoWithMsg(this.$t('errors.emit_e_prescription'));
              } else {
                this.showMsgKo();
              }
            })
            .catch(err => {
              if (err?.data?.error?.code === 'invalid_treatment_status') {
                this.$toast.error(this.$t('common.alreadyDiscardedPrescription'), {
                  timeout: 10000,
                });
              } else {
                const textAlert = this.$t(err?.data?.error?.message || 'errors.try_again');
                this.$toast.error(textAlert);
              }
            })
            .finally(async () => {
              this.resetPincodeDialog();
              await this.handleOverlay(false);
            });
        });
    },

    showEmmitConfirm() {
      if (this.failCheckMinimumFields()) {
        this.$toast.error(this.$t('errors.form_invalid'));
        return;
      }

      if (this.failCheckMaxPackages()) {
        const textAlert = this.$t('prescriptions.maxPackagesWarningNoAdjust', {
          limit: this.maxPackageLimitHomologation,
        });
        this.$toast.error(textAlert);
        return;
      }

      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return;
      }

      if (this.useNewAccountPage && this.twoFaEnabled) {
        this.showPincodePrompt = true;

        this.methodToProceedWith = {
          name: 'savePrescription',
          params: [true],
        };
        return;
      }

      this.emmitNewPresc.dialog = true;
    },

    getPrescriptionDataSign(prescription_id) {
      return this.$http.get(prescriptionSaveUrl + '/' + prescription_id, { headers: this.getRequestHeaders() });
    },
  },
};
</script>

<style lang="scss" scoped>
.treatment {
  border: 1px solid lightgray;
  margin-top: 1rem;
  padding-bottom: 1em;
}

.recipeExpand {
  display: none;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}

.title-prescription ::v-deep .v-toolbar__title.mt-3 {
  margin-top: 5px !important;
}

@media (max-width: 575px) {
  .treatment {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  ::v-deep .v-expansion-panel__header {
    height: 77px !important;
  }

  .title-prescription ::v-deep .v-toolbar__title {
    margin-left: -20px !important;
  }
}

@media (min-width: 576px) and (max-width: 740px) {
  .treatment {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .table-medicines {
    margin-top: 2em;
  }
}

.treat-section {
  padding-left: 1em;
}

.title-prescription ::v-deep .v-toolbar__content {
  height: unset !important;
}

.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
